import React from 'react';

import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import { sizing } from '@material-ui/system';



class InPatientStayEditScreen extends React.Component {

  render() {
    return (
      <>
        <h3>Neuer Eintrag</h3>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField name="date_start" value={this.props.data["date_start"] || ""} onChange={this.props.onChange} label="Von" />
          </Grid>
          <Grid item xs={6}>
            <TextField name="date_end" value={this.props.data["date_end"] || ""} onChange={this.props.onChange} label="Bis" />
          </Grid>
          <Grid item>
            <TextField name="hospital" value={this.props.data["hospital"] || ""} onChange={this.props.onChange} label="Klinik" />
          </Grid>
          <Grid item>
            <TextField name="diagnosis" value={this.props.data["diagnosis"] || ""} onChange={this.props.onChange} label="Diagnose" />
          </Grid>
          <Grid item>
            <TextField multiline rows="3" name="therapy" value={this.props.data["therapy"] || ""} onChange={this.props.onChange} label="Therapie" />
          </Grid>
        </Grid><br/><br/>
        <Button variant="contained" color="primary" onClick={this.props.updateFunction} disableElevation>Speichern</Button>
      </>
    )
  }
}

export default InPatientStayEditScreen;