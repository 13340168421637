import React from 'react';

import { Link } from "react-router-dom";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { useTheme } from '@material-ui/core/styles';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './MeasurementCard.scss';



export default function MeasurementCard(props) {
   const theme = useTheme(); 

   return (
      <Link to={props.to} className="measurement-card-wrapper">
         <Card className="measurement-card" style={{backgroundColor: theme.palette.primary.main}}>
            <CardContent>
               <FontAwesomeIcon icon={props.icon} size="3x"/>
               <div>
                  {props.title}
               </div>
            </CardContent>
            <CardActions className="measurement-card-footer">
               Ansehen
            </CardActions>
         </Card>
      </Link>
   )
}