import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import GravidogramEntry from './GravidogramEntry';
import GravidogramEdit from './GravidogramEdit';

import DataContext from 'services/DataContext';
import Globals from 'services/Globals';
import axios from 'axios';


export default function GravidogramData(props) {

  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(Globals.SERVER_URL + "/dmr/gravidogram").then(function (res) {
      if ( !res.data ) return;
      setData(res.data);
    }).catch(function (err) {
      console.log(err);
    });
  }, []);

  return (
    <>
      <div className="gravidogram-container">
        {
          data &&
          data.map((value, index) => {
            return (
              <GravidogramEntry key={index} data={value}></GravidogramEntry>
            )
          })
        }
      </div>
    </>
);  
}