import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class PainManagement extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Geburt &gt; Schmerztherapie unter Geburt</div>
            
            <Typography variant="h1">Schmerztherapie unter Geburt</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Was kann man gegen die Schmerzen unter der Geburt tun? Nach dem Lesen dieses Artikels kennen Sie die am besten wirksamen Schmerztherapien.</b></p>

            <p>Fast alle Frauen, die ihr erstes Kind erwarten, haben viele Fragen zur Entbindung. Viele Informationen zum allgemeinen Ablauf und den verschiedenen Entbindungsformen habe ich in der letzten Audiofile bereits gegeben. Heute geht es um das Thema Schmerztherapie unter Geburt. Welche Formen der Schmerztherapie gibt es? Muss eine Entbindung schmerzhaft sein? Was kann ich oder mein Partner machen, um mir die Geburt zu erleichtern?</p>
            <Typography variant="h2">Nicht alle Krankenhäuser bieten alle Schmerztherapien</Typography>
            <p>Vorab möchte ich Ihnen die Information geben, dass nicht jede Form der Schmerztherapie in jedem Krankenhaus angeboten wird, daher erkundigen Sie sich vorab bei Ihrer Geburtsklinik, wenn Sie sich für ein bestimmtes Verfahren interessieren. Zudem ist nicht jede Form der Schmerztherapie zu jedem Zeitpunkt der Entbindung sinnvoll, daher werden Ihre Hebamme und das Ärzteteam Sie jeweils beraten, welches die sinnvollste Methode für die aktuelle Situation ist. </p>
            <Typography variant="h2">Wenn die Geburt beginnt</Typography>
            <p>Regelmäßige und kräftige Wehen sind ein gutes Zeichen, dass die Geburt beginnt und voranschreitet. Viele Frauen kommen mit der Wehentätigkeit gut zurecht. Häufig hilft es, sich auf sich und sein Kind zu konzentrieren, während der Wehe diese zu veratmen, also langsam tief ein- und auszuatmen, und sich zwischen den Wehen bewusst zu entspannen. Versuchen Sie während der Wehe nicht zu verkrampfen. Sie dürfen jede Körperposition einnehmen, die Sie möchten. Manche Frauen möchten sich bewegen, z.B. laufen oder auf einem Pezziball vorsichtig wippen. Einige können am besten in Seitenlage auf dem Bett entspannen. Andere wiederum bevorzugen ein warmes Bad, das ihnen hilft, locker zu lassen. Welche Position Ihnen in dieser Situation am besten hilft, finden Sie durch Ausprobieren heraus und Ihre Hebamme wird Ihnen Tipps geben und Sie diesbezüglich beraten.  </p>
            <Typography variant="h2">Während der Eröffnungsphase</Typography>
            <p>Für die Eröffnungsphase sind häufig krampflösende Medikamente hilfreich. Hier wird zum Beispiel Buscopan verabreicht. Dieses wird häufig auch in Kombination mit dem Schmerzmittel Paracetamol verwendet. Reichen diese Medikamente nicht mehr aus, stehen stärkere Schmerzmedikamente, sogenannte Opiate zur Verfügung. Diese können intramuskulär, also in den Muskel oder über die Vene verabreicht werden. Sie wirken allerdings nicht nur bei der Mutter, sondern auch beim Feten und können beim Neugeborenen Probleme mit der Atmung machen. Daher werden sie kurz vor der Entbindung nicht mehr eingesetzt. </p>
            <p>Eine weitere Möglichkeit während der Entbindung ist Livopan. Dies ist eine Kombination von medizinischem Lachgas und Sauerstoff. Sie atmen es in der Wehe ein, hier führt es in kurzer Zeit in der Wehe zur Schmerzlinderung und die Wirkung ist kurz nach der Wehe, wenn Sie wieder normale Raumluft einatmen, wieder weg. Damit können Sie es selbst dosieren. </p>
            <p>Sicherlich haben Sie auch schon von der Möglichkeit einer PDA, einer sogenannten Periduralanästhesie gehört. Hierfür wird ein dünner Katheter am Rücken in den Wirbelkanal eingeführt. Über diesen Katheter kann in regelmäßigen Abständen ein Schmerzmedikament verabreicht werden. Mit diesem Verfahren können häufig die Schmerzen deutlich gelindert oder komplett genommen werden. Allerdings ist häufig die Kraft in den Beinen und die Beweglichkeit eingeschränkt. Falls es im Geburtsverlauf zu einem Kaiserschnitt kommt, kann die PDA auch beim Kaiserschnitt zur Schmerztherapie eingesetzt werden. </p>
            <Typography variant="h2">Bei einem Kaiserschnitt</Typography>
            <p>Ist aus einem bestimmten Grund ein geplanter Kaiserschnitt notwendig, erfolgt dieser im Regelfall mit einer Spinalanästhesie. Dies ist ebenso wie die PDA ein rückenmarksnahes Verfahren, bei dem ein Schmerzmedikament in den Spinalkanal gespritzt wird. Das heißt, Sie sind während des Kaiserschnittes wach, haben keine Schmerzen und können im optimalen Fall Ihr Kind noch während der Operation das erste Mal in den Arm nehmen. </p>
            <p>Manchmal ist aus verschiedenen Gründen keine rückenmarksnahe Anästhesie, also keine PDA oder Spinalanästhesie möglich. Dies ist zum Beispiel bei einer bekannten Blutungsneigung oder Bluterkrankung der Mutter der Fall oder aber auch, wenn es sich um eine akute Notfallsituation für Mutter oder Kind handelt und die Zeit für diese Form der Schmerztherapie nicht ausreicht. In diesen Fällen wird ein Kaiserschnitt in einer Vollnarkose durchgeführt, das heißt, Sie schlafen während der Operation. Die Vollnarkose wird, wenn möglich vermieden, da zum einen das Kind einen Teil des Narkosemittels mitbekommt und dann nach der Entbindung kurzzeitig Anpassungsstörungen haben kann, zum anderen, da Sie erst nachdem Sie aus der Narkose aufgewacht sind mit Ihrem Kind Kontakt aufnehmen und bonden können. Sie sehen, es stehen Ihnen viele verschiedene Möglichkeiten zur Schmerzlinderung zur Verfügung.</p>

            <p><b>Daher keine Angst vor der Entbindung. Ihre Hebamme und Ihr Ärzteteam empfehlen Ihnen in der jeweiligen Situation die richtige Schmerztherapie.</b></p>
          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default PainManagement;