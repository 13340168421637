import React from 'react';
import { toggleMenu } from 'helpers/MenuHelper';

/**
 * Note: MenuToggle only aims to provide functionality
 * to toggle the menu by using this component, however not
 * to style the respective button. The styling should either be
 * done in the parent or child components.
 */

export default class MenuToggle extends React.Component {

  render(props) {
      return (
        <div onClick={toggleMenu}>
          {this.props.children}
        </div>
      )
  }
}