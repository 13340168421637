import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import NeverRarelySometimesFrequentAlways from './StandardizedAnswerOptions/NeverRarelySometimesFrequentAlways'

import withNotifications from 'services/NotificationService/withNotifications';

import '../Questionnaire.scss';
import QuestionnaireHeadNotice from 'components/QuestionnaireHeadNotice/QuestionnaireHeadNotice';

class CSS_15_FU extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">CSS-15</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/16) * 100 } />
          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />
          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "css15_fu_1": 1,
                "css15_fu_2": 2,
                "css15_fu_3": 3,
                "css15_fu_4": 4,
                "css15_fu_5": 5,
                "css15_fu_6": 6,
                "css15_fu_7": 7,
                "css15_fu_8": 8,
                "css15_fu_9": 9,
                "css15_fu_10": 10,
                "css15_fu_11": 11,
                "css15_fu_12": 12,
                "css15_fu_13": 13,
                "css15_fu_14": 14,
                "css15_fu_15": 15,
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <div style={{textAlign: "center", marginTop: "1em"}}>
                <p>Bitte beantworten Sie die nachfolgenden Fragen. Wählen Sie die Antwortoption, die am ehesten zutrifft.</p>
                <p>Bitte beziehen Sie Ihre Antworten auf die <b>letzten vier Wochen.</b></p>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Fragebogen starten</Button>
                </div>
              </StepperPage>
            </Container>

            { this.state.activeStep >= 1 &&
              <QuestionnaireHeadNotice>
                Bitte beziehen Sie Ihre Antworten auf die letzten vier Wochen.
              </QuestionnaireHeadNotice>
            }
            <div className="questionnaire-body">
              <Container maxWidth="lg">
                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wenn ich eine unerklärte Körperempfindung wahrnehme, suche ich danach im Internet.</FormLabel>
                    <CustomErrorMessage name="css15_fu_1" />
                    <RadioGroup aria-label="position" name="css15_fu_1" value={this.props.values.css15_fu_1} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentAlways />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={2}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Ich suche mehrfach die gleichen Symptome im Internet.</FormLabel>
                    <CustomErrorMessage name="css15_fu_2" />
                    <RadioGroup aria-label="position" name="css15_fu_2" value={this.props.values.css15_fu_2} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentAlways />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={3}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Die Internetsuche nach Informationen über Symptome oder vermutete Krankheiten stört die Suche nach anderen Online-Informationen (z.B. für meine Arbeit, mein Studium oder die Schule).</FormLabel>
                    <CustomErrorMessage name="css15_fu_3" />
                    <RadioGroup aria-label="position" name="css15_fu_3" value={this.props.values.css15_fu_3} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentAlways />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={4}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Die Internetsuche nach Informationen über Symptome oder vermutete Krankheiten stört meine Online-Freizeitaktivitäten (z.B. Filme streamen).</FormLabel>
                    <CustomErrorMessage name="css15_fu_4" />
                    <RadioGroup aria-label="position" name="css15_fu_4" value={this.props.values.css15_fu_4} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentAlways />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={5}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Ich messe der Einschätzung meines Arztes mehr Gewicht bei als meinen eigenen Online-Nachforschungen.</FormLabel>
                    <CustomErrorMessage name="css15_fu_5" />
                    <RadioGroup aria-label="position" name="css15_fu_5" value={this.props.values.css15_fu_5} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentAlways />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={6}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Ich gerate in Panik, wenn ich online lese, dass ein Symptom, das ich habe, bei einer seltenen oder ernsten Krankheit vorkommt.</FormLabel>
                    <CustomErrorMessage name="css15_fu_6" />
                    <RadioGroup aria-label="position" name="css15_fu_6" value={this.props.values.css15_fu_6} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentAlways />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={7}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Die Internetsuche nach Informationen über Symptome oder vermutete Krankheiten stört meine Arbeit am Computer (z.B. Schreiben von Mails, Arbeit an Dokumenten oder Berechnungen).</FormLabel>
                    <CustomErrorMessage name="css15_fu_7" />
                    <RadioGroup aria-label="position" name="css15_fu_7" value={this.props.values.css15_fu_7} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentAlways />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={8}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Ich bespreche die Ergebnisse meiner Online-Suche mit meinem Arzt oder Apotheker.</FormLabel>
                    <CustomErrorMessage name="css15_fu_8" />
                    <RadioGroup aria-label="position" name="css15_fu_8" value={this.props.values.css15_fu_8} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentAlways />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={9}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Nachdem ich Informationen über Symptome oder vermutete Krankheiten gesucht habe, fühle ich mich ängstlicher und gestresster als vorher.</FormLabel>
                    <CustomErrorMessage name="css15_fu_9" />
                    <RadioGroup aria-label="position" name="css15_fu_9" value={this.props.values.css15_fu_9} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentAlways />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={10}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Die Internetsuche nach Informationen über Symptome oder vermutete Krankheiten führt dazu, dass ich einen Facharzt/Spezialisten aufsuche.</FormLabel>
                    <CustomErrorMessage name="css15_fu_10" />
                    <RadioGroup aria-label="position" name="css15_fu_10" value={this.props.values.css15_fu_10} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentAlways />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={11}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Es beruhigt mich, die Online-Informationen über vermutete Krankheiten mit meinem Arzt zu besprechen.</FormLabel>
                    <CustomErrorMessage name="css15_fu_11" />
                    <RadioGroup aria-label="position" name="css15_fu_11" value={this.props.values.css15_fu_11} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentAlways />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={12}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Ich vertraue der Diagnose meines Arztes mehr als meiner eigenen Online-Selbstdiagnose.</FormLabel>
                    <CustomErrorMessage name="css15_fu_12" />
                    <RadioGroup aria-label="position" name="css15_fu_12" value={this.props.values.css15_fu_12} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentAlways />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={13}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wenn ich online Symptome oder Krankheiten suche, besuche ich sowohl vertrauenswürdige Seiten als auch Betroffenen-, bzw. Laienforen.</FormLabel>
                    <CustomErrorMessage name="css15_fu_13" />
                    <RadioGroup aria-label="position" name="css15_fu_13" value={this.props.values.css15_fu_13} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentAlways />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={14}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Nachdem ich Informationen über Symptome oder vermutete Krankheiten gesucht habe, habe ich Schwierigkeiten einzuschlafen.</FormLabel>
                    <CustomErrorMessage name="css15_fu_14" />
                    <RadioGroup aria-label="position" name="css15_fu_14" value={this.props.values.css15_fu_14} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentAlways />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={15}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wenn mein Arzt die Ergebnisse meiner eigenen Online-Nachforschungen für falsch hält, höre ich auf, mir darüber Sorgen zu machen.</FormLabel>
                    <CustomErrorMessage name="css15_fu_15" />
                    <RadioGroup aria-label="position" name="css15_fu_15" value={this.props.values.css15_fu_15} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentAlways />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>
                
                <StepperPage activeStep={this.state.activeStep} thisStep={16}>
                  <Typography variant="h2">Fragebogen absenden</Typography>
                  Vielen Dank! Sie können den Fragebogen nun absenden.
                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                    <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                  </div>
                </StepperPage>

                {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}       
              </Container>  
            </div>
          </form>
        </main>
      <Footer showMenu={false} />
    </>
    );
  }
}

export default withNotifications(withFormik({
  mapPropsToValues: () => ({
    css15_fu_1: '',
    css15_fu_2: '',
    css15_fu_3: '',
    css15_fu_4: '',
    css15_fu_5: '',
    css15_fu_6: '',
    css15_fu_7: '',
    css15_fu_8: '',
    css15_fu_9: '',
    css15_fu_10: '',
    css15_fu_11: '',
    css15_fu_12: '',
    css15_fu_13: '',
    css15_fu_14: '',
    css15_fu_15: '',
  }),  
  validationSchema: Yup.object().shape({
    css15_fu_1: Yup.number().required('Pflichtfeld'),
    css15_fu_2: Yup.number().required('Pflichtfeld'),
    css15_fu_3: Yup.number().required('Pflichtfeld'),
    css15_fu_4: Yup.number().required('Pflichtfeld'),
    css15_fu_5: Yup.number().required('Pflichtfeld'),
    css15_fu_6: Yup.number().required('Pflichtfeld'),
    css15_fu_7: Yup.number().required('Pflichtfeld'),
    css15_fu_8: Yup.number().required('Pflichtfeld'),
    css15_fu_9: Yup.number().required('Pflichtfeld'),
    css15_fu_10: Yup.number().required('Pflichtfeld'),
    css15_fu_11: Yup.number().required('Pflichtfeld'),
    css15_fu_12: Yup.number().required('Pflichtfeld'),
    css15_fu_13: Yup.number().required('Pflichtfeld'),
    css15_fu_14: Yup.number().required('Pflichtfeld'),
    css15_fu_15: Yup.number().required('Pflichtfeld'),
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "css-15-fu",
      questionnaireData: values,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
    });
  }
})(CSS_15_FU));

/* https://stackoverflow.com/questions/59497550/handling-formik-form-when-validation-error-occurs */