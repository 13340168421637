import axios from 'axios';
import Globals from '../Globals';


// Based on https://stackoverflow.com/questions/34351804/how-to-declare-a-global-variable-in-react

class Analytics {

  /**
   * Send an Analytics Event to the frontend
   *
   * @param {*} type
   * @param {*} name
   * @param {*} details
   */
  emit(type, name, details) {
    axios.post(Globals.SERVER_URL + "/analytics", {
      type: type,
      name: name,
      details: details,
    }).then((response) => {}).catch((err) => {
      if (err.response) {
        console.log(err.response.data)
      }
    }
    )
  }
}

const analytics = new Analytics();

export default analytics;

/**
 * var Foo;

export default window.Foo;
 */