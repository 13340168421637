import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class Nutrition extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Während der Schwangerschaft &gt; Ernährung</div>
            
            <Typography variant="h1">Ernährung</Typography>

            <BackToInformationsGeneralButton />

            <Typography variant="h2">Ernährung während der Schwangerschaft</Typography>
            <p>Schön, dass Sie wieder dabei sind. Einige Wochen Ihrer Schwangerschaft haben Sie nun bereits geschafft, der Großteil liegt noch vor Ihnen. Die Ernährung in der Schwangerschaft ist nicht nur für Sie, sondern auch für Ihr Baby wichtig. Daher möchten wir Ihnen hier einige Tipps und Infos geben, denn während der Schwangerschaft verändert sich nicht nur Ihr Körper, sondern auch Ihr Bedarf an Nährstoffen, um Ihr Kind und Sie optimal zu versorgen.  </p>
            <p>Wichtig ist zu allererst: Eine Schwangerschaft bedeutet nicht, dass man für zwei essen muss! Sondern, dass Sie auf eine abwechslungsreiche Ernährung mit ausreichend Mineralien, Kalorien, Vitaminen und Spurenelementen achten. Das ist leicht gesagt, nur was bedeutet dies eigentlich? </p>
            <Typography variant="h2">Kohlenhydrate und Fette</Typography>
            <p>Wichtig ist eine ausgewogene Ernährung. Der Hauptenergielieferant sind die Kohlenhydrate. Dabei ist es in der Schwangerschaft wichtig hochwertige Kohlenhydrate zu essen. Sie liefern länger Energie und machen auch länger satt. Sie sind z.B. in Vollkornprodukten (wie Reis, Nudeln oder dunklem Brot) und Kartoffeln enthalten.  Weißmehlprodukte und Süßigkeiten sind weniger empfehlenswert. Gute Energiespeicher sind auch Fette. Diese sollten nicht zu viel aufgenommen werden, einige aus Raps-, Oliven- und Fischöl sind jedoch besonders geeignet. Ebenso brauchen Sie Ballaststoffe. Diese sind wichtig für Ihre Verdauung. Sie finden Sie vor allem in Vollkorngetreideprodukten und Gemüse. </p>
            <Typography variant="h2">Obst und Gemüse</Typography>
            <p>Obst und Gemüse spielen natürlich auch eine Rolle. Sie sind Vitaminlieferanten. Vitamin C können Sie z.B. über Zitrusfrüchte, Erdbeeren, Weißkohl und Paprika zuführen. Das Wichtigste in der Schwangerschaft bezüglich Fruchtverzehr ist, dass Obst und auch jegliches Gemüse vor dem Genuss oder der Zubereitung sorgfältig gewaschen werden muss, damit Bakterien, die ggf. an der Oberfläche haften, entfernt werden. Manche Schwangere bekommen aber bei stark säurehaltigen Fruchtsorten (z.B. Zitrone, Ananas, Kiwi) vermehrte Übelkeit und Erbrechen. Wenn dies bei Ihnen auch so sein sollte, dann sollten Sie davon lieber nicht so viel essen und eher Fruchtsorten ohne großen Säureanteil zu sich nehmen. Hier eignen sich z.B. Bananen. </p>
            <Typography variant="h2">Vitaminpräparate</Typography>
            <p>Die Einnahme von Vitaminpräparaten sollte nur in Rücksprache mit Ihrem Frauenarzt oder Frauenärztin erfolgen. Vitaminpräparate können eine gesunde Ernährung ergänzen, aber sie können diese nicht ersetzen.</p>
            <Typography variant="h2">Eiweiße</Typography>
            <p>Zudem empfiehlt es sich, auch regelmäßig Eiweiße, die Grundbausteine menschlicher Zellen, zu sich zu nehmen. Diese sind vor allem in Milchprodukten und Eiern zu finden. Wichtig ist, dass Sie in der Schwangerschaft nur pasteurisierte und abgekochte Milch verwenden. Dies sollte man auch bei Käse beachten. Käsesorten, die aus Rohmilch hergestellt werden, sollten in der Schwangerschaft gemieden werden. Käse aus Rohmilch kann Bakterien (Listerien) enthalten. Diese können zu Entzündungen führen und Ihr Kind und die Schwangerschaft schädigen. </p>
            <Typography variant="h2">Käse und Eier</Typography>
            <p>Käse aus pasteurisierter Milch darf in der Schwangerschaft bedenkenlos gegessen werden.  </p>
            <p>Eier sollten stets hart gekocht sein. Auf den Genuss roher Eier sollte verzichtet werden, da hier ebenso Bakterien (v.a. Salmonellen) vorkommen können, die für Sie und Ihr Kind  schädlich sind. Daher lohnt es sich darauf zu achten, dass auch in bestimmten Salatdressings (z.B. französisches Dressing), Nachspeisen (z.B. Tiramisu) oder Soßen (Mayonaise) ebenfalls rohe Eier enthalten sind. Diese sollte man in der Schwangerschaft weglassen. </p>
            <Typography variant="h2">Mikronährstoffe</Typography>
            <p>Auch der Bedarf an Mikronährstoffen wie z.B. Eisen, Jod, Zink und Omega-3-Fettsäuren ist in der Schwangerschaft erhöht. Eisen kann bei einem festgestellten Eisenmangel durch Ersatzpräparate zugeführt werden. Dies wird im Rahmen der Schwangerschaftsvorsorge regelmäßig getestet und ggf. wird Ihnen ein Präparat von Ihrem Frauenarzt oder Ihrer Frauenärztin verschrieben. Diese sollten am besten mit Orangensaft aber nicht auf nüchternen Magen eingenommen werden. Kaffee, schwarzer oder grüner Tee reduzieren die körpereigene Eisenaufnahme. </p>
            <Typography variant="h2">Folsäure</Typography>
            <p>Ebenso wichtig ist die Einnahme von Folsäure! Da der Körper Folsäure zur Zellsynthese und Entwicklung von Organ- und Skelettsystemen braucht, ist Folsäure für Ihr Kind besonders wichtig. Durch eine zusätzliche Einnahme von Folsäure vor und in der Schwangerschaft kann ein „offener Rücken“ bei Ihrem Kind vermieden werden. Deshalb empfehlen wir Ihnen, wie Sie es aber bestimmt schon seit einiger Zeit umsetzen, täglich 0,4 mg Folsäure zu sich zu nehmen. Dies kann man sowohl in der Apotheke als auch in der Drogerie kaufen. Neben der Folsäure spielt auch Jod als Bote der Schilddrüse eine wichtige Rolle in der Entwicklung Ihres Kindes. Es sollte vor allem jodiertes Salz gegessen werden und etwa zweimal in der Woche Meeresfisch, vollständig gegart versteht sich. </p>
            <p>Falls eine Schilddrüsenerkrankung bei Ihnen bekannt ist, sollten die Schilddrüsenwerte regelmäßig in der Schwangerschaft kontrolliert werden, da die Schilddrüsenhormone für die richtige Entwicklung des Gehirns Ihres Kindes wichtig sind.  </p>
            <Typography variant="h2">Fazit</Typography>
            <p>Das klingt auf den ersten Blick ganz schön viel, aber Sie werden sehen, dass Sie sich schnell daran gewöhnen. </p>
            <p>Für einen Überblick haben wir Ihnen die Empfehlungen als Do´s und Don´ts zusammengestellt. </p>
            <Typography variant="h2">Auf einen Blick</Typography>
            <Typography variant="h3">Was für mich gut ist</Typography>
            <ul>
              <li>Nicht für zwei essen</li>
              <li>Regelmäßiges Essen mit ggf. kleinen Zwischenmahlzeiten </li>
              <li>Abwechslungsreiche Ernährung </li>
              <li>Hochwertige Kohlenhydrate: Vollkornprodukte (Brot, Reis, Nudeln), Kartoffeln</li>
              <li>Ballaststoffe (aus Vollkornprodukten, Gemüse und Obst)  zur Vermeidung von Verstopfung, </li>
              <li>Nur Milchprodukte aus pasteurisierter Milch essen </li>
              <li>Eier immer ausreichend lang kochen und nicht roh essen</li>
              <li>Nur ausreichend erhitztes Fleisch und Fisch. </li>
              <li>Obst und Gemüse immer gut waschen</li>
            </ul>

            <Typography variant="h3">Was ich vermeiden sollte</Typography>
            <ul>
              <li>minderwertige Kohlenhydrate (Weißbrot, weiße Nudeln) und zu viel Zucker</li>
              <li>rohe, unpasteurisierte Milch und Rohmilchkäse wie Brie, Camembert, Gorgonzola </li>
              <li>Ungewaschenes Gemüse, Obst, Salate </li>
              <li>rohe oder nicht vollständig erhitzte Eier, Mayonaise, Soße auf Roheibasis, Tiramisu</li>
              <li>rohes oder nicht vollständig gebratenes Fleisch (Hackfleisch, rohe Wurst, Tartar, Bratwurstgeheck, rohes Schweinefleisch, Salami, Mett-, Teewurst) </li>
              <li>Geräucherte Produkte wie Parma-, Serrano-, Kantenschinken oder kalt geräucherter Fisch wie Räucherlachs, Aal, Schillerlocke</li>
              <li>Fertigsalate</li>
              <li>streng vegetarische Kost </li>
              <li>Chininhaltige Limonade (Bitterlemon, Tonic Water) und zu viel koffeinhaltige Getränke</li>
              <li>Nahrungsmittel aus Industrie und Landwirtschaft mit Schwermetallen (Kleie, Sojabohnen, Blattgemüse) </li>
              <li>Alkohol und Nikotin </li>
            </ul>
          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default Nutrition;