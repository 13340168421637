import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Checkbox, FormGroup, TextField, Typography } from '@material-ui/core';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import withNotifications from 'services/NotificationService/withNotifications';

import 'scenes/Questionnaires/Questionnaire.scss';
import QuestionnaireHeadNotice from 'components/QuestionnaireHeadNotice/QuestionnaireHeadNotice';
import UXCheckbox from '../Components/UXCheckbox/UXCheckbox';
import FormikMaterialRadioGroups from 'components/Forms/FormikMaterialRadioGroup/FormikMaterialRadioGroup';
import { YesOnceYesSeveralNo } from '../StandardizedAnswerOptions/YesOnceYesSeveralNo/YesOnceYesSeveralNo';
import { AgreeDisagreeLikert } from '../StandardizedAnswerOptions/YesOnceYesSeveralNo/AgreeDisagreeLikert';


//import Textfield from '../../components/Textfield/Textfield';
//import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';


class BloodPressureUsability extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Usability: Blutdruck-Manschette</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/13) * 100 } />
          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />
          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "ux_bpm_01_yn": 1,
                "ux_bpm_02": 2,
                "ux_bpm_03": 3,
                "ux_bpm_04": 4,
                "ux_bpm_05": 5,
                "ux_bpm_06": 6,
                "ux_bpm_07": 7,
                "ux_bpm_08": 8,
                "ux_bpm_09": 9,
                "ux_bpm_10": 10,
                "ux_bpm_11": 11,
                "ux_bpm_12": 12
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <div style={{textAlign: "center", marginTop: "1em"}}>
                Heute wollen wir von Ihnen wissen, wie es Ihnen bei der Überwachung Ihres Blutdrucks bisher ergangen ist. Bitte füllen Sie dazu den folgenden Fragebogen vollständig und ehrlich aus. Wählen Sie die Antwortoption, die für Sie am ehesten zutrifft.<br/><br/>
                  <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Fragebogen starten</Button>
                </div>
              </StepperPage>
            </Container>

            { this.state.activeStep >= 4 && this.state.activeStep <= 12 &&
              <QuestionnaireHeadNotice>
                In wieweit stimmen Sie der folgenden Aussage zu?
              </QuestionnaireHeadNotice>
            }

            <div className="questionnaire-body">
              <Container maxWidth="lg">
                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Haben Sie Ihren Blutdruck regelmäßig gemessen (2x pro Woche, jeweils morgens und abends)?</FormLabel>
                    <CustomErrorMessage name="ux_bpm_01_yn" />
                    <RadioGroup aria-label="position" name="ux_bpm_01_yn" value={this.props.values.ux_bpm_01_yn} onChange={this.props.handleChange} required={true}>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Ja" labelPlacement="end"/>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>

                  { this.props.values.ux_bpm_01_yn === "0" &&
                  <>
                    <b>Wenn Sie Messungen nicht gemacht haben, was waren die Gründe dafür (Mehrfachantwort möglich)?</b>
                    <UXCheckbox label="Das Gerät ließ sich nicht bedienen." name="ux_bpm_01a" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    <UXCheckbox label="Ich konnte die Manschette nicht richtig anlegen." name="ux_bpm_01b" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    <UXCheckbox label="Der Akku war leer." name="ux_bpm_01c" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    <UXCheckbox label="Ich hatte das Gerät nicht zur Hand." name="ux_bpm_01d" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    <UXCheckbox label="Ich hatte Sicherheitsbedenken." name="ux_bpm_01e" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    <UXCheckbox label="Ich hatte keine Zeit." name="ux_bpm_01f" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    <UXCheckbox label="Ich hatte keine Lust." name="ux_bpm_01g" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    <UXCheckbox label="Ich habe es vergessen." name="ux_bpm_01h" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    <UXCheckbox label="Andere Gründe" name="ux_bpm_01i" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    { 
                      this.props.values.ux_bpm_01i === "1" &&
                      <>
                        <FormControl component="fieldset" fullWidth={true}>
                          <CustomErrorMessage name="ux_bpm_01i_txt" />
                          <TextField fullWidth name="ux_bpm_01i_txt" label="Anderer Grund" value={this.props.values.ux_bpm_01i_txt} onChange={this.props.handleChange} />
                        </FormControl>
                      </>
                    }
                  </>
                  }
                  <ForwardBackwardButtons handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={2}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Haben Sie aufgrund eines gemessenen Wertes einen Arzt kontaktiert, z.B. Ihren Frauenarzt/Ihre Frauenärztin oder Ihren Studienarzt/Ihre Studienärztin?"
                    name="ux_bpm_02" 
                    options={YesOnceYesSeveralNo}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={3}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Sind Sie aufgrund eines gemessenen Wertes zum Arzt gegangen, z.B. zu Ihrem Frauenarzt/Ihrer Frauenärztin oder in die Frauenklinik?"
                    name="ux_bpm_03" 
                    options={YesOnceYesSeveralNo}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={4}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Ich fand es einfach, die Blutdruckmanschette zu verwenden."
                    name="ux_bpm_04" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={5}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Ich habe mich im Umgang mit der Blutdruckmanschette unsicher gefühlt."
                    name="ux_bpm_05" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={6}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Die Messungen in meinen Alltag zu integrieren war unproblematisch."
                    name="ux_bpm_06" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={7}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Die Messungen durchzuführen war lästig."
                    name="ux_bpm_07" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={8}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Die engmaschige Überwachung meines Blutdrucks hat mich beruhigt."
                    name="ux_bpm_08" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={9}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Ich würde die Blutdruckmessung zu Hause nur über einen kurzen Zeitraum, z.B. einen Monat, durchführen."
                    name="ux_bpm_09" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={10}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Ich würde die Blutdruckmessung zu Hause auch über die gesamte Schwangerschaft durchführen."
                    name="ux_bpm_10" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={11}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label={<>Die Blutdruckmessung zu Hause wäre für mich eine <u>sinnvolle Ergänzung</u> zur Blutdruckmessung beim Frauenarzt/bei der Frauenärztin.</>}
                    name="ux_bpm_11" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={12}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label={<>Die Blutdruckmessung zu Hause wäre für mich eine <u>echte Alternative</u> zur Blutdruckmessung beim Frauenarzt/bei der Frauenärztin.</>}
                    name="ux_bpm_12" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={13}>
                  
                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                  <Typography variant="h2">Fragebogen absenden</Typography>
                  Vielen Dank! Sie können den Fragebogen nun absenden.<br/><br/>
                  <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                  </div>
                </StepperPage>

                {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}       
              </Container>  
            </div>
          </form>
        </main>
      <Footer showMenu={false} />
    </>
    );
  }
}

export default withNotifications(withFormik({
  mapPropsToValues: () => ({
    ux_bpm_01_yn: '',
    ux_bpm_01a: '',
    ux_bpm_01b: '',
    ux_bpm_01c: '',
    ux_bpm_01d: '',
    ux_bpm_01e: '',
    ux_bpm_01f: '',
    ux_bpm_01g: '',
    ux_bpm_01h: '',
    ux_bpm_01i: '',
    ux_bpm_01i_txt: '',
    ux_bpm_02: '',
    ux_bpm_03: '',
    ux_bpm_04: '',
    ux_bpm_05: '',
    ux_bpm_06: '',
    ux_bpm_07: '',
    ux_bpm_08: '',
    ux_bpm_09: '',
    ux_bpm_10: '',
    ux_bpm_11: '',
    ux_bpm_12: ''
  }),  
  validationSchema: Yup.object().shape({
    ux_bpm_01_yn: Yup.number().required('Pflichtfeld'),
    ux_bpm_02: Yup.number().required('Pflichtfeld'),
    ux_bpm_03: Yup.number().required('Pflichtfeld'),
    ux_bpm_04: Yup.number().required('Pflichtfeld'),
    ux_bpm_05: Yup.number().required('Pflichtfeld'),
    ux_bpm_06: Yup.number().required('Pflichtfeld'),
    ux_bpm_07: Yup.number().required('Pflichtfeld'),
    ux_bpm_08: Yup.number().required('Pflichtfeld'),
    ux_bpm_09: Yup.number().required('Pflichtfeld'),
    ux_bpm_10: Yup.number().required('Pflichtfeld'),
    ux_bpm_11: Yup.number().required('Pflichtfeld'),
    ux_bpm_12: Yup.number().required('Pflichtfeld'),
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "ux_bpm",
      questionnaireData: values,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
    });
  }
})(BloodPressureUsability));

/* https://stackoverflow.com/questions/59497550/handling-formik-form-when-validation-error-occurs */