import axios from 'axios';
import Globals from '../Globals';
import {useState, useEffect} from 'react';

export default function useActivities() {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    axios.get(Globals.SERVER_URL + "/activities").then(function(res){
      setActivities(res.data);
    }).catch(function(err){
      console.log(err);
    });
  }, []);

  return activities;
}