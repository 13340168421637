import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import withNotifications from 'services/NotificationService/withNotifications';

import '../Questionnaire.scss';
import QuestionnaireHeadNotice from 'components/QuestionnaireHeadNotice/QuestionnaireHeadNotice';


//import Textfield from '../../components/Textfield/Textfield';
//import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';


class EPDS extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">EPDS</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/10) * 100 } />
          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />
          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "epds01": 1,
                "epds02": 2,
                "epds03": 3,
                "epds04": 4,
                "epds05": 5,
                "epds06": 6,
                "epds07": 7,
                "epds08": 8,
                "epds09": 9,
                "epds10": 10
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <div style={{textAlign: "center", marginTop: "1em"}}>
                  Da Sie vor kurzem ein Kind bekommen haben oder bald eines bekommen werden, würde wir gerne wissen, wie Sie sich in den letzten 7 Tagen bzw. in den Tagen seit der Geburt gefühlt haben.
                  Bitte markieren Sie bei jeder Frage die Antwort, die für Sie am ehesten zutrifft.<br/><br/>
                  <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Fragebogen starten</Button>
                </div>
              </StepperPage>
            </Container>

            { this.state.activeStep >= 1 &&
              <QuestionnaireHeadNotice>
                In den letzten 7 Tagen...<br/> (In den Tagen seit der Geburt...)
              </QuestionnaireHeadNotice>
            }
            <div className="questionnaire-body">
              <Container maxWidth="lg">
                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">...konnte ich lachen und das Leben von der sonnigen Seite sehen.</FormLabel>
                    <CustomErrorMessage name="epds01" />
                    <RadioGroup aria-label="position" name="epds01" value={this.props.values.epds01} onChange={this.props.handleChange} required={true}>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="So wie ich es immer konnte" labelPlacement="end"/>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Nicht ganz so wie immer" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Deutlich weniger als früher" labelPlacement="end"/>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Überhaupt nicht" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={2}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">...konnte ich mich so richtig auf etwas freuen.</FormLabel>
                    <CustomErrorMessage name="epds02" />
                    <RadioGroup aria-label="position" name="epds02" value={this.props.values.epds02} onChange={this.props.handleChange}>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="So wie immer" labelPlacement="end"/>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Etwas weniger als sonst" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Deutlich weniger als früher" labelPlacement="end"/>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Kaum" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={3}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">...fühlte ich mich unnötigerweise schuldig, wenn etwas schieflief.</FormLabel>
                    <CustomErrorMessage name="epds03" />
                    <RadioGroup aria-label="position" name="epds03" value={this.props.values.epds03} onChange={this.props.handleChange}>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Ja, meistens" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Ja, manchmal" labelPlacement="end"/>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Nein, nicht so oft" labelPlacement="end"/>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein, niemals" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={4}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">...war ich ängstlich und besorgt aus nichtigen Gründen.</FormLabel>
                    <CustomErrorMessage name="epds04" />
                    <RadioGroup aria-label="position" name="epds04" value={this.props.values.epds04} onChange={this.props.handleChange}>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein, überhaupt nicht" labelPlacement="end"/>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Selten" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Ja, manchmal" labelPlacement="end"/>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Ja, häufig" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={5}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">...erschrak ich leicht bzw. reagierte panisch aus unerfindlichen Gründen.</FormLabel>
                    <CustomErrorMessage name="epds05" />
                    <RadioGroup aria-label="position" name="epds05" value={this.props.values.epds05} onChange={this.props.handleChange}>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Ja, oft" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Ja, manchmal" labelPlacement="end"/>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Nein, nicht oft" labelPlacement="end"/>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein, überhaupt nicht" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={6}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">...überforderten mich verschiedene Umstände.</FormLabel>
                    <CustomErrorMessage name="epds06" />
                    <RadioGroup aria-label="position" name="epds06" value={this.props.values.epds06} onChange={this.props.handleChange}>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Ja, die meiste Zeit war ich nicht in der Lage, damit fertig zu werden" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Ja, manchmal konnte ich damit nicht fertig werden" labelPlacement="end"/>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Nein, die meiste Zeit konnte ich gut damit fertig werden" labelPlacement="end"/>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein, ich wurde so gut wie immer damit fertig" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={7}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">...war ich so unglücklich, dass ich nicht schlafen konnte..</FormLabel>
                    <CustomErrorMessage name="epds07" />
                    <RadioGroup aria-label="position" name="epds07" value={this.props.values.epds07} onChange={this.props.handleChange}>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Ja, die meiste Zeit" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Ja, manchmal" labelPlacement="end"/>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Nein, nicht sehr oft" labelPlacement="end"/>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein, überhaupt nicht" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={8}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">...habe ich mich traurig und schlecht gefühlt.</FormLabel>
                    <CustomErrorMessage name="epds08" />
                    <RadioGroup aria-label="position" name="epds08" value={this.props.values.epds08} onChange={this.props.handleChange}>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Ja, die meiste Zeit" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Ja, manchmal" labelPlacement="end"/>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Selten" labelPlacement="end"/>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein, überhaupt nicht" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={9}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">...war ich so unglücklich, dass ich geweint habe.</FormLabel>
                    <CustomErrorMessage name="epds09" />
                    <RadioGroup aria-label="position" name="epds09" value={this.props.values.epds09} onChange={this.props.handleChange}>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Ja, die ganze Zeit" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Ja, manchmal" labelPlacement="end"/>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Nur gelegentlich" labelPlacement="end"/>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein, niemals" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>
                <StepperPage activeStep={this.state.activeStep} thisStep={10}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">...überkam mich der Gedanke, mir selbst Schaden zuzufügen.</FormLabel>
                    <CustomErrorMessage name="epds10" />
                    <RadioGroup aria-label="position" name="epds10" value={this.props.values.epds10} onChange={this.props.handleChange}>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Ja, ziemlich oft" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Manchmal" labelPlacement="end"/>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Kaum" labelPlacement="end"/>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Niemals" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                    <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                  </div>
                  <ForwardBackwardButtons handleBack={this.handleBack} />
                </StepperPage>
                {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}       
              </Container>  
            </div>
          </form>
        </main>
      <Footer showMenu={false} />
    </>
    );
  }
}

export default withNotifications(withFormik({
  mapPropsToValues: () => ({
    epds01: '',
    epds02: '',
    epds03: '',
    epds04: '',
    epds05: '',
    epds06: '',
    epds07: '',
    epds08: '',
    epds09: '',
    epds10: ''
  }),  
  validationSchema: Yup.object().shape({
    epds01: Yup.number().required('Pflichtfeld'),
    epds02: Yup.number().required('Pflichtfeld'),
    epds03: Yup.number().required('Pflichtfeld'),
    epds04: Yup.number().required('Pflichtfeld'),
    epds05: Yup.number().required('Pflichtfeld'),
    epds06: Yup.number().required('Pflichtfeld'),
    epds07: Yup.number().required('Pflichtfeld'),
    epds08: Yup.number().required('Pflichtfeld'),
    epds09: Yup.number().required('Pflichtfeld'),
    epds10: Yup.number().required('Pflichtfeld'),
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "epds",
      questionnaireData: values,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
    });
  }
})(EPDS));

/* https://stackoverflow.com/questions/59497550/handling-formik-form-when-validation-error-occurs */