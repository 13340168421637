import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import PHBSNeverRarelySometimesFrequentOften from './StandardizedAnswerOptions/PHBSNeverRarelySometimesFrequentOften'

import withNotifications from 'services/NotificationService/withNotifications';

import '../Questionnaire.scss';
import QuestionnaireHeadNotice from 'components/QuestionnaireHeadNotice/QuestionnaireHeadNotice';


//import Textfield from '../../components/Textfield/Textfield';
//import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';


class PHBS extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">PHBS</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/21) * 100 } />
          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />
          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "phbs01": 1,
                "phbs02": 2,
                "phbs03": 3,
                "phbs04": 4,
                "phbs05": 5,
                "phbs06": 6,
                "phbs07": 7,
                "phbs08": 8,
                "phbs09": 9,
                "phbs10": 10,
                "phbs11": 11,
                "phbs12": 12,
                "phbs13": 13,
                "phbs14": 14,
                "phbs15": 15,
                "phbs16": 16,
                "phbs17": 17,
                "phbs18": 18,
                "phbs19": 19,
                "phbs20": 20,
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <div style={{textAlign: "center", marginTop: "1em"}}>
                  Der folgende Fragebogen dreht sich um Dinge, die Menschen manchmal tun und die sich auf ihre Gesundheit auswirken. Bitte denke Sie nach, was Sie in den letzten vier Wochen getan haben. Bitte bedenken Sie, dass wir wissen wollen, was Sie wirklich getan haben und nicht, was Sie gerne getan hätten.
                  Bitte tippen Sie eine der folgenden Antwortmöglichkeiten an, um zu beschreiben, wie oft Sie die einzelnen Dinge getan haben.
                  <br/><br/>
                  <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Fragebogen starten</Button>
                </div>
              </StepperPage>
            </Container>

            { this.state.activeStep >= 1 &&
              <QuestionnaireHeadNotice>
                Wie oft haben Sie in den letzten 4 Wochen...
              </QuestionnaireHeadNotice>
            }

            <div className="questionnaire-body">
              <Container maxWidth="lg">

                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">...mindestens 15 Minuten Sport gemacht?</FormLabel>
                    <CustomErrorMessage name="phbs01" />
                    <RadioGroup aria-label="position" name="phbs01" value={this.props.values.phbs01} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={2}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... genug geschlafen?</FormLabel>
                    <CustomErrorMessage name="phbs02" />
                    <RadioGroup aria-label="position" name="phbs02" value={this.props.values.phbs02} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={3}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... sich viel bücken müssen?</FormLabel>
                    <CustomErrorMessage name="phbs03" />
                    <RadioGroup aria-label="position" name="phbs03" value={this.props.values.phbs03} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={4}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... Milch getrunken oder Milchprodukte gegessen, wie z.B. Joghurt oder Käse?</FormLabel>
                    <CustomErrorMessage name="phbs04" />
                    <RadioGroup aria-label="position" name="phbs04" value={this.props.values.phbs04} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={5}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... fettiges oder öliges Essen gegessen?</FormLabel>
                    <CustomErrorMessage name="phbs05" />
                    <RadioGroup aria-label="position" name="phbs05" value={this.props.values.phbs05} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={6}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... Vitamine eingenommen?</FormLabel>
                    <CustomErrorMessage name="phbs06" />
                    <RadioGroup aria-label="position" name="phbs06" value={this.props.values.phbs06} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={7}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... genascht anstatt eine ordentliche Mahlzeit zu sich zu nehmen?</FormLabel>
                    <CustomErrorMessage name="phbs07" />
                    <RadioGroup aria-label="position" name="phbs07" value={this.props.values.phbs07} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={8}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... für längere Zeit auf den Füßen gestanden?</FormLabel>
                    <CustomErrorMessage name="phbs08" />
                    <RadioGroup aria-label="position" name="phbs08" value={this.props.values.phbs08} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={9}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... koffeinhaltige Getränke getrunken, wie z.B. Kaffee, Cola oder Energy Drinks?</FormLabel>
                    <CustomErrorMessage name="phbs09" />
                    <RadioGroup aria-label="position" name="phbs09" value={this.props.values.phbs09} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={10}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... sich satt gegessen?</FormLabel>
                    <CustomErrorMessage name="phbs10" />
                    <RadioGroup aria-label="position" name="phbs10" value={this.props.values.phbs10} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={11}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... Ihre Muskeln gedehnt?</FormLabel>
                    <CustomErrorMessage name="phbs11" />
                    <RadioGroup aria-label="position" name="phbs11" value={this.props.values.phbs11} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={12}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... Alkohol getrunken, wie z.B. Wein, Bier oder Schnaps?</FormLabel>
                    <CustomErrorMessage name="phbs12" />
                    <RadioGroup aria-label="position" name="phbs12" value={this.props.values.phbs12} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={13}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... ballaststoffreiche Lebensmittel zu sich genommen, wie z.B. Vollkornbrot oder Müsli?</FormLabel>
                    <CustomErrorMessage name="phbs13" />
                    <RadioGroup aria-label="position" name="phbs13" value={this.props.values.phbs13} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={14}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... schwere Gegenstände gehoben?</FormLabel>
                    <CustomErrorMessage name="phbs14" />
                    <RadioGroup aria-label="position" name="phbs14" value={this.props.values.phbs14} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={15}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... genug Flüssigkeit getrunken?</FormLabel>
                    <CustomErrorMessage name="phbs15" />
                    <RadioGroup aria-label="position" name="phbs15" value={this.props.values.phbs15} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={16}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... zu viel gegessen?</FormLabel>
                    <CustomErrorMessage name="phbs16" />
                    <RadioGroup aria-label="position" name="phbs16" value={this.props.values.phbs16} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={17}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... Zigaretten geraucht?</FormLabel>
                    <CustomErrorMessage name="phbs17" />
                    <RadioGroup aria-label="position" name="phbs17" value={this.props.values.phbs17} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={18}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... ein Nickerchen gemacht?</FormLabel>
                    <CustomErrorMessage name="phbs18" />
                    <RadioGroup aria-label="position" name="phbs18" value={this.props.values.phbs18} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={19}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... eine Mahlzeit ausgelassen, wie z.B. Frühstück oder Mittagessen?</FormLabel>
                    <CustomErrorMessage name="phbs19" />
                    <RadioGroup aria-label="position" name="phbs19" value={this.props.values.phbs19} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={20}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... sich ausgewogen ernährt, also auch Obst und Gemüse gegessen?</FormLabel>
                    <CustomErrorMessage name="phbs20" />
                    <RadioGroup aria-label="position" name="phbs20" value={this.props.values.phbs20} onChange={this.props.handleChange} required={true}>
                      <PHBSNeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>


                <StepperPage activeStep={this.state.activeStep} thisStep={21}>
                  <Typography variant="h2">Fragebogen absenden</Typography>
                  Vielen Dank! Sie können den Fragebogen nun absenden.
                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                    <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                  </div>
                </StepperPage>

                {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}       
              </Container>  
            </div>
          </form>
        </main>
      <Footer showMenu={false} />
    </>
    );
  }
}

export default withNotifications(withFormik({
  mapPropsToValues: () => ({
    phbs01: '',
    phbs02: '',
    phbs03: '',
    phbs04: '',
    phbs05: '',
    phbs06: '',
    phbs07: '',
    phbs08: '',
    phbs09: '',
    phbs10: '',
    phbs11: '',
    phbs12: '',
    phbs13: '',
    phbs14: '',
    phbs15: '',
    phbs16: '',
    phbs17: '',
    phbs18: '',
    phbs19: '',
    phbs20: '',
  }),  
  validationSchema: Yup.object().shape({
    phbs01: Yup.number().required('Pflichtfeld'),
    phbs02: Yup.number().required('Pflichtfeld'),
    phbs03: Yup.number().required('Pflichtfeld'),
    phbs04: Yup.number().required('Pflichtfeld'),
    phbs05: Yup.number().required('Pflichtfeld'),
    phbs06: Yup.number().required('Pflichtfeld'),
    phbs07: Yup.number().required('Pflichtfeld'),
    phbs08: Yup.number().required('Pflichtfeld'),
    phbs09: Yup.number().required('Pflichtfeld'),
    phbs10: Yup.number().required('Pflichtfeld'),
    phbs11: Yup.number().required('Pflichtfeld'),
    phbs12: Yup.number().required('Pflichtfeld'),
    phbs13: Yup.number().required('Pflichtfeld'),
    phbs14: Yup.number().required('Pflichtfeld'),
    phbs15: Yup.number().required('Pflichtfeld'),
    phbs16: Yup.number().required('Pflichtfeld'),
    phbs17: Yup.number().required('Pflichtfeld'),
    phbs18: Yup.number().required('Pflichtfeld'),
    phbs19: Yup.number().required('Pflichtfeld'),
    phbs20: Yup.number().required('Pflichtfeld'),
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "phbs",
      questionnaireData: values,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
    });
  }
})(PHBS));

/* https://stackoverflow.com/questions/59497550/handling-formik-form-when-validation-error-occurs */