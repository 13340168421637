import { useEffect, useState } from "react";

import React from 'react';

import { getMonthlyAvgData, getYearMonthGermanTextStringFromDate } from "../../GraphUtils/GraphUtils";

export default function HeartRateTendencyText(props) {

  const [data, setData] = useState(0);

  useEffect(() => {
    let monthlyHeartRate = getMonthlyAvgData(props.data, getYearMonthGermanTextStringFromDate);
    setData(monthlyHeartRate);
  }, [props])

  return (
    <>
      { typeof data === 'object' && 
        <table class="wearable-data-table">
          <thead>
            <tr>
              <th>Monat</th>
              <th>Mittlere tägliche Herzfrequenz</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(data).map((key) => {
                return(
                  <tr>
                    <td>{key}</td>
                    <td>{ Math.round(data[key]) }</td>
                    {
                    /*
                    <>
                     Im <b>{key}</b> betrug Ihre mittlere täglich gemessene Herzfrequenz durchschnittlich <b>{ Math.round(data[key]) }</b> Schläge pro Minute. <br/>
                    </>
                    */
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      }
    </>
  )
}

