import { useEffect, useState } from "react";

import React from 'react';

import moment from "moment";
import localization from 'moment/locale/de'


import { averageArray } from 'helpers/Helpers';
import { getMonthlyAvgData, getYearMonthGermanTextStringFromDate } from "../../GraphUtils/GraphUtils";

export default function SleepTendencyText(props) {

  const [data, setData] = useState(0);

  useEffect(() => {
    let monthlySleepData = getMonthlyAvgData(props.data, getYearMonthGermanTextStringFromDate);
    setData(monthlySleepData);
  }, [props])

  return (
    <>
      { typeof data === 'object' && 
        <table class="wearable-data-table">
        <thead>
          <tr>
            <th>Monat</th>
            <th>Durchschnittlicher Schlaf pro Nacht</th>
          </tr>
        </thead>
        <tbody>
          {
            Object.keys(data).map((key) => {
              return(
                <tr>
                  <td>{key}</td>
                  <td>{ Math.round(data[key]) } Stunden</td>
                  {
                  /*
                <>
                  Im <b>{key}</b> betrug Ihr durschnittlicher Schlaf <b>{ Math.round(data[key]) }</b> Stunden pro Nacht. <br/>
                </>
                  */
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
      }
    </>
  )
}

