import React, { useEffect, useState } from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';


import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';


import MeasurementCard from './MeasurementCard/MeasurementCard';


import womanBloodPressure from 'resources/svgs/woman-blood-pressure.svg';

import { faHeartbeat, faProcedures, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';


import './Measurements.scss';
import axios from 'axios';
import Globals from 'services/Globals';
import StudyContactData from 'components/StudyContactData/StudyContactData';



export default function Measurements() {

  const [userAppPackage, setUserAppPackage] = useState();

  useEffect(() => {
    axios.get(Globals.SERVER_URL + "/settings/study_package").then(function(res) {
      setUserAppPackage(res.data);
      console.log(res);
    }).catch(function(err) {
      console.log(err);
    });
  });

  return (
    <>
      <Header>
        <Typography variant="h2">Messwerte</Typography>
      </Header>
      <main>
        <Container maxWidth="lg">
          <div style={{textAlign: "center", paddingTop: "1em"}}>
            <div className="photo-circle">
              <img src={womanBloodPressure} alt="Illustration einer Frau, die ihren Blutdruck misst." />
            </div>
            <Typography variant="h2">Meine Messwerte</Typography>
          </div>
          <div>
            {
              (userAppPackage === 'AppA' || userAppPackage === 'AppB' || userAppPackage === 'AppAB') ? 
              <>
                <p>Wählen Sie, welche Messungen Sie ansehen möchten.</p>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <MeasurementCard title="Herzfrequenz" to="/measurements/heart-rate" icon={faHeartbeat} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MeasurementCard title="Schlaf" to="/measurements/sleep" icon={faProcedures} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MeasurementCard title="Blutdruck" to="/measurements/blood-pressure" icon={faTachometerAlt} />
                  </Grid>
                </Grid>
              </> :
              <>
              { /*
                <p>Sie haben sich für die Verwendung der Mutterpass-App ohne Geräte entschieden. Daher ist die Seite leider nicht verfügbar.</p>
                <p>Falls Sie sich doch für ein Paket mit Sensorik (z.B. Schlafmatte, Smartwatch) entscheiden möchten, wenden Sie sich bitte an die Studienzentrale.</p>
                <p>Sobald Sie den Einstiegs-Fragebogen ausgefüllt haben und dort das richtige Paket auswählen, verschwindet diese Meldung.</p> */ }
              
              <p>Schade, diesen Bereich der App können Sie aktuell leider nicht nutzen!</p>
              <p>Möchten Sie während Ihrer Schwangerschaft verschiedene Geräte testen, mit denen Sie zu Hause z.B. Ihren Blutdruck überwachen, Ihr Gewicht kontrollieren, Ihre Aktivität verfolgen oder Ihre Schlafqualität messen können?</p>
              <p>Dann kontaktieren Sie eine der folgenden Anlaufstellen und lassen Sie sich dort vor Ort für die SMART Start Studie registrieren. Die Geräte werden Ihnen für den Zeitraum der Studienteilnahme überlassen, Sie müssen nichts extra kaufen. Ihre Messwerte können Sie dann hier in der Mutterpass-App einsehen und verfolgen.</p>

              <p>Ihre Anlaufstellen für die SMART Start Studie sind:</p>
              <StudyContactData />
              </>
            }
            
          </div>
        </Container>
      </main>
      <Footer />
    </>
  );
}