import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class Nursing extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Wochenbett &gt; Stillen</div>
            
            <Typography variant="h1">Stillen</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Tipps und Tricks ums Stillen - Was sollte ich beachten?</b></p>

            <p>Nun ist es bald geschafft, ein Großteil der Schwangerschaft liegt bereits hinter Ihnen. Die Spannung steigt. Wie wird das Baby aussehen? Wird es gut durchschlafen und hoffentlich isst es gut! Tja und mit genau diesem Thema haben wir den wunden Punkt der Wochen nach Geburt getroffen. </p>
            <p>Stillen ist die natürlichste Ernährungsform der Welt, theoretisch kann jede Frau stillen. Doch manchmal klappt es einfach nicht und manchmal fließt die Milch nach einiger Zeit auch in Strömen. </p>
            <Typography variant="h2">Tipps der Weltgesundheitsorganisation</Typography>
            <p>Zu aller erst die 10 Top Tipps der Weltgesundheitsorganisaton zur Ernährung von Neugeborenen. Die WHO empfiehlt als ausschließliche Ernährung Ihres Kindes während der ersten 6 Monate das Stillen. Das Baby soll in der ersten Lebensstunde bereits das allererste Mal angelegt werden, um die Milchproduktion anzuregen und das Saugen zu versuchen. Ihr Kind sollte möglichst viel Zeit bei Ihnen im Zimmer sein außer Ihr Kind oder sie benötigen spezielle Überwachung oder Unterstützung. Ab dem 6. Lebensmonat kann mit dem Zufüttern begonnen werden. Ihr Kind kann bis zu 2 Jahre weiter gestillt werden. </p>
            <Typography variant="h2">Muttermilch ist die optimale Nahrung - und hat viele Vorteile</Typography>
            <p>Insgesamt ist Ihre Muttermilch die ideale Nahrung für Ihr Neugeborenes. Die ersten Tropfen, das sogenannte Kolostrum, enthalten dabei viele Immunglobuline, die die Abwehrkraft ihres Babys fördern. Insgesamt enthält Muttermilch alle Nährstoffe, die das Neugeborene zur Entwicklung braucht und Schutzfaktoren, die Infektionen mit Kinderkrankheiten oder Durchfälle reduzieren können. Darüber hinaus ist Muttermilch unglaublich praktisch und günstig, da sie immer verfügbar ist und nicht erst im Fläschchen erhitzt werden muss. Außerdem kann Ihr Kind besonders gut von Ihrer Brust trinken und fühlt sich dabei geschützt und geborgen. Falls Sie viel Milch produzieren, können Sie diese natürlich in kleinen Fläschchen im Kühlschrank für kurze Zeit aufbewahren und später das Kind damit füttern. Die Produktion von Muttermilch ist auch für Sie gut. Zum einen verbrennen Sie damit die überschüssigen Fettressourcen, die Sie während der Schwangerschaft aufgebaut haben. Zum anderen senkt es langfristig Ihr Risiko an Brust- oder Eierstockkrebs, an Diabetes mellitus oder einer postpartalen Depression zu erkranken. </p>
            <Typography variant="h2">Herausforderungen beim Stillen</Typography>
            <p>Stillen ist jedoch manchmal nicht so einfach wie wir uns das gerade vorstellen. Stillen ist etwas das man häufig lernen muss, manchmal braucht es etwas Übung. Falls es nicht gleich zu Beginn klappen sollte oder nur sehr wenig Milch kommt, dann ist das ganz normal. Denn am Anfang trinkt Ihr Kind jedes Mal nur wenige Milliliter und die Milchproduktion muss erst richtig in Gang kommen. Hierzu sollten Sie ihr Kind regelmäßig anlegen und selbst viel Flüssigkeit trinken. Auch feuchtwarme Wickel vor dem Stillen, können das Anlegen und Trinken des Kindes erleichtern. Das Kind sollte in den ersten Tagen etwa 12mal in 24h angelegt werden, bzw. immer, wenn es nach der Brust verlangt. Zudem sollte Ihr Kind an beiden Brüsten trinken. </p>
            <Typography variant="h2">Ihre Hebamme hat viele Tipps</Typography>
            <p>Sprechen Sie zudem mit Ihrer Hebamme darüber, wie Sie sich beim Stillen fühlen und lassen Sie sich weitere Tipps von ihr geben. Vor allem lassen Sie sich nicht entmutigen, wenn es am Anfang nicht gleich perfekt funktioniert. Stillen ist Übungssache. Manchmal müssen Sie auch einfach die für Sie und Ihr Kind richtige Stillposition erst finden. Stillpositionen gibt es ganz unterschiedliche, wichtig ist grundsätzlich, dass Sie in dieser Position entspannt für einige Zeit sitzen oder liegen können und eine ruhige Atmosphäre herrscht. Sie können Ihr Kind im Sitzen, im Liegen oder in Seitenlage stillen. Finden Sie die beste und Position für sich und Ihr Neugeborenes.  </p>
            <Typography variant="h2">Gewicht des Kinds beim Stillen</Typography>
            <p>Eine Gewichtsabnahme von 10% des Geburtsgewichtes ist in der ersten Lebenswoche normal. Zwischen dem 10. bis 14. Lebenstag erreicht Ihr Kind das Geburtsgewicht wieder. Falls dies nicht der Fall sein sollte, dann wenden Sie sich bitte an Ihren Frauen-/Kinderarzt bzw. Ihre Hebamme. Viele von Ihnen werden Angst haben zu wenig Milch zu produzieren und vielleicht das Gefühl haben, dass das Kind zu wenig trinke. Es gibt einige Hinweise dafür, dass Ihr Kind genügend Muttermilch trinkt. Dies sind zum Beispiel eine Gewichtszunahme bis zum 14. Tag, die Produktion von 3 Stuhlgängen in 24h, oder 6 nasse Windeln in 24h nach dem vierten Lebenstag. Und wenn Ihr Baby entspannt wirkt, dann ist dies auch immer ein gutes Zeichen. </p>
            <Typography variant="h2">Schonen Sie sich während der Stillzeit</Typography>
            <p>Wichtig für Sie ist, dass Sie sich während der Stillzeit, besonders direkt nach Geburt, schonen, denn Stillen braucht viel Energie. Deswegen ist es auch wichtig, dass Sie ausreichende und abwechslungsreiche Nahrung zu sich nehmen. Blähende Lebensmittel sollten Sie vermeiden, da diese auch zu Blähungen bei Ihrem Kind führen können. Gelegentlich kann ein Still- oder Fencheltee gut sein. Rauchen und Alkohol sollten Sie auch in der Stillzeit nicht.  Ansonsten können Sie alles Essen auf was Sie Lust haben.</p>
            <Typography variant="h2">Wundheit der Brustwarzen</Typography>
            <p>Gelegentlich können Ihre Brustwarzen wund sein, oft ist dies ein Grund, dass frühzeitig mit dem Stillen aufgehört wird. Daher lohnt es sich darauf zu achten, dass möglichst keine wunde Brustwarze entsteht. Dazu sollte Ihr Kind stets die gesamte Brustwarze in den Mund nehmen, nach dem Stillen ist die Brustwarze zu säubern und mit etwas Muttermilch zu betupfen, die dann an frischer Luft trocknet. Ist die Brustwarze bereits wund, dann helfen Stillhütchen und Rotlichtbestrahlung. Bei Milchstau kann das Ausstreichen und Hochwickeln der Brüste und das Kühlen mit Quarkwickeln hilfreich sein. </p>
            <p>Mit etwas abgepumpter Milch kann Ihr Partner Sie beim Füttern gelegentlich auch unterstützen, denn auch er kann in die Versorgung Ihres Kindes eingebunden werden. Durch die Verwendung von Fläschchen oder Schnullern kann jedoch bei Ihrem Kind eine Saugverwirrung auftreten. Lassen Sie sich im Fachhandel beraten, es gibt unzählige weitere Alternativen dazu. </p>
            <p>Falls es bei Ihnen einen medizinischen Grund gibt, dass Sie nicht stillen dürfen oder es einen anderen Grund gibt, dass Sie nicht stillen möchten, dann gibt es auch hierfür Alternativen. Wir leben zum Glück in einem industrialisierten Land mit guten hygienischen Verhältnissen und haben den Luxus auf PreNahrung zurückgreifen zu können. Diese ist extra an die Bedürfnisse des Neugeborenen angepasst und bietet, wenn Stillen nicht möglich ist, eine gute Alternative. </p>
            
            <p><b>Geben Sie den Versuch des Stillens nicht zu früh auf, denn es kann eine wunderschöne Erfahrung sein. Schließlich gilt: "Breast is best", zumindest in den allermeisten Fällen. </b></p>


          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default Nursing;