import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';

import withNotifications from 'services/NotificationService/withNotifications';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import NoBarelySometimesPrettyMuch from './StandardizedAnswerOptions/NoBarelySometimesPrettyMuch'

import '../Questionnaire.scss';
import QuestionnaireHeadNotice from 'components/QuestionnaireHeadNotice/QuestionnaireHeadNotice';


//import Textfield from '../../components/Textfield/Textfield';
//import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';


class PRAQ_R2 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">PRAQ-R2</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/11) * 100 } />
          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />
          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "praqr201": 1,
                "praqr202": 2,
                "praqr203": 3,
                "praqr204": 4,
                "praqr205": 5,
                "praqr206": 6,
                "praqr207": 7,
                "praqr208": 8,
                "praqr209": 9,
                "praqr210": 10
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <div style={{textAlign: "center", marginTop: "1em"}}>
                  Einleitung folgt!
                  <br/><br/>
                  <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Fragebogen starten</Button>
                </div>
              </StepperPage>
            </Container>

            { this.state.activeStep >= 1 &&
              <QuestionnaireHeadNotice>
              Bitte kreuzen Sie an, wie gut die folgenden Aussagen auf Ihre Situation zutreffen:
              </QuestionnaireHeadNotice>
            }
            <div className="questionnaire-body">
              <Container maxWidth="lg">
                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Ich habe Angst vor der Entbindung</FormLabel>
                    <CustomErrorMessage name="praqr201" />
                    <RadioGroup aria-label="position" name="praqr201" value={this.props.values.praqr201} onChange={this.props.handleChange} required={true}>
                      <NoBarelySometimesPrettyMuch />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>
                
                <StepperPage activeStep={this.state.activeStep} thisStep={2}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Ich mache mir Sorgen wegen der Schmerzen durch die Wehen und während der Entbindung.</FormLabel>
                    <CustomErrorMessage name="praqr202" />
                    <RadioGroup aria-label="position" name="praqr202" value={this.props.values.praqr202} onChange={this.props.handleChange} required={true}>
                      <NoBarelySometimesPrettyMuch />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={3}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Ich bin über die Tatsache besorgt, dass ich nach der Schwangerschaft meine Figur nicht zurückbekommen werde.</FormLabel>
                    <CustomErrorMessage name="praqr203" />
                    <RadioGroup aria-label="position" name="praqr203" value={this.props.values.praqr203} onChange={this.props.handleChange} required={true}>
                      <NoBarelySometimesPrettyMuch />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={4}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Ich denke manchmal, dass unser Kind bei schlechter Gesundheit oder anfällig für Krankheiten sein wird.</FormLabel>
                    <CustomErrorMessage name="praqr204" />
                    <RadioGroup aria-label="position" name="praqr204" value={this.props.values.praqr204} onChange={this.props.handleChange} required={true}>
                      <NoBarelySometimesPrettyMuch />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={5}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Ich mache mir Sorgen, dass ich ein unattraktives Erscheinungsbild habe.</FormLabel>
                    <CustomErrorMessage name="praqr205" />
                    <RadioGroup aria-label="position" name="praqr205" value={this.props.values.praqr205} onChange={this.props.handleChange} required={true}>
                      <NoBarelySometimesPrettyMuch />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={6}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Ich mache mir Sorgen darum, mich während der Wehen nicht unter Kontrolle zu haben und befürchte, dass ich schreien werde.</FormLabel>
                    <CustomErrorMessage name="praqr206" />
                    <RadioGroup aria-label="position" name="praqr206" value={this.props.values.praqr206} onChange={this.props.handleChange} required={true}>
                      <NoBarelySometimesPrettyMuch />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={7}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Ich mache mir über meine hohe Gewichtszunahme Sorgen.</FormLabel>
                    <CustomErrorMessage name="praqr207" />
                    <RadioGroup aria-label="position" name="praqr207" value={this.props.values.praqr207} onChange={this.props.handleChange} required={true}>
                      <NoBarelySometimesPrettyMuch />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={8}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Ich habe Angst davor, dass das Baby geistig beeinträchtigt sein oder unter einer Gehirnschädigung leiden könnte.</FormLabel>
                    <CustomErrorMessage name="praqr208" />
                    <RadioGroup aria-label="position" name="praqr208" value={this.props.values.praqr208} onChange={this.props.handleChange} required={true}>
                      <NoBarelySometimesPrettyMuch />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={9}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Ich habe Angst, dass unser Baby tot geboren wird oder kurz nach der Entbindung stirbt.</FormLabel>
                    <CustomErrorMessage name="praqr209" />
                    <RadioGroup aria-label="position" name="praqr209" value={this.props.values.praqr209} onChange={this.props.handleChange} required={true}>
                      <NoBarelySometimesPrettyMuch />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={10}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Ich habe Angst, dass das Baby körperlich beeinträchtigt sein könnte oder fürchte, dass etwas körperlich mit dem Baby nicht stimmt.</FormLabel>
                    <CustomErrorMessage name="praqr210" />
                    <RadioGroup aria-label="position" name="praqr210" value={this.props.values.praqr210} onChange={this.props.handleChange} required={true}>
                      <NoBarelySometimesPrettyMuch />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={11}>
                  <Typography variant="h2">Fragebogen absenden</Typography>
                  Vielen Dank! Sie können den Fragebogen nun absenden.
                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                    <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                  </div>
                </StepperPage>

                {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}       
              </Container>  
            </div>
          </form>
        </main>
      <Footer showMenu={false} />
    </>
    );
  }
}

export default withNotifications(withFormik({
  mapPropsToValues: () => ({
    praqr201: '',
    praqr202: '',
    praqr203: '',
    praqr204: '',
    praqr205: '',
    praqr206: '',
    praqr207: '',
    praqr208: '',
    praqr209: '',
    praqr210: '',
  }),  
  validationSchema: Yup.object().shape({
    praqr201: Yup.number().required('Pflichtfeld'),
    praqr202: Yup.number().required('Pflichtfeld'),
    praqr203: Yup.number().required('Pflichtfeld'),
    praqr204: Yup.number().required('Pflichtfeld'),
    praqr205: Yup.number().required('Pflichtfeld'),
    praqr206: Yup.number().required('Pflichtfeld'),
    praqr207: Yup.number().required('Pflichtfeld'),
    praqr208: Yup.number().required('Pflichtfeld'),
    praqr209: Yup.number().required('Pflichtfeld'),
    praqr210: Yup.number().required('Pflichtfeld'),
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "praq_r2",
      questionnaireData: values,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
    });
  }
})(PRAQ_R2));

/* https://stackoverflow.com/questions/59497550/handling-formik-form-when-validation-error-occurs */