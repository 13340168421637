import React from 'react';

import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';


export default function InPatientStayList(props) {
  const theme = useTheme(); 

  return (
    <>
      {
        props.data && 
        props.data.map((value, index) => {
          return (
            <div key={index} style={{margin: "1em 0", padding: "0.5em", backgroundColor: theme.palette.secondary.main}}>
              <small><b>{value["date_start"]} - {value["date_end"]}</b> ({value["hospital"]})</small>
              <p>{value["diagnosis"]}</p>
              <p>{value["therapy"]}</p>
            </div>
          )
        })
      }
    </>
  )
}