import React, { useEffect, useState } from "react";
import axios from 'axios';

import Globals from 'services/Globals';

import Header from "components/Header/Header";

import Textfield from "components/Textfield/Textfield";
import RadioCheckboxesYN from "components/RadioCheckboxesYN/RadioCheckboxesYN";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { TextField, Typography } from '@material-ui/core';

import "./Anamnesis.scss";
import Footer from "components/Footer/Footer";

export default function Anamnesis() {

  const [data, setData] = useState({
    alter_jahre: "",
    gewicht_prepreg: "",
    groesse: "",
    gravida: "",
    para: "",
    katalog_a01: "",
    katalog_a02: "",
    katalog_a03: "",
    katalog_a04: "",
    katalog_a05: "",
    katalog_a06: "",
    katalog_a07: "",
    katalog_a08: "",
    katalog_a09: "",
    katalog_a10: "",
    katalog_a11: "",
    katalog_a12: "",
    katalog_a13: "",
    katalog_a14: "",
    katalog_a15: "",
    katalog_a16: "",
    katalog_a17: "",
    katalog_a18: "",
    katalog_a19: "",
    katalog_a20: "",
    katalog_a21: "",
    katalog_a22: "",
    katalog_a23: "",
    katalog_a24: "",
    katalog_a25: "",
    katalog_a26: "",
    fambelastungtxt: "",
    katalog_a02_txt: "",
    katalog_a04_txt: "",
    katalog_a21_txt: "",
    katalog_a22_txt: "",
    katalog_a24_txt: "",
    katalog_a26_txt: "",
    katalog_a_risk: "",
    weiterebesonder: "",
    katalog_a_random_yn: "",
    sonstigeinfo: "",
    katalog_a_random: "",
    ergaenzungatxt: "",
  });

  useEffect(() => {
    axios.get(Globals.SERVER_URL + "/dmr/anamnesis").then(function (res) {
      
      if ( !res.data ) return;

      let newData = res.data;
      console.log(newData);

      if ( typeof newData === 'object' && newData !== null ) {
        Object.keys(newData).forEach(key => {
          if ( newData[key] === undefined || newData[key] === null ) {
            newData[key] = "";
          }
        })
        setData(newData);
      }

    }).catch(function (err) {
      console.log(err);
    });
  }, []);

  return (
    <>
      <Header>
        <Typography variant="h2">Anamnese (Vorgeschichte) und erste Vorsorge-Untersuchung</Typography>
      </Header>
      <Container maxWidth="lg">
        <main>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField fullWidth value={data.alter_jahre} disabled label="Alter (Jahre)" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth value={data.gewicht_prepreg} disabled label="Gewicht vor SS-Beginn (kg)" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth value={data.groesse} disabled label="Größe (cm)" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth value={data.gravida} disabled label="Gravida" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth value={data.para} disabled label="Para" />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              1. Familiäre Belastung (z.B. Diabetes, Hypertonie, Fehlbildungen,
              genetische Krankheiten, psychische Krankheiten) <br />
              <TextField fullWidth value={data.fambelastungtxt} disabled label="Bemerkung" />
            </Grid>
            <Grid item xs={3}>
            <RadioCheckboxesYN value={data.katalog_a01} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              2. Frühere eigene schwere Erkrankungen (z.B. Herz, Lunge, Leber,
              Nieren, ZNS, Psyche), ggfs. welche <br />
              <TextField fullWidth value={data.katalog_a02_txt} disabled label="Bemerkung" />
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a02} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              3. Blutungs-/Thromboseneigung <br />
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a03} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              4. Allergie, z.B. gegen Medikamente <br />
              <TextField fullWidth value={data.katalog_a04_txt} disabled label="Bemerkung" />
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a04} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              5. Frühere Bluttransfusionen <br />
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a05} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              6. Besondere psychische Belastungen (z.B. familiär o. beruflich){" "}
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a06} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              7. Besondere soziale Belastung (Integrationsprobleme, wirtsch.
              Probleme)
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a07} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              8. Rhesus-Inkompabilität (bei vorangegangenen Schwangerschaften){" "}
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a08} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              9. Diabetes mellitus
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a09} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              10. Adipositas
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a10} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              11. Kleinwuchs
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a11} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              12. Skelettanomalien
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a12} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              13. Schwangere unter 18 Jahren
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a13} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              14. Schwangere über 35 Jahren
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a14} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              15. Vielgebärende (mehr als 4 Kinder)
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a15} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              16. Zustand nach Sterilitätsbehandlung
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a16} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              17. Zustand nach Frühgeburt (vor Ende der 37. SSW)
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a17} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              18. Zustand nach Mangelgeburt
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a18} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              19. Zustand nach 2 oder mehr Fehlgeburten/Abbrüchen
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a19} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              20. Totes/geschädigtes Kind in der Anamnese
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a20} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              21. Komplikationen bei vorausgegangen Entbindungen <br />
              <TextField fullWidth value={data.katalog_a21_txt} disabled label="Bemerkung" />
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a21} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              22. Komplikationen post partum <br />
              <TextField fullWidth value={data.katalog_a22_txt} disabled label="Bemerkung" />
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a22} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              23. Zustand nach Sectio
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a23} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              24. Zustand nach anderen Uterusoperationen <br />
              <TextField fullWidth value={data.katalog_a24_txt} disabled label="Bemerkung" />
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a24} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              25. Rasche Schwangerschaftsfolge (weniger als 1 Jahr)
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a25} />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="anamnesis-item">
            <Grid item xs={9}>
              26. Andere Besonderheiten <br />
              <TextField fullWidth value={data.katalog_a26_txt} disabled label="Bemerkung" />
            </Grid>
            <Grid item xs={3}>
              <RadioCheckboxesYN value={data.katalog_a26} />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{ background: "Salmon", border: "1px solid #000" }}
          >
            <Grid item sm={9}>
              Nach ärztlicher Bewertung des Kataloges A liegt bei der
              Erstuntersuchung ein Schwangerschaftsrisiko vor
            </Grid>
            <Grid item sm={3}>
              <RadioCheckboxesYN value={data.katalog_a} />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField fullWidth value={data.weiterebesonder} disabled label="Besonderheiten" />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              { /*<b>Besonderheiten zu den Katalogen A. und B.</b>
              <br />
              <Textfield
                
                textarea={true}
                rows="8"
              /> */ }
              <TextField fullWidth value={data.sonstigeinfo} disabled label="Sonstige Info" />
              <TextField fullWidth value={data.ergaenzungatxt} disabled label="Ergänzungstext" />
            </Grid>
          </Grid>
        </main>
        <Footer />
      </Container>
    </>
  );
}