import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { InputLabel, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
// import DateAdapter from '@materia-ui/ lab/AdapterMoment';


import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'


import 'scenes/Questionnaires/Questionnaire.scss';


class InitialQuestionnaire extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Geburtsmeldung</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/2) * 100 } />
          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />
          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "delivery_date": 1,
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <div style={{textAlign: "center", marginTop: "1em"}}>
                  <Typography variant="h2">Hat die Geburt stattgefunden?</Typography>
                  <p>Bitte melden Sie uns die Geburt sowie das Datum, damit wir die App entsprechend auf Sie anpassen können.</p>
                  <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Geburtsdatum angeben</Button>
                </div>
              </StepperPage>
            </Container>

            <div className="questionnaire-body">
              <Container maxWidth="lg">
                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">An welchem Tag fand die Geburt statt?</FormLabel>
                    <CustomErrorMessage name="delivery_date" />
                    <TextField
                      id="delivery_date"
                      label="Tag der Geburt"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.props.values.delivery_date}
                      onChange={this.props.handleChange}
                    />
                  </FormControl>

                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                    <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                  </div>
                </StepperPage>

                {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}       
              </Container>  
            </div>
          </form>
        </main>
      <Footer/>
    </>
    );
  }
}

export default withFormik({
  mapPropsToValues: () => ({
    delivery_date: '',
  }),  
  validationSchema: Yup.object().shape({
    delivery_date: Yup.date().required('Pflichtfeld'),
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/setup/delivery-note", {
      delivery_date: values.delivery_date,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      // TODO show error
      console.log(err);
    });
  }
})(InitialQuestionnaire);

/* https://stackoverflow.com/questions/59497550/handling-formik-form-when-validation-error-occurs */