import React from 'react';

export default function NotEnoughMeasurements() {
  return (
    <>
      <b>Noch nicht ausreichend Daten.</b>

      <p>Diese Anzeige steht erst zur Verfügung, wenn mindestens zwei Messungen durchgeführt wurden.</p>
      <p>Messungen werden einmal täglich durch das System synchronisiert.</p>
      <p>Falls diese Meldung weiterhin angezeigt wird, obwohl Sie regelmäßig Messungen durchführen, wenden Sie sich bitte an die Studienleitung.</p>
    </>
  )
}