import React from "react";

import Header from '../../components/Header/Header';
import Footer from 'components/Footer/Footer';
import { Box, Container, Grid, ImageList, ImageListItem, Typography } from "@material-ui/core";
import MenuCard from "components/MenuCard/MenuCard";
import MenuCardGridItem from "components/MenuCardGridItem/MenuCardGridItem";
import ActivityItem from "components/ActivityItem/ActivityItem";
import { faBabyCarriage, faClipboardList, faEnvelope, faInfo, faMusic, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopSpacer from "components/TopSpacer/TopSpacer";

export default function More() {
  return (
    <>
      <Header>
        <Typography variant="h2">Digitaler Mutterpass</Typography>
      </Header>
      <main>
        <Container maxWidth="lg">
          <TopSpacer />
          <Typography component="h2" variant="h3">Zusatz-Inhalte</Typography>
          <ul className="activity-list">
            <ActivityItem to="/my-pregnancy-week" title="Meine Schwangerschaftswoche" icon={faBabyCarriage} />
            <ActivityItem to="/informations" title="Information &amp; Wissen" icon={faInfo} />
            <ActivityItem to="/mindfulness" title="Achtsamkeitsübungen" icon={faMusic} />
            <ActivityItem to="/journal" title="Gefühls-Tagebuch" icon={faClipboardList} />
          </ul>

          <Typography component="h2" variant="h3">Studie</Typography>
          <ul className="activity-list">
            <ActivityItem to="/profile" title="Mein Profil &amp; Konto" icon={faUser} />
            <ActivityItem to="/help-contact" title="Hilfe &amp; Kontakt" icon={faEnvelope}/>
          </ul>
        </Container>
      </main>
      <Footer />
    </>
  );
}