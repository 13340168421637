import React from 'react';

import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';


import './NotificationModal.scss';


function NotificationModal(props) {
  // Declare a new state variable, which we'll call "count"
  return (
      <>
        { props.notifications.length > 0 && 
            <div className="notification-modal">
              <div className="notification-modal-content">
                <Typography variant="h2">Hinweis</Typography>
                {props.notifications}
                <br/><br/>
                <Button variant="contained" color="primary" size="small" disableElevation onClick={props.clear}>
                  OK
                </Button>
              </div>
            </div>
        }
      </>
  );
}

export default NotificationModal;