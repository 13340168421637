import React from "react";

import {Link} from "react-router-dom";

import Button from "@material-ui/core/Button";

import "./Start.scss";

export default class StartDMR extends React.Component {

    render() {
        return (
            <div className="start-container">
                <div className="smartstart-logo"></div>
                <div className="digital-maternity-log-caption">
                    <h1>
                        <span style={{color: "#783476"}}>Digitaler</span>{" "}
                        <span style={{color: "#ff6bae"}}>Mutterpass</span>
                    </h1>
                </div>
                <div className="start-button">
                    <Button
                        component={Link}
                        to="/overview"
                        variant="contained"
                        color="primary"
                        style={{color: "white", fontSize: "20px", textTransform: "none"}}
                        disableElevation
                    >
                        Öffnen
                    </Button>
                </div>
                <div className="smartstart-text">
                    <span style={{color: "#783476"}}>SMART</span>{" "}
                    <span style={{color: "#ff6bae"}}>Start</span>
                </div>
            </div>
        );
    }
}