import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class StemCells extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Untersuchungen &amp; Vorsorge &gt; Stammzellen in der Nabelschnur</div>
            
            <Typography variant="h1">Stammzellen in der Nabelschnur</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Was sind Stammzellen und was können Sie bewirken? Hier erfahren Sie alles über die Nabelschnur und Ihre faszinierenden Möglichkeiten.</b></p>

            <p>Vielleicht haben Sie ja schon davon gehört, dass im Nabelschnurblut viele Stammzellen enthalten sind und sich Gedanken gemacht, ob Sie diese verwenden möchten. Nabelschnurblut ist viel zu wertvoll, um es einfach wegzuwerfen. Daher möchte ich Ihnen zu diesem Thema einiges erzählen.  </p>
            <Typography variant="h2">Was sind Stammzellen?</Typography>
            <p>Stammzellen sind Zellen, aus denen jedes andere Gewebe im Körper entstehen kann. Sie können sich zum Beispiel in Lungengewebe, aber auch zu Hautzellen oder Knochen differenzieren. Viele dieser Zellen befinden sich zum Zeitpunkt der Geburt im Blut des Kindes. Später sind diese Zellen nur noch im Knochenmark zu finden. Die Stammzellen verlieren im Laufe des Lebens durch Alterungsprozesse aber zunehmend ihre Differenzierungsfähigkeit. </p>
            <Typography variant="h2">Entnahme aus der Nabelschnur</Typography>
            <p>Die Stammzellen können nach der Geburt Ihres Kindes leicht aus der Nabelschnur entnommen werden ohne dass Einfluss auf die Geburt genommen wird. Ihr Kind kommt ganz normal auf die Welt. Danach wird die Nabelschnur durchtrennt, bei einer normalen Geburt übernimmt dies häufig der Vater des Kindes. Danach dürfen Sie Ihr Kind, wenn es ihm gut geht, direkt in den Arm nehmen und mit ihm Bonden. In dieser Zeit werden die Stammzellen, die sich in der Nabelschnur befinden abgenommen. </p>
            <Typography variant="h2">Aktuelle Forschung</Typography>
            <p>Aktuell werden verschiedene Verfahren zur Verwendung der Stammzellen erforscht. Diese beschäftigen sich unter anderem damit, ob man mit Stammzellen Erkrankungen wie einen Typ 1 Diabetes oder einen frühkindlichen Hirnschaden heilen kann. Hier muss aber die Forschung noch große Schritte tun, damit dieses möglich ist. Ob dies in Zukunft zum Beispiel in 10 oder 20 Jahren möglich sein wird, ist zum aktuellen Zeitpunkt leider noch nicht klar. </p>
            <Typography variant="h2">Einlagerung der Stammzellen</Typography>
            <p>Für werdende Eltern, die sich diese Option für ihr Kind offen halten möchten, besteht die Möglichkeit die Stammzellen in der Nabelschnur ihres eigenen Kindes einzulagern. Dies kostet jedoch rund 2500 Euro und ist über verschiedene Anbieter möglich. Falls Sie über diese Möglichkeit nachdenken, sollten Sie frühzeitig mit dem jeweiligen Anbieter Kontakt aufnehmen, damit alle Formalitäten vor der Entbindung geregelt sind und Sie sich während der Entbindung nicht darum kümmern müssen. </p>
            <Typography variant="h2">Spende der Stammzellen</Typography>
            <p>Auch für den Fall, dass Sie die Stammzellen nicht für Ihr eigenes Kind einlagern möchten, gibt es eine gute Verwendungsmöglichkeit. Die meisten Paare entscheiden sich für eine Spende der Stammzellen. Diese Stammzellen stehen dann für die Allgemeinheit zur Verfügung. Wenn ein anderes Kind eine Leukämie hat, können vielleicht die Stammzellen aus dem Nabelschnurblut Ihres Kindes diesem Kind  das Leben retten und bei einer Stammzellspende transplantiert werden, wenn kein anderer Spender gefunden wird. Diese Möglichkeit, die Stammzellen zu verwenden ist kostenfrei für Sie an bestimmten Kliniken möglich. Die Universitätsfrauenklinik Erlangen bietet den Frauen, die zur Geburt kommen diese Möglichkeit an. Der Arzt und die Hebammen entscheiden hierfür, ob die Spende von Stammzellen bei Ihnen möglich ist, da bestimmte Voraussetzungen erfüllt sein müssen. </p>

            <p><b>Was die Stammzellen in Zukunft noch alles erreichen können – das steht noch in den Sternen, aber schon jetzt können Sie mit Ihrer Spende Leben retten.</b></p>

          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default StemCells;