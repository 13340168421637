import React, { useEffect, useState } from 'react';

import { useTheme } from '@material-ui/core/styles';


import './MappingBoxedItem.scss';

export default function MappingBoxedItem(props) {

  const [data, setData] = useState();
  const {value, ...childProps} = props;

  useEffect(() => {
    if ( props.mapping && props.mapping[value] ) {
      setData(props.mapping[value]);
    } 
    else {
      setData(value);
    }
    
  });


  const theme = useTheme(); 

    return (
      <>
        <div className="boxed-item">
          <i><small>{props.name }</small></i>
          <div>{data}</div>
        </div>
      </>
    );  
}