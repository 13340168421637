import React from 'react';
import { toggleMenu } from 'helpers/MenuHelper';
import { Link } from "react-router-dom";


export default function MenuListItem(props) {
  return (
    <li>
      <Link to={props.destination} onClick={toggleMenu}>
        {props.icon} {props.label}
      </Link>
    </li>);
}
