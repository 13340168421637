import React from 'react';

class ErrorFocus extends React.Component {
  componentDidUpdate(props) {
    const { isSubmitting, isValidating, errors } = props.parentProps;
    const keys = Object.keys(errors);
    if (keys.length > 0 && isSubmitting && !isValidating) {
     
      // Navigate to the respective slider step if applicable
      if (this.props.setStep && this.props.stepMapping) {
        let step = this.props.stepMapping[keys[0]];
        this.props.setStep(step);  
      }

      // Focus on the respective element if applicable
      const selector = `[name="${keys[0]}"]`;
      const errorElement = document.querySelector(selector);
      if (errorElement) {
        errorElement.focus();
      }
      
    }
  }

  render() {
    return null;
  }
}

export default ErrorFocus;
