import React from "react";

import TextField from "@material-ui/core/TextField";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Textfield from "components/Textfield/Textfield";
import RadioCheckboxes from "components/RadioCheckboxes/RadioCheckboxes";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

class UltrasoundBasicExaminations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0
        };

        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(event, newValue) {
        this.setState({
            activeTab: newValue
        });
    }

    render() {
        return (
            <>
                <AppBar position="static" className="margin-bottom-2em">
                    <Tabs
                        value={this.state.activeTab}
                        onChange={this.handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        color="secondary"
                        style={{background: "grey"}}
                    >
                        <Tab value={0} label="I. Screening 8+0 bis 11+6 SSW" />
                        <Tab value={1} label="II. Screening 18+0 bis 21+6 SSW" />
                        <Tab value={2} label="III. Screening 28+0 bis 31+6 SSW" />
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.activeTab} index={0}> 
                    <main>
                        <Container>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Textfield identifier="ultrasound_basic_I_date" label="Datum" />
                                </Grid>
                                <Grid item xs={4}>
                                    <Textfield
                                        identifier="ultrasound_basic_I_pregnancy_week"
                                        label="SSW (LR)"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Textfield
                                        identifier="ultrasound_basic_I_pregnancy_week_corrected"
                                        label="SSW korr."
                                    />
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    Intrauteriner Sitz:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_I_intrauterine_position"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Embryo darstellbar:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_I_embryo"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Herzaktion:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_I_heart_action"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Mehrlinge:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_I_multifetal"
                                        options={[
                                            {value: "neg", label: "nein"},
                                            {value: "pos", label: "ja"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Monochorial:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_I_monochorial"
                                        options={[
                                            {value: "neg", label: "nein"},
                                            {value: "pos", label: "ja"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Auffälligkeiten:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_I_suspicious"
                                        options={[
                                            {value: "neg", label: "nein"},
                                            {value: "pos", label: "ja"},
                                            {value: "control", label: "Kontrolle"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Zeitgerechte Entwicklung:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_I_timely_development"
                                        options={[
                                            {value: "neg", label: "nein"},
                                            {value: "pos", label: "ja"},
                                            {value: "control", label: "Kontrolle"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Konsiliaruntersuchung veranlasst:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_I_additional_examination"
                                        options={[
                                            {value: "neg", label: "nein"},
                                            {value: "pos", label: "ja"},
                                            {value: "control", label: "Kontrolle"}
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Textfield identifier="ultrasound_basic_I_fs" label="FS" />
                                </Grid>
                                <Grid item xs={4}>
                                    <Textfield identifier="ultrasound_basic_I_ssl" label="SSL" />
                                </Grid>
                                <Grid item xs={4}>
                                    <Textfield identifier="ultrasound_basic_I_bpd" label="BPD" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Textfield
                                        identifier="ultrasound_basic_I_biometry_I"
                                        label="Biometrie I"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Textfield
                                        multiline
                                        rows="3"
                                        identifier="ultrasound_basic_I_remarks"
                                        label="Bemerkungen"
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                    </main>
                </TabPanel>
                <TabPanel value={this.state.activeTab} index={1}>
                    <main>
                        <Container>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Textfield
                                        identifier="ultrasound_basic_II_date"
                                        label="Datum"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Textfield
                                        identifier="ultrasound_basic_II_pregnancy_week"
                                        label="SSW (LR)"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Textfield
                                        identifier="ultrasound_basic_II_pregnancy_week_corrected"
                                        label="SSW korr."
                                    />
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <b>a)</b>
                                </Grid>
                                <Grid item xs={6}>
                                    Einling:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_singleton"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Herzaktion:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_heart_action"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Plazentalok./-struktur:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_placenta_localisation_structure"
                                        options={[
                                            {value: "normal", label: "Normal"},
                                            {value: "control", label: "Kontrolle"}
                                        ]}
                                    />
                                    <TextField
                                        identifier="ultrasound_basic_II_placenta_localisation_structure_remark"
                                        label="Kommentar"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Zeitgerechte Entwicklung:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_timely_development"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"},
                                            {value: "control", label: "Kontrolle"}
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <b>
                                        b)
                                        <br />
                                        Kopf:
                                    </b>
                                </Grid>
                                <Grid item xs={6}>
                                    Ventrikelauffälligkeiten
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_ventricular_abnormalities"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Auffälligkeiten der Kopfform
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_head_form_abnormalities"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Darstellbarkeit des Kleinhirns
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_cerebellum"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <b>Hals und Rücken:</b>
                                </Grid>
                                <Grid item xs={6}>
                                    Unregelmäßigkeiten der dorsalen Hautkontur
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_dorsal_skin_contour_irregularities"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <b>Thorax:</b>
                                </Grid>
                                <Grid item xs={6}>
                                    Auffällige Herz/Thorax-Relation (Blickdiagnose)
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_consipicous_heart_thorax_relation"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Linksseitige Herzposition:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_left_heart_position"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Persistierende Arrhythmie im Untersuchungszeitraum
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_persistent_arrhythmia_during_examination"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Darstellbarkeit des Vier-Kammer-Blicks
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_heart_four_chamber_depictable"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <b>Rumpf:</b>
                                </Grid>
                                <Grid item xs={6}>
                                    Konturunterbrechung an der vorderen Bauchwand
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_disruption_frontal_abdomen"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Darstellbarkeit des Magens im linken Oberbauch
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_stomach_left_upper_abdomen_depictable"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Darstellbarkeit der Harnblase
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_bladder_depictable"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={3}>
                                <Grid item xs={2}>
                                    <Textfield identifier="ultrasound_basic_II_bpd" label="BPD" />
                                </Grid>
                                <Grid item xs={3}>
                                    <Textfield
                                        identifier="ultrasound_basic_II_fod_ku"
                                        label="FOD/KU"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Textfield identifier="ultrasound_basic_II_atd" label="ATD" />
                                </Grid>
                                <Grid item xs={3}>
                                    <Textfield
                                        identifier="ultrasound_basic_II_apd_au"
                                        label="APD/AU"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Textfield identifier="ultrasound_basic_II_fl" label="FL" />
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <b>Kontrollbedürftige Hinweise hinsichtlich:</b>
                                </Grid>
                                <Grid item xs={6}>
                                    Fruchtwassermenge:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_amniotic_fluid_volume_control_indication"
                                        options={[
                                            {value: "neg", label: "nein"},
                                            {value: "pos", label: "ja"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    körperlicher Entwicklung:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_body_development_control_indication"
                                        options={[
                                            {value: "neg", label: "nein"},
                                            {value: "pos", label: "ja"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Konsiliaruntersuchung veranlasst:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_II_additional_examination"
                                        options={[
                                            {value: "neg", label: "nein"},
                                            {value: "pos", label: "ja"}
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Textfield
                                        identifier="ultrasound_basic_II_biometry_II"
                                        label="Biometrie II"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Textfield
                                        multiline
                                        rows="3"
                                        identifier="ultrasound_basic_II_remarks"
                                        label="Bemerkungen"
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                    </main>
                </TabPanel>
                <TabPanel value={this.state.activeTab} index={2}>
                    <main>
                        <Container>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Textfield
                                        identifier="ultrasound_basic_III_date"
                                        label="Datum"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Textfield
                                        identifier="ultrasound_basic_III_pregnancy_week"
                                        label="SSW (LR)"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Textfield
                                        identifier="ultrasound_basic_III_pregnancy_week_corrected"
                                        label="SSW korr."
                                    />
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    Einling:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_III_singleton"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Kindslage:
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        identifier="ultrasound_basic_III_child_position"
                                        label="Kindslage"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Herzaktion:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_III_heart_action"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Plazentalok./-struktur:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_III_placenta_localisation_structure"
                                        options={[
                                            {value: "normal", label: "Normal"},
                                            {value: "control", label: "Kontrolle"}
                                        ]}
                                    />
                                    <TextField
                                        identifier="ultrasound_basic_III_placenta_localisation_structure_remark"
                                        label="Kommentar"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Zeitgerechte Entwicklung:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_III_timely_development"
                                        options={[
                                            {value: "pos", label: "ja"},
                                            {value: "neg", label: "nein"},
                                            {value: "control", label: "Kontrolle"}
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <b>Kontrollbedürftige Hinweise hinsichtlich:</b>
                                </Grid>
                                <Grid item xs={6}>
                                    Fruchtwassermenge:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_III_amniotic_fluid_volume_control_indication"
                                        options={[
                                            {value: "neg", label: "nein"},
                                            {value: "pos", label: "ja"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    körperlicher Entwicklung:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_III_body_development_control_indication"
                                        options={[
                                            {value: "neg", label: "nein"},
                                            {value: "pos", label: "ja"}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    Konsiliaruntersuchung veranlasst:
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioCheckboxes
                                        identifier="ultrasound_basic_III_additional_examination"
                                        options={[
                                            {value: "neg", label: "nein"},
                                            {value: "pos", label: "ja"}
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={3}>
                                <Grid item xs={2}>
                                    <Textfield identifier="ultrasound_basic_III_bpd" label="BPD" />
                                </Grid>
                                <Grid item xs={3}>
                                    <Textfield
                                        identifier="ultrasound_basic_III_fod_ku"
                                        label="FOD/KU"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Textfield identifier="ultrasound_basic_III_atd" label="ATD" />
                                </Grid>
                                <Grid item xs={3}>
                                    <Textfield
                                        identifier="ultrasound_basic_III_apd_au"
                                        label="APD/AU"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Textfield identifier="ultrasound_basic_III_fl" label="FL" />
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Textfield
                                        identifier="ultrasound_basic_III_biometry_III"
                                        label="Biometrie III"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Textfield
                                        multiline
                                        rows="3"
                                        identifier="ultrasound_basic_III_remarks"
                                        label="Bemerkungen"
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                    </main>
                </TabPanel>
            </>
        );
    }
}

export default UltrasoundBasicExaminations;
