import React from 'react';
import axios from 'axios';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Globals from 'services/Globals';

import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

import withNotifications from 'services/NotificationService/withNotifications';


class ChangePassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            newPassword: "",
            repeatNewPassword: "",
        };

        this.changePassword = this.changePassword.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });    
    }

    async changePassword() {
      let self = this;
      // Check if password matches
      if(this.state.newPassword !== this.state.repeatNewPassword) {
        this.props.notificationService.push("Passwörter stimmen nicht überein.", "info");
        return;
      }

      axios.post(Globals.SERVER_URL + "/users/changePassword", {
        newPassword: this.state.newPassword,
      }).then(function(res){
        self.props.notificationService.push("Passwort geändert!", "info");
      }).catch(function(err){
        self.props.notificationService.push("Fehler", "info", 100000, err);
      });
    }

    render() {
        return (
            <>
              <Header>
                <Typography variant="h2">Passwort ändern</Typography>
              </Header>
              <main>
                <Container maxWidth="lg" spacing={1}>
                  <Grid container spacing={1} >
                    <p>Hier können Sie ein neues Passwort festlegen.</p>
                    <Grid item xs={12}>
                      <TextField name="newPassword" value={this.state.newPassword} onChange={this.handleInputChange} label="Neues Passwort" type="password" variant="outlined" fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField name="repeatNewPassword" value={this.state.repeatNewPassword} onChange={this.handleInputChange} label="Neues Passwort wiederholen" type="password" variant="outlined" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" disableElevation onClick={this.changePassword}>Passwort ändern</Button>
                    </Grid>
                  </Grid>
                </Container>
              </main>
              <Footer />
            </>
        );
    }
}

export default withNotifications(ChangePassword);