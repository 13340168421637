import React from 'react';
import axios from 'axios';

import Header from 'components/Header/Header';
import Globals from 'services/Globals';

import { Line } from 'react-chartjs-2';

import { formatISODateToGermanDate } from 'helpers/Helpers';


import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TabPanel } from 'components/TabPanel/TabPanel';

import HeartRateTendencyGraph from '../Graphs/HeartRate/HeartRateTendencyGraph/HeartRateTendencyGraph';
import HeartRateIntervalGraph from '../Graphs/HeartRate/HeartRateIntervalGraph/HeartRateIntervalGraph';
import HeartRateTendencyText from '../Graphs/HeartRate/HeartRateTendencyText/HeartRateTendencyText';
import HeartRateFullGraph from '../Graphs/HeartRate/HeartRateFullGraph/HeartRateFullGraph';


import ObfuscationQuestionnaire from '../ObfuscationStudy/ObfuscationQuestionnaire/ObfuscationQuestionnaire';
import BackToMeasurementsGeneralButton from '../BackToMeasurementsGeneralButton';


import '../Measurements.scss';
import AnswerFewQuestions from '../ObfuscationStudy/AnswerFewQuestions/AnswerFewQuestions';
import Footer from 'components/Footer/Footer';
import NotEnoughMeasurements from '../NotEnoughMeasurementsNotice';
import LoadingNotice from '../LoadingNotice';

class HeartRate extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      heartRateData: {},
      activeTab: 0,
      loading: false
    };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleTabChange(event, newValue) {
    this.setState({
        activeTab: newValue
    });
  }


  componentDidMount() {
    var self = this;

    this.setState({loading: true})

    axios.get(Globals.SERVER_URL + "/measurements/summary/all", {
    }).then(function(res){
      self.setState({loading: false})
      let allDataPerDayList = res.data;
      let heartRatePerDayList = {};

      for (let day in allDataPerDayList) {
        let thisDayHeartRate = allDataPerDayList[day].hr_average;

        // Remove zero values
        if (thisDayHeartRate !== 0) {
          heartRatePerDayList[day] = thisDayHeartRate;
        }
      }

      self.setState({
        heartRateData: heartRatePerDayList
      });

    }).catch(function(err){
      self.setState({loading: false})

      // TODO show error
      console.log(err);
    });
  }



  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Messwerte</Typography>
        </Header>
        
        <AppBar position="static" className="margin-bottom-2em">
          <Tabs value={this.state.activeTab} onChange={this.handleTabChange} variant="scrollable" scrollButtons="auto">
            <Tab value={0} label="Alle Daten" />
            <Tab value={1} label="Monatlich" />
            <Tab value={2} label="Wöchentlich" />
            <Tab value={3} label="Text" />
          </Tabs>
        </AppBar>

        <main>
          <Container maxWidth="lg">

            <div className="breadcrumbs">Messwerte &gt; Herzfrequenz</div>
            
            <Typography variant="h1">Herzfrequenz</Typography>

            <BackToMeasurementsGeneralButton />
            <br/><br/>
            <>
            { this.state.loading
            ? <LoadingNotice />
            : (this.state.heartRateData && Object.keys(this.state.heartRateData).length <= 2)
              ? <NotEnoughMeasurements />
              : 
              <>
                <TabPanel value={this.state.activeTab} index={0}>
                  <HeartRateFullGraph data={this.state.heartRateData} />
                  <AnswerFewQuestions />
                  <ObfuscationQuestionnaire location="regular-measurements" type="heart-rate-fullgraph" data={this.state.heartRateData} />
                </TabPanel>
                <TabPanel value={this.state.activeTab} index={1}>
                  <HeartRateTendencyGraph data={this.state.heartRateData} />
                  <AnswerFewQuestions />
                  <ObfuscationQuestionnaire location="regular-measurements" type="heart-rate-tendencygraph" data={this.state.heartRateData} />
                </TabPanel>
                <TabPanel value={this.state.activeTab} index={2}>
                  <HeartRateIntervalGraph data={this.state.heartRateData} />
                  <AnswerFewQuestions />
                  <ObfuscationQuestionnaire location="regular-measurements" type="heart-rate-intervalgraph" data={this.state.heartRateData} />
                </TabPanel>
                <TabPanel value={this.state.activeTab} index={3}>
                  <HeartRateTendencyText data={this.state.heartRateData} />
                  <AnswerFewQuestions />
                  <ObfuscationQuestionnaire location="regular-measurements" type="heart-rate-tendencytext" data={this.state.heartRateData} />
                </TabPanel>
              </>
            }
            </>
          </Container>
        </main>

        <Footer />
      </>
    );
  }
}

export default HeartRate;