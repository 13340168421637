import React from 'react';

import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import { sizing } from '@material-ui/system';



class LabRubellaEdit extends React.Component {
  constructor(props) {
    super(props);

    if(this.props.data) {
      this.state = this.props.data;
    }
    else {
      this.state = {
        type: ""
      };
    }

    this.save = this.save.bind(this);
    this.changeField = this.changeField.bind(this);
    this.changeType = this.changeType.bind(this);
  }

  changeType(evt) {
    this.setState({
      type: evt.target.value
    })
  }

  changeField(evt)  {
    const value = evt.target.value;
    this.setState({
      ...this.state,
      [evt.target.name]: value
    });
  }

  save() {
    this.props.updateFunction(this.state);
  }



  render() {
    
    return (
      <>
        <h3>Neuer Eintrag</h3>
        <Select value={this.state.type} onChange={this.changeType}>
          <MenuItem value={"blood_group"}>Blutgruppe</MenuItem>
          <MenuItem value={"antibodies_search_test"}>Antikörper-Suchtest</MenuItem>
          <MenuItem value={"rubella_antibody_test"}>Röteln-Antikörpertest</MenuItem>
          <MenuItem value={"chlamydia_trachomatis"}>Chlamydia Trachomatis Antigen-Nachweis</MenuItem>
          <MenuItem value={"lues_search_reaction"}>Lues-Such-Reaktionstest</MenuItem>
          <MenuItem value={"hepatitis_b_antigen"}>Hepatitis-B Antigen</MenuItem>
          <MenuItem value={"hiv_examination"}>HIV-Test</MenuItem>
          <MenuItem value={"others"}>Sonstige</MenuItem>
          <MenuItem value={"generic"}>Freitextangabe</MenuItem>
        </Select>

        <hr/>

        { this.state.type === "blood_group" &&
          <>
            Ergebnis: <Select name="result" value={this.state["result"] || ""} onChange={this.changeField}>
              <MenuItem value={"a+"}>A+</MenuItem>
              <MenuItem value={"0+"}>0+</MenuItem>
              <MenuItem value={"b+"}>B+</MenuItem>
              <MenuItem value={"a-"}>A-</MenuItem>
              <MenuItem value={"0-"}>0-</MenuItem>
              <MenuItem value={"ab+"}>AB+</MenuItem>
              <MenuItem value={"b-"}>B-</MenuItem>
              <MenuItem value={"ab-"}>AB-</MenuItem>
            </Select><br/>

            Datum: <TextField name="date" value={this.state["date"]} onChange={this.changeField}></TextField><br/>
            Labor-Nr.: <TextField name="lab_nr" value={this.state["lab_nr"]} onChange={this.changeField}></TextField><br/>
            Bemerkung: <TextField name="remark" value={this.state["remark"]} onChange={this.changeField}></TextField><br/>

            <Button variant="contained" color="primary" onClick={this.save} disableElevation>Speichern</Button>
          </>
        }

        { this.state.type === "rubella_antibody_test" &&
          <>
            Ergebnis: <Select name="result" value={this.state["result"] || ""} onChange={this.changeField}>
              <MenuItem value={""}></MenuItem>
              <MenuItem value={"positive"}>Positiv</MenuItem>
              <MenuItem value={"negative"}>Negativ</MenuItem>
            </Select><br/>

            Wert: <TextField name="value" value={this.state["value"]} onChange={this.changeField} width="25%"></TextField> <Select name="unit" value={this.state["unit"] || ""} onChange={this.changeField}>
              <MenuItem value={""}></MenuItem>
              <MenuItem value={"Titer 1"}>Titer 1</MenuItem>
              <MenuItem value={"IE/ml"}>IE/ml</MenuItem>
            </Select><br/>
            Immunität anzunehmen: <Select name="immunity" value={this.state["immunity"] || ""} onChange={this.changeField}>
              <MenuItem value={""}></MenuItem>
              <MenuItem value={"yes"}>Ja</MenuItem>
              <MenuItem value={"no"}>Nein</MenuItem>
            </Select><br/>
            Datum: <TextField name="date" value={this.state["date"]} onChange={this.changeField}></TextField><br/>
            Labor-Nr.: <TextField name="lab_nr" value={this.state["lab_nr"]} onChange={this.changeField}></TextField><br/>
            Ggfs. erg. serologische Untersuchungen: <TextField name="additional_serologic_examinations" value={this.state["additional_serologic_examinations"]} onChange={this.changeField}></TextField><br/>            
            Bemerkung: <TextField name="remark" value={this.state["remark"]} onChange={this.changeField}></TextField><br/>

            <Button variant="contained" color="primary" onClick={this.save} disableElevation>Speichern</Button>
          </>
        }

        { this.state.type === "hiv_examination" &&
          <>
            Situation: <Select name="state" value={this.state["result"] || ""} onChange={this.changeField}>
              <MenuItem value={""}></MenuItem>
              <MenuItem value={"done"}>Durchgeführt</MenuItem>
            </Select><br/>
            Datum: <TextField name="date" value={this.state["date"]} onChange={this.changeField}></TextField><br/>
            Labor-Nr.: <TextField name="lab_nr" value={this.state["lab_nr"]} onChange={this.changeField}></TextField><br/>
            Bemerkung: <TextField name="remark" value={this.state["remark"]} onChange={this.changeField}></TextField><br/>

            <Button variant="contained" color="primary" onClick={this.save} disableElevation>Speichern</Button>
          </>
        }


        { ["chlamydia_trachomatis", "lues_search_reaction", "hepatitis_b_antigen"].includes(this.state.type) &&
          <>
            Ergebnis: <Select name="result" value={this.state["result"] || ""} onChange={this.changeField}>
              <MenuItem value={""}></MenuItem>
              <MenuItem value={"positive"}>Positiv</MenuItem>
              <MenuItem value={"negative"}>Negativ</MenuItem>
            </Select><br/>
            Datum: <TextField name="date" value={this.state["date"]} onChange={this.changeField}></TextField><br/>
            Labor-Nr.: <TextField name="lab_nr" value={this.state["lab_nr"]} onChange={this.changeField}></TextField><br/>
            Bemerkung: <TextField name="remark" value={this.state["remark"]} onChange={this.changeField}></TextField><br/>

            <Button variant="contained" color="primary" onClick={this.save} disableElevation>Speichern</Button>
          </>
        }

        { this.state.type === "generic" &&
          <>
            This function will follow at a later point in time (TODO)
            <Button variant="contained" color="primary" onClick={this.save} disableElevation>Speichern</Button>
          </>
        }


        { !["", "rubella_antibody_test", "blood_group", "chlamydia_trachomatis", "lues_search_reaction", "hepatitis_b_antigen", "hiv_examination", "generic"].includes(this.state.type) &&
          <>
            Bezeichnung: <TextField name="name" value={this.state["name"]} onChange={this.changeField}></TextField><br/>
            Ergebnis: <Select name="result" value={this.state["result"] || ""} onChange={this.changeField}>
              <MenuItem value={""}></MenuItem>
              <MenuItem value={"positive"}>Positiv</MenuItem>
              <MenuItem value={"negative"}>Negativ</MenuItem>
            </Select><br/>

            Wert: <TextField name="value" value={this.state["value"]} onChange={this.changeField} width="25%"></TextField> <Select name="unit" value={this.state["unit"] || ""} onChange={this.changeField}>
              <MenuItem value={""}></MenuItem>
              <MenuItem value={"Titer 1"}>Titer 1</MenuItem>
              <MenuItem value={"IE/ml"}>IE/ml</MenuItem>
            </Select><br/>
            Datum: <TextField name="date" value={this.state["date"]} onChange={this.changeField}></TextField><br/>
            Labor-Nr.: <TextField name="lab_nr" value={this.state["lab_nr"]} onChange={this.changeField}></TextField><br/>
            Bemerkung: <TextField name="remark" value={this.state["remark"]} onChange={this.changeField}></TextField><br/>

            <Button variant="contained" color="primary" onClick={this.save} disableElevation>Speichern</Button>
          </>
        }

      </>
    )
  }
}

export default LabRubellaEdit;