import React from 'react';

import Header from 'components/Header/Header';

import Textfield from 'components/Textfield/Textfield';

import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';


import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Footer from 'components/Footer/Footer';


class DateOfBirthDetermination extends React.Component {
  render() {
    return (
      <>
        <Header>
          <h2>Terminbestimmung</h2>
        </Header>
        <main>
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Textfield identifier="dob_determination_cycle" label="Zyklus" />
              </Grid>
              <Grid item xs={6}>
                <Textfield identifier="dob_determination_last_period" label="Letzte Periode" />
              </Grid>
              <Grid item xs={6}>
                <Textfield identifier="dob_determination_conception_date" label="Konzeptionstermin (soweit sicher)" />
              </Grid>
              <Grid item xs={6}>
                <Textfield identifier="dob_determination_date_pregnancy_ascertained" label="Schwangerschaft festgestellt am" />
              </Grid>
              <Grid item xs={6}>
                <Textfield identifier="dob_determination_date_pregnancy_ascertained_week" label="in der SSW:" />
              </Grid>
              <Grid item xs={6}>
                <Textfield identifier="dob_determination_calculated_delivery_date" label="Entbindungstermin" />
              </Grid>
              <Grid item xs={12}>
                <b>Kommentar</b><br/>
                <Textfield textarea={true} rows="4" identifier="dob_determination_remark" />
              </Grid>
            </Grid>
          </Container>
        </main>
        <Footer />
      </>
    );
  }
}

export default DateOfBirthDetermination;