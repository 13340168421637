import React from 'react';

import Header from '../../components/Header/Header';
import Footer from 'components/Footer/Footer';

import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom'

import womanMbsrBelly from 'resources/svgs/woman-mbsr-belly.svg';



export default function MindfulnessOverview() {
    return (
      <>
        <Header>
          <Typography variant="h2">Achtsamkeitsübungen</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            <div style={{textAlign: "center", marginTop: "1em"}}>
              <div className="photo-circle">
                <img src={womanMbsrBelly} alt="Illustration einer schwangeren Frau, die Achtsamkeitsübungen macht" />
              </div>
            </div>
            <Typography variant="h2">Achtsamkeitsübungen</Typography>
            <p>
              Du möchtest dich während der Schwangerschaft besonders gut um dich kümmern? Achtsamkeit und Achtsamkeitsübungen können dir dabei helfen.
              Mehr dazu findest du im Einführungstext. Die einzelnen Übungen bestehen aus einem kurzen Einführungstext sowie einer entsprechenden Audiodatei.
            </p>
            <Typography variant="h2" style={{marginTop: "0.5em"}}>Einführung</Typography>
            <ul>
              <li><Link to="/mindfulness/introduction">Zur Einführung "Was bedeutet Achtsamkeit?"</Link></li>
            </ul>
            <Typography variant="h2">Übungen</Typography>
            <ul>
              <li><Link to="/mindfulness/bodyscan">Bodyscan</Link></li>
              <li><Link to="/mindfulness/yoga">Yoga</Link></li>
              <li><Link to="/mindfulness/sitting-meditation">Sitzmeditation</Link></li>
              <li><Link to="/mindfulness/sos">Achtsamkeits-SOS</Link></li>
            </ul>
          </Container>
        </main>
        <Footer/>
      </>
    );
}