import React from 'react';

import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import { sizing } from '@material-ui/system';



class GravidogramEdit extends React.Component {
  constructor(props) {
    super(props);

    if(this.props.data) {
      this.state = this.props.data;
    }
    else {
      this.state = {
        type: ""
      };
    }

    this.save = this.save.bind(this);
    this.changeField = this.changeField.bind(this);
    this.changeType = this.changeType.bind(this);
  }

  changeType(evt) {
    this.setState({
      type: evt.target.value
    })
  }

  changeField(evt)  {
    const value = evt.target.value;
    this.setState({
      ...this.state,
      [evt.target.name]: value
    });
  }

  save() {
    this.props.updateFunction(this.state);
  }



  render() {
    
    return (
      <>
        <h3>Neuer Eintrag</h3>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField name="date" value={this.state["date"] || ""} onChange={this.changeField} label="Datum"></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField name="gestation_week" value={this.state["gestation_week"] || ""} onChange={this.changeField} label="SSW"></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField name="gestation_week_corrected" value={this.state["gestation_week_corrected"] || ""} onChange={this.changeField} label="SSW ggf. Korr."></TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField name="fundal_height" value={this.state["fundal_height"] || ""} onChange={this.changeField} label="Fundusstand"></TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField name="fetal_position" value={this.state["fetal_position"] || ""} onChange={this.changeField} label="Kindslage"></TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField name="heart_tones" value={this.state["heart_tones"] || ""} onChange={this.changeField} label="Herztöne"></TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField name="child_movement" value={this.state["child_movement"] || ""} onChange={this.changeField} label="Kindsbew."></TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField name="edema" value={this.state["edema"] || ""} onChange={this.changeField} label="Ödeme"></TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField name="varikosis" value={this.state["varikosis"] || ""} onChange={this.changeField} label="Varikosis"></TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField name="weight" value={this.state["weight"] || ""} onChange={this.changeField} label="Gewicht"></TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField name="blood_pressure" value={this.state["blood_pressure"] || ""} onChange={this.changeField} label="Blutdruck"></TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField name="haemoglobin_erythrocytes" value={this.state["haemoglobin_erythrocytes"] || ""} onChange={this.changeField} label="Hb (Eryl)"></TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField name="proteine" value={this.state["proteine"] || ""} onChange={this.changeField} label="Eiweiß"></TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField name="glucose" value={this.state["glucose"] || ""} onChange={this.changeField} label="Glukose"></TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField name="nitrite" value={this.state["nitrite"] || ""} onChange={this.changeField} label="Nitrit"></TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField name="blood" value={this.state["blood"] || ""} onChange={this.changeField} label="Blut"></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField name="sediment_bacteriology" value={this.state["sediment_bacteriology"] || ""} onChange={this.changeField} label="Sediment, ggfs. bakteriol. Bef."></TextField>
          </Grid>
          <Grid item xs={9}>
            <TextField name="vaginal_examination" value={this.state["vaginal_examination"] || ""} onChange={this.changeField} label="Vaginale Untersuchung"></TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField name="risk_no" value={this.state["risk_no"] || ""} onChange={this.changeField} label="Risiko-Nr."></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField multiline rows="5" name="others_therapy_treatment" value={this.state["others_therapy_treatment"] || ""} onChange={this.changeField} label="Sonstiges/Therapie/Maßnahmen"></TextField>
          </Grid>
        </Grid>

        <Button variant="contained" color="primary" onClick={this.save} disableElevation>Speichern</Button>
      </>
    )
  }
}

export default GravidogramEdit;