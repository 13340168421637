import React from 'react';

import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';


export default function InPatientStayList(props) {
  const theme = useTheme(); 

  return (
    <>
      {
        props.data && 
        props.data.map((value, index) => {
          return (
            <div key={index} style={{margin: "1em 0", padding: "0.5em", backgroundColor: theme.palette.secondary.main}}>
              <b>{value["date"]} ({ value["pregnancy_week"] })</b>
              <p>{ value["assessment"] }</p>
            </div>
          )
        })
      }
    </>
  )
}