import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class PrenatalDiagnostics extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Untersuchungen &amp; Vorsorge &gt; Pränataldiagnostik</div>
            
            <Typography variant="h1">Pränataldiagnostik</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Hier erhalten Sie Informationen über Untersuchungen, die Fehlbildungen und Chromosomenanomalien entdecken sollen.</b></p>

            <p>Unter Pränataldiagnostik versteht man die Untersuchung des Kindes im Mutterleib. Hierzu stehen verschiedene Verfahren zur Verfügung.</p>
            <Typography variant="h2">Kosten bei den Untersuchungen</Typography>
            <p>Ein Teil dieser Untersuchungen, wie zum Beispiel das Organscreening, wird von der Krankenkasse bei bestimmten Indikationen, z.B. einem Kind mit einem schweren Herzfehler in der ersten Schwangerschaft, übernommen. Wenn kein offensichtlicher medizinischer Grund vorliegt, kann die Schwangere diese Untersuchung aber auch auf eigenen Wunsch durchführen lassen und die Kosten selbst zahlen. Ein anderer Teil der Untersuchungen wird nie von der Krankenkasse übernommen, sondern muss von der Schwangeren selbst gezahlt werden. </p>
            <Typography variant="h2">Das Ersttrimesterscreening</Typography>
            <p>In der 12. bis 14. Schwangerschaftswoche kann man das Ersttrimesterscreening durchführen lassen. Dies ist eine Untersuchung, die aus einer Ultraschalluntersuchung der kindlichen Nackenfalte und verschiedener zusätzlicher Marker und einer Blutuntersuchung besteht. Hier soll das Risiko der einzelnen Schwangeren für ein Kind mit Down Syndrom (Trisomie 21) oder anderen Trisomien (Trisomie 13 +18) bestimmt werden. Jede Frau hat durch ihr Alter und ihre Anamnese ein bestimmtes Hintergrundrisiko für ein Kind mit z.B. Down-Syndrom. Dieses Risiko steigt mit dem Alter der Mutter an. Durch die Ultraschalluntersuchung und die Bestimmung von Hormonwerten im Blut der Schwangeren kann dieses Risiko für die aktuelle Schwangerschaft angepasst und errechnet werden. Das Ergebnis der Untersuchung ist eine Wahrscheinlichkeit für das Auftreten der Erkrankung, z.B. 1:2000, das bedeutet, dass von 2000 schwangeren Frauen 1999 ein gesundes Kind erwarten und 1 Frau ein Kind mit Down-Syndrom. Damit ist das Risiko sehr niedrig. Falls Auffälligkeiten bei den einzelnen Untersuchungen vorliegen oder das Risiko für ein Kind mit einer Trisomie hoch ist, können weitere Untersuchungen veranlasst werden. </p>
            <Typography variant="h2">Analyse der Gene</Typography>
            <p>Seit einiger Zeit gibt es zudem die Möglichkeit die Gene des Kindes im Blut der Mutter zu untersuchen. Dabei wird sogenannte zellfreie DNA, die im mütterlichen Blutkreislauf vorkommt, nach einer einfachen Blutabnahme untersucht. Dafür gibt es verschiedene Anbieter auf dem Markt. Diese Tests geben der Schwangeren mit bis zu 99%iger Sicherheit die Information, dass ihr Kind keine Trisomie 13,18 oder 21 hat. Falls doch der Verdacht auf eine Trisomie besteht, sollte zur Abklärung eine invasive Diagnostik mittels Punktion von Fruchtwasser (Amniozentese) oder  Punktion von Plazentagewebe(Chorionzottenbiopsie) erfolgen. Zusätzlich kann über die zellfreie DNA das Geschlecht des Kindes bestimmt werden und darf offiziell ab der 14. Schwangerschaftswoche der Schwangeren mitgeteilt werden. Somit kann die spannende Frage, ob es ein Mädchen oder ein Junge wird, häufig schon früher geklärt werden als mit dem Ultraschall. </p>
            <Typography variant="h2">Chorionzottenbiopsie und Amniozentese</Typography>
            <p>Falls sich beim Feten im Ultraschall schwerwiegende Auffälligkeiten zeigen oder die bisher genannten Untersuchungen einen Hinweis auf eine genetische Erkrankung geben sollten, erfolgt meist eine Abklärung mittels Chorionzottenbiopsie oder Amniozentese. Dies sind invasive Abklärungen, bei denen mittels einer dünnen Nadel Gewebe entnommen wird. Die Chorionzottenbiopsie kann schon am Ende des ersten Trimenons durchgeführt werden, hierbei wird Zottengewebe aus der Plazenta entnommen. Für die Amniozentese sollte das Amnion (die Fruchtblase) schon mit der Wand der Gebärmutter verschmolzen sein. Daher ist diese erst zu einem etwas späteren Zeitpunkt möglich. </p>
            <Typography variant="h2">Zusätzliche Ultraschall-Untersuchungen</Typography>
            <p>Um die 20.-22. Schwangerschaftswoche sind die Ultraschallbedingungen zur Untersuchung des Feten am besten. Hierbei können z.B. die Entwicklung des Gehirns, des Herzens und der anderen Organe untersucht werden. Das Ziel dieser Untersuchung ist es, falls Auffälligkeiten vorliegen, die besten Voraussetzungen für die Versorgung des Kindes nach der Entbindung zu schaffen, bzw. falls eine Fehlbildung vorliegt mit der das Kind nicht lebensfähig ist ggf. einen medizinisch indizierten Schwangerschaftsabbruch zu planen.</p>
            <p>Falls sich bei den regulären gesetzlich vorgegebenen Untersuchungen eine Auffälligkeit zeigt, zum Beispiel, dass das Kind zu klein ist oder einen nichtrhythmischen Herzschlag hat, wird eine genaue Ultraschalluntersuchung durchgeführt. Hier besteht neben der Messung des kindlichen Wachstums und der Untersuchung der Organe die Möglichkeit, die Durchblutung in der Nabelschnur und in verschiedenen Gefäßen im Kind zu untersuchen. Diese Untersuchung wird Doppler-Untersuchung genannt und kann einen Hinweis auf eine Mangelversorgung des Kindes geben. Dies war ein kurzer Einblick in die Möglichkeiten der Pränatalmedizin. Ihre Frauenärztin oder Ihr Frauenarzt berät Sie sicherlich gerne, welche Untersuchungen für Sie sinnvoll sind, da viele dieser Untersuchungen nur in bestimmten Risikosituationen durchgeführt werden sollten und auch das Recht auf Nicht-Wissen gewahrt werden muss. </p>

            <p><b>Wir hoffen Ihnen hiermit einen guten Einblick in die Möglichkeiten der Fehlbildungsuntersuchungen gegeben zu haben, damit Sie eine Entscheidung treffen können, welche Untersuchung Sie ggf. durchführen lassen möchten.</b></p>


          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default PrenatalDiagnostics;