import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class UltrasoundExaminations extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Untersuchungen &amp; Vorsorge &gt; Ultraschalluntersuchungen</div>
            
            <Typography variant="h1">Ultraschalluntersuchungen</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Die spannendste Untersuchung in der Schwangerschaft, hier können Sie Ihrem Baby ganz nahe sein.</b></p>

            <p>Hier möchten wir uns dem Thema Ultraschalluntersuchungen widmen. Sicherlich hatten Sie schon mindestens eine Ultraschalluntersuchung in der aktuellen Schwangerschaft.</p>
            <Typography variant="h2">Wie Ultraschall funktioniert</Typography>
            <p>Der Ultraschall ist eine Untersuchungsmethode, die auf der physikalischen Grundlage von Schallwellen und deren Reflexion von unterschiedlich dichten Gewebsstrukturen durchgeführt wird, wie diese auch von Fledermäusen oder anderen Tieren bei der räumlichen Orientierung verwendet werden. Diese Schallwellen haben Frequenzen oberhalb des Hörbereichs des Menschen. Der Vorteil dieser Untersuchungstechnik ist, dass sie keinerlei Strahlenbelastung für Sie darstellt und daher gut auch in der Schwangerschaft angewendet werden kann. </p>
            <p>Für die Mutter stellt es immer ein schönes Erlebnis dar, wenn sie ihr Kind und den Herzschlag des Kindes auf dem Monitor sieht. Diese Bilder helfen vielen Frauen eine erste Bindung zu ihrem Kind aufzubauen, bevor sie ihr Kind erst mehrere Wochen später zum ersten Mal spüren. </p>
            <Typography variant="h2">Ultraschalluntersuchungen in der Schwangerschaft</Typography>
            <p>Vielleicht haben Sie Ihren Mutterpass schon einmal genauer angesehen und festgestellt, dass insgesamt 3 Untersuchungen in den Mutterschaftsrichtlinien vorgesehen sind. Diese finden um die 10., die 20. Und die 30. Schwangerschaftswoche statt.</p>
            <Typography variant="h3">Die erste Untersuchung</Typography>
            <p>Bei der ersten Untersuchung geht es vor allem darum, dass sich die Schwangerschaft an der richtigen Stelle also in der Gebärmutter eingenistet hat und dass das Herz des Embryos schlägt. Zudem beurteilt der Frauenarzt, ob es sich um ein Kind oder vielleicht sogar mehrere, also Zwillinge oder Drillinge, handelt. Manchmal ist die Entwicklung am Anfang der Schwangerschaft etwas verzögert, sodass in manchen Fällen der errechnete Entbindungstermin im Mutterpass zu diesem Zeitpunkt noch einmal angepasst wird. Hierfür wird der Embryo, so wird das ungeborene Kind zu diesem Zeitpunkt genannt, von Kopf bis Steiß, also Kopf bis Po vermessen. Zu diesem Zeitpunkt ist das Ungeborene noch komplett auf dem Monitor des Ultraschallgerätes darstellbar.</p>
            <Typography variant="h3">Die zweite Untersuchung</Typography>
            <p>Die zweite Untersuchung findet um die 20. SSW statt. Hier können Sie entscheiden, ob der Frauenarzt nur schauen soll, ob das Kind richtig gewachsen ist und keine groben Auffälligkeiten des Kindes und seiner Umgebung, wie z.B. zu viel oder zu wenig Fruchtwasser vorliegen oder ob der Frauenarzt sich das Kind genauer ansehen soll. Dazu gehört neben dem Wachstum des Kindes die Begutachtung bestimmter Organe, wie zum Beispiel des Kleinhirns oder des Herzens. Für eine genaue Organdiagnostik ist das der beste Zeitpunkt in der Schwangerschaft, da alle Organe schon angelegt sind und der Arzt oder die Ärztin die besten Sichtbedingungen vorfindet. Der Fet ist zu diesem Zeitpunkt der Schwangerschaft nicht mehr komplett auf dem Bild darstellbar. Um das Wachstum des Kindes abzuschätzen, werden deshalb der Kopf, der Bauch und der Oberschenkel vermessen und daraus das Gewicht des Kindes berechnet. </p>
            <Typography variant="h3">Die dritte Untersuchung</Typography>
            <p>In der dritten Untersuchung um die 30. Schwangerschaftswoche geht es primär um das kindliche Wachstum und Wohlbefinden. Dafür werden die positive Herzaktion, die Fruchtwassermenge, die Lage der Plazenta und verschiedene weitere Faktoren bezüglich des fetalen Wohlbefindens beurteilt. </p>
            <Typography variant="h3">Weitere Untersuchungen</Typography>
            <p>Falls Auffälligkeiten oder Risikofaktoren bestehen wird die Ärztin oder der Arzt gegebenenfalls weitere Ultraschalluntersuchungen durchführen oder eine weitere Untersuchung veranlassen. </p>
            <p>Falls das Kind bis zum errechneten Entbindungstermin nicht auf der Welt sein sollte, werden alle 2-3 Tage die Fruchtwassermenge und ggf. die Durchblutung in der Nabelschnur untersucht und bei Auffälligkeiten die Geburt eingeleitet. </p>
            <Typography variant="h2">Zusätzliche Möglichkeiten und Leistungen</Typography>
            <p>Manche Praxen bieten als zusätzliche Leistung 3D-Aufnahmen des Feten an. Dieses sogenannte Babyfernsehen ist für die Eltern häufig kostenpflichtig, Aber dennoch  ein schönes Erlebnis. Die Qualität der 3D-Aufnahmen ist sehr davon abhängig, wie das Kind gerade liegt und wie die Untersuchungsbedingungen sind.</p>
            <p>Bei Interesse können Sie gerne in Ihrer Frauenarztpraxis nachfragen, ob eine solche Untersuchung angeboten wird. </p>

            <p><b>Viel Freude bei den ersten Bildern und Videos, die Sie von Ihrem Kind sehen.</b></p>

          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default UltrasoundExaminations;