import React, { useEffect, useState } from 'react';

import axios from 'axios';
import Globals from 'services/Globals';
import moment from 'moment';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import TopSpacer from 'components/TopSpacer/TopSpacer';

import './MyPregnancyWeek.scss'

export default function MyPregnancyWeek(props) {

  const [pregnancyWeek, setPregnancyWeek] = useState(-1);
 
  useEffect(() => {
    axios.get(Globals.SERVER_URL + "/settings/conception_date").then(function(res) {
      let conceptionDate = res.data;
      let today = moment();
      let pregnancyWeek = (today.diff(conceptionDate, 'weeks') + 1); // Add 1, because "1st pregnancy week is from 0+0 to 0+6"
      setPregnancyWeek(pregnancyWeek);
    }).catch(function(err) {
      console.log(err);
    });
  });

  return (
    <>
      <Header>
        <Typography variant="h2">Aktuelle Schwangerschaftswoche</Typography>
      </Header>
      <main>
        <TopSpacer />
        <Container maxWidth="lg">
          { pregnancyWeek === -1 && 
          <>
            Lade Daten. Bitte warten.
          </>
          }
          { Number.isNaN(pregnancyWeek) && 
          <>
            <Typography variant="h2">Meine Schwangerschaftswoche</Typography>
            <p>Ihre Schwangerschaftswoche ist <b>noch nicht im System hinterlegt</b>. Daher werden an dieser Stelle noch keine Informationen angezeigt. </p>
          </>
          }
          { pregnancyWeek <= 5 &&
            <>
              <Typography variant="h1">Bis zur 5. Schwangerschaftswoche</Typography>
              <p>Eine Schwangerschaft wird meistens in der fünften oder sechsten Woche bekannt.</p>
              <p>Gerade in den ersten Wochen passiert enorm viel. Die verschiedenen Organsysteme und das menschliche Aussehen entwickeln sich. </p>
              <p>Nach den ersten Zellteilungsstadien entwickelt sich ein Teil des so gebildeten Zellhaufens zu Ihrem Embryo. In der fünften Schwangerschaftswoche ist er in etwa so groß wie ein Stecknadelkopf, hat die Form einer Scheibe und besteht aus drei sogenannten Keimblättern. Aus diesen entwickeln sich die verschiedenen Organe. Leber, Schilddrüse, Bauchspeicheldrüse, Lunge, Darm, Blase und Geschlechtsorgane wachsen aus dem inneren Keimblatt. Aus dem mittleren Blatt entwickeln sich Wirbelsäule, Knochen, Knorpel, Muskeln, Herz und Blutgefäße. Aus dem äußeren Blatt entstehen Nervensystem, Augen, Ohren und die Haut. In der Mittellinie der Scheibe entsteht die Neuralrinne. Aus dem vorderen Anteil der Scheibe wird der Kopf, aus dem hinteren Anteil entwickelt sich der restliche Körper.</p>
              <p>Der andere Teil des Zellhaufens wird zum Choriongewebe, hieraus entwickelt sich später der Mutterkuchen. </p>
            </>
          }
          { pregnancyWeek === 6 && 
            <>
              <Typography variant="h1">6. Schwangerschaftswoche</Typography>
              <p>In der sechsten Woche ist Ihr Baby 2&nbsp;-&nbsp;4&nbsp;mm groß. Das Herz beginnt sich zu entwickeln und schlägt am Ende der Woche zuerst langsam, dann schneller bis zu 100&nbsp;-&nbsp;120&nbsp;mal in der Minute. Im Ultraschall sieht man jedoch meist erst nur eine Fruchthöhle mit einem Dottersack. </p>
              <p>Unter einem Mikroskop würde man bereits den Kopf erkennen können, da das obere Ende etwas verdickt ist, der Rücken ist etwas gebeugt und endet mit einem kleinen Schwanz. Die Nabelschnur verbindet den Bauch des Embryos mit dem Mutterkuchen, hierdurch wird das Baby später mit Nährstoffen und Sauerstoff von der Mutter versorgt. Anfangs wird der Embryo noch über die Gebärmutterschleimhaut ernährt.</p>
            </>
          }
          { pregnancyWeek === 7 && 
            <>
              <Typography variant="h1">7. Schwangerschaftswoche</Typography>
              <p>In der siebten Woche ist der Embryo bereits 2,5&nbsp;-&nbsp;7&nbsp;mm groß (ca. so groß wie ein Reiskorn). Arme und Beine fangen an sich als Knospen zu bilden und Augen und Ohren sind bereits angelegt. Vor allem der Kopf wächst rasch aufgrund der schnellen Entwicklung des Nervensystems. Das zunächst offenliegende Rückenmark schließt sich. Zu diesem Zeitpunkt kann man im Ultraschall schon eine Embryonalanlage und das Herz des Embryos schlagen sehen. </p>
            </>
          }
          { pregnancyWeek === 8 && 
            <>
              <Typography variant="h1">8. Schwangerschaftswoche</Typography>
              <p>In der achten Woche ist der Embryo ca. 12&nbsp;-&nbsp;16&nbsp;mm groß. Kopf und Herz haben in etwa die gleiche Dimension und sind in der Ultraschalluntersuchung differenziert darstellbar. Das Gesicht mit Augen, Mund, Nase, Unterkiefer und die Stimmbänder entwickeln sich. Eine Darmschleife ragt in die Nabelschnur, da der Bauch noch zu klein für die gesamte Menge an Darm ist. Dies nennt man den physiologischen Nabelbruch. Magen und Niere arbeiten schon und produzieren Magensaft und Urin. Die Arme entwickeln sich schneller als die Beine. Am Ende dieser Woche macht das Baby bereits erste Bewegungen. Knorpel bilden sich als Anlage für die späteren Knochen. Beim männlichen Embryo produzieren die Hoden bereits Testosteron.</p>
            </>
          }
          { pregnancyWeek === 9 && 
            <>
              <Typography variant="h1">9. Schwangerschaftswoche</Typography>
              <p>In der neunten Schwangerschaftswoche ist der Embryo 16&nbsp;-&nbsp;24&nbsp;mm groß, wiegt etwa ein Gramm und wird in der Ultraschalluntersuchung mit der Scheitel-Steiß-Länge gemessen. Die Wirbelsäule entsteht, die Arme verknöchern bereits, Finger und Zehen fangen an sich zu entwickeln. Die Leber beginnt mit der Blutbildung. Der Embryo bekommt zunehmend ein menschliches Aussehen. Die Augen sind jedoch noch seitlich am Kopf zu finden, sie rutschen im Laufe der Zeit immer weiter zur Mitte. Hier beginnt dann auch die Pigmentierung. Im Mund wächst die Zunge und Ohren und Nase sind gut erkennbar. Alle Organsysteme sind bereits angelegt und funktionieren teilweise schon. </p>
            </>
          }
          { pregnancyWeek === 10 && 
            <>
              <Typography variant="h1">10. Schwangerschaftswoche</Typography>
              <p>Am Ende der zehnten Woche wird der Embryo zum Fetus, die zehnte Woche ist also die letzte Phase der Embryonalperiode. Der Embryo ist nun ca. 25&nbsp;-&nbsp;32&nbsp;mm groß und wiegt schon 8&nbsp;-&nbsp;10&nbsp;g. Alle Organe haben sich entwickelt und wachsen. Das Herz ist auch in eine rechte und linke Herzhälfte unterteilt. Die Schwimmhäute zwischen den Fingern und Zehen lösen sich zunehmend auf, dadurch können sich die Finger und Zehen verlängern. Man kann bereits Ellbogen, Knie, Finger und Zehen erkennen. Lippen, Nase, Augen und Ohren sieht man deutlich, dies verleiht dem Embryo sein menschliches Aussehen. Das Geschlecht lässt sich noch nicht bestimmen, da die Geschlechtsorgane sind noch nicht ausreichend entwickelt sind. Ein Teil des Darms ist immer noch ausgelagert in der Nabelschnur.</p>
            </>
          }
          { pregnancyWeek === 11 && 
            <>
              <Typography variant="h1">11. Schwangerschaftswoche</Typography>
              <p>In der elften Woche beginnt die Fetalperiode. Das Baby ist ca. 32&nbsp;-&nbsp;40&nbsp;mm groß und bewegt fleißig Arme und Beine. Nägel an Händen und Füßen, sowie Haare beginnen zu wachsen. Sogar ein Fingerabdruck hat sich bereits gebildet. Die vorher transparente Haut des Babys wird dicker und vielschichtiger und es bilden sich Haarfollikel. Die Augen sind in die Mitte gerückt. Beim männlichen Fetus bildet sich jetzt der Penis und ist erkennbar. Beim Mädchen sind die inneren Geschlechtsorgane angelegt, diese sind jedoch noch nicht zu erkennen. Der Fetus reagiert noch nicht auf äußere Reize, da ihn das Fruchtwasser komplett umspült.</p>
            </>
          }
          { pregnancyWeek === 12 && 
            <>
              <Typography variant="h1">12. Schwangerschaftswoche</Typography>
              <p>In der zwölften Woche ist der Fetus nun 43&nbsp;-&nbsp;51&nbsp;mm groß und wiegt ca. 15&nbsp;g, das Herz schlägt ca. 120&nbsp;-&nbsp;160&nbsp;mal in der Minute. Das Baby hat nun typische menschliche Züge und eine kleine Stupsnase, die tief sitzenden Ohren sind nach oben gewandert. Der Kopf ist bereits ca. 20&nbsp;mm groß. Die Zähne werden angelegt, indem sich im Kiefer Zahnknospen bilden. Beim weiblichen Feten wird zunehmend die Entwicklung der Genitalien sichtbar. Die Schilddrüse bildet Hormone. Der Fet wird immer beweglicher, er kann seinen Kopf drehen, Handgelenke und Ellbogen beugen und mit den Fingern eine winzige Faust machen. Ihr Kind kann bereits die Stirn runzeln, gähnen, Schluckauf haben, am Daumen lutschen und macht Atemübungen.</p>
            </>
          }
          { pregnancyWeek === 13 && 
            <>
              <Typography variant="h1">13. Schwangerschaftswoche</Typography>
              <p>In der dreizehnten Woche ist Ihr Baby jetzt ca. 6&nbsp;cm groß, vergleichbar mit der Größe einer Kiwi. Es wiegt ca. 14&nbsp;g. Das Baby kann die Hand zur Faust schließen und spielt mit der Nabelschnur. Die inneren Organe bilden sich weiter aus, hierunter Magen, Darm, Leber und Bauchspeicheldrüse. Zudem beginnt die Entwicklung der Stimmbänder im Kehlkopf. Aus Knorpel entstehen die ersten Knochen, diese sieht man auch im Ultraschall.</p>
            </>
          }
          { pregnancyWeek === 14 && 
            <>
              <Typography variant="h1">14. Schwangerschaftswoche</Typography>
              <p>Ab der vierzehnten Woche schluckt Ihr Baby Fruchtwasser und scheidet dieses über die Nieren wieder aus. Hierbei wird das Fruchtwasser innerhalb von drei Stunden komplett ausgetauscht. Auch die Atmung übt es schon einmal für später. Inzwischen kann es auch schon am Daumen lutschen. Das Baby bewegt sich in Ihrer Gebärmutter ständig hin und her, spüren können Sie das allerdings erst später.</p>
            </>
          }
          { pregnancyWeek === 15 && 
            <>
              <Typography variant="h1">15. Schwangerschaftswoche</Typography>
              <p>In der fünfzehnten Schwangerschaftswoche ist der Fet jetzt schon 8,5&nbsp;cm groß und wiegt ca. 45&nbsp;g. Es reibt seine Augen und kann gähnen. Hornhaut, Linse und Iris des Auges sind jetzt entwickelt, die Augenlider lässt das Baby aber noch geschlossen. Auch Wimpern und Augenbrauen entstehen bereits. An den Fingern und Zehen sind Rillen erkennbar, die später zu unverwechselbaren Fingerabdrücken werden. Die inneren Organe beginnen zu funktionieren, es wird Gallenflüssigkeit gebildet und Insulin produziert.</p>
            </>
          }
          { pregnancyWeek === 16 && 
            <>
              <Typography variant="h1">16. Schwangerschaftswoche</Typography>
              <p>Ihr Baby ist in der sechzehnten Woche 10&nbsp;cm groß und wiegt ca. 75&nbsp;g. Es kann schon Geräusche über eine Knochen-Leitung wahrnehmen. Sie können also schon mit Ihrem Baby sprechen und ihm Lieder vorsingen – es wird sich sicher nach der Geburt an die vertraute Stimme erinnern. Auch einen Wechsel der Lichtverhältnisse kann es wahrnehmen. Mittlerweile kann man – wenn Ihr Baby es zulässt – mit dem Ultraschall einen Blick auf die Geschlechtsorgane werfen und vielleicht schon erfahren ob es ein Mädchen oder ein Junge wird.</p>
            </>
          }
          { pregnancyWeek === 17 && 
            <>
              <Typography variant="h1">17. Schwangerschaftswoche</Typography>
              <p>Ab der siebzehnten Woche kann ihr Kind schon verschiedene Geschmacksrichtungen unterscheiden. Die Venen der durchsichtigen Haut sind nun weniger stark sichtbar, da sich Fettzellen zum Schutz Ihres Babys bilden. Die Knochen werden zunehmend härter, es bildet sich ein Immunsystem und auch der Darm arbeitet und fängt bereits an, den ersten Stuhl zu bilden.</p>
            </>
          }
          { pregnancyWeek === 18 && 
            <>
              <Typography variant="h1">18. Schwangerschaftswoche</Typography>
              <p>In der achtzehnten Schwangerschaftswoche ist ihr Kind etwa so groß wie eine Essiggurke, ca. 12,5&nbsp;cm und 150&nbsp;g schwer. Brustwarzen bilden sich aus und bei den Mädchen haben sich auch die Eierstöcke gebildet. Ihr Baby macht erste Atemübungen im Fruchtwasser. Auch die Nabelschnur wächst mit, um eine ausreichende Versorgung zu gewährleisten. </p>
            </>
          }
          { pregnancyWeek === 19 && 
            <>
              <Typography variant="h1">19. Schwangerschaftswoche</Typography>
              <p>Um die neunzehnte Woche ist ihr Kind  mit der Käseschmiere bedeckt, die seine Haut im Mutterleib schützt. Manche Babys werden mit dieser Schutzschicht geboren. Das kleine Herz befördert jeden Tag 28 Liter Blut durch den Körper Ihres Babys. Ihr Baby ist nun etwa sechs Stunden am Tag wach, die restliche Zeit schlummert es tief und fest in Ihrem Bauch.</p>
            </>
          }
          { pregnancyWeek === 20 && 
            <>
              <Typography variant="h1">20. Schwangerschaftswoche</Typography>
              <p>In der zwanzigsten Woche spüren Sie vielleicht schon die Bewegungen Ihres mittlerweile ca. 14&nbsp;cm großen und 240&nbsp;g schweren Babys. Es ist etwa so groß wie eine Banane und entwickelt ständig neue Gehirnzellen. Auch die Haare wachsen rege. Bei den männlichen Babys senken sich die Hoden in den Hodensack, bei weiblichen Feten ist die Gebärmutter jetzt bereits voll ausgebildet.</p>
            </>
          }
          { pregnancyWeek === 21 && 
            <>
              <Typography variant="h1">21. Schwangerschaftswoche</Typography>
              <p>In der einundzwanzigsten Woche sieht der Fet schon fast wie bei der Geburt aus, er ist mit etwa 25&nbsp;cm nur ein bisschen kleiner und wiegt ca. 300&nbsp;g. Jetzt wachsen die richtigen festen, borstigen Haare auf dem Kopf. Wimpern und Augenbrauen sind voll ausgebildet, es kann Ihnen im Ultraschall sogar zuzwinkern. Die Nieren übernehmen nun ganz alleine die Fruchtwasserproduktion, vorher hat der Mutterkuchen ebenfalls mitgeholfen. Die Tritte Ihres Babys werden immer stärker, vielleicht sehen Sie auch schon von außen wie es gerade turnt und Purzelbäume macht.</p>
            </>
          }
          { pregnancyWeek === 22 && 
            <>
              <Typography variant="h1">22. Schwangerschaftswoche</Typography>
              <p>Ab der zweiundzwanzigsten Woche kann sich Ihr Kind auch durch Schluckauf bemerkbar machen. Hierbei wird Fruchtwasser ausgestoßen – auch eine Form der Atemübung. Ihr Baby bewegt sich ganz gezielt und trainiert so seine feinmotorischen Fähigkeiten für später. Die Haut Ihres Babys ist zwar schon rosig-rötlich, aber immer noch durchsichtig und sehr empfindlich. </p>
            </>
          }
          { pregnancyWeek === 23 && 
            <>
              <Typography variant="h1">23. Schwangerschaftswoche</Typography>
              <p>In der dreiundzwanzigsten Woche ist Ihr Baby 28&nbsp;cm groß und wiegt 450&nbsp;g. Ihr Baby hat Wach- und Schlafphasen und erlebt beim Schlafen auch die REM-Phasen. Das Wachstum steht im Vordergrund, noch sieht man Knochen und Organe durch die zarte Haut, aber schon bald werden sich viele Fettzellen gebildet haben.</p>
            </>
          }
          { pregnancyWeek === 24 && 
            <>
              <Typography variant="h1">24. Schwangerschaftswoche</Typography>
              <p>Die Haare Ihres Babys nehmen jetzt langsam die Farbe an, die sie bei der Geburt haben werden. In der vierundzwanzigsten Woche ist Ihr Baby 29&nbsp;cm groß und wiegt 550&nbsp;g. Es kann das Fruchtwasser durch Geschmacksknospen im Mund jetzt richtig schmecken. Das Fruchtwasser enthält nun auch Haare, abgestorbene Zellen und Teile der Käseschmiere. Ihr Baby kann aufrecht in Ihrem Bauch sitzen, da die Knochen immer fester werden. Laute Geräusche können Ihr Baby erschrecken, hier können Sie einen Tritt in der Gebärmutter wahrnehmen.</p>
            </>
          }
          { pregnancyWeek === 25 && 
            <>
              <Typography variant="h1">25. Schwangerschaftswoche</Typography>
              <p>In der fünfundzwanzigsten Woche hinterlassen Musik und Stimmen bei Ihrem Baby einen bleibenden Eindruck, es reagiert hierauf mit Bewegungen oder einem gesteigerten Puls. Ihr Baby ist jetzt 30&nbsp;cm groß und ca. 650&nbsp;g schwer. Die Hände Ihres Babys sind schon voll entwickelt, es spielt mit seinen Händchen und Füßchen und kann sich sogar die Ohren zuhalten.</p>
            </>
          }
          { pregnancyWeek === 26 && 
            <>
              <Typography variant="h1">26. Schwangerschaftswoche</Typography>
              <p>In der sechsundzwanzigsten Woche können die Augen Ihres Kindes hell und dunkel unterscheiden. Es ist bereits ca. 32&nbsp;cm groß und ca. 800&nbsp;g schwer. Vielleicht möchten Sie mit Ihrem Baby sprechen, es kann Ihnen über eine Bewegung schon Antwort geben. Auch könnte Ihr Baby bereits atmen, der Austausch von Kohlendioxid und Sauerstoff in der Lunge würde schon funktionieren, jedoch bräuchte es außerhalb des Bauches eine Atemhilfe und viel Unterstützung. </p>
            </>
          }
          { pregnancyWeek === 27 && 
            <>
              <Typography variant="h1">27. Schwangerschaftswoche</Typography>
              <p>Ab der siebenundzwanzigsten Woche wird das Fruchtwasser im Bauch langsam weniger. Ihr 34&nbsp;cm großes und 1000&nbsp;g schweres Baby bewegt sich weiterhin viel. Vielleicht spüren Sie die Bewegungen sogar mehr, da jetzt weniger Fruchtwasser da ist. Es kann mal mit dem Kopf oben oder unten liegen. Sie können über die Tritte manchmal erkennen wie Ihr Baby gerade im Bauch liegt. Das Baby saugt ganz fleißig an seinem Daumen und übt für die Zeit danach. Die Blutbildung hat bereits das Knochenmark übernommen.</p>
            </>
          }
          { pregnancyWeek === 28 && 
            <>
              <Typography variant="h1">28. Schwangerschaftswoche</Typography>
              <p>In der achtundzwanzigsten Schwangerschaftswoche ist Ihr Baby ca. 36&nbsp;cm groß und wiegt ca. 1100&nbsp;g. Würde es jetzt auf die Welt kommen, hätte es bereits gute Überlebenschancen. Die Augen sind manchmal geöffnet, wenn es wach ist und Ihr Baby blinzelt. Wenn es schläft schließt es die Augen. Die Augen können schon ein wenig gefärbt sein, die Pigmentierung startet aber erst, wenn das Baby sich im Licht befindet. Aus diesem Grund kommen auch die meisten Babys mit blauen Augen zur Welt. Bis die Augen ihre richtige Farbe annehmen, kann es durchaus ein Jahr dauern. Abstufungen des Lichts kann das Baby bereits erkennen, Farben kann es erst ein paar Monate nach der Geburt wahrnehmen.</p>
            </>
          }
          { pregnancyWeek === 29 && 
            <>
              <Typography variant="h1">29. Schwangerschaftswoche</Typography>
              <p>Ab der neunundzwanzigsten Woche kann Ihr Baby nun seine Körpertemperatur und die Atmung selbst steuern. Die inneren Organe sind fast fertig ausgebildet. Die Muskulatur wird täglich mit Purzelbäumen und Turnübungen weiter trainiert. </p>
            </>
          }
          { pregnancyWeek === 30 && 
            <>
              <Typography variant="h1">30. Schwangerschaftswoche</Typography>
              <p>Ab der dreißigsten Woche fällt das Wollhaar Ihres Kindes nun langsam aus und das Haupthaar wird kräftiger. Auch die Haut verändert sich – durch die ständig wachsenden Fettzellen wird die Haut weniger durchsichtig und deutlich glatter. Ihr Baby kann sich auch schon in die Geburtsposition drehen – aber keine Sorge, falls es das noch nicht getan hat, in den nächsten Wochen ist noch genug Zeit dafür. Meist zieht es auch die Beinchen schon etwas an den Bauch heran, da es langsam eng wird in der Gebärmutter.</p>
            </>
          }
          { pregnancyWeek === 31 && 
            <>
              <Typography variant="h1">31. Schwangerschaftswoche</Typography>
              <p>In der einunddreißigsten Woche ist ihr Baby ca. 40&nbsp;cm groß und etwa 1600&nbsp;g schwer. Aber keine Sorge, das sind nur Normwerte. Ihr Baby kann durchaus auch etwas größer oder kleiner sein – auch wir Erwachsenen sind ja unterschiedlich groß. Die Lungen entwickeln sich weiter, das Baby übt für die bald anstehende Atmung. Auch das Gewicht nimmt weiter zu – nicht zuletzt, da die Nebennieren Steroide produzieren, die für das Wachstum erforderlich sind. Ihr Baby kann inzwischen auch alle fünf Sinne verwenden: es schaut umher, hört auf Ihre Stimme, probiert das Fruchtwasser, öffnet die Nasenlöcher und spielt mit der Nabelschnur.</p>
            </>
          }
          { pregnancyWeek === 32 && 
            <>
              <Typography variant="h1">32. Schwangerschaftswoche</Typography>
              <p>Ab der zweiunddreißigsten Schwangerschaftswoche wird es immer enger im Bauch, die Bewegungen Ihres Kindes lassen jedoch nicht nach. Die Tritte sind zwar etwas langsamer geworden, dafür fühlen Sie sie umso öfter. Das Baby hat sich inzwischen an die Embryonalhaltung mit angezogenen Beinen aufgrund des Platzproblems gewöhnt.</p>
            </>
          }
          { pregnancyWeek === 33 && 
            <>
              <Typography variant="h1">33. Schwangerschaftswoche</Typography>
              <p>In der dreiunddreißigsten Woche werden die Knochen hart, sodass Ihr Baby wirklich gut sitzen kann und auch für die Geburt gut gerüstet ist. Die Schädelknochen sind nicht miteinander verwachsen, dies ist jedoch auch erforderlich, damit es den engen Geburtskanal gut passieren kann. Rund 42&nbsp;cm groß und ca. 1900&nbsp;g schwer ist Ihr Baby jetzt. Es sammelt Erinnerungen und erkennt so Ihre Stimme, wenn es geboren ist. Neun von zehn Babys haben sich schon in die Schädellage gedreht – das ist die Geburtsposition mit Kopf nach unten und Füßchen nach oben.</p>
            </>
          }
          { pregnancyWeek === 34 && 
            <>
              <Typography variant="h1">34. Schwangerschaftswoche</Typography>
              <p>In der vierunddreißigsten Woche ist das Wollhaar Ihres Kindes fast vollständig verschwunden. Zudem besitzt Ihr Baby mittlerweile ein eigenes Immunsystem. Mit den mittlerweile gut ausgebildeten Nägeln kann sich Ihr Kind inzwischen gut kratzen. Die Knochen werden auch weiterhin immer härter – hierzu holt sich das Baby Kalzium aus dem Blut. Das Verdauungssystem ist fast vollständig tätig und Urin wird fleißig in das Fruchtwasser abgegeben.</p>
            </>
          }
          { pregnancyWeek === 35 && 
            <>
              <Typography variant="h1">35. Schwangerschaftswoche</Typography>
              <p>Ab der fünfunddreißigsten Woche legt der Fetus ordentlich an braunem Fettgewebe zu. Dieses hält Ihr Kind nach der Geburt gut warm. Braunes Fettgewebe kann Körperwärme produzieren und befindet sich vor allem am Rücken und an den Schultern. Wenn Ihr rund 45&nbsp;cm großes und 2500&nbsp;g schweres Baby jetzt zur Welt kommen würde, könnte es meist selbstständig atmen. Auch das Gehirn ist nahezu vollständig ausgebildet. </p>
            </>
          }
          { pregnancyWeek === 36 && 
            <>
              <Typography variant="h1">36. Schwangerschaftswoche</Typography>
              <p>In der sechsunddreißigsten Woche macht das Fettgewebe 15% des Gewichts Ihres Kindes aus. Die Käseschmiere bildet sich bis auf ein paar Reste zurück. Die Wangen werden durch die Ausbildung von Muskeln stärker ausgeprägt und das Fett nimmt auch in den Wangen zu. Wenn sich Ihr Baby noch nicht in der Geburtsposition mit Köpfchen nach unten befindet, empfehlen wir Ihnen die Vorstellung zur Geburtsmodusbesprechung. Vielleicht kann eine äußere Wendung versucht werden und das Kind von außen in die richtige Geburtsposition gebracht werden. Die Art der Bewegungen Ihres Babys ändert sich aufgrund des Platzmangels. Jedoch spüren Sie weiterhin gleich viel Bewegungen.</p>
            </>
          }
          { pregnancyWeek === 37 && 
            <>
              <Typography variant="h1">37. Schwangerschaftswoche</Typography>
              <p>Wenn Ihr Kind in der siebenunddreißigsten Woche geboren wird, bräuchte es keinen Brutkasten mehr, da das Fett Ihres Babys es ausreichend warm hält. Die Lungen sind voll ausgebildet und Ihr Baby übt weiter für die bevorstehende Geburt und genießt die Massagen durch die immer wieder auftretenden Kontraktionen der Gebärmutter.</p>
            </>
          }
          { pregnancyWeek === 38 && 
            <>
              <Typography variant="h1">38. Schwangerschaftswoche</Typography>
              <p>In der achtunddreißigsten Woche ist Surfactant – ein Stoff, der wichtig ist, damit die Lungen nicht zusammenfallen, bereits ausreichend vorhanden. Dies sorgt dafür, dass es nach der Geburt meist keine Atemprobleme gibt. Alle Organe Ihres Babys können jetzt ihre Funktion ausüben. Aber Ihrem Baby gefällt es durchaus auch in Ihrer Gebärmutter und genießt die Reifung, die es die letzten Wochen noch durchleben kann. Es lässt es sich nicht nehmen, weiterhin zu wachsen und an Gewicht zuzulegen.</p>
            </>
          }
          { pregnancyWeek === 39 && 
            <>
              <Typography variant="h1">39. Schwangerschaftswoche</Typography>
              <p>In der neununddreißigsten Woche ist es richtig eng geworden im Bauch, ca. 49&nbsp;cm groß und ca. 3250&nbsp;g schwer ist Ihr Baby jetzt. Die Fettzellen bilden sich weiter aus, das Verdauungssystem arbeitet und die Nieren produzieren fleißig Urin. Ein Tritt in die Rippen kann jetzt unter Umständen auch mal schmerzhaft sein. Es zeigen sich Furchen im Zahnfleisch, die Zähne kommen jedoch erst etwa im sechsten Lebensmonat zum Vorschein.</p>
            </>
          }
          { pregnancyWeek >= 40 && 
            <>
              <Typography variant="h1">40. Schwangerschaftswoche</Typography>
              <p>Ab der vierzigsten Woche sind Sie fast am Ziel. Ihr Baby liegt jetzt meist mit dem Kopf nach unten, ist ca. 51&nbsp;cm groß und 3500&nbsp;g schwer. Der Körper passt gerade noch so in Ihren Bauch. Ihr Baby drückt nach unten und die Geburt kann sich durch Wehen oder Fruchtwasserabgang ankündigen. </p>
            </>
          }
        </Container>
      </main>
      <Footer/>
    </>
  );
}