import React from 'react';

import Button from '@material-ui/core/Button';

function HelperModalButton(props) {
  // Declare a new state variable, which we'll call "count"
  return (
    <Button variant="contained" color="secondary" size="small" disableElevation onClick={props.toggle}>
      Hilfe einblenden
    </Button>
  );
}

export default HelperModalButton;