import React from 'react';
import { useHistory } from "react-router-dom";

import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

export default function BackToLastPage() {
  const history = useHistory();

    return (
      <>
        <Button onClick={() => {history.goBack()}} variant="contained" color="primary" size="small" disableElevation>
          <KeyboardArrowLeft />
          Zurück
        </Button>
      </>
    );
}