import React, { useEffect, useState } from "react";
import axios from 'axios';

import Globals from 'services/Globals';

import Header from "components/Header/Header";

import Textfield from "components/Textfield/Textfield";
import RadioCheckboxesYN from "components/RadioCheckboxesYN/RadioCheckboxesYN";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { TextField, Typography } from '@material-ui/core';

import "./PregnancyCard.scss";
import Footer from "components/Footer/Footer";
import MappingTextfield from "components/MappingTextfield/MappingTextfield";
import TopSpacer from "components/TopSpacer/TopSpacer";
import { formatDate, unpackObject } from "helpers/Helpers";

export default function PregnancyCard() {

  const [data, setData] = useState({
    vorherigegeburten: "",
    geburtskanalanzahl: "",
    kaiserschnitt: "",
    datumgeburtanam: "",
    geburtsterminulta: "",
    transfertermin: "",
    blutgruppe_ab0: "",
    blutgruppe_rhd1: "",
    fetenanzahl: "",
  });

  useEffect(() => {
    axios.get(Globals.SERVER_URL + "/dmr/pregnancy-card").then(function (res) {
      if ( !res.data ) return;
      setData(unpackObject(res.data));
    }).catch(function (err) {
      console.log(err);
    });
  }, []);

  return (
    <>
      <Header>
        <Typography variant="h2">Schwangerschafts-Karte</Typography>
      </Header>
      <main>
        <Container maxWidth="lg">
          <TopSpacer />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField fullWidth value={data.vorherigegeburten} disabled label="Anzahl aller vorhergehender Geburten" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth value={data.geburtskanalanzahl} disabled label="Anzahl Geburten per Geburtskanal" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth value={data.kaiserschnitt} disabled label="Anzahl Geburten per Kaiserschnitt" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth value={formatDate(data.datumgeburtanam)} disabled label="Berechneter Entbindungstermin nach Anamnese" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth value={formatDate(data.geburtsterminulta)} disabled label="Berechneter Entbindungstermin nach Ultraschall in der Frühschwangerschaft" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth value={formatDate(data.transfertermin)} disabled label="Berechneter Entbindungstermin nach Transfertermin (IVF)" />
            </Grid>
            <Grid item xs={12}>
              <MappingTextfield fullWidth value={data.blutgruppe_ab0} disabled label="Blutgruppe" mapping={{1:"A", 2:"B", 3:"0", 4:"AB"}} />
            </Grid>
            <Grid item xs={12}>
              <MappingTextfield fullWidth value={data.blutgruppe_rhd1} disabled label="Rhesusfaktor" mapping={{1:"Rh positiv (D+)", 2:"Rh negativ (D-)"}} />
            </Grid>
            <Grid item xs={12}>
            <TextField fullWidth value={data.fetenanzahl} disabled label="Fetusanzahl in dieser Schwangerschaft" />
            </Grid>
          </Grid>
        </Container>
        </main>
      <Footer />
    </>
  );
}