import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class LifeTravelSauna extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Während der Schwangerschaft &gt; Freizeit, Reisen, Sauna</div>
            
            <Typography variant="h1">Freizeit, Reisen, Sauna</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Hinaus in die ferne, weite Welt. Hier bekommen Sie Tipps, damit das Reisen auch in der Schwangerschaft gelingt.</b></p>
            <Typography variant="h2">Reisen ist meistens kein Problem</Typography>
            <p>Herzlich willkommen zu Verreisen und Freizeit in der Schwangerschaft. Reisen in der Schwangerschaft ist grundsätzlich kein Problem und vermutlich auch eine der letzten Gelegenheiten Zweisamkeit mit seinem Partner zu erleben. Also nichts wie los. Nur was sollte man als Schwangere beachten? </p>
            <p>Wenn Sie sich wohlfühlen und Sie eine regelrechte Schwangerschaft haben spricht nichts dagegen eine Reise mit dem Zug, Auto oder sogar Flugzeug anzutreten. Die beste Reisezeit ist vor allem zwischen dem vierten und siebten Monat. Grundsätzlich sind eher Reisen in Europa empfohlen, doch auch eine Fernreise ist möglich. Wichtig zu wissen ist, dass es sowohl in den Verkehrsmitteln als auch in den verschiedenen Reisezielen einige Sachen zu beachten gibt. Bei der Auswahl des Reiseortes geht es nun nicht nur nach der Frage auf welches Reiseziel habe ich gerade Lust, sondern eben auch nach der Frage wie ist die medizinische Versorgung in diesem Land, gibt es dort „gefährliche“ Krankheiten, brauche ich spezielle Impfungen und sind diese in der Schwangerschaft überhaupt möglich. Die aller wichtigste Frage ist aber: Fühle ich mich wohl eine Reise anzutreten?</p>
            <Typography variant="h2">Komplikationen in der Schwangerschaft</Typography>
            <p>Schwangerschaftskomplikationen können jederzeit in der Schwangerschaft auftreten kann, egal ob man zuhause ist oder in einem Urlaubsdomizil. Beschäftigen Sie sich daher vor der Abreise einmal mit der Medizinischen Versorgung Ihres Reiseziels. Sie sollten die Adresse eines Krankenhauses bzw. einer gynäkologischen Praxis sowie die Notfallnummer des jeweiligen Reiseziels vor Abreise notiert haben und verreisen Sie nie ohne Ihren Mutterpass (und einer Kopie). </p>
            <Typography variant="h2">Die Wahl des Verkehrsmittels</Typography>
            <p>Bei der Anreise egal in welchem Verkehrsmittel besteht während der Schwangerschaft bei langem Sitzen ein erhöhtes Thromboserisiko. Vermehrte Pausen in denen man sich bewegt, viel trinken und das Tragen von Thrombosestrümpfen (Kompressionsklasse 2) können dieses Risiko minimieren. Ebenso ist es wichtig im Sitzen Gymnastik mit den Füßen zu machen, damit die Durchblutung des Beins weiter angeregt wird und auf Beinfreiheit zu achten. Beim Autofahren ist es wichtig den Gurt unterhalb des Bauches anzubringen. Im Fachhandel können zudem spezielle Polster erworben werden, die das Angurten während der Schwangerschaft erleichtern. </p>
            <p>Wenn Sie eine Flugreise planen oder bereits gebucht haben, dann fragen Sie bei der Airline nach wie lange sie schwangere Passagiere transportieren. In der Regel ist eine Beförderung bis zur 35. SSW möglich, manche Airlines verlangen jedoch bereits in früheren Schwangerschaftswochen ein ärztliches Attest. Bei bekannten Schwangerschaftskomplikationen wie einer Gestose oder Placenta praevia als auch bei Mehrlingsschwangerschaften, vorherigen Fehlgeburten, mütterlichen oder kindlichen Erkrankungen oder Flugangst ist eine Flugreise nicht empfohlen. In einigen Ländern gelten Einreiseregeln für Schwangere, diese gilt es zu beachten. Der Lärm, die Vibrationen und die kosmische Strahlung im Flugzeug seien für den gelegentlichen Flugreisenden in der Schwangerschaft vernachlässigbar. Die maximale Strahlenbelastung während der 40 Schwangerschaftswochen sollte bei 1mSv maximal liegen. Selbst der längste Interkontinentalflug erreicht 15% dieses Grenzwertes.   Somit besteht bei einem Gelegenheitsflug während der Schwangerschaft kein Risiko. Zwischen der 8.-15. SSW ist die Strahlenempfindlichkeit des Feten kritischer zu betrachten. Bezüglich des Thromboserisikos während eines 4 Stunden Flugs haben laut Literatur Schwangere Frauen ein 5-10fach höheres Risiko eine Thrombose zu bekommen im Vergleich zu nicht Schwangeren Passagierinnen.  </p>
            <Typography variant="h2">Wahl des Reiseziels</Typography>
            <p>Von einer Reise in ein Malariarisikogebiet, Gelbfieber- und Zika-Virus-Risiko ist abzuraten. Ebenso sollten keine Reisen nach Südostasien, Indien, Mittelamerika und Zentralafrika erfolgen, da dort ein erhöhtes Risiko für Hepatits A und E, deren Infektion in der Schwangerschaft schwere Verläufe zeigen, vermieden werden.  Falls Sie eine Fernreise planen, informieren Sie sich bitte rechtzeitig bei einem Tropeninsitut in Ihrer Nähe, ob dies als Schwangere möglich ist, bzw. welche Impfungen dazu notwendig sind, denn in der Schwangerschaft sollten nur sehr zurückhaltend Impfungen durchgeführt werden. Manche Impfungen sind in der Schwangerschaft auch vollständig verboten. </p>
            <Typography variant="h2">Gestaltung der Freizeit</Typography>
            <p>Falls Sie sich für Urlaub zu Hause entscheiden oder einfach etwas in der Freizeit zu Hause unternehmen möchten gilt, dass Sie fast alles machen können, das Ihnen vor der Schwangerschaft auch schon Spaß gemacht hat. </p>
            <p>Sind Sie vor der Schwangerschaft z.B. regelmäßig in die Sauna gegangen und hat dies Ihrem Kreislauf nichts ausgemacht, dann sollte es während der Schwangerschaft auch kein Problem sein. Ein Saunagang sollte jedoch erst ab dem vierten Monat erfolgen und nicht länger als 10min andauern. Bei Schwindel oder Kreislaufproblemen sollten Sie Sauna jedoch sofort verlassen. Temperaturen um die 45°C sind auch für Anfängerinnen möglich. Ein Schwimmbad-Besuch ist in der Schwangerschaft ebenso möglich. Zudem ist er auch besonders entspannend für Sie. Achten Sie jedoch darauf, sich nicht ohne ein Handtuch unterzulegen auf Sitzflächen zu sitzen, denn in der Schwangerschaft ist aufgrund eines veränderten Scheidenmilieus die Infektionsanfälligkeit leicht erhöht. Deswegen sollten Sie während Ihrer Schwangerschaft auch keinen Whirlpool oder über 37°C beheiztes Badewasser besuchen, denn hier fühlen sich Bakterien und Krankheitskeime besonders wohl. Sollten Sie sich aus Versehen auf einen Sitz ohne Handtuch gesetzt haben, dann hilft eine kurze Dusche. Doch haben Sie keine Angst, falls Sie vor diesem Spot schon mal im Schwimmbad oder Whirlpool waren und auf das oben genannte nicht geachtet haben. Im Regelfall passiert nichts und wenn dann merken Sie dies und Ihr Frauenarzt oder Frauenärztin gibt Ihnen eine Behandlung dagegen.  Einem Besuch in einem Wellnesstempel mit Massagen und Peeling steht natürlich auch nichts im Wege. Manche Einrichtungen bieten sogar extra Verwöhnpakete für werdende Mütter an. Hören Sie sich doch einmal in Ihrer Umgebung um, was es so für Angebote gibt. </p>
            <p>Was gibt es sonst noch so für Frauenfreizeit-Beschäftigungen? Shoppen, Kaffeeklatsch und Tanzen gehen mit den Freundinnen sind natürlich weiterhin möglich.  Und wenn Ihnen mal ganz langweilig ist oder Sie erschöpft von einer kleinen Wanderung zurückkommen, dann vergessen Sie nicht, dass Sie ja zu zweit sind. Sprechen Sie so viel es geht mit Ihrem Baby oder streicheln Sie an Ihrer Bauchdecke, irgendwann, meist in der späteren Schwangerschaft, wird Ihr Kleines darauf vielleicht mit einem kleinen Stubs von innen antworten. Also viel Spaß mit Ihrer freien Zeit. Und im nächsten Spot hören Sie  ganz viel über Sport in der Schwangerschaft, das ist für einige von Ihnen ja auch Freizeitbeschäftigung pur ist. </p>
            <p><b>Viel Freude beim Planen Ihrer Reise und Ihrer Freizeitaktivitäten.</b></p>

          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default LifeTravelSauna;