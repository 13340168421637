import React from 'react';

import Header from 'components/Header/Header';

import './Gravidogram.scss';

import Container from '@material-ui/core/Container';
import GravidogramData from './components/GravidogramData';
import Footer from 'components/Footer/Footer';
import { Typography } from '@material-ui/core';

class Gravidogram extends React.Component {
  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Gravidogramm</Typography>
        </Header>
        
        <main>
          <Container maxWidth="lg">
            <GravidogramData />
          </Container>
        </main>
        <Footer />
      </>
    );
  }
}

export default Gravidogram;