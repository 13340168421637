import React from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

import AuthContext from './AuthContext';
import Globals from 'services/Globals';

// https://levelup.gitconnected.com/react-template-for-jwt-authentication-with-private-routes-and-redirects-f77c488bfb85

class AuthContextProvider extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      token: "",
    };

    this.login = this.login.bind(this);
    this.tokenExpired = this.tokenExpired.bind(this);
    this.getLogin = this.getLogin.bind(this);
  }

  async login (pseudonym, password) {
    try {
      let tokenResponse = await axios.post(Globals.SERVER_URL + "/users/login", {
        pseudonym: pseudonym,
        password: password
      });

      let token = tokenResponse.data;
      let decodedJwt = jwt_decode(token);
      console.log(decodedJwt);
      localStorage.setItem('token_exp', decodedJwt.exp);
      localStorage.setItem('token_pseudonym', decodedJwt.pseudonym);
    } catch (err) {
      throw err;
    }
  }

  async logout () {
    try {
      await axios.get(Globals.SERVER_URL + "/users/logout");
      localStorage.removeItem('token_exp');
      localStorage.removeItem('token_pseudonym');
    } catch (err) {
      throw err;
    }
  }

  async register (pseudonym, initialPassword, password) {
    return axios.post(Globals.SERVER_URL + "/users/register", {
      pseudonym: pseudonym,
      initialPassword: initialPassword,
      password: password
    });
  }

  async selfRegister (password) {
    return axios.post(Globals.SERVER_URL + "/users/self-register", {
      password: password
    });
  }

  getLogin () {
    return localStorage.getItem('token_pseudonym');
  }

  
  tokenExpired() {
    let now = Math.round(Date.now() / 1000) // Current date in epoch (seconds since unix epoch)
    let tokenExpiration = localStorage.getItem('token_exp');

    if(tokenExpiration) {
      if(now < tokenExpiration) {
        return false;
      }
    }

    return true;
  }

  render() {
      return (
          <AuthContext.Provider
              value={{
                user: this.state,
                login: this.login,
                logout: this.logout,
                register: this.register,
                selfRegister: this.selfRegister,
                tokenExpired: this.tokenExpired,
                getLogin: this.getLogin
              }}
          >
            {this.props.children}
          </AuthContext.Provider>
      );
  }
}

export default AuthContextProvider;