import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Input, Typography } from '@material-ui/core';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import withNotifications from 'services/NotificationService/withNotifications';

import '../Questionnaire.scss';
import QuestionnaireHeadNotice from 'components/QuestionnaireHeadNotice/QuestionnaireHeadNotice';


//import Textfield from '../../components/Textfield/Textfield';
//import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';


class SocialDemographics extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Fragebogen Soziodemographie</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/13) * 100 } />
          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />
          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "app_gebjahr": 1,
                "app_gebland": 2,
                "app_gebland_txt": 2,
                "app_deutschland": 2,
                "app_staatsangeh": 3,
                "app_staatsangeh_txt": 3,
                "app_familienstand": 4,
                "app_partner": 5,
                "app_haushalt_gesamt": 6,
                "app_haushalt_kinder": 6,
                "app_bildung_schule": 7,
                "app_bildung_beruf": 8,
                "app_beruf_umfang": 9,
                "app_beruf_muschu": 10,
                "app_beruf_change_yn": 11,
                "app_beruf_vorschws": 11,
                "app_techno_interest": 12,
                "app_techno_easy": 13
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <div style={{textAlign: "center", marginTop: "1em"}}>
                Mit dem folgenden Fragebogen erheben wir Daten zu Ihrer Person, z.B. Alter, Familienstand, Ausbildung und Berufstätigkeit. Bitte füllen Sie den Fragebogen vollständig und ehrlich aus.<br/><br/>
                  <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Fragebogen starten</Button>
                </div>
              </StepperPage>
            </Container>

            { this.state.activeStep >= 1 &&
              <QuestionnaireHeadNotice>
                Bitte füllen Sie die folgenden Angaben zu Ihrer Person aus:
              </QuestionnaireHeadNotice>
            }
            <div className="questionnaire-body">
              <Container maxWidth="lg">
                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Geburtsjahr</FormLabel>
                    <CustomErrorMessage name="app_gebjahr" />
                    <Input
                        name="app_gebjahr"
                        value={this.props.values.app_gebjahr}
                        onChange={this.props.handleChange}
                        type="number"
                      />  
                  </FormControl>
                  <ForwardBackwardButtons handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={2}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Geburtsland</FormLabel>
                    <CustomErrorMessage name="app_gebland" />
                    <RadioGroup aria-label="position" name="app_gebland" value={this.props.values.app_gebland} onChange={this.props.handleChange}>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Deutschland" labelPlacement="end"/>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Anderes Land" labelPlacement="end"/>
                    </RadioGroup>
                    { this.props.values.app_gebland === "0" &&
                      <>
                        <br/><br/>
                        <FormLabel component="legend">Name des Lands:</FormLabel>
                        <CustomErrorMessage name="app_gebland_txt" />
                        <Input
                          name="app_gebland_txt"
                          value={this.props.values.app_gebland_txt}
                          onChange={this.props.handleChange}
                        />
                        <br/><br/>
                        <FormLabel component="legend">Seit wann leben Sie in Deutschland (Jahr)?</FormLabel>
                        <CustomErrorMessage name="app_deutschland" />
                        <Input
                          name="app_deutschland"
                          value={this.props.values.app_deutschland}
                          onChange={this.props.handleChange}
                          type="number"
                        />
                      </>
                    }
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={3}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Staatsangehörigkeit</FormLabel>
                    <CustomErrorMessage name="app_staatsangeh" />
                    <RadioGroup aria-label="position" name="app_staatsangeh" value={this.props.values.app_staatsangeh} onChange={this.props.handleChange}>
                      <FormControlLabel value="deutsch" control={<Radio color="primary" />} label="Deutsche Staatsangehörigkeit" labelPlacement="end"/>
                      <FormControlLabel value="andere" control={<Radio color="primary" />} label="Andere Staatsangehörigkeit" labelPlacement="end"/>
                      <FormControlLabel value="mehrere" control={<Radio color="primary" />} label="Mehrere Staatsangehörigkeiten" labelPlacement="end"/>
                    </RadioGroup>
                    { (this.props.values.app_staatsangeh === "andere" || this.props.values.app_staatsangeh === "mehrere") &&
                      <>
                        <br/><br/>
                        <FormLabel component="legend">Staatsangehörigkeiten:</FormLabel>
                        <CustomErrorMessage name="app_staatsangeh_txt" />
                        <Input
                          name="app_staatsangeh_txt"
                          value={this.props.values.app_staatsangeh_txt}
                          onChange={this.props.handleChange}
                        />
                      </>
                    }
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={4}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Familienstand</FormLabel>
                    <CustomErrorMessage name="app_familienstand" />
                    <RadioGroup aria-label="position" name="app_familienstand" value={this.props.values.app_familienstand} onChange={this.props.handleChange}>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Ledig" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Verheiratet/Eingetragene Lebensgemeinschaft" labelPlacement="end"/>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Getrennt" labelPlacement="end"/>
                      <FormControlLabel value="4" control={<Radio color="primary" />} label="Geschieden/Lebenspartnerschaft aufgehoben" labelPlacement="end"/>
                      <FormControlLabel value="5" control={<Radio color="primary" />} label="Verwitwet/Eingetragene Lebenspartnerin verstorben" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={5}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Haben Sie derzeit eine(n) feste(n) Partner(in)?</FormLabel>
                    <CustomErrorMessage name="app_partner" />
                    <RadioGroup aria-label="position" name="app_partner" value={this.props.values.app_partner} onChange={this.props.handleChange}>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Ja" labelPlacement="end"/>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={6}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wie viele Personen, Sie selbst mit eingeschlossen, wohnen ständig in Ihrem Haushalt?</FormLabel>
                    <CustomErrorMessage name="app_haushalt_gesamt" />
                    <Input
                      name="app_haushalt_gesamt"
                      value={this.props.values.app_haushalt_gesamt}
                      onChange={this.props.handleChange}
                      type="number"
                    />
                    <br/><br/>
                    <FormLabel component="legend">Wie viele davon sind Kinder unter 18 Jahren?</FormLabel>
                    <CustomErrorMessage name="app_haushalt_kinder" />
                    <Input
                      name="app_haushalt_kinder"
                      value={this.props.values.app_haushalt_kinder}
                      onChange={this.props.handleChange}
                      type="number"
                    />
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={7}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Was ist der höchste Grad der allgemeinen Schulausbildung, den Sie abgeschlossen haben?</FormLabel>
                    <CustomErrorMessage name="app_bildung_schule" />
                    <RadioGroup aria-label="position" name="app_bildung_schule" value={this.props.values.app_bildung_schule} onChange={this.props.handleChange}>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Noch in schulischer Ausbildung" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Haupt- oder Volksschulabschluss" labelPlacement="end"/>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Abschluss der polytechnischen Oberschule" labelPlacement="end"/>
                      <FormControlLabel value="4" control={<Radio color="primary" />} label="Mittlere Reife (Realschulabschluss)" labelPlacement="end"/>
                      <FormControlLabel value="5" control={<Radio color="primary" />} label="Fachhochschul- oder Hochschulreife (Abitur)" labelPlacement="end"/>
                      <FormControlLabel value="6" control={<Radio color="primary" />} label="Kein allgemeiner Schulabschluss" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={8}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Was ist der höchste Grad der beruflichen Ausbildung, den Sie abgeschlossen haben?</FormLabel>
                    <CustomErrorMessage name="app_bildung_beruf" />
                    <RadioGroup aria-label="position" name="app_bildung_beruf" value={this.props.values.app_bildung_beruf} onChange={this.props.handleChange}>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Noch in beruflicher Ausbildung" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Lehre/Berufsausbildung im dualen System" labelPlacement="end"/>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Fachschulabschluss" labelPlacement="end"/>
                      <FormControlLabel value="4" control={<Radio color="primary" />} label="Bachelor" labelPlacement="end"/>
                      <FormControlLabel value="5" control={<Radio color="primary" />} label="Master" labelPlacement="end"/>
                      <FormControlLabel value="6" control={<Radio color="primary" />} label="Diplom/Staatsexamen" labelPlacement="end"/>
                      <FormControlLabel value="7" control={<Radio color="primary" />} label="Promotion" labelPlacement="end"/>
                      <FormControlLabel value="8" control={<Radio color="primary" />} label="Keine Berufsausbildung" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={9}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">In welchem Umfang sind Sie aktuell berufstätig?</FormLabel>
                    <CustomErrorMessage name="app_beruf_umfang" />
                    <RadioGroup aria-label="position" name="app_beruf_umfang" value={this.props.values.app_beruf_umfang} onChange={this.props.handleChange}>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Nicht berufstätig" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Geringfügig beschäftigt (Mini-Job, 450€)" labelPlacement="end"/>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Teilzeit (34 Stunden/Woche oder weniger)" labelPlacement="end"/>
                      <FormControlLabel value="4" control={<Radio color="primary" />} label="Vollzeit (35 Stunden/Woche oder mehr)" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={10}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Sind Sie aktuell in Mutterschutz, Elternzeit o.Ä.?</FormLabel>
                    <CustomErrorMessage name="app_beruf_muschu" />
                    <RadioGroup aria-label="position" name="app_beruf_muschu" value={this.props.values.app_beruf_muschu} onChange={this.props.handleChange}>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Ja" labelPlacement="end"/>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={11}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Hat sich der Umfang Ihrer Berufstätigkeit durch die Schwangerschaft geändert?</FormLabel>
                    <CustomErrorMessage name="app_beruf_change_yn" />
                    <RadioGroup aria-label="position" name="app_beruf_change_yn" value={this.props.values.app_beruf_change_yn} onChange={this.props.handleChange}>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Ja" labelPlacement="end"/>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein" labelPlacement="end"/>
                    </RadioGroup>
                    { this.props.values.app_beruf_change_yn === "1" &&
                      <>
                        <br/><br/>
                        <FormLabel component="legend">In welchem Umfang waren Sie vor der Schwangerschaft berufstätig?</FormLabel>
                        <CustomErrorMessage name="app_beruf_vorschws" />
                        <RadioGroup aria-label="position" name="app_beruf_vorschws" value={this.props.values.app_beruf_vorschws} onChange={this.props.handleChange}>
                          <FormControlLabel value="1" control={<Radio color="primary" />} label="Nicht berufstätig" labelPlacement="end"/>
                          <FormControlLabel value="2" control={<Radio color="primary" />} label="Geringfügig beschäftigt (Mini-Job, 450€)" labelPlacement="end"/>
                          <FormControlLabel value="3" control={<Radio color="primary" />} label="Teilzeit (34 Stunden/Woche oder weniger)" labelPlacement="end"/>
                          <FormControlLabel value="4" control={<Radio color="primary" />} label="Vollzeit (35 Stunden/Woche oder mehr)" labelPlacement="end"/>
                        </RadioGroup>
                      </>
                    }
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={12}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Interessieren Sie sich privat für Digitalisierung und smarte Geräte?</FormLabel>
                    <CustomErrorMessage name="app_techno_interest" />
                    <RadioGroup aria-label="position" name="app_techno_interest" value={this.props.values.app_techno_interest} onChange={this.props.handleChange}>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Überhaupt nicht" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Eher nicht" labelPlacement="end"/>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Teils, teils" labelPlacement="end"/>
                      <FormControlLabel value="4" control={<Radio color="primary" />} label="Eher stark" labelPlacement="end"/>
                      <FormControlLabel value="5" control={<Radio color="primary" />} label="Sehr stark" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={13}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Fällt es Ihnen leicht oder schwer, ein neues Gerät oder eine neue App zu bedienen?</FormLabel>
                    <CustomErrorMessage name="app_techno_easy" />
                    <RadioGroup aria-label="position" name="app_techno_easy" value={this.props.values.app_techno_easy} onChange={this.props.handleChange}>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Sehr schwer" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Eher schwer" labelPlacement="end"/>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Teils, teils" labelPlacement="end"/>
                      <FormControlLabel value="4" control={<Radio color="primary" />} label="Eher leicht" labelPlacement="end"/>
                      <FormControlLabel value="5" control={<Radio color="primary" />} label="Sehr leicht" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                    <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                  </div>
                  <ForwardBackwardButtons handleBack={this.handleBack} />
                </StepperPage>

                {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}
              </Container>  
            </div>
          </form>
        </main>
      <Footer showMenu={false} />
    </>
    );
  }
}

export default withNotifications(withFormik({
  mapPropsToValues: () => ({
    app_gebjahr: '',
    app_gebland: '',
    app_gebland_txt: '',
    app_deutschland: '',
    app_staatsangeh: '',
    app_staatsangeh_txt: '',
    app_familienstand: '',
    app_partner: '',
    app_haushalt_gesamt: '',
    app_haushalt_kinder: '',
    app_bildung_schule: '',
    app_bildung_beruf: '',
    app_beruf_umfang: '',
    app_beruf_muschu: '',
    app_beruf_change_yn: '',
    app_beruf_vorschws: '',
    app_techno_interest: '',
    app_techno_easy: '',
  }),  
  validationSchema: Yup.object().shape({
    app_gebjahr: Yup.number().required('Pflichtfeld'),
    app_gebland: Yup.string().required('Pflichtfeld'),
    app_staatsangeh: Yup.string().required('Pflichtfeld'),
    app_familienstand: Yup.number().required('Pflichtfeld'),
    app_partner: Yup.number().required('Pflichtfeld'),
    app_haushalt_gesamt: Yup.number().required('Pflichtfeld'),
    app_haushalt_kinder: Yup.number().required('Pflichtfeld'),
    app_bildung_schule: Yup.number().required('Pflichtfeld'),
    app_bildung_beruf: Yup.number().required('Pflichtfeld'),
    app_beruf_umfang: Yup.number().required('Pflichtfeld'),
    app_beruf_muschu: Yup.number().required('Pflichtfeld'),
    app_beruf_change_yn: Yup.number().required('Pflichtfeld'),
    app_beruf_vorschws: Yup.number().when('app_beruf_change_yn', {
      is: '1',
      then: Yup.number().required()
    }),
    app_techno_interest: Yup.number().required('Pflichtfeld'),
    app_techno_easy: Yup.number().required('Pflichtfeld')
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "socialdemographics",
      questionnaireData: values,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
    });
  }
})(SocialDemographics));