import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class Birth extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Die Geburt</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Geburt &gt; Die Geburt</div>
            
            <Typography variant="h1">Die Geburt</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Der lang ersehnte Zeitpunkt ist gekommen – Ihr Baby und Sie trennt nicht mehr viel. Hier erfahren Sie alles über die Geburt.</b></p>
            
            <p>Nur 3-5% der Kinder kommen genau am errechneten Geburtstermin zur Welt – deshalb wundern Sie sich nicht, wenn Ihr Baby schon vor dem errechneten Termin kommen sollte oder sich etwas Zeit lässt.</p>

            <Typography variant="h2">Wann Sie ins Krankenhaus fahren sollten</Typography>
            <p>Verschiedene Zeichen können die Geburt ankündigen. Zum einen kann sich der Schleimpfropf lösen. Manchmal setzen die Wehen jedoch erst Tage danach ein. In 10-15% kündigt das Platzen der Fruchtblase die nahende Geburt an, manchmal ist dies ein Schwall Fruchtwasser und die ganze Hose ist nass. Manchmal kommt es aber nur zu einem hohen Blasensprung und dies kann sich in leichtem Tröpfeln von Fruchtwasser äußern. In 70-80% der Fälle kündigt Ihr Baby den Geburtsbeginn mit Wehen an. Hier kann es auch zu Beginn zu einer minimalen Blutung kommen,  die Zeichnungsblutung genannt wird. Eine Blutung ist ebenso wie der Blasensprung oder falls Sie Ihr Kind nicht mehr so gut spüren immer ein Grund zur Vorstellung in der Entbindungsklinik.  </p>
            <p>Beim ersten Kind suchen Sie am besten das Krankenhaus auf, wenn die Wehen alle 5 bis 7 Minuten auftreten oder wenn Sie ein Schmerzmittel gegen den Wehenschmerz möchten. Dann wird untersucht, wie es Ihnen und Ihrem Kind geht und was sich schon am Muttermund getan hat. Je nachdem, wie weit die Geburt vorangeschritten ist werden Sie im Kreißsaal aufgenommen und dort weiter betreut. Manchmal hat sich am Muttermund noch nicht so viel getan und ggf. können Sie auch noch einmal nach Hause oder Laufen gehen. Bei der zweiten Geburt werden Sie am besten schon bei Wehen in 10-minütigen Abständen aktiv. Echte Geburtswehen nehmen zu, auch wenn Sie sich bewegen. Die Intensität wird zunehmend stärker und die Wehenabstände werden kürzer. Die Wehen beginnen im Kreuzbein und ziehen dann nach vorne. </p>
            
            <Typography variant="h2">Was in die Tasche sollte</Typography>
            <p>Ihre Tasche sollten Sie gepackt Zuhause stehen haben, sodass kein Stress entsteht, wenn die Zeit gekommen ist. Bademantel, bequeme Kleidung, Socken, eine Sweatshirt-Jacke, Still-BHs, Baumwollslips, Nachthemden oder Schlafanzüge zum Aufknöpfen, ein altes T-Shirt für die Geburt, Binden, Bücher/Musik, Personalausweis und Versichertenkarte, das Familienstammbuch oder die Heiratsurkunde, der Mutterpass, Handtücher/Waschlappen, Kulturbeutel mit Zahnbürste, Duschgel etc. und Traubenzucker/Gummibärchen sollten mit eingepackt werden. Zudem empfiehlt sich eine Baby-Tasche mit Spucktüchern, Baby-Bodys, Strampelanzügen, Wickeljäckchen, Mützchen, Schnuller und ein Kinderwagen oder eine Baby-Sicherheitsschale für das Auto mit einer Decke. Mehr dazu erfahren Sie im Artikel "Was brauche ich für die Geburt?".</p>

            <Typography variant="h2">Ort der Geburt</Typography>
            <Typography variant="h3">Die Hausgeburt und die Geburt im Geburtshaus</Typography>
            <p>Manche Frauen wünschen eine Entbindung zu Hause oder in einem Geburtshaus. Hier ist nur eine Hebamme anwesend. Eine Geburt Zuhause oder im Geburtshaus kann eine beruhigende Erfahrung sein, da Sie vielleicht in der familiäreren Umgebung besser entspannen können. Sie sollten schon ab der 12. bis etwa zur 22. Schwangerschaftswoche mit Ihrer Hebamme in Kontakt treten um ein Vertrauensverhältnis zu schaffen und um die Organisation zu besprechen, sodass eine gemütliche Atmosphäre geschaffen werden kann und Sie sich gut kennenlernen. Falls es aber zu Komplikationen kommt kann der Zeitverlust durch den Transport ins Krankenhaus bei einer fehlgeschlagenen Geburt  allerdings Ihr Leben und das Ihres Kindes in Gefahr bringen. Zum Abbruch einer Hausgeburt kommt es in ca. 10-20% der Fälle. </p>

            <Typography variant="h3">Die Geburt in der Klinik</Typography>
            <p>99% der Frauen entscheiden sich für eine Geburt in der Klinik. Eine Notfallbehandlung ist hier schneller und unkompliziert möglich. Auch hier ist eine individuelle Betreuung durch das Hebammen- und Ärzteteam gewährleistet. Ihr Partner oder eine andere Person kann als Unterstützung bei Ihrer Geburt mit dabei sein und sich um Sie kümmern.</p>

            <Typography variant="h2">Die Geburtsphasen</Typography>
            <p>Die Dauer der Geburt unterscheidet sich von Frau zu Frau. Die Geburt des ersten Babys dauert durchschnittlich 13 Stunden. Wenn Sie schon ein Kind geboren haben, geht die Geburt in der Regel etwas zügiger, durchschnittlich dauert sie hier 8 Stunden. Es gibt aber auch Verläufe, die deutlich kürzer oder länger dauern.  </p>
            <p>Während der Geburt können Sie verschiedene Geburtspositionen einnehmen. Sie können gehen, stehen, auf einem Ball oder Hocker sitzen, im Bett liegen, knien mit einer Stütze oder auf allen Vieren stehen. Auch ein Bad, im Sinne einer Wassergeburt ist möglich.</p>
            <p>Ob und in wieweit Sie Schmerzmittel benötigen, entscheiden Sie selbst. Einen Überblick über die verschiedenen Möglichkeiten der Schmerztherapie geben wir Ihnen im nächsten Kapitel. </p>

            <Typography variant="h3">Latenzphase</Typography>
            <p>Häufig beginnt die Geburt mit Wehen. Manchmal bewirken die Kontraktionen aber noch nicht direkt eine Eröffnung des Muttermundes. Diese Phase wird Latenzphase genannt. Sie kann von einigen Stunden bis zu mehreren Tagen dauern. Im Verlauf geht diese Phase in die Eröffnungsphase über. </p>

            <Typography variant="h3">Eröffnungsperiode</Typography>
            <p>In der Eröffnungsperiode wird der Gebärmutterhals weicher, er verkürzt sich und der Muttermund öffnet sich durch die Wehentätigkeit. Die Wehenabstände werden zunehmend kürzer. Beim ersten Kind kann die Eröffnungsperiode bis zu zwölf Stunden dauern. Versuchen Sie sich in dieser Phase der Geburt möglichst zu entspannen und die Wehen zu veratmen, damit Sie die Kraft für den folgenden Teil der Entbindung haben.</p>

            <Typography variant="h3">Austreibungsperiode</Typography>
            <p>Wenn der Muttermund vollständig geöffnet ist, das bedeutet bei 10cm, beginnt die Austreibungsperiode. Die Austreibungsperiode kann bei Ihrem ersten Kind mehrere  dauern, bei den Geburten danach verkürzt sie sich im Regelfall. Wenn der Kopf Ihres Kindes schrittweise tiefer tritt, drückt er auf den Darm und dies bewirkt bei Ihnen einen Drang zu pressen. Wenn der Kopf tief genug getreten ist, wird Sie die Hebamme dazu anleiten,  dem Pressdrang in der Wehe nachzugeben und im Verlauf auch mit voller Kraft mitzupressen. Wenn die Wehe beginnt sollten Sie tief einatmen, die Luft anhalten und mit voller Kraft mitschieben. Dazwischen neu tief einatmen und versuchen die Wehe zum Pressen voll auszunutzen. Außerhalb der Wehe soll die Zeit zur Entspannung genützt werden. Hier atmen Sie langsam tief ein und aus und versuchsen sich zu entspannen. </p>
            <p>Das Baby erblickt das Licht der Welt, indem es den Beckenboden erreicht, der Hinterkopf sich an der Schambeinfuge abstützt und der Kopf durch eine Streckbewegung geboren wird. In der folgenden Wehe  werden die Schultern und der restliche Körper geboren. Die Hebamme achtet bei Durchtreten des Kopfes und der Schultern durch den Dammschutz darauf, dass das empfindliche Gewebe entlastet wird und bei Ihnen keine unnötigen Geburtsverletzungen entstehen.</p>

            <p>Manchmal ist eine Hilfestellung am Ende der Geburt erforderlich, die zu einer rascheren Geburt verhilft, wenn z.B. das Baby Stressanzeichen zeigt oder Sie körperlich erschöpft sind. Hierbei gibt es verschiedene Möglichkeiten. </p>
            <p>Beim Kristeller-Handgriff wird der Po des Kindes in der Wehe mit in Richtung Geburtsweg geschoben.</p>
            <p>Bei der Saugglocke wird eine kleine Kappe auf den Kopf des Babys aufgesetzt und ein Unterdruck erzeugt. Während der Wehe wird durch den Zug am Kopf das Pressen unterstützt. </p>
            <p>Bei einer Zangengeburt wird die Geburtszange in die Scheide eingeführt und seitlich rechts und links am Kopf des Babys angelegt. Bei der nächsten Wehe wird mitgezogen und das Pressen unterstützt.</p>
            <p>Ein Dammschnitt wird selten durchgeführt. Er wird beispielsweise gebraucht, wenn das Kind unter Stress gerät, ggf. bei einer Zangengeburt oder falls der Damm sehr straff und hoch ist. </p>

            <p>Ist die Anpassungsphase nach der Geburt für das Baby geschafft, kann der Vater die Nabelschnur durchschneiden, wenn er dies möchte. Danach können Sie Ihr Baby das erste Mal in den Arm nehmen.</p>

            <Typography variant="h3">Nachgeburtsphase</Typography>
            <p>In der letzten Phase der Geburt wird der Mutterkuchen, die Plazenta geboren. Dies dauert ca. 10 bis 30 Minuten. Durch ein Wehenmittel kann die Blutung aus deiner Gebärmutter etwas verringert werden.</p>
            <p>Eventuelle Geburtsverletzungen werden in dieser Nachgeburtsphase mit lokaler Betäubung versorgt.</p>

            <Typography variant="h2">Der Kaiserschnitt</Typography>
            <p>Der Kaiserschnitt ist die Geburt durch einen Bauchschnitt. In Deutschland kommen ca. 20 bis 30% der Babys durch einen Kaiserschnitt zur Welt. Gründe für einen geplanten Kaiserschnitt sind zum Beispiel eine geburtsunmögliche Lage des Kindes z.B. wenn das Kind quer in Ihrer Gebärmutter liegt, wenn ein Missverhältnis zwischen dem mütterlichen Becken und der Größe des Kindes vorliegt oder schwere Erkrankungen der Mutter oder des Kindes vorhanden sind, bei denen keine Spontangeburt möglich sind. Auch während einer normalen Entbindung kann es aber Gründe für einen Kaiserschnitt geben. Dies ist zum Beispiel der Fall, wenn eine  drohende Sauerstoffunterversorgung des Kindes auftritt oder über einen längeren Zeitraum kein Geburtsfortschritt vorhanden ist. Insgesamt dauert der Eingriff ca. 30 Minuten. Der Eingriff wird meist in rückenmarksnaher Regionalanästhesie durchgeführt. Wichtig ist, dass Sie sich im Vorfeld über die Vor- und Nachteile eines Kaiserschnittes informieren, falls dieser die Geburtsmethode der Wahl für Sie darstellt.</p>
            <p>Da das Fruchtwasser nach einer mehrstündigen Wehentätigkeit und durch eine Spontangeburt leichter aus der Lunge abtransportiert werden kann, leiden per geplantem Kaiserschnitt geborene Kinder häufiger unter Atemproblemen oder Anpassungsstörungen</p>
            <p>Mit Ausnahme von Notfallsituationen kann der Vater des Kindes oder eine Begleitperson auch bei einem Kaiserschnitt mit dabei sein und es wird versucht, dass das Kind zügig nach der Entbindung zu Ihnen kommt.</p>
            <p>Ein Notfallkaiserschnitt erfolgt bei einer lebensbedrohlichen Situation für Sie oder für das Baby, z.B. bei einem akuten Sauerstoffmangel. Dieser wird in Vollnarkose durchgeführt. </p>

            <Typography variant="h2">Beckenendlage</Typography>
            <p>Etwa 3% aller Babys werden aus Beckenendlage geboren, hierbei liegt das Baby mit dem Po voran in der Gebärmutter. Selbst in der 37. Schwangerschaftswoche kann sich das Baby noch einmal drehen, sodass es mit dem Kopf nach unten liegt. Das Baby kann jedoch auch in Steißlage vaginal geboren werden. Alternativ wird ein Kaiserschnitt durchgeführt.  Die Entscheidung welche Geburtsmethode die richtige ist, hängt von weiteren Faktoren ab,  hier beraten Sie Ihre Ärzte über den richtigen Entbindungsmodus.  </p>

            <p><b>Nach all den Informationen über die Geburt kann es sicher bald auch für Sie losgehen. Wir wünschen Ihnen eine schöne Zeit und viel Freude mit diesem besonderen Erlebnis.</b></p>

          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default Birth;