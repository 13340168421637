import React from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Header from 'components/Header/Header';
import Textfield from 'components/Textfield/Textfield';
import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';


import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Footer from 'components/Footer/Footer';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
    )}
    </div>
  );
}

class Epicrisis extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0
    };

    this.handleTabChange = this.handleTabChange.bind(this);

  }

  handleTabChange (event, newValue) {
    this.setState({
      activeTab: newValue
    })
  }

  render() {

    return (
      <>
        <Header>
          <Typography variant="h2">Abschluss-Untersuchung/Epikrise</Typography>
        </Header>
        <AppBar position="static">
          <Tabs value={this.state.activeTab} onChange={this.handleTabChange} variant="scrollable" scrollButtons="auto">
            <Tab value={0} label="Schwangerschaft" />
            <Tab value={1} label="Geburt" />
            <Tab value={2} label="Wochenbett" />
            <Tab value={3} label="2. Untersuchung n. Entbindung (6.-8. Woche)" />
          </Tabs>
        </AppBar>
        <Container maxWidth="lg">
          <TabPanel value={this.state.activeTab} index={0}>
            <main style={{marginTop: '2em'}}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Textfield identifier="epicrisis_age" label="Alter (Jahre)" />
                </Grid>
                <Grid item xs={6}>
                  <RadioCheckboxes
                          identifier="epicrisis_single"
                          options={[
                            { value: "pos", label: 'Alleinstehend' },
                  ]} />
                </Grid>
                <Grid item xs={6}>
                  <Textfield identifier="epicrisis_nationality" label="Nationalität" />
                </Grid>
                <Grid item xs={6}>
                  <Textfield identifier="epicrisis_pregnancies" label="Schwangerschaften (m. dieser)" />
                </Grid>
                <Grid item xs={6}>
                  <Textfield identifier="epicrisis_births" label="Geburten (m. dieser)" />
                </Grid>
                <Grid item xs={6}>
                  <Textfield identifier="epicrisis_first_exam_pregnancy_week" label="Erst-Untersuchung in SSW" />
                </Grid>
                <Grid item xs={6}>
                  <Textfield identifier="epicrisis_no_preventive_exams" label="Zahl Vors.-Unters." />
                </Grid>
                <Grid item xs={6}>
                  <RadioCheckboxes
                            identifier="epicrisis_hospital_presentation_before_birth"
                            options={[
                              { value: "pos", label: 'Vor Entbindung in Klinik vorgestellt' },
                    ]} />
                </Grid>
                <Grid item xs={12}>
                  <Textfield identifier="epicrisis_hospital_stay_ap_weeks" label="Stat. Aufenthalt ante partum (Wochen)" />
                </Grid>
                <Grid item xs={12}>
                <Textfield identifier="epicrisis_important_risk_numbers" label="Nach Kat. A/B dokumentierte wichtigste Risikonummern" />
                </Grid>
              </Grid>
            </main>
          </TabPanel>
          <TabPanel value={this.state.activeTab} index={1}>
            <main style={{marginTop: '2em'}}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Textfield identifier="epicrisis_date" label="Datum" />
                </Grid>
                <Grid item xs={4}>
                  <Textfield identifier="epicrisis_pregnancy_week" label="SSW" />
                </Grid>
                <Grid item xs={4}>
                  <RadioCheckboxes
                            identifier="epicrisis_pregnancy_week"
                            options={[
                              { value: "pos", label: 'Extern entbunden' },
                    ]} />
                </Grid>
              </Grid>
              <hr/>
              <Grid container spacing={3}>
                <Grid item xs={12}><b>1. Kind</b></Grid>
                <Grid item xs={4}>Lebendgeburt</Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                            identifier="epicrisis_child_1_live_birth"
                            options={[
                              { value: "pos", label: 'Ja' },
                              { value: "neg", label: 'Nein' },
                    ]} />
                </Grid>
                <Grid item xs={4}>Geschlecht</Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                            identifier="epicrisis_child_1_gender"
                            options={[
                              { value: "m", label: 'M' },
                              { value: "f", label: 'W' },
                    ]} />
                </Grid>
                <Grid item xs={4}>Geburtsmodus</Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                            identifier="epicrisis_child_1_birth_mode"
                            options={[
                              { value: "sp", label: 'sp' },
                              { value: "s", label: 'S' },
                              { value: "vagop", label: 'vag. Op.'}
                    ]} />
                </Grid>
                <Grid item xs={4}>Kindslage</Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                            identifier="epicrisis_child_1_position"
                            options={[
                              { value: "sl", label: 'SL' },
                              { value: "bel", label: 'BEL' },
                              { value: "ql", label: 'QL'}
                    ]} />
                </Grid>
                <Grid item xs={4}><Textfield identifier="epicrisis_child_1_weight" label="Gewicht" /></Grid>
                <Grid item xs={4}><Textfield identifier="epicrisis_child_1_length" label="Länge" /></Grid>
                <Grid item xs={4}><Textfield identifier="epicrisis_child_1_head_scope" label="Kopfumfang" /></Grid>
                <Grid item xs={4}><Textfield identifier="epicrisis_child_1_apgar_5" label="APGAR 5'" /></Grid>
                <Grid item xs={4}><Textfield identifier="epicrisis_child_1_apgar_10" label="APGAR 10'" /></Grid>
                <Grid item xs={4}><Textfield identifier="epicrisis_child_1_ph_umbilical_artery" label="PH-Wert Nabelarterie" /></Grid>
                <Grid item xs={4}>Auffällige Fehlbildung</Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                            identifier="epicrisis_child_1_malformation"
                            options={[
                              { value: "pos", label: 'Ja' },
                              { value: "neg", label: 'Nein' },
                    ]} />
                </Grid>
              </Grid>
              <hr/>
              <Grid container spacing={3}>
                <Grid item xs={12}><b>2. Kind</b></Grid>
                <Grid item xs={4}>Lebendgeburt</Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                            identifier="epicrisis_child_2_live_birth"
                            options={[
                              { value: "pos", label: 'Ja' },
                              { value: "neg", label: 'Nein' },
                    ]} />
                </Grid>
                <Grid item xs={4}>Geschlecht</Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                            identifier="epicrisis_child_2_gender"
                            options={[
                              { value: "m", label: 'M' },
                              { value: "f", label: 'W' },
                    ]} />
                </Grid>
                <Grid item xs={4}>Geburtsmodus</Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                            identifier="epicrisis_child_2_birth_mode"
                            options={[
                              { value: "sp", label: 'sp' },
                              { value: "s", label: 'S' },
                              { value: "vagop", label: 'vag. Op.'}
                    ]} />
                </Grid>
                <Grid item xs={4}>Kindslage</Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                            identifier="epicrisis_child_2_position"
                            options={[
                              { value: "sl", label: 'SL' },
                              { value: "bel", label: 'BEL' },
                              { value: "ql", label: 'QL'}
                    ]} />
                </Grid>
                <Grid item xs={4}><Textfield identifier="epicrisis_child_2_weight" label="Gewicht" /></Grid>
                <Grid item xs={4}><Textfield identifier="epicrisis_child_2_length" label="Länge" /></Grid>
                <Grid item xs={4}><Textfield identifier="epicrisis_child_2_head_scope" label="Kopfumfang" /></Grid>
                <Grid item xs={4}><Textfield identifier="epicrisis_child_2_apgar_5" label="APGAR 5'" /></Grid>
                <Grid item xs={4}><Textfield identifier="epicrisis_child_2_apgar_10" label="APGAR 10'" /></Grid>
                <Grid item xs={4}><Textfield identifier="epicrisis_child_2_ph_umbilical_artery" label="PH-Wert Nabelarterie" /></Grid>
                <Grid item xs={4}>Auffällige Fehlbildung</Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                            identifier="epicrisis_child_2_malformation"
                            options={[
                              { value: "pos", label: 'Ja' },
                              { value: "neg", label: 'Nein' },
                    ]} />
                </Grid>
                <Grid item xs={12}>
                  <Textfield multiline rows="3" identifier="epicrisis_birth_specialities" label="Besonderheiten" />
                </Grid>

              </Grid>
            </main>
          </TabPanel>
          <TabPanel value={this.state.activeTab} index={2}>
            <main style={{marginTop: '2em'}}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  Wochbett normal
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_puerperal_normal"
                          options={[
                            { value: "pos", label: 'Ja' },
                            { value: "neg", label: 'Nein' },
                  ]} />
                </Grid>
                <Grid item xs={4}>
                  Gyn. Befund normal
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_gynaecological_finding_normal"
                          options={[
                            { value: "pos", label: 'Ja' },
                            { value: "neg", label: 'Nein' },
                  ]} />
                </Grid>
                <Grid item xs={6}>
                  <Textfield identifier="epicrisis_hb" label="Hb" />
                </Grid>
                <Grid item xs={6}>
                  <Textfield identifier="epicrisis_rr" label="RR" />
                </Grid>
                <Grid item xs={4}>
                  Anti-D-Prophylaxe
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_rh_prophylaxis"
                          options={[
                            { value: "pos", label: 'Ja' },
                            { value: "neg", label: 'Nein' },
                  ]} />
                </Grid>
                <Grid item xs={4}>
                  Beratung über ausreichende Jodzufuhr während der Stillzeit erfolgt
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_consultation_iodine"
                          options={[
                            { value: "pos", label: 'Ja' },
                            { value: "neg", label: 'Nein' },
                  ]} />
                </Grid>
                <Grid item xs={4}>
                  <Textfield multiline rows="3" identifier="epicrisis_puerperal_specialities" label="Besonderheiten" />
                </Grid>
              </Grid>
              <hr />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <b>1. Kind</b>
                </Grid>
                <Grid item xs={4}>
                  Blutgruppe und Untergruppen<br/><small>(Nur bei RH neg.-Mutter; kein Ausweis!)</small>
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_child_1_blood_group"
                          options={[
                            { value: "A+", label: 'A+' },
                            { value: "0+", label: '0+' },
                            { value: "b+", label: 'B+' },
                            { value: "a-", label: 'A-' },
                            { value: "0-", label: '0-' },
                            { value: "ab+", label: 'AB+' },
                            { value: "b-", label: 'B-' },
                            { value: "AB-", label: 'AB-' },
                  ]} />
                </Grid>
                <Grid item xs={4}>
                  Direkter Coombstest
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_child_1_direct_coombstest"
                          options={[
                            { value: "neg", label: 'Neg.' },
                            { value: "pos", label: 'Pos.' },
                  ]} />
                </Grid>
                <Grid item xs={4}>
                  <Textfield multiline rows="3" identifier="epicrisis_child_1_date_discharged" label="K. unauffällig entl. am" />
                </Grid>
                <Grid item xs={4}>
                  <Textfield multiline rows="3" identifier="epicrisis_child_1_date_transferred" label="K. verlegt am" />
                </Grid>
                <Grid item xs={4}>
                  <Textfield multiline rows="3" identifier="epicrisis_child_1_date_death" label="K. verstorben am" />
                </Grid>
              </Grid>
              <hr/>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <b>2. Kind</b>
                </Grid>
                <Grid item xs={4}>
                  Blutgruppe und Untergruppen<br/><small>(Nur bei RH neg.-Mutter; kein Ausweis!)</small>
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_child_2_blood_group"
                          options={[
                            { value: "A+", label: 'A+' },
                            { value: "0+", label: '0+' },
                            { value: "b+", label: 'B+' },
                            { value: "a-", label: 'A-' },
                            { value: "0-", label: '0-' },
                            { value: "ab+", label: 'AB+' },
                            { value: "b-", label: 'B-' },
                            { value: "AB-", label: 'AB-' },
                  ]} />
                </Grid>
                <Grid item xs={4}>
                  Direkter Coombstest
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_child_2_direct_coombstest"
                          options={[
                            { value: "neg", label: 'Neg.' },
                            { value: "pos", label: 'Pos.' },
                  ]} />
                </Grid>
                <Grid item xs={4}>
                  <Textfield identifier="epicrisis_child_2_date_discharged" label="K. unauffällig entl. am" />
                </Grid>
                <Grid item xs={4}>
                  <Textfield identifier="epicrisis_child_2_date_transferred" label="K. verlegt am" />
                </Grid>
                <Grid item xs={4}>
                  <Textfield identifier="epicrisis_child_2_date_death" label="K. verstorben am" />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Textfield identifier="epicrisis_puerperal_exam_date" label="Untersuchungsdatum" />
                </Grid>
              </Grid>
            </main>
          </TabPanel>
          <TabPanel value={this.state.activeTab} index={3}>
            <main>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  Gyn. Befund unauffällig
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_exam_2_gynaecological_finding_normal"
                          options={[
                            { value: "pos", label: 'Ja' },
                            { value: "neg", label: 'Nein' },
                  ]} />
                </Grid>
                <Grid item xs={6}>
                  <Textfield identifier="epicrisis_exam_2_hb" label="Hb (g%)" />
                </Grid>
                <Grid item xs={6}>
                  <Textfield identifier="epicrisis_exam_2_rr" label="RR" />
                </Grid>
                <Grid item xs={4}>
                  Urin
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_exam_2_urine"
                          options={[
                            { value: "z_pos", label: 'Z pos.' },
                            { value: "e_pos", label: 'E. pos.' },
                  ]} />
                </Grid>
                <Grid item xs={4}>
                  Sediment
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_sediment"
                          options={[
                            { value: "oB", label: 'o.B.' },
                  ]} />
                </Grid>
                <Grid item xs={12}>
                  <Textfield multiline rows="3" identifier="epicrisis_exam_2_specialities" label="Besonderheiten" />
                </Grid>
                <Grid item xs={12}>
                  <RadioCheckboxes
                          identifier="epicrisis_exam_2_breastfeeding"
                          options={[
                            { value: "breastfeeding", label: 'Z pos.' },
                            { value: "did_not_breastfeed", label: 'E. pos.' },
                            { value: "finished_breastfeeding", label: 'E. pos.' },
                  ]} />
                </Grid>
              </Grid>
              <hr/>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <b>1. Kind - U3</b>
                </Grid>
                <Grid item xs={4}>
                  U3 durchgeführt
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_exam_2_child_1_u3_conducted"
                          options={[
                            { value: "pos", label: 'Ja' },
                            { value: "neg", label: 'Nein' },
                  ]} />
                </Grid>
                <Grid item xs={4}>
                  Lebt und ist gesund
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_exam_2_child_1_u3_alive_and_well"
                          options={[
                            { value: "pos", label: 'Ja' },
                            { value: "neg", label: 'Nein' },
                  ]} />
                </Grid>
                <Grid item xs={4}>
                  lt. U3 behandlungsbedürftig
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_exam_2_child_1_u3_requires_treatment"
                          options={[
                            { value: "pos", label: 'Ja' },
                            { value: "neg", label: 'Nein' },
                  ]} />
                </Grid>
                <Grid item xs={12}>
                <Textfield identifier="epicrisis_exam_2_child_1_u3_died_on" label="Verstorben am" />
                </Grid>
              </Grid>
              <hr/>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <b>2. Kind - U3</b>
                </Grid>
                <Grid item xs={4}>
                  U3 durchgeführt
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_exam_2_child_2_u3_conducted"
                          options={[
                            { value: "pos", label: 'Ja' },
                            { value: "neg", label: 'Nein' },
                  ]} />
                </Grid>
                <Grid item xs={4}>
                  Lebt und ist gesund
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_exam_2_child_2_u3_alive_and_well"
                          options={[
                            { value: "pos", label: 'Ja' },
                            { value: "neg", label: 'Nein' },
                  ]} />
                </Grid>
                <Grid item xs={4}>
                  lt. U3 behandlungsbedürftig
                </Grid>
                <Grid item xs={8}>
                  <RadioCheckboxes
                          identifier="epicrisis_exam_2_child_2_u3_requires_treatment"
                          options={[
                            { value: "pos", label: 'Ja' },
                            { value: "neg", label: 'Nein' },
                  ]} />
                </Grid>
                <Grid item xs={12}>
                <Textfield identifier="epicrisis_exam_2_child_2_u3_died_on" label="Verstorben am" />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Textfield identifier="epicrisis_exam_2_date" label="Untersuchungsdatum" />
                </Grid>
              </Grid>
            </main>
          </TabPanel>
        <Footer />
        </Container>
      </>
    );
  }
}

export default Epicrisis;