import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';


import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import withNotifications from 'services/NotificationService/withNotifications';

import '../Questionnaire.scss';
import A_DAY_0_7_30_60_120_180 from './StandardizedAnswerOptions/A_DAY_0_7_30_60_120_180';
import A_DAY_0_7_30_120_240_360 from './StandardizedAnswerOptions/A_DAY_0_7_30_120_240_360';
import A_WEEK_0_7_30_60_120_180 from './StandardizedAnswerOptions/A_WEEK_0_7_30_60_120_180';
import A_WEEK_7_30_60_120_180 from './StandardizedAnswerOptions/A_WEEK_7_30_60_120_180';
import QuestionnaireHeadNotice from 'components/QuestionnaireHeadNotice/QuestionnaireHeadNotice';



class PPAQ extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">PPAQ</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/35) * 100 } />
          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />
          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "ppaq01": 1,
                "ppaq02": 2,
                "ppaq03": 3,
                "ppaq04": 4,
                "ppaq05": 5,
                "ppaq06": 6,
                "ppaq07": 7,
                "ppaq08": 8,
                "ppaq09": 9,
                "ppaq10": 10,
                "ppaq11": 11,
                "ppaq12": 12,
                "ppaq13": 13,
                "ppaq14": 14,
                "ppaq15": 15,
                "ppaq16": 16,
                "ppaq17": 17,
                "ppaq18": 18,
                "ppaq19": 19,
                "ppaq20": 20,
                "ppaq21": 21,
                "ppaq22": 22,
                "ppaq23": 23,
                "ppaq24": 24,
                "ppaq25": 25,
                "ppaq26": 26,
                "ppaq27": 27,
                "ppaq28": 28,
                "ppaq29": 29,
                "ppaq30": 29,
                "ppaq31": 29,
                "ppaq32": 29,
                "ppaq33": 29,
                "ppaq34": 31, // Note: Shift on purpose, as Q29-33 all on page 29
                "ppaq35": 32,
                "ppaq36": 33,
                "ppaq37": 34,
                "ppaq38": 35            
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <div style={{textAlign: "center", marginTop: "1em"}}>
                Der folgende Fragebogen beschäftigt sich mit Aktivität und Bewegung im Alltag und im Beruf.<br/><br/>
                Es ist wichtig, dass Sie auf die Fragen ehrlich antworten, es gibt kein richtig oder falsch.<br/><br/>
                Bitte kreuzen Sie bei jeder Frage die Antwortmöglichkeiten an, die am ehesten für Sie zutrifft.<br/><br/>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Fragebogen starten</Button>
                </div>
              </StepperPage>
            </Container>

            { this.state.activeStep >= 1 && this.state.activeStep <= 16 &&
              <QuestionnaireHeadNotice>
                <b>Zu Hause und mit der Familie</b><br/>
                Wie viel Zeit haben Sie während des letzten Trimesters <u>im privaten Alltag</u> (also wenn sie nicht bei der Arbeit sind) durchschnittlich mit den folgenden Aktivitäten verbracht?
              </QuestionnaireHeadNotice>
            }
            { this.state.activeStep >= 17 && this.state.activeStep <= 20 &&
              <QuestionnaireHeadNotice>
                <b>Unterwegs</b><br/>
                Wie viel Zeit haben Sie während des letzten Trimesters <u>im privaten Alltag</u> (also wenn sie nicht bei der Arbeit sind) durchschnittlich mit den folgenden Aktivitäten verbracht?
              </QuestionnaireHeadNotice>
            }
            { this.state.activeStep >= 21 && this.state.activeStep <= 29 &&
              <QuestionnaireHeadNotice>
                <b>Spaß und Bewegung</b><br/>
                Wie viel Zeit haben Sie während des letzten Trimesters <u>im privaten Alltag</u> (also wenn sie nicht bei der Arbeit sind) durchschnittlich mit den folgenden Aktivitäten verbracht?
              </QuestionnaireHeadNotice>
            }
            { this.state.activeStep === 30 &&
              <QuestionnaireHeadNotice>
                <b>Bei der Arbeit</b><br/>
              </QuestionnaireHeadNotice>
            }
            { this.state.activeStep >= 31 && this.state.activeStep <= 35 &&
              <QuestionnaireHeadNotice>
                <b>Bei der Arbeit</b><br/>
                Wie viel Zeit haben Sie <u>bei der Arbeit/in der Schule/im Studium</u> während des letzten Trimesters durchschnittlich mit den folgenden Aktivitäten verbracht?
              </QuestionnaireHeadNotice>
            }
            <div className="questionnaire-body">
              <Container maxWidth="lg">
                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Mahlzeiten vor- und zubereiten (Kochen, Tischdecken, Abwaschen)</FormLabel>
                    <CustomErrorMessage name="ppaq01" />
                    <RadioGroup aria-label="position" name="ppaq01" value={this.props.values.ppaq01} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={2}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend"><u>Im Sitzen</u> Kinder anziehen, baden oder füttern</FormLabel>
                    <CustomErrorMessage name="ppaq02" />
                    <RadioGroup aria-label="position" name="ppaq02" value={this.props.values.ppaq02} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={3}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend"><u>Im Stehen</u> Kinder anziehen, baden oder füttern</FormLabel>
                    <CustomErrorMessage name="ppaq03" />
                    <RadioGroup aria-label="position" name="ppaq03" value={this.props.values.ppaq03} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={4}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend"><u>Im Sitzen oder Stehen</u> mit Kindern spielen</FormLabel>
                    <CustomErrorMessage name="ppaq04" />
                    <RadioGroup aria-label="position" name="ppaq04" value={this.props.values.ppaq04} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={5}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend"><u>Im Gehen oder Rennen</u> mit Kindern spielen</FormLabel>
                    <CustomErrorMessage name="ppaq05" />
                    <RadioGroup aria-label="position" name="ppaq05" value={this.props.values.ppaq05} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={6}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Kinder tragen</FormLabel>
                    <CustomErrorMessage name="ppaq06" />
                    <RadioGroup aria-label="position" name="ppaq06" value={this.props.values.ppaq06} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={7}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Einen älteren Erwachsenen pflegen</FormLabel>
                    <CustomErrorMessage name="ppaq07" />
                    <RadioGroup aria-label="position" name="ppaq07" value={this.props.values.ppaq07} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={8}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Am Schreibtisch oder am Computer sitzen (außerhalb der Arbeit)</FormLabel>
                    <CustomErrorMessage name="ppaq08" />
                    <RadioGroup aria-label="position" name="ppaq08" value={this.props.values.ppaq08} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={9}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Fernsehen oder Video/DVD etc. schauen</FormLabel>
                    <CustomErrorMessage name="ppaq09" />
                    <RadioGroup aria-label="position" name="ppaq09" value={this.props.values.ppaq09} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_120_240_360 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={10}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Sitzen und lesen, sich unterhalten oder telefonieren (außerhalb der Arbeit)</FormLabel>
                    <CustomErrorMessage name="ppaq10" />
                    <RadioGroup aria-label="position" name="ppaq10" value={this.props.values.ppaq10} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_120_240_360 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={11}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Mit Haustieren spielen</FormLabel>
                    <CustomErrorMessage name="ppaq11" />
                    <RadioGroup aria-label="position" name="ppaq11" value={this.props.values.ppaq11} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={12}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Leichte Hausarbeit (Betten machen, Wäsche waschen/bügeln, Aufräumen)</FormLabel>
                    <CustomErrorMessage name="ppaq12" />
                    <RadioGroup aria-label="position" name="ppaq12" value={this.props.values.ppaq12} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={13}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Einkaufen (Essen, Kleidung oder Sonstiges)</FormLabel>
                    <CustomErrorMessage name="ppaq13" />
                    <RadioGroup aria-label="position" name="ppaq13" value={this.props.values.ppaq13} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={14}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Schwerere Hausarbeit (Staubsaugen, Wischen, Fegen, Fensterputzen)</FormLabel>
                    <CustomErrorMessage name="ppaq14" />
                    <RadioGroup aria-label="position" name="ppaq14" value={this.props.values.ppaq14} onChange={this.props.handleChange} required={true}>
                      <A_WEEK_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={15}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Rasen mähen mit einem Aufsitzrasenmäher</FormLabel>
                    <CustomErrorMessage name="ppaq15" />
                    <RadioGroup aria-label="position" name="ppaq15" value={this.props.values.ppaq15} onChange={this.props.handleChange} required={true}>
                      <A_WEEK_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={16}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Rasen mähen mit einem Handrasenmäher, Laub rechen oder Gartenarbeit</FormLabel>
                    <CustomErrorMessage name="ppaq16" />
                    <RadioGroup aria-label="position" name="ppaq16" value={this.props.values.ppaq16} onChange={this.props.handleChange} required={true}>
                      <A_WEEK_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={17}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Langsam gehen, um an ein Ziel zu kommen (z.B. zum Bus, zur Arbeit)</FormLabel>
                    <CustomErrorMessage name="ppaq17" />
                    <RadioGroup aria-label="position" name="ppaq17" value={this.props.values.ppaq17} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={18}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Schnell gehen, um an ein Ziel zu kommen (z.B. zum Bus, zur Arbeit)</FormLabel>
                    <CustomErrorMessage name="ppaq18" />
                    <RadioGroup aria-label="position" name="ppaq18" value={this.props.values.ppaq18} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={19}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Fahrrad fahren, um an ein Ziel zu kommen (z.B. zum Einkaufen, zur Arbeit)</FormLabel>
                    <CustomErrorMessage name="ppaq19" />
                    <RadioGroup aria-label="position" name="ppaq19" value={this.props.values.ppaq19} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={20}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Auto oder Bus fahren</FormLabel>
                    <CustomErrorMessage name="ppaq20" />
                    <RadioGroup aria-label="position" name="ppaq20" value={this.props.values.ppaq20} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={21}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Langsames Gehen, zum Spaß oder als Training</FormLabel>
                    <CustomErrorMessage name="ppaq21" />
                    <RadioGroup aria-label="position" name="ppaq21" value={this.props.values.ppaq21} onChange={this.props.handleChange} required={true}>
                      <A_WEEK_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={22}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Schnelles Gehen, zum Spaß oder als Training</FormLabel>
                    <CustomErrorMessage name="ppaq22" />
                    <RadioGroup aria-label="position" name="ppaq22" value={this.props.values.ppaq22} onChange={this.props.handleChange} required={true}>
                      <A_WEEK_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={23}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Schnell bergauf gehen, zum Spaß oder als Training </FormLabel>
                    <CustomErrorMessage name="ppaq23" />
                    <RadioGroup aria-label="position" name="ppaq23" value={this.props.values.ppaq23} onChange={this.props.handleChange} required={true}>
                      <A_WEEK_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={24}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Fahrrad fahren, zum Spaß oder als Training</FormLabel>
                    <CustomErrorMessage name="ppaq24" />
                    <RadioGroup aria-label="position" name="ppaq24" value={this.props.values.ppaq24} onChange={this.props.handleChange} required={true}>
                      <A_WEEK_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={25}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Joggen</FormLabel>
                    <CustomErrorMessage name="ppaq25" />
                    <RadioGroup aria-label="position" name="ppaq25" value={this.props.values.ppaq25} onChange={this.props.handleChange} required={true}>
                      <A_WEEK_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={26}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Schwangerschaftsgymnastik</FormLabel>
                    <CustomErrorMessage name="ppaq26" />
                    <RadioGroup aria-label="position" name="ppaq26" value={this.props.values.ppaq26} onChange={this.props.handleChange} required={true}>
                      <A_WEEK_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={27}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Schwimmen</FormLabel>
                    <CustomErrorMessage name="ppaq27" />
                    <RadioGroup aria-label="position" name="ppaq27" value={this.props.values.ppaq27} onChange={this.props.handleChange} required={true}>
                      <A_WEEK_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={28}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Tanzen</FormLabel>
                    <CustomErrorMessage name="ppaq28" />
                    <RadioGroup aria-label="position" name="ppaq28" value={this.props.values.ppaq28} onChange={this.props.handleChange} required={true}>
                      <A_WEEK_0_7_30_60_120_180 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={29}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Andere Aktivitäten</FormLabel>
                    <CustomErrorMessage name="ppaq29" />
                    <RadioGroup aria-label="position" name="ppaq29" value={this.props.values.ppaq29} onChange={this.props.handleChange} required={true}>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Ja" labelPlacement="end"/>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                   { this.props.values.ppaq29 === "1" &&
                    <>
                      <div>
                        <b>Weitere Aktivitäten - Aktivität 1</b>
                        <TextField fullWidth name="ppaq30" label="Bezeichnung/Name Aktivität 1" value={this.props.values.ppaq30} onChange={this.props.handleChange} />
                        
                        <CustomErrorMessage name="ppaq31" />
                        <RadioGroup aria-label="position" name="ppaq31" value={this.props.values.ppaq31} onChange={this.props.handleChange} required={true}>
                          <A_WEEK_7_30_60_120_180 />
                        </RadioGroup>
                      </div>

                      <div>
                        <b>Weitere Aktivitäten - Aktivität 2</b>
                        <TextField fullWidth name="ppaq32" label="Bezeichnung/Name Aktivität 2" value={this.props.values.ppaq32} onChange={this.props.handleChange} />
                        
                        <CustomErrorMessage name="ppaq33" />
                        <RadioGroup aria-label="position" name="ppaq33" value={this.props.values.ppaq33} onChange={this.props.handleChange} required={true}>
                          <A_WEEK_7_30_60_120_180 />
                        </RadioGroup>
                      </div>
                    </>
                   }
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={30}>
                   <div style={{textAlign: "center"}}>
                     <p>Bitte füllen Sie den folgenden Abschnitt nur aus, wenn Sie berufstätig sind, ehrenamtlich arbeiten oder wenn Sie Schülerin/Studentin sind.</p>
                     <Button variant="contained" color="primary" disableElevation onClick={() => this.setStep(31)}>Fragebogen weiter ausfüllen</Button><br/><br/>
                   </div>
                   <div style={{textAlign: "center"}}>
                     <p>Wenn Sie Hausfrau sind oder arbeitslos oder arbeitsunfähig, überspringen Sie bitte den Abschnitt.</p>
                     <Button variant="contained" color="primary" disableElevation onClick={() => this.setStep(36)}>Abschnitt überspringen</Button><br/><br/>
                   </div> 
                  <ForwardBackwardButtons handleBack={this.handleBack} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={31}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">In der Arbeit/im Unterricht/in einer Vorlesung sitzen</FormLabel>
                    <CustomErrorMessage name="ppaq34" />
                    <RadioGroup aria-label="position" name="ppaq34" value={this.props.values.ppaq34} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_120_240_360 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={32}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Stehen oder langsam gehen und dabei schwere Dinge (mehr als 4 kg) tragen</FormLabel>
                    <CustomErrorMessage name="ppaq35" />
                    <RadioGroup aria-label="position" name="ppaq35" value={this.props.values.ppaq35} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_120_240_360 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={33}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Stehen oder langsam gehen, ohne dabei etwas zu tragen</FormLabel>
                    <CustomErrorMessage name="ppaq36" />
                    <RadioGroup aria-label="position" name="ppaq36" value={this.props.values.ppaq36} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_120_240_360 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={34}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Schnell gehen und dabei schwere Dinge (mehr als 4 kg) tragen</FormLabel>
                    <CustomErrorMessage name="ppaq37" />
                    <RadioGroup aria-label="position" name="ppaq37" value={this.props.values.ppaq37} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_120_240_360 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={35}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Schnell gehen, ohne dabei etwas zu tragen</FormLabel>
                    <CustomErrorMessage name="ppaq38" />
                    <RadioGroup aria-label="position" name="ppaq38" value={this.props.values.ppaq38} onChange={this.props.handleChange} required={true}>
                      <A_DAY_0_7_30_120_240_360 />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={36}>
                  <Typography variant="h2">Fragebogen absenden</Typography>
                  Vielen Dank! Sie können den Fragebogen nun absenden.
                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                    <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                  </div>
                </StepperPage>

                {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}       
              </Container>  
            </div>
          </form>
        </main>
      <Footer showMenu={false} />
    </>
    );
  }
}

export default withNotifications(withFormik({
  mapPropsToValues: () => ({
    ppaq01: '',
    ppaq02: '',
    ppaq03: '',
    ppaq04: '',
    ppaq05: '',
    ppaq06: '',
    ppaq07: '',
    ppaq08: '',
    ppaq09: '',
    ppaq10: '',
    ppaq11: '',
    ppaq12: '',
    ppaq13: '',
    ppaq14: '',
    ppaq15: '',
    ppaq16: '',
    ppaq17: '',
    ppaq18: '',
    ppaq19: '',
    ppaq20: '',
    ppaq21: '',
    ppaq22: '',
    ppaq23: '',
    ppaq24: '',
    ppaq25: '',
    ppaq26: '',
    ppaq27: '',
    ppaq28: '',
    ppaq29: '',
    ppaq30: '',
    ppaq31: '',
    ppaq32: '',
    ppaq33: '',
    ppaq34: '',
    ppaq35: '',
    ppaq36: '',
    ppaq37: '',
    ppaq38: '',
  }),  
  validationSchema: Yup.object().shape({
      ppaq01: Yup.number().required('Pflichtfeld'),
      ppaq02: Yup.number().required('Pflichtfeld'),
      ppaq03: Yup.number().required('Pflichtfeld'),
      ppaq04: Yup.number().required('Pflichtfeld'),
      ppaq05: Yup.number().required('Pflichtfeld'),
      ppaq06: Yup.number().required('Pflichtfeld'),
      ppaq07: Yup.number().required('Pflichtfeld'),
      ppaq08: Yup.number().required('Pflichtfeld'),
      ppaq09: Yup.number().required('Pflichtfeld'),
      ppaq10: Yup.number().required('Pflichtfeld'),
      ppaq11: Yup.number().required('Pflichtfeld'),
      ppaq12: Yup.number().required('Pflichtfeld'),
      ppaq13: Yup.number().required('Pflichtfeld'),
      ppaq14: Yup.number().required('Pflichtfeld'),
      ppaq15: Yup.number().required('Pflichtfeld'),
      ppaq16: Yup.number().required('Pflichtfeld'),
      ppaq17: Yup.number().required('Pflichtfeld'),
      ppaq18: Yup.number().required('Pflichtfeld'),
      ppaq19: Yup.number().required('Pflichtfeld'),
      ppaq20: Yup.number().required('Pflichtfeld'),
      ppaq21: Yup.number().required('Pflichtfeld'),
      ppaq22: Yup.number().required('Pflichtfeld'),
      ppaq23: Yup.number().required('Pflichtfeld'),
      ppaq24: Yup.number().required('Pflichtfeld'),
      ppaq25: Yup.number().required('Pflichtfeld'),
      ppaq26: Yup.number().required('Pflichtfeld'),
      ppaq27: Yup.number().required('Pflichtfeld'),
      ppaq28: Yup.number().required('Pflichtfeld'),
      ppaq29: Yup.number().required('Pflichtfeld'), // Note: Anything after 34 is not required, as it is an optional part of the survey (only filled out if "berufstaetig")
      ppaq34: Yup.number(),
      ppaq35: Yup.number(),
      ppaq36: Yup.number(),
      ppaq37: Yup.number(),
      ppaq38: Yup.number(),
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "ppaq",
      questionnaireData: values,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
    });
  }
})(PPAQ));

/* https://stackoverflow.com/questions/59497550/handling-formik-form-when-validation-error-occurs */