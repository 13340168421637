import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Header from 'components/Header/Header';
import Globals from 'services/Globals';


import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';


import Footer from 'components/Footer/Footer';
import { datetimeKeysToDateKeys } from 'helpers/Helpers';

import './AdminMeasurements.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import YesNoDetails from './YesNoDetails/YesNoDetails';
import withNotifications from 'services/NotificationService/withNotifications';
import TopSpacer from 'components/TopSpacer/TopSpacer';


export default withNotifications(
  function AdminMeasurements(props) {

    const [measurementData, setMeasurementData] = useState({});
    const [userId, setUserId] = useState();
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      // TODO: Error handling
      getUsers();
    }, []);

    const getUsers = () => {
      axios.get(Globals.SERVER_URL + "/admin/users").then(function(res){
        setUsers(res.data);
      }).catch((err) =>{
        props.notificationService.push("Benutzer konnten nicht abgerufen werden.", "error", 0, err); 
      });
    }

    const setUser = (userId) => {
      setUserId(userId);
      setLoading(true);
      axios.get(Globals.SERVER_URL + "/admin/measurements?selectedUserId=" + userId, {
      }).then(function(res){
        setMeasurementData(res.data);
        setLoading(false);
      }).catch((err) =>{
        setLoading(false);
        props.notificationService.push("Konnte Daten der gewählten Person nicht abrufen.", "error", 0, err); 
      });
    }

    return (
      <>
        <Header>
          <Typography variant="h2">Admin: User</Typography>
        </Header>

        <main>
          <TopSpacer />
          <Container maxWidth="lg">
            <div>
              Auf dieser Seite werden die für einen Benutzer in der Datenbank abgespeicherten Wearable-Daten angezeigt.
            </div>
            <div>
              <b>Benutzer wählen: </b>
            
              <select style={{width: "auto"}} onChange={e => setUser(e.target.value)}>
                { 
                  users.map((user) => 
                    <option key={user.id} value={user.id}>
                      { user.id }: { user.pseudonym }
                    </option>
                  )
                }
              </select>
            </div>
            { userId ? 
              loading ?
                <p>Lade Daten - bitte warten...</p> 
                : 
                <table className="wearable-data-table">
                  <thead>
                    <tr>
                      <th>Datum</th>
                      <th>Aktivität Übersicht<br/>(API: getactivity)</th>
                      <th>Aktivität Details<br/>(API: getintradayactivity)</th>
                      <th>Schlaf Übersicht<br/>(API: getsummary)</th>
                      <th>Schlaf Details<br/>(API: get)</th>
                      <th>Messungen<br/>(API: getmeas)</th>
                      <th>Workouts<br/>(API: getworkout)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Object.keys(measurementData).map(idx => {
                        return(
                          <tr key={idx}>
                            <td>{ idx }</td>
                            <td>
                              <YesNoDetails data={measurementData[idx]['activityData']} />
                            </td>
                            <td>
                              <YesNoDetails data={measurementData[idx]['activityDetailData']} />
                            </td>
                            <td>
                              <YesNoDetails data={measurementData[idx]['sleepData']} />
                            </td>
                            <td>
                              <YesNoDetails data={measurementData[idx]['sleepDetailData']} />
                            </td>
                            <td>
                              <YesNoDetails data={measurementData[idx]['measData']} />
                            </td>
                            <td>
                              <YesNoDetails data={measurementData[idx]['workoutData']} />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              :
              <>
                <p>Bitte Benutzer auswählen, um Daten anzuzeigen</p>
              </>
            }
          </Container>
        </main>
        <Footer />
      </>
    );
  }
)