import React from "react";

class UltrasoundList extends React.Component {

    render() {
        return (
            <table>
                <tbody>
                    {this.props.data &&
                        this.props.data.map((value, index) => {
                            return (
                                <tr key={index}>
                                    <td>{value["date"]}</td>
                                    <td>{value["indication"]}</td>
                                    <td>{value["findings"]}</td>
                                    <td>{value["comment"]}</td>
                                    <td>{value["examiner"]}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        );
    }
}

export default UltrasoundList;
