import React, { useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';

import Header from '../../components/Header/Header';
import Footer from 'components/Footer/Footer';

import Globals from 'services/Globals';

import './Overview.scss';

import Container from '@material-ui/core/Container';
import { Button, Typography } from '@material-ui/core';

import TaskCard from './TaskCard/TaskCard';
import InformationsCard from './InformationsCard/InformationsCard';
import MBSRCard from './MBSRCard/MBSRCard';
import MyReadingsCard from './MyReadingsCard/MyReadingsCard';
import MyPregnancyWeekCard from './MyPregnancyWeekCard/MyPregnancyWeekCard';
import { PaletteContext } from 'App';
import ActivitiesFab from 'components/ActivitiesFab/ActivitiesFab';
import TopSpacer from 'components/TopSpacer/TopSpacer';
import PrintLoginCard from 'components/PrintLoginCard/PrintLoginCard';


export default function Overview () {

  const [activities, setActivities] = useState([]);
  const { palette, setPalette } = React.useContext(PaletteContext);

  useLayoutEffect(() => {

    axios.get(Globals.SERVER_URL + "/settings/colorPalette").then(function(res) {
      if(res.data.length !== 0) {
        let userPalette = res.data;
        setPalette(userPalette)  
      }    
    });

    axios.get(Globals.SERVER_URL + "/activities").then(function(res){
      setActivities(res.data);
    }).catch(function(err){
      console.log(err);
    });
  }, []);

  return (
    <>
      <Header>
          <Typography variant="h2">Startseite</Typography>
      </Header>
      <main>
        <TopSpacer />
        <Container maxWidth="lg">
          { /* <Typography variant="h2" style={{marginTop: "0.5em"}}>Wichtig</Typography> */ }
          <TaskCard />
          { /* <Typography variant="h2">Mehr</Typography> */ }
          <MyReadingsCard />
          <MyPregnancyWeekCard />
          <InformationsCard />
          <MBSRCard />
          <PrintLoginCard />
        </Container>
      </main>
      { /* <ActivitiesFab /> */ }
      <Footer />
    </>
  );
}