import { useTheme } from "@material-ui/core";
import React from "react";

export default function QuestionnaireHeadNotice(props) {
  const theme = useTheme(); 
  return(
    <>
      <div className="questionnaire-title" style={{backgroundColor: theme.palette.secondary.main}}>
        { props.children }
      </div>
    </>
  );
}