import React from 'react';  

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Button from '@material-ui/core/Button';

import './ForwardBackwardButtons.scss';

class ForwardBackwardButtons extends React.Component {
  render(props) {
    return (
      <div className="button-group">
        <Button className="questionnaire-backward" variant="contained" color="primary" size="small" disableElevation onClick={this.props.handleBack} disabled={!this.props.handleBack}>
          <KeyboardArrowLeft />
          Zurück
        </Button>
        <Button className="questionnaire-forward" variant="contained" color="primary" size="small" disableElevation onClick={this.props.handleNext} disabled={!this.props.handleNext}>
          Nächste
          <KeyboardArrowRight />
        </Button>
      </div>
    );
  }
}

export default ForwardBackwardButtons;