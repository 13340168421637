import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import DataContext from 'services/DataContext';


/**
 * Creates several checkboxes that act like radio buttons, i.e. only one option at a time may be selected
 * However, the use of checkboxes allows the user to de-select a previously 
 */


export default class RadioCheckboxesYN extends React.Component {

  constructor(props) {
    super(props);
  }

  render(props) {
    return (
      <>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.props.value == "1"}
              name="ja"
              color="primary"
            />
          }
          label="ja"
          key="ja"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.props.value == "0"}
              name="nein"
              color="primary"
            />
          }
          label="nein"
          key="nein"
        />
      </>
    )
    /*return (
      <>
        {
          this.props.options.map(({ value, label }, index) => 
          {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dataContext.data[this.props.identifier] === value}
                    onChange={this.handleChange}
                    name={value}
                    color="primary"
                  />
                  }
                label={label}
                key={value}
              />
            );
          })
        }
      </>
    )*/
  }
}