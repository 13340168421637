import React from "react";
import './SUSItem.scss'

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { FormControlLabel, FormGroup, Radio, Typography } from '@material-ui/core';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';


export default function SUSItem(props) {
  return (
    <>
      <FormControl className="sus-group" component="fieldset" fullWidth={true}>
        <FormLabel>
          { props.question }
        </FormLabel>
        <CustomErrorMessage name={props.name} />
        <RadioGroup className="sus-answers" aria-label="position" name={props.name} value={props.value} onChange={props.onChange} required={true}>
          <div>
            Stimme überhaupt nicht zu.
            <FormControlLabel value="0" control={<Radio color="primary" />} />
          </div>
          <div>
          <FormControlLabel value="1" control={<Radio color="primary" />} />
          </div>
          <div>
          <FormControlLabel value="2" control={<Radio color="primary" />} />
          </div>
          <div>
          <FormControlLabel value="3" control={<Radio color="primary" />} />
          </div>
          <div>
            Stimme voll zu
          <FormControlLabel value="4" control={<Radio color="primary" />} />
          </div>
        </RadioGroup>
      </FormControl>
    </>
  );
}