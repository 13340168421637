import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class Sports extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Während der Schwangerschaft &gt; Sport in der Schwangerschaft</div>
            
            <Typography variant="h1">Sport in der Schwangerschaft</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Jetzt wird's sportlich – auch Sport wird in der Schwangerschaft groß geschrieben, wir sagen Ihnen was Sie beachten müssen.</b></p>
            <p>Sie fragen sich sicher, ob Sie Sport in der Schwangerschaft treiben dürfen. Darauf gibt es eine eindeutige Antwort – ja. Auf Leistungssport sollten Sie  jedoch verzichten. Viele Studien haben gezeigt, dass z.B. die Geburt bei sportlich aktiven Schwangeren komplikationsfreier verlief. Auch Ihr Risiko für einen Schwangerschaftsdiabetes oder eine Schwangerschaftsvergiftung ist vermindert.</p>
            <Typography variant="h2">Der Belastungsbereich</Typography>
            <p>Verschiedene Sportarten vor allem Ausdauersportarten können Sie im mittleren Belastungsbereich durchführen. Hierfür können Sie Intensivität Ihres sportlichen Trainings anhand Ihres Pulses kontrollieren, dieser sollte zwischen 125 und 155 Schläge pro Minute sein, beim Fahrradfahren sollte er maximal 145 sein und beim Schwimmen nur 135 Schläge, um Ihren Körper nicht zu stark zu belasten. Als Faustregel gilt hier, dass Sie sich am besten während Ihrer sportlichen Aktivität auch noch unterhalten können. Daneben sollten Sie wirklich nur so viel Sport betreiben, wie Sie sich wohl fühlen. Maximal jedoch 1 Stunde pro Tag. </p>
            <Typography variant="h2">Verletzungsrisiken</Typography>
            <p>Sportarten mit einem hohen Verletzungsrisiko oder Mannschaftssportarten mit Körperkontakt sollten Sie in der Schwangerschaft wegen dem Verletzungsrisiko nicht durchführen. Hierzu gehören Sportarten wie Boxen, Kampfsportarten. Auch Tieftauchen oder Höhensport sollte während Ihrer Schwangerschaft vermieden werden. Sportarten wie Skilaufen, Reiten oder Inlineskating sollten Sie wegen des Sturzrisikos auch lieber vertagen. Bei Ballsportarten kann es sein, dass versehentlich der Ball Ihren Bauch trifft – auch das kann Folgen wie eine vorzeitige Lösung des Mutterkuchens haben, daher ist hier Vorsicht geboten. Viele andere Sportarten können aber problemlos durchgeführt werden.</p>
            <p>Wenn Sie Joggerin sind und an das Laufen gewöhnt sind, können Sie dies auch während der Schwangerschaft weiterführen. Sprechen Sie jedoch vorher mit Ihrem Arzt, ob bei Ihnen Schwangerschaftsrisiken vorliegen, die dies nicht erlauben.</p>
            <p>Auch das Fitnessstudio kann weiter genützt werden. Haben Sie noch nicht so viel sportliche Erfahrung, sind Walking, Fahrradfahren oder Wassersportarten wie Schwimmen oder Aquafitness empfohlen. Vor allem Wassersportarten sind gut gegen Wassereinlagerungen in den Beinen. Beim Walking wird die Gesamtmuskulatur trainiert, aber Ihr Beckenboden nicht belastet. Auch Yoga, Pilates und leichtes Krafttraining sind empfehlenswert. Besonders Übungen für die Beckenbodenmuskeln sind auch im Hinblick auf die langfristige Stabilisierung des Beckenbodens wichtig. Es gibt auch verschiedene Kurse speziell mit Sportangeboten für Schwangere.</p>
            <Typography variant="h2">Leistungen der Krankenkasse</Typography>
            <p>Auch die Krankenkasse zahlt oder übernimmt einen Teil von sportlichen Kursen in der Schwangerschaft, wenn die Kurse von Physiotherapeuten oder Hebammen geleitet werden, am besten Sie informieren sich hier bei Ihrer Kasse.</p>
            <Typography variant="h2">Welche Sportarten Sie besser vermeiden sollten</Typography>
            <p>Sit-Ups und gezieltes Bauchmuskeltraining sollten jedoch vermieden werden. Bei Sit-Ups drücken die Organe gegen Ihre Gebärmutter, daher sollte das vermieden werden. Auch Übungen im Liegen können problematisch sein, wenn es Ihnen in Rückenlage bei fortgeschrittener Schwangerschaft schwindelig wird. Übungen im Liegen können Sie dann am besten immer leicht auf der linken Seite liegend durchführen, da hier die Blutzufuhr zum Herzen nicht durch die Gebärmutter vermindert wird.</p>
            
            <Typography variant="h2">Übungen für Zuhause</Typography>
            <p>Abschließend zeigen wir Ihnen noch ein paar Übungen die Sie prima Zuhause alleine durchführen können.</p>

            <Typography variant="h3">Stärkung der Arm-/Brustmuskulatur</Typography>
            <p>Sie stellen sich aufrecht und mit geschlossenen Beinen ca. einen Schritt breit vor einer Wand hin, strecken die Hände nach vorne aus und legen die Handinnenflächen an die Wand. Die Fingerspitzen zeigen hierbei zueinander und die Ellbogen zeigen nach außen. Jetzt beugen Sie sich ganz langsam nach vorne und die Arme beugen sich, Sie bleiben allerdings fest mit den Füßen am Boden stehen. Anschließend drücken Sie sich wieder zurück sodass Sie wieder in der Anfangsposition stehen.</p>

            <Typography variant="h3">Stärkung der Fußmuskulatur</Typography>
            <p>Sie stellen sich gerade hin und heben Ihren Fuß an, die Fußspitze zeigt nach unten. Der Fuß wird im Fußgelenk erst in die eine Richtung ca. 10mal, dann in die andere Richtung gekreist. Dann wechseln Sie die Füße.</p>

            <Typography variant="h3">Stärkung der Oberschenkelmuskeln</Typography>
            <p>Sie setzen sich gerade auf einen Stuhl und stützen sich mit Ihren Armen am Sitz ab. Der Unterschenkel wird angehoben, sodass eine gerade Linie mit Ihrem Oberschenkel gebildet wird. Dann wird diese Position für ca. 10 Sekunden gehalten. Das Gleiche wird dann mit dem anderen Bein wiederholt.</p>

            <Typography variant="h3">Oberkörperdehnung</Typography>
            <p>Stellen Sie sich aufrecht hin, die Beine stehen ca. schrittbreit auseinander. Die Arme sind nach oben gestreckt, die Handflächen berühren sich über dem Kopf. Der Oberkörper wird so weit wie möglich nach links gekippt, in dieser Position kurz gehalten und dann auf die andere Seite gekippt und auch hier kurz gehalten. </p>

            <b>Wir wünschen viel Freude beim Training.</b>

          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default Sports;