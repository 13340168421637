import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';

export default function FileUploadNotice(props) {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        open={props.loading}
        message={<div>Bild wird hochgeladen, bitte warten...<br/>Je nach Internetverbindung kann das bis zu drei Minuten dauern.<br/>Der Dialog wird automatisch fortgesetzt.</div>}
      />
    ); 
}