import React from 'react';

import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

import StepperPage from 'components/StepperPage/StepperPage';

import axios from 'axios';
import Globals from 'services/Globals';
import moment from 'moment';

import { Link } from 'react-router-dom'

import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

import { withRouter } from 'react-router-dom';
import withNotifications from 'services/NotificationService/withNotifications';
import { createFileForDownload } from 'helpers/Helpers';

class CalendarInvites extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
    }

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.submitCalendarSetup = this.submitCalendarSetup.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  downloadCalendarDates() {
    let self = this;

    axios.get(Globals.SERVER_URL + "/settings/conception_date").then(function(res) {
      let conceptionDate = moment(res.data);
      let now = moment();
      let v12ssw = conceptionDate.clone().add(10, 'weeks');
      let v38end = conceptionDate.clone().add(38, 'weeks'); // This is more towards "the end" to give a safety margin
      let ppquestionnaires = conceptionDate.clone().add(46, 'weeks') // Assumes approx 40 weeks pregnancy

      // https://icalendar.org/iCalendar-RFC-5545/3-8-5-3-recurrence-rule.html
       
      let ics = `BEGIN:VCALENDAR
PRODID:-//SMART Start Calendar Invite V1.0//DE
VERSION:2.0
BEGIN:VEVENT
DTSTAMP:` + now.format('YYYYMMDDTHHMMSS') + `
SUMMARY:SMART Start: Neue Aufgaben verfügbar - jetzt bearbeiten
DTSTART:` + v12ssw.format('YYYYMMDD') + 'T120000' + `
RRULE:FREQ=WEEKLY;INTERVAL=4;UNTIL=` + v38end.format('YYYYMMDD') + 'T120000' + `;
DESCRIPTION:Es sind neue Aufgaben verfügbar! Jetzt bearbeiten: https://studie.digitalermutterpass.de
BEGIN:VALARM
TRIGGER:-PT60M
ACTION:DISPLAY
DESCRIPTION:SMART Start: Neue Aufgaben verfügbar
END:VALARM
END:VEVENT
BEGIN:VEVENT
DTSTAMP:` + now.format('YYYYMMDDTHHMMSS') + `
SUMMARY:SMART Start: Neue Aufgaben verfügbar - jetzt bearbeiten
DTSTART:` + ppquestionnaires.format('YYYYMMDD') + 'T120000' + `
DTEND:` + ppquestionnaires.format('YYYYMMDD') + 'T130000' + `
DESCRIPTION:Es sind neue Aufgaben verfügbar! Jetzt bearbeiten: https://studie.digitalermutterpass.de
BEGIN:VALARM
TRIGGER:-PT60M
ACTION:DISPLAY
DESCRIPTION:SMART Start: Neue Aufgaben verfügbar
END:VALARM
END:VEVENT
END:VCALENDAR
`
      createFileForDownload("smartstart.ics", ics);
    }).catch(function(err) {
      self.props.notificationService.push("Der Konzeptionszeitpunkt konnte nicht abgerufen werden. Daher konnten keine Kalendereinladungen erstellt werden. Bitte versuchen Sie es später erneut oder kontaktieren Sie die Studienzentrale.", "error", 0, err);
    });
  }

  submitCalendarSetup() {
    let self = this;
    axios.post(Globals.SERVER_URL + "/setup/calendar-invites", {
    }).then(function(res){
      self.props.notificationService.push("Einrichtung der Benachrichtigungen erfolgreich abgeschlossen!", "success", 0);
      self.props.history.push('/overview');
    }).catch(function(err){
      self.props.notificationService.push("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie die Studienzentrale.", "error", 0, err);
      console.log(err);
    });
  }

  
        


  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Benachrichtigungen-Einrichtung</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            <StepperPage activeStep={this.state.activeStep} thisStep={0}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Einrichtung der Benachrichtigungen</Typography>
                <p>Nachfolgend werden die Benachrichtigungen der App eingerichtet.</p>
                <p>So wissen Sie immer Bescheid, wenn neue Aufgaben verfügbar sind.</p>
                <p>Die Benachrichtigungen erhalten Sie als Kalender-Termin.</p>
          
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Los geht's <KeyboardArrowRight /></Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={1}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Herunterladen der Benachrichtigungen</Typography>
                <p>Klicken Sie auf den Button unten, um die Kalender-Termine (Benachrichtigungen) herunterzuladen. </p>
                <p>Falls diese nicht automatisch zu Ihrem Smartphone-Kalender hinzugefügt werden, wählen Sie "Öffnen mit Kalender" (oder ähnlich). Wenn Sie eine ganz bestimmte Kalender-App nutzen, wählen Sie diese App aus.</p>

                <Button variant="contained" onClick={this.downloadCalendarDates} autoFocus color="primary">
                  Benachrichtigungen (Kalender-Termine) herunterladen
                </Button>

                <br/><br/>

                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Weiter <KeyboardArrowRight /></Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={2}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Alles erledigt?</Typography>
                <p>Bitte überprüfen Sie jetzt, ob die Termine in Ihrem Kalender sichtbar sind.</p>
                <p>Wenn alles geklappt hat, klicken Sie auf den Button "Benachrichtigungen erfolgreich eingerichtet". Gehen Sie sonst zurück und sehen Sie sich die Anleitung erneut an.</p>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleBack}><KeyboardArrowLeft /> Zurück</Button><br/><br/>
                <Button variant="contained" onClick={this.submitCalendarSetup} autoFocus color="primary">
                  Benachrichtigungen (Termine) erfolgreich eingerichtet
                </Button>
              </div>
            </StepperPage>

            </Container>

        </main>
        <Footer />
      </>
    );  
  }
}

export default withNotifications(withRouter(CalendarInvites));