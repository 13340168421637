import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';

export default function MappingTextfield(props) {

  const [data, setData] = useState();
  const {value, ...childProps} = props;

  useEffect(() => {
    setData(props.mapping[value]);
  });

  return (
    <>
      <TextField value={data ? data : ''} {...childProps} />
    </>
  );
    
}