import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';

import withNotifications from 'services/NotificationService/withNotifications';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import '../Questionnaire.scss';
import QuestionnaireHeadNotice from 'components/QuestionnaireHeadNotice/QuestionnaireHeadNotice';
import SUSItem from './SUSItem/SUSItem';



//import Textfield from '../../components/Textfield/Textfield';
//import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';


class SUS extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">SUS</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/2) * 100 } />
          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />

          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "sus01": 1,
                "sus02": 1,
                "sus03": 1,
                "sus04": 1,
                "sus05": 1,
                "sus06": 1,
                "sus07": 1,
                "sus08": 1,
                "sus09": 1,
                "sus10": 1
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <div style={{textAlign: "center", marginTop: "1em"}}>
                  Bitte beantworten Sie die nachfolgenden Fragen hinsichtlich Ihrer Erfahrungen mit dieser App.
                  <br/><br/>
                  <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Fragebogen starten</Button>
                </div>
              </StepperPage>
            </Container>

            <div className="questionnaire-body">
              <Container maxWidth="lg">
                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <SUSItem name="sus01" value={this.props.values.sus01} question="Ich denke, dass ich das System gerne häufig benutzen würde." onChange={this.props.handleChange} />
                  <SUSItem name="sus02" value={this.props.values.sus02} question="Ich fand das System unnötig komplex." onChange={this.props.handleChange} />
                  <SUSItem name="sus03" value={this.props.values.sus03} question="Ich fand das System einfach zu benutzen." onChange={this.props.handleChange} />
                  <SUSItem name="sus04" value={this.props.values.sus04} question="Ich glaube, ich würde die Hilfe einer technisch versierten Person benötigen, um das System benutzen zu können." onChange={this.props.handleChange} />
                  <SUSItem name="sus05" value={this.props.values.sus05} question="Ich fand, die verschiedenen Funktionen in diesem System waren gut integriert." onChange={this.props.handleChange} />
                  <SUSItem name="sus06" value={this.props.values.sus06} question="Ich denke, das System enthielt zu viele Inkonsistenzen." onChange={this.props.handleChange} />
                  <SUSItem name="sus07" value={this.props.values.sus07} question="Ich kann mir vorstellen, dass die meisten Menschen den Umgang mit diesem System sehr schnell lernen." onChange={this.props.handleChange} />
                  <SUSItem name="sus08" value={this.props.values.sus08} question="Ich fand das System sehr umständlich zu nutzen." onChange={this.props.handleChange} />
                  <SUSItem name="sus09" value={this.props.values.sus09} question="Ich fühlte mich bei der Benutzung des Systems sehr sicher." onChange={this.props.handleChange} />
                  <SUSItem name="sus10" value={this.props.values.sus10} question="Ich musste eine Menge lernen, bevor ich anfangen konnte das System zu verwenden." onChange={this.props.handleChange} />

                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                    <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                  </div>

                  <ForwardBackwardButtons handleBack={this.handleBack} />
                </StepperPage>
                
                {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}       
              </Container>  
            </div>
          </form>
        </main>
      <Footer showMenu={false} />
    </>
    );
  }
}

export default withNotifications(withFormik({
  mapPropsToValues: () => ({
    sus01: '',
    sus02: '',
    sus03: '',
    sus04: '',
    sus05: '',
    sus06: '',
    sus07: '',
    sus08: '',
    sus09: '',
    sus10: '',
  }),  
  validationSchema: Yup.object().shape({
    sus01: Yup.number().required('Pflichtfeld'),
    sus02: Yup.number().required('Pflichtfeld'),
    sus03: Yup.number().required('Pflichtfeld'),
    sus04: Yup.number().required('Pflichtfeld'),
    sus05: Yup.number().required('Pflichtfeld'),
    sus06: Yup.number().required('Pflichtfeld'),
    sus07: Yup.number().required('Pflichtfeld'),
    sus08: Yup.number().required('Pflichtfeld'),
    sus09: Yup.number().required('Pflichtfeld'),
    sus10: Yup.number().required('Pflichtfeld'),
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "sus",
      questionnaireData: values,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
    });
  }
})(SUS));