import React from 'react';

import Header from 'components/Header/Header';

import Textfield from 'components/Textfield/Textfield';
import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Footer from 'components/Footer/Footer';





class Consultation extends React.Component {
    render() {
        return (
          <>
            <Header>
              <h2>Beratung</h2>
            </Header>
            
              <main>
              <Container maxWidth="lg">
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      a) Ernährung (u.a. Jodzufuhr), Medikamente, Genussmittel (Alkohol, Tabak und andere Drogen)<br/>
                      <Textfield identifier="consultation_nutrition_medicaton_drugs_remarks" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="consultation_nutrition_medicaton_drugs"
                        options={[
                          { value: "pos", label: 'ja' },
                        ]} />
                    </Grid>
                  </Grid>
                  <hr/>
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      b) Tätigkeit/Beruf, Sport, Reisen<br/>
                      <Textfield identifier="consultation_profession_sports_travel_remarks" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="consultation_profession_sports_travel"
                        options={[
                          { value: "pos", label: 'ja' },
                        ]} />
                    </Grid>
                  </Grid>
                  <hr/>
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      c) Risikoberatung<br/>
                      <Textfield identifier="consultation_risk_remarks" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="consultation_risk"
                        options={[
                          { value: "pos", label: 'ja' },
                        ]} />
                    </Grid>
                  </Grid>
                  <hr/>
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      d) Geburtsvorbereitung, Schwangerschaftsgymnastik<br/>
                      <Textfield identifier="consultation_birth_preparation_gym_remarks" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="consultation_birth_preparation_gym"
                        options={[
                          { value: "pos", label: 'ja' },
                        ]} />
                    </Grid>
                  </Grid>
                  <hr/>
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      e) Krebsfrüherkennungsuntersuchung<br/>
                      <Textfield identifier="consultation_cancer_early_detection_examination_remarks" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="consultation_cancer_early_detection_examination"
                        options={[
                          { value: "pos", label: 'ja' },
                        ]} />
                    </Grid>
                  </Grid>
                  <hr/>
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      f) Zum HIV-Antikörpertest<br/>
                      HIV-Antikörpertest durchgeführt?
                      <RadioCheckboxes
                        identifier="consultation_hiv_antibodies_test_conducted"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' }
                        ]} />
                      <Textfield identifier="consultation_hiv_antibodies_test_remarks" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="consultation_hiv_antibodies_test"
                        options={[
                          { value: "pos", label: 'ja' },
                        ]} />
                    </Grid>
                  </Grid>
                  <hr/>
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      g) Zur Mundgesundheit<br/>
                      <Textfield identifier="consultation_oral_health_remarks" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="consultation_oral_health"
                        options={[
                          { value: "pos", label: 'ja' },
                        ]} />
                    </Grid>
                  </Grid>
                </Container>
            </main> 
            <Footer />
          </>
        );
      }
    }

export default Consultation;