import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import CustomizedRatings from "./customizedRatings";
import { Card, CardContent, useTheme } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .MuiTextField-root": {},
    "& .outlined-textarea": {
      margin: theme.spacing(1),
      justifyContent: "center",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


export default function Entry({ postEntry }) {
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const [mood, setMood] = useState(null);
  const fieldMessageRef = useRef();
  const theme = useTheme(); 
  const handleOnChangeMessage = (e) => setMessage(e.target.value);
  const handleOnChangeMood = (e) => setMood(e.target.value);
  const handleOnClick = (e) => {
    e.preventDefault();
    if (mood !== null) {
      postEntry({
        message,
        mood,
        date: new Date(),
      });
      setMessage("");
      setMood(null);
    }
  };

  useEffect(() => {
    fieldMessageRef.current.focus();
  }, []);

  return (
    <Card className={classes.root} variant="outlined" style={{border: "1px solid " + theme.palette.primary.main, textAlign: "center"}}>
      <CardContent>
        <CustomizedRatings value={parseInt(mood)} onChange={handleOnChangeMood} />
        <TextField
          label="Hier können Sie weitere Notizen hinzufügen"
          multiline
          rows={3}
          variant="outlined"
          onChange={handleOnChangeMessage}
          ref={fieldMessageRef}
          value={message}
          style={{width: "100%"}}
        />
        <br/><br/>
        <Button variant="contained" color="primary" disabled={mood === null} onClick={handleOnClick}>
          Speichern
        </Button>
      </CardContent>
    </Card>
  );
}
