import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class BirthPreparationClass extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Geburt &gt; Geburtsvorbereitungskurs</div>
            
            <Typography variant="h1">Geburtsvorbereitungskurs</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Für die Geburt möchten Sie sich sicher gut vorbereitet fühlen. Was ein Geburtsvorbereitungskurs für Sie bereit hält, erfahren Sie hier.</b></p>

            <p>Zitat: "Die Geburt eines Kindes ist ein wilder Sturm!" (Frederick Leboyer)</p>
            <p>Sind Sie noch vollkommen aufgelöst von der Nachricht der Schwangerschaft oder denken Sie schon an die anstehende Geburt?</p>
            <Typography variant="h2">Kurse sind schnell ausgebucht</Typography>
            <p>Egal, was Sie nun gerade empfinden, ab Woche 14 wird es Zeit sich um den Geburtsvorbereitungskurs zu kümmern, obwohl die Geburt noch in weiter Ferne liegt! Denn die Kurse sind rar und schnell ausgebucht. Doch keine Sorge, meist finden alle dennoch einen Platz. Aber besser man kümmert sich rechtzeitig darum, als kurzfristig in Stress zu geraten. Eines sei schon mal gesagt, ein Kind zu bekommen geht durchaus auch ohne  Geburtsvorbereitungskurs.  Es ist schließlich das natürlichste auf der Welt. Doch wenn man kurz an das Eingangszitat denk: „Die Geburt eines Kindes ist ein wilder Sturm!“, dann ist es vielleicht besser solch einen Kurs besucht zu haben. </p>
            <Typography variant="h2">Kursinhalte</Typography>
            <p>In einem Geburtsvorbereitungskurs trifft Theorie auf Praxis und Sie und Ihr Partner erfahren nicht nur wichtige Dinge zur Schwangerschaft, sondern Sie erleben und lernen auch Ihren Körper besser kennen. Es ist für Sie ein Kurs zur Selbsterfahrung in dem man Atem- und Entspannungstechniken zur Schmerzreduktion während der Geburt erlernt. Verschiedene Geburtspositionen vom Vierfüßlerstand, der Halbseitenlage oder der Geburt in der Hocke, werden schon einmal in Ruhe ausprobiert, bevor Wehen mit ins Spiel kommen. Zusammen mit Ihrem Partner erlernen Sie Massagetechniken, die für Entspannung sorgen, diese kann man dann auch in der weiteren Schwangerschaft anwenden. Sie werden den Geburtsmechanismus in der Theorie und meist auch an einem Modell erklärt bekommen und sehen welchen engen Weg Ihr Kleines auf dem Weg ins Leben nimmt. Zudem wird auch das auf die Geburt folgende Wochenbett erklärt. Dafür gibt es aber meist auch Spezialkurse. </p>
            <p>Der Geburtsvorbereitungskurs ist aber nicht nur ein Kurs in dem Sie etwas über sich, Ihren Partner und Ihr Kind erfahren, sondern auch ein Ort, an dem man perfekt in den Austausch mit anderen Paaren treten kann. Häufig ist der Geburtsvorbereitungskurs auch ein Ort an den Freundschaften fürs Leben geschlossen werden. Also nichts wie anmelden! Doch stopp, wie kommt man nun an so einen Geburtsvorbereitungskurs bzw. von wem wird er überhaupt angeboten? Und wann ist eigentlich der optimalste Zeitpunkt dafür? </p>
            <Typography variant="h2">Wann sollte ein Kurs besucht werden?</Typography>
            <p>Am einfachsten Sie fragen bei Ihrer nächsten Vorsorgeuntersuchung bei Ihrem Frauenarzt oder Ihrer Hebamme nach oder schauen einmal ins Internet, hier werden Sie ganz vielen verschiedenen Geburtsvorbereitungskursen begegnen. Angeboten werden Geburtsvorbereitungskurse von Hebammen in Geburtshäusern, Hebammenpraxen, Geburtskliniken oder auch Träger sozialer Einrichtungen. Die gesetzliche Krankenkasse übernimmt meist mindestens einen Teil der Kursgebühren, wenn er von einer Hebamme geleitet wird. Auch bei den Kursen gibt es verschieden Angebote. Kurse die zu jedem Termin mit dem Partner besucht werden, Kurse in denen nur Frauen alleine sind, Kurse in denen nur zu einzelnen Terminen Ihr Partner mitkommen kann und natürlich kann Ihr Partner auch eine Freundin sein! </p>
            <Typography variant="h2">Auswahl der Kurse</Typography>
            <p>Zudem gibt es spezielle Kurse, die auf einer bestimmten Theorie basieren. Eine Methode ist z.B. das HypnoBirthing von Marie Mongan. Bei dieser Methode wird die Geburt als eine autonome Körperfunktion der Frau verstanden. Das Kind wird mit Hilfe einer speziellen Atemtechnik geboren.   Wichtig ist hierbei nicht zu vergessen, dass zum Schluss der Geburt ein Pressreflex einsetzt, der eben ganz natürlich ist. </p>
            <p>In den 70er Jahren entwickelte Frederik Leboyer eine Geburtsmethode, die er als die „sanfte Geburt“ bezeichnete.  Er dachte hierbei vor allem an die Gefühle und Gedanken des Neugeborenen. Er empfahl ein Gebären in Räumen mit sanften Licht und ruhiger Atmosphäre, den direkten Hautkontakt nach der Geburt mit der Mutter und das Abtrennen der Nabelschnur nach dem Auspulsieren dieser. </p>
            <p>Eine weitere Methode ist die Geburt nach Zilgrei. Bei der Schmerzen unter der Geburt mittels Lösungen von Verkrampfungen und Verbesserung der Körperhaltung reduziert werden sollen. </p>
            <p>Sie sehen, Sie haben die Qual der Wahl bei der Vielfalt der verschiedenen Angebote. Sie finden sicher für sich die beste Option. </p>
            <p>Viel Spaß und Selbsterfahrung wünschen wir Ihnen schon jetzt bei dem Kurs und möge Ihre Geburt nur ein kurzer Sturm sein! </p>

            <p><b>Gut vorbereitet können Sie  beruhigt in Ihr eigenes Geburtserlebnis starten.</b></p>

          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default BirthPreparationClass;