import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';

import withNotifications from 'services/NotificationService/withNotifications';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import PSS10NeverRarelySometimesFrequentOftenASC from './StandardizedAnswerOptions/PSS10NeverRarelySometimesFrequentOftenASC'
import PSS10NeverRarelySometimesFrequentOftenDESC from './StandardizedAnswerOptions/PSS10NeverRarelySometimesFrequentOftenDESC'


import '../Questionnaire.scss';


//import Textfield from '../../components/Textfield/Textfield';
//import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';


class PSS_10 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">PSS-10</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/11) * 100 } />
          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />
          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "pss01": 1,
                "pss02": 2,
                "pss03": 3,
                "pss04": 4,
                "pss05": 5,
                "pss06": 6,
                "pss07": 7,
                "pss08": 8,
                "pss09": 9,
                "pss10": 10,
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <div style={{textAlign: "center", marginTop: "1em"}}>
                  Die folgenden Fragen beschäftigen sich mit Ihren Gedanken und Gefühlen während des letzten Monats. Bitte geben Sie für jede Frage an, wie oft sie in entsprechender Art und Weise gedacht oder gefühlt haben.
                  <br/><br/>
                  <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Fragebogen starten</Button>
                </div>
              </StepperPage>
            </Container>

            <div className="questionnaire-body">
              <Container maxWidth="lg">
                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wie oft waren Sie im letzten Monat aufgewühlt, weil etwas unerwartet passiert ist?</FormLabel>
                    <CustomErrorMessage name="pss01" />
                    <RadioGroup aria-label="position" name="pss01" value={this.props.values.pss01} onChange={this.props.handleChange} required={true}>
                      <PSS10NeverRarelySometimesFrequentOftenASC />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={2}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wie oft hatten Sie im letzten Monat das Gefühl, nicht in der Lage zu sein, die wichtigen Dinge in Ihrem Leben kontrollieren zu können?</FormLabel>
                    <CustomErrorMessage name="pss02" />
                    <RadioGroup aria-label="position" name="pss02" value={this.props.values.pss02} onChange={this.props.handleChange} required={true}>
                      <PSS10NeverRarelySometimesFrequentOftenASC />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={3}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wie oft haben sie sich im letzten Monat nervös und gestresst gefühlt?</FormLabel>
                    <CustomErrorMessage name="pss03" />
                    <RadioGroup aria-label="position" name="pss03" value={this.props.values.pss03} onChange={this.props.handleChange} required={true}>
                      <PSS10NeverRarelySometimesFrequentOftenASC />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={4}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wie oft waren Sie im letzten Monat zuversichtlich, dass Sie fähig sind, ihre persönlichen Probleme zu bewältigen?</FormLabel>
                    <CustomErrorMessage name="pss04" />
                    <RadioGroup aria-label="position" name="pss04" value={this.props.values.pss04} onChange={this.props.handleChange} required={true}>
                      <PSS10NeverRarelySometimesFrequentOftenDESC />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={5}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wie oft hatten Sie im letzten Monat das Gefühl, dass sich die Dinge zu Ihren Gunsten entwickeln?</FormLabel>
                    <CustomErrorMessage name="pss05" />
                    <RadioGroup aria-label="position" name="pss05" value={this.props.values.pss05} onChange={this.props.handleChange} required={true}>
                      <PSS10NeverRarelySometimesFrequentOftenDESC />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={6}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wie oft hatten Sie im letzten Monat den Eindruck, nicht all Ihren anstehenden Aufgaben gewachsen zu sein?</FormLabel>
                    <CustomErrorMessage name="pss06" />
                    <RadioGroup aria-label="position" name="pss06" value={this.props.values.pss06} onChange={this.props.handleChange} required={true}>
                      <PSS10NeverRarelySometimesFrequentOftenASC />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={7}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wie oft waren Sie im letzten Monat in der Lage, ärgerliche Situationen in Ihrem Leben zu beeinflussen?</FormLabel>
                    <CustomErrorMessage name="pss07" />
                    <RadioGroup aria-label="position" name="pss07" value={this.props.values.pss07} onChange={this.props.handleChange} required={true}>
                      <PSS10NeverRarelySometimesFrequentOftenDESC />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={8}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wie oft hatten Sie im letzten Monat das Gefühl, alles im Griff zu haben?</FormLabel>
                    <CustomErrorMessage name="pss08" />
                    <RadioGroup aria-label="position" name="pss08" value={this.props.values.pss08} onChange={this.props.handleChange} required={true}>
                      <PSS10NeverRarelySometimesFrequentOftenDESC />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={9}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wie oft haben Sie sich im letzten Monat über Dinge geärgert, über die Sie keine Kontrolle hatten?</FormLabel>
                    <CustomErrorMessage name="pss09" />
                    <RadioGroup aria-label="position" name="pss09" value={this.props.values.pss09} onChange={this.props.handleChange} required={true}>
                      <PSS10NeverRarelySometimesFrequentOftenASC />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={10}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wie oft hatten Sie im letzten Monat das Gefühl, dass sich so viele Schwierigkeiten angehäuft haben, dass Sie diese nicht überwinden konnten?</FormLabel>
                    <CustomErrorMessage name="pss10" />
                    <RadioGroup aria-label="position" name="pss10" value={this.props.values.pss10} onChange={this.props.handleChange} required={true}>
                      <PSS10NeverRarelySometimesFrequentOftenASC />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>
                
                <StepperPage activeStep={this.state.activeStep} thisStep={11}>
                  <Typography variant="h2">Fragebogen absenden</Typography>
                  Vielen Dank! Sie können den Fragebogen nun absenden.
                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                    <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                  </div>
                </StepperPage>

                {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}       
              </Container>  
            </div>
          </form>
        </main>
      <Footer showMenu={false} />
    </>
    );
  }
}

export default withNotifications(withFormik({
  mapPropsToValues: () => ({
    pss01: '',
    pss02: '',
    pss03: '',
    pss04: '',
    pss05: '',
    pss06: '',
    pss07: '',
    pss08: '',
    pss09: '',
    pss10: '',
  }),  
  validationSchema: Yup.object().shape({
    pss01: Yup.number().required('Pflichtfeld'),
    pss02: Yup.number().required('Pflichtfeld'),
    pss03: Yup.number().required('Pflichtfeld'),
    pss04: Yup.number().required('Pflichtfeld'),
    pss05: Yup.number().required('Pflichtfeld'),
    pss06: Yup.number().required('Pflichtfeld'),
    pss07: Yup.number().required('Pflichtfeld'),
    pss08: Yup.number().required('Pflichtfeld'),
    pss09: Yup.number().required('Pflichtfeld'),
    pss10: Yup.number().required('Pflichtfeld'),
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "pss-10",
      questionnaireData: values,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
    });
  }
})(PSS_10));

/* https://stackoverflow.com/questions/59497550/handling-formik-form-when-validation-error-occurs */