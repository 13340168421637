import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Header from 'components/Header/Header';
import Globals from 'services/Globals';


import Container from '@material-ui/core/Container';
import { Button, Typography } from '@material-ui/core';


import Footer from 'components/Footer/Footer';
import withNotifications from 'services/NotificationService/withNotifications';
import TopSpacer from 'components/TopSpacer/TopSpacer';

import './AdminUsers.scss';


export default withNotifications(function AdminUsers(props) {

  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // TODO: Error handling
    getUsers();
  }, []);

  const getUsers = () => {
    axios.get(Globals.SERVER_URL + "/admin/users").then(function(res){
      setUsers(res.data);
      console.log(res.data);
    }).catch((err) =>{
      props.notificationService.push("Benutzer konnten nicht abgerufen werden.", "error", 0, err); 
    });
  }

  const changePassword = (userId) => {
    let newPassword = prompt("Bitte geben Sie das neue Passwort ein.");

    if (newPassword != null) {
      axios.post(Globals.SERVER_URL + "/admin/users/ " + userId + "/changePassword", {
        newPassword: newPassword,
      }).then(function(res){
        props.notificationService.push("Passwort geändert!", "info");
      }).catch(function(err){
        props.notificationService.push("Fehler", "info", 100000, err);
      });
    }
  }

  return (
    <>
      <Header>
        <Typography variant="h2">Admin: Benutzer</Typography>
      </Header>

      <main>
        <TopSpacer />
        <Container maxWidth="lg">
          <Typography variant="h2">Benutzerverwaltung</Typography>
          <table className="user-table">
            <thead>
              <th>ID</th>
              <th>Studien-ID</th>
              <th>Optionen</th>
            </thead>
            <tbody>
              { users.map(user => 
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.pseudonym}</td>
                  <td><Button variant="outlined" size="small" onClick={() => {changePassword(user.id)}}>Passwort ändern</Button></td>
                </tr>
              )}
            </tbody>
          </table>
        </Container>
      </main>
      <Footer />
    </>
  );


  return (
    <>
      <Header>
        <Typography variant="h2">Admin: User</Typography>
      </Header>

      <main>
        <Container maxWidth="lg">
          Test
        </Container>
      </main>
      <Footer />
    </>
  );
});