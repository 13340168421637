import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Checkbox, Container, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, LinearProgress, Radio, RadioGroup, Typography } from '@material-ui/core';

import AuthContext from '../../../services/AuthContext';

import { withRouter } from 'react-router-dom';
import withNotifications from 'services/NotificationService/withNotifications';
import Header from 'components/Header/Header';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import StepperPage from 'components/StepperPage/StepperPage';
import TopSpacer from 'components/TopSpacer/TopSpacer';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Globals from 'services/Globals';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons';
import Footer from 'components/Footer/Footer';
import MuiDialogActions from '@material-ui/core/DialogActions';

import { Link } from 'react-router-dom'


class Register extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
          activeStep: 0
      };

      this.handleBack = this.handleBack.bind(this);
      this.handleNext = this.handleNext.bind(this);
      this.validateInclusionCriteriaAndNext = this.validateInclusionCriteriaAndNext.bind(this);
      this.validateSecondPageCheckedAndContinue = this.validateSecondPageCheckedAndContinue.bind(this);
      this.setStep = this.setStep.bind(this);
    }
    
    handleBack () {
      this.setState({
        activeStep: (this.state.activeStep - 1)
      });
    }
  
    async handleNext(validate) {

      if ( validate === true ) {
        let result = await this.props.validateForm();
        if ( Object.keys(result).length !== 0 ) {
          return;
        }
      }

      this.setState({
        activeStep: (this.state.activeStep + 1)
      });  
    }

    async validateInclusionCriteriaAndNext () {
      let result = await this.props.validateForm();

      if ( !result.is_pregnant && !result.is_correct_age && !result.does_agree ) {
        console.log(this.props.values);
        if ( this.props.values.is_pregnant !== "1" || this.props.values.is_correct_age !== "1" || this.props.values.does_agree !== "1" ) {
          this.props.notificationService.push("Vielen Dank für Ihr Interesse an der SMART Start Studie. Leider können Sie an der Studie nicht teilnehmen.", "error");
        }
        else {
          this.setState({
            activeStep: (this.state.activeStep + 1)
          });  
        }    
      }
    }

    async validateSecondPageCheckedAndContinue () {
      let result = await this.props.validateForm();

      if ( !result.accept_participation && !result.accept_tos && !result.accept_data ) {
        this.setState({
          activeStep: (this.state.activeStep + 1)
        });  
      }
    }

    setStep (newStep) {
      this.setState({
        activeStep: newStep
      });
    }
    
    render() {
        return (
            <>
              <Header>
                <Typography variant="h2">Registrieren</Typography>
              </Header>
            
            <main>
              <LinearProgress variant="determinate" value={ (this.state.activeStep/5) * 100 } />

{
  /*              <Dialog aria-labelledby="simple-dialog-title" open={this.props.dialogOpen}>
                <DialogTitle id="simple-dialog-title">Fragebogen erfolgreich abgeschickt</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Vielen Dank. Der Fragebogen wurde erfolgreich abgeschickt.
                  </DialogContentText>
                </DialogContent>
                <MuiDialogActions>
                  <Button component={Link} to="/tasks" autoFocus color="primary">
                    Zurück zu den aktuellen Aufgaben
                  </Button>
                </MuiDialogActions>
              </Dialog>
 */
}


              <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
                <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
                  {
                    "is_pregnant": 1,
                    "is_correct_age": 1,
                    "does_agree": 1,
                    "password": 5,
                    "repeatPassword": 5
                  }
                } />
              

              <Container maxWidth="lg">
                <TopSpacer />
                <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                    <Typography variant="h2">Willkommen in der Mutterpass-App der SMART Start Studie!</Typography>
                    <p>Die SMART Start Studie wird von der Frauenklinik des Universitätsklinikums Erlangen in Kooperation mit Partnern der Friedrich-Alexander-Universität (FAU) Erlangen-Nürnberg durchgeführt. Sie richtet sich an schwangere Frauen, die sich für die Digitalisierung der Schwangerenvorsorge interessieren. </p>
                    <p>Sie können die Mutterpass-App im Rahmen der SMART Start Studie nutzen. Zunächst möchten wir Sie bitten, die folgenden Fragen zu beantworten. Damit wollen wir erfahren, ob Sie für die Teilnahme an der Studie geeignet sind. Es steht Ihnen frei die Fragen zu beantworten. Sie können die Beantwortung ablehnen oder jeder Zeit abbrechen, indem Sie das Browser-Tab oder -Fenster schließen. Wenn Sie die Fragen beantworten, beantworten Sie sie bitte ehrlich.</p>
                    <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Jetzt teilnehmen</Button>
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Sind Sie aktuell schwanger?</FormLabel>
                    <CustomErrorMessage name="is_pregnant" />
                    <RadioGroup aria-label="position" name="is_pregnant" value={this.props.values.is_pregnant} onChange={this.props.handleChange} required={true}>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein" labelPlacement="end"/>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Ja" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>

                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Sind Sie zwischen 18 und 50 Jahre alt?</FormLabel>
                    <CustomErrorMessage name="is_correct_age" />
                    <RadioGroup aria-label="position" name="is_correct_age" value={this.props.values.is_correct_age} onChange={this.props.handleChange} required={true}>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein" labelPlacement="end"/>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Ja" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>

                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Sind Sie bereit, uns Ihr Einverständnis zur Nutzung Ihrer Daten für die Zwecke der SMART Start Studie zu geben?</FormLabel>
                    <CustomErrorMessage name="does_agree" />
                    <RadioGroup aria-label="position" name="does_agree" value={this.props.values.does_agree} onChange={this.props.handleChange} required={true}>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein" labelPlacement="end"/>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Ja" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>

                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.validateInclusionCriteriaAndNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={2}>
                  <Typography variant="h3">Vielen Dank! Sie können teilnehmen!</Typography>
                  <p>
                    Der nachfolgende Informationstext soll Ihnen kurz den Hintergrund, die Ziele und den Ablauf der Studie näher erläutern und Sie über die wichtigsten Aspekte einer möglichen Teilnahme informieren. Die Teilnahme ist absolut freiwillig und es entstehen Ihnen keine Nachteile, wenn Sie sich gegen die Teilnahme an der Studie entscheiden.
                  </p>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={3}>
                  <Typography variant="h3">Hintergrund und Ziele</Typography>
                  <p>Aktuell gibt es für schwangere Frauen keine andere Möglichkeit der Schwangerschaftsvorsorge, als die Untersuchungstermine bei Ihrem Frauenarzt/Ihrer Frauenärztin wahrzunehmen. In der SMART Start Studie sollen deshalb verschiedene Fragen zum Thema „Digitale Schwangerenvorsorge“ geklärt werden. Wir wollen herausfinden, ob die bestehende Routinevorsorge durch Digitalisierung unterstützt oder sogar verbessert werden kann. Das Projekt wird gefördert vom Bundesministerium für Gesundheit (BMG).</p>
                  <Typography variant="h3">Ablauf der Studie</Typography>
                  <p>Wenn Sie an der SMART Start Studie teilnehmen, erhalten Sie Zugriff auf die Mutterpass-App. Dafür müssen Sie Ihr eigenes Smartphone verwenden. Die Mutterpass-App liefert gezielte Informationen zu Ihrer Schwangerschaft, bietet Audioübungen und bittet Sie in regelmäßigen Abständen, ein paar Fragebögen zu beantworten (z.B. zu Gesundheitsverhalten, Aktivitäten im Alltag oder Schlaf). Die Studienteilnahme endet mit dem Wochenbett, ca. 6-8 Wochen nach der Geburt.</p>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={4}>
                  <Typography variant="h3">Einverständniserklärung</Typography>
                  <p>Wenn Sie sich für die Teilnahme an der SMART Start Studie entscheiden, geben Sie bitte unten Ihre Einwilligungserklärung zur Studienteilnahme und zur Datennutzung. Ohne eine Einwilligungserklärung können Sie nicht an der Studie teilnehmen. Sie können Ihre Einwilligung jederzeit und ohne Angabe von Gründen wieder zurückziehen, ohne dass Ihnen dadurch Nachteile für Ihre weitere medizinische Behandlung entstehen.</p>
                  <CustomErrorMessage name="accept_participation" />
                  <FormControlLabel control={<Checkbox checked={this.props.values.accept_participation} onChange={this.props.handleChange} name="accept_participation" />} label="Hiermit willige ich in die Teilnahme an der SMART Start Studie ein." />
                  <CustomErrorMessage name="accept_data" />
                  <FormControlLabel control={<Checkbox checked={this.props.values.accept_data} onChange={this.props.handleChange} name="accept_data" />} label="Ich bin damit einverstanden, dass meine Daten zur Durchführung und Auswertung der SMART Start Studie und damit zur medizinisch-wissenschaftlichen Forschung verwendet werden." />
                  <CustomErrorMessage name="accept_tos" />
                  <FormControlLabel control={<Checkbox checked={this.props.values.accept_tos} onChange={this.props.handleChange} name="accept_tos" />} label={<>Ich akzeptiere die <a href="/legal/terms-of-use" target="_blank">Nutzungsbedingungen</a> und stimme der Verarbeitung meiner Daten gemäß der <a href="/legal/privacy" target="_blank">Datenschutzerklärung</a> zu.</>} />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={() => {this.validateSecondPageCheckedAndContinue()}} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={5}>
                  <Typography variant="h3">Passwort festlegen</Typography>
                  <p>Geben Sie nun ein geheimes Passwort ein. Mit diesem können Sie sich zukünftig in der App anmelden. Ihre App-ID (Benutzername) erhalten Sie nach dem Abschluss der Registrierung.</p>
                  <CustomErrorMessage name="password" />
                  <TextField name="password" value={this.props.values.password} onChange={this.props.handleChange} label="Neues Passwort" type="password" autoComplete="current-password" variant="outlined" fullWidth />
                  <CustomErrorMessage name="repeatPassword" />
                  <TextField name="repeatPassword" value={this.props.values.repeatPassword} onChange={this.props.handleChange} label="Neues Passwort wiederholen" type="password" autoComplete="current-password" variant="outlined" fullWidth />
                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                    <Button type="submit" variant="contained" color="primary" disableElevation>Registrierung abschließen</Button>
                  </div>
                  <ForwardBackwardButtons handleBack={this.handleBack} />
                </StepperPage>
              </Container>
              </form>
            </main>
            <TopSpacer />
            </>
        );
    }
}

export default withNotifications(withRouter(withFormik({
  mapPropsToValues: () => ({
    is_pregnant: '',
    is_correct_age: '',
    does_agree: '',
    accept_participation: false,
    accept_data: false,
    accept_tos: false,
    password: '',
    repeatPassword: ''    

  }),  
  validationSchema: Yup.object().shape({
    is_pregnant: Yup.number().required('Pflichtfeld'),
    is_correct_age: Yup.number().required('Pflichtfeld'),
    does_agree: Yup.number().required('Pflichtfeld'),
    accept_participation: Yup.boolean().equals([true], 'Pflichtfeld'),
    accept_data: Yup.boolean().equals([true], 'Pflichtfeld'),
    accept_tos: Yup.boolean().equals([true], 'Pflichtfeld'),
    password: Yup.string().min(6, 'Mindestens 6 Zeichen eingeben').required('Pflichtfeld'),
    repeatPassword: Yup.string().oneOf([Yup.ref('password'), null], "Passwörter stimmen nicht überein!").required('Pflichtfeld')
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  mapPropsToTouched: () => ({
    is_pregnant: true,
    is_correct_age: true,
    does_agree: true,
    accept_participation: true,
    accept_data: true,
    accept_tos: true
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/users/self-register", {
      password: values.password
    }).then(function(res){
      // console.log(res);
      // props.props.notificationService.push("Registrierung erfolgreich! Ihre App-ID lautet: " + res.data + ". Bitte notieren Sie sich diese App-ID unbedingt sofort. Sie kann später nicht mehr hergestellt werden.", "success", 0);
      props.props.history.push('/?app_id=' + res.data);
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler aufgetreten.", "error", 0, err);
    });
  }
})(Register)));