import React from "react";

import TextField from "@material-ui/core/TextField";
import { Typography } from '@material-ui/core';

import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";

class UltrasoundEditScreen extends React.Component {
    render() {
        return (
            <>
                <Typography variant="h3">Neuer Eintrag</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            name="date"
                            value={this.props.data["date"] || ""}
                            onChange={this.props.onChange}
                            label="Datum"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="examiner"
                            value={this.props.data["examiner"] || ""}
                            onChange={this.props.onChange}
                            label="Untersucher"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="indication"
                            value={this.props.data["indication"] || ""}
                            onChange={this.props.onChange}
                            label="Indikation zur Untersuchng"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="findings"
                            value={this.props.data["findings"] || ""}
                            onChange={this.props.onChange}
                            label="Befunde"
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            multiline
                            rows="3"
                            name="comment"
                            value={this.props.data["comment"] || ""}
                            onChange={this.props.onChange}
                            label="Beurteilung"
                        />
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.props.updateFunction}
                    disableElevation
                >
                    Speichern
                </Button>
            </>
        );
    }
}

export default UltrasoundEditScreen;
