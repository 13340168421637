import { useEffect, useState } from "react";

import React from 'react';

import moment from "moment";

import { Chart, Line } from 'react-chartjs-2';

import { averageArray, formatISODateToGermanDate } from 'helpers/Helpers';
import { RoomSharp } from "@material-ui/icons";
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(zoomPlugin);

const options = {
  // responsive: false,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    }, 
    zoom: {
      pan: {
        enabled: true,
        mode: 'x'
      },
      limits: {
        x: {
          min: 'original',
          max: 'original'
        },
        y: {
          min: 'original',
          max: 'original'
        }
        // axis limits
      },
      zoom: {
        wheel: {
          enabled: true
        },
        pinch: {
          enabled: true
        },
        mode: 'x'
        // zoom options and/or events
      }
    },
    tooltip: {
      callbacks: {
        // Remove the "title", which usually shows a arbitrary date in an incorrect format
        title: function(context) {
          /*console.log(context[0].label);
          return (new MomentUtils())*/
          return "";
        }
      }
    }
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: 'DD.MM.YYYY'
        }
      },
      ticks: {
        // rotation: 90
        // display: false, // Remove dates
        maxRotation: 70,
        minRotation: 70,/*
        autoSkipPadding: 30,
        includeBounds: true*/
      },
      grid: {
        display: false, // Remove grid
        drawBorder: false, // Remove bottom border
      },
      title: {
        display: true,
        text: "Tag",
      }
      // type: 'timeseries'
    },
    y: {
      ticks: {
        autoSkipPadding: 30,
        mirror: true, // Place inside the graph
        showLabelBackdrop: true, // Add a box around the axis legend
        backdropColor: 'rgba(255, 255, 255, 0.95)', // Make the box white
        z: 1 // Make sure the box appears in front of the chart
      },
      grid: {
        drawBorder: false, // Remove left border
        borderDash: [8, 4], // Make grid dashed
        // display: false
      },
      /*title: {
        display: true,
        text: "Durchschnittliche Herzfrequenz",
      }*/
    }
  }
};

export default function HeartRateFullGraph(props) {

  const [data, setData] = useState(0);

  useEffect(() => {
    setData({
      labels: Object.keys(props.data),
      datasets: [
        {
          data: Object.values(props.data),
          fill: false,
          backgroundColor: 'rgb(255, 99, 132)',
          borderColor: 'rgba(255, 99, 132, 0.2)',
        },
      ],
    });

    // 
  }, [props])

  return (
    <>
      <div className="data-plot">
      <Line data={data} options={options} />
      </div>

      <p className="graph-subtitle"><b>Durchschnittliche tägliche Herzfrequenz (Schläge pro Minute).</b><br/>
        { /* data.labels && 
          <>
            { formatISODateToGermanDate(data.labels[0]) } bis { formatISODateToGermanDate(data.labels[data.labels.length - 1]) }
          </> */
        }
        { /* <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Los geht's <KeyboardArrowRight /></Button> */ }
      </p>
    </>
  )
}

