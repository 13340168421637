import React, { useContext } from 'react';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import ActivityItem from 'components/ActivityItem/ActivityItem';
import ActivityItemNoLink from 'components/ActivityItem/ActivityItemNoLink';
import AuthContext from 'services/AuthContext';
import withNotifications from 'services/NotificationService/withNotifications';
import { useHistory } from "react-router-dom";


import { faArrowAltCircleUp, faArrowCircleRight, faBackward, faBan, faCalendar, faCircle, faCross, faCrosshairs, faDoorClosed, faKey, faPaintBrush, faPlusCircle, faWrench } from '@fortawesome/free-solid-svg-icons';
import TopSpacer from 'components/TopSpacer/TopSpacer';
import PrintLoginCard from 'components/PrintLoginCard/PrintLoginCard';

export default withNotifications(function Profile(props) {

  const authContext = useContext(AuthContext);
  const history = useHistory();

  function logout() {
    authContext.logout();
    props.notificationService.push("Sie wurden erfolgreich abgemeldet!", "info"); 
    history.push("/");
  }

  return (
    <>
      <Header>
        <Typography variant="h2">Profil</Typography>
      </Header>
      <main>
        <Container maxWidth="lg">
          <TopSpacer />
          <Typography variant="h2">Mein Profil</Typography>
          <p>Hier können Sie Änderungen an Ihren persönlichen Daten vornehmen oder Installationen verwalten.</p>
          <PrintLoginCard />
          <ul className="activity-list">
            <ActivityItem icon={faCircle} title="Meine früheren Schwangerschaften" to="/profile/my-previous-pregnancies"></ActivityItem>
            <ActivityItem icon={faPaintBrush} title="App individuell gestalten" to="/profile/customize"></ActivityItem>
            <ActivityItem icon={faKey} title="Mein Passwort ändern" to="/profile/change-password"></ActivityItem>
            <ActivityItem icon={faPlusCircle} title="App-Installation erneut durchführen" to="/setup/a2hs"></ActivityItem>
            <ActivityItem icon={faWrench} title="Geräte-Setup erneut durchführen" to="/setup/withings-devices"></ActivityItem>
            <ActivityItem icon={faArrowAltCircleUp} title="Einstiegs-Fragebogen erneut durchführen" to="/setup/initial-questionnaire"></ActivityItem>
            <ActivityItem icon={faCalendar} title="Benachrichtigungen erneut einrichten" to="/setup/calendar-invites"></ActivityItem>
            <ActivityItem icon={faBan} title="Einverständnis zurückziehen" to="/profile/retract-consent"></ActivityItem>
            <ActivityItemNoLink icon={faDoorClosed} title="Abmelden" onClick={logout} />
          </ul>
        </Container>
      </main>
      <Footer />
    </>
  );
});
