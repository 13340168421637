import React from 'react';

import { Link } from 'react-router-dom'

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

import heartMeasurement from 'resources/svgs/heart-measurement.svg';

export default function MyReadingsCard() {
  const theme = useTheme(); 


  return (
    <Link to="/measurements" className="overview-card">
      <Card>
        <CardActionArea>
          <CardMedia image={heartMeasurement} title="Meine Aufgaben" className="overview-card-media" style={{backgroundColor: theme.palette.secondary.main}} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2" style={{color: theme.palette.primary.main }}>
              Meine Messwerte
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Letzte Geräte-Messungen ansehen. <br />
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" variant="contained" disableElevation>
            Jetzt ansehen
          </Button>
        </CardActions>
      </Card>
    </Link>
  );
}
