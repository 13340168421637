import React from 'react';
import axios from 'axios';

import Header from '../../components/Header/Header';
import Globals from 'services/Globals';


//import Textfield from '../../components/Textfield/Textfield';
//import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';


class Surveys extends React.Component {

  constructor(props) {
    super(props);
    this.submitSurvey = this.submitSurvey.bind(this);
  }
  
  async submitSurvey() {

    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "test",
      questionnaireData: {a: "A", b: "B"},
    }).then(function(res){
      console.log(res);
    }).catch(function(err){
      console.log(err);
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Umfragen</Typography>
        </Header>
        <Container maxWidth="lg">
          <main>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                <Button variant="contained" disableElevation onClick={this.submitSurvey}>Umfrage Test-Button</Button><br/>
                </Grid>
              </Grid>
            </main>
        </Container>
      </>
    );
  }
}

export default Surveys;