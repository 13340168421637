import { Container, TextField } from "@material-ui/core";
import TopSpacer from "components/TopSpacer/TopSpacer";
import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";
import MappingTextfield from "components/MappingTextfield/MappingTextfield";
import { emptyIfUndefined, formatDate } from "helpers/Helpers";

export default function Child(props) {

  if(props.data && props.index && props.data[props.index]) {
    return (
      <>
        <main style={{marginTop: '2em'}}>
        <Container maxWidth="lg">
          <TopSpacer />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField fullWidth value={formatDate(emptyIfUndefined(props.data[props.index].geburtsdatum))} disabled label="Geburtsdatum" />
            </Grid>
            <Grid item xs={12}>
              <MappingTextfield fullWidth value={emptyIfUndefined(props.data[props.index].kindslage)} disabled label="Kindslage" mapping={{1:"Beckenendlage (BEL)", 2:"Schädellage (SL)", 3:"Querlage (QL)", 4:"Schräglage", 99: "Nicht untersucht"}} />
            </Grid>
            <Grid item xs={12}>
              <MappingTextfield fullWidth value={emptyIfUndefined(props.data[props.index].geburtsmodus)} disabled label="Geburtsmodus" mapping={{1:"Spontangeburt", 2:"Primäre Sektio", 3:"Sekundäre Sektio", 4:"Vaginale Operation - Forceps", 5: "Vaginale Operation - Vakuumextraktion"}} />
            </Grid>
            <Grid item xs={12}>
              <MappingTextfield fullWidth value={emptyIfUndefined(props.data[props.index].ausgangdergeburt)} disabled label="Ausgang der Geburt" mapping={{1:"Kind lebend geboren", 2:"Kind perinatal verstorben", 3:"Totgeburt"}} />
            </Grid>
            <Grid item xs={12}>
              <MappingTextfield fullWidth value={emptyIfUndefined(props.data[props.index].geschlecht)} disabled label="Geschlecht" mapping={{1:"männlich", 2:"weiblich", 99:"unbestimmt"}} />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth value={emptyIfUndefined(props.data[props.index].gewicht)} disabled label="Gewicht (g)" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth value={emptyIfUndefined(props.data[props.index].laenge)} disabled label="Länge (cm)" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth value={emptyIfUndefined(props.data[props.index].kopfumfang)} disabled label="Kopfumfang (cm)" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth value={emptyIfUndefined(props.data[props.index].apgarzahl5)} disabled label="APGAR-Zahl 5'" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth value={emptyIfUndefined(props.data[props.index].apgarzahl10)} disabled label="APGAR-Zahl 10'" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth value={emptyIfUndefined(props.data[props.index].phwert)} disabled label="pH-Wert (Nabelarterie)" />
            </Grid>
          </Grid>
        </Container>
      </main>
      </>
    )  
  } else {
    return (
      <></>
    )
  }
}