import React from 'react';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';


import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

import PaletteSelector from './PaletteSelector/PaletteSelector';
// import LightDarkModeSwitch from './LightDarkModeSwitch/LightDarkModeSwitch';


export default function Customize () {
  return (
      <>
        <Header>
          <Typography variant="h2">App individuell anpassen</Typography>
        </Header>
        <main>
          <Container maxWidth="lg" spacing={1}>
              <p>Passen Sie Ihre App nach Ihren Wünschen an. Wählen Sie das Farbschema aus, das Ihnen am besten gefällt.</p>
              <PaletteSelector/>
              { /* <LightDarkModeSwitch /> */ }
          </Container>
        </main>
        <Footer />
      </>
  );
}