import moment from "moment";
import localization from 'moment/locale/de'

export function formatISODateToGermanDate(date) {

  let d = new Date(date);

  let year = d.getFullYear();
  let month = d.getMonth()+1;
  let day = d.getDate();

  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }

  return (day + '.' + month + '.' + year);

  /*

  return dateWithLeadingZero(d.getDay()) + "." + d.getMonth() + "." + d.getFullYear();*/
} 

export function formatDate(date, format) {
  if ( !date ) {
    return "";
  }

  if ( format === undefined ) {
    format = "D.M.Y";
  }

  return moment(date).format(format);
}

export function averageArray (array) {
  return array.reduce((a, b) => a + b) / array.length;
}

/**
 * Shuffles the given array in-place based on Durstenfeld-Fisher-Yates algorithm (in place)
 * @param {Array} array Input array
 * @returns {Array} The randomly shuffled array
 */

export function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
  }

  return array;
}

export function createFileForDownload(filename, text) {
  let el = document.createElement('a');

  let blob = new Blob([text], {type: 'text/calendar'})
  // el.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  el.setAttribute('download', filename);
  el.setAttribute('href', window.URL.createObjectURL(blob))
  el.style.display = 'none';
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
}

export function unpackObject(newData) {
  if ( typeof newData === 'object' && newData !== null ) {
    Object.keys(newData).forEach(key => {
      if ( newData[key] === undefined || newData[key] === null ) {
        newData[key] = "";
      }
    })
    return newData;
  }
  return false;
}

export function emptyIfUndefined(val) {
  if ( val == undefined ) {
    return "";
  }
  else {
    return val;
  }
}