import React from 'react';
import axios from 'axios';

import Globals from 'services/Globals';
import Header from '../../../components/Header/Header';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

function urlBase64ToUint8Array (base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+') // Was: .replace(/\-/g, '+') to remove error
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}



class PushNotificationSetup extends React.Component {
  constructor(props) {
    super(props);

    this.setupNotifications = this.setupNotifications.bind(this);
    this.setupVapidPublicKey = this.setupVapidPublicKey.bind(this);
    this.subscribe = this.subscribe.bind(this);
  }

  componentDidMount() {
    this.setupVapidPublicKey();
  }

  setupVapidPublicKey() {
    let self = this;
    axios.get(Globals.SERVER_URL + "/notifications/web-push").then(function(res){
      self.vapidPublicKey = res.data;
    }).catch(function(err){
      console.log(err);
    });
  }

  // Copied from web-push documentation

  setupNotifications() {
    Notification.requestPermission().then((result) => {
      if (result === 'granted') {
        // this.randomNotification();
      }
    });  
  }

  async subscribe() {
    if (!('serviceWorker' in navigator)) return;

    let self = this;

    const registration = await navigator.serviceWorker.ready;
  
    // Subscribe to push notifications
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(self.vapidPublicKey),
    });  

    axios.post(Globals.SERVER_URL + "/notifications/web-push", {
      subscription: subscription
    }).then(function(res){
      console.log("success");
    }).catch(function(err){
      // TODO show error
      console.log(err);
    });
  }  

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Benachrichtigungen</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            In regelmäßigen Abständen sind neue Umfragen verfügbar. Sie erhalten dann eine Benachrichtigung auf Ihrem Handy. Diese Benachrichtigungen möchten wir nun einrichten.

            Klicken Sie auf den Button, und anschließend auf "Erlauben".
            <Button variant="contained" color="primary" disableElevation onClick={this.setupNotifications}>Benachrichtigungen</Button>
            <Button variant="contained" color="primary" disableElevation onClick={this.subscribe}>Subscribe</Button>
          </Container>
        </main>
      </>
    );  
  }
}

export default PushNotificationSetup;