import React from 'react';

import Button from '@material-ui/core/Button';


import DataContext from 'services/DataContext';

//mport './DataList.scss';



class DataList extends React.Component {


  static dataContext = DataContext;

  constructor(props) {
    super(props);
    this.state = {
      view: "list",
      elementData: {}
    }
    this.changeView = this.changeView.bind(this);
    this.changeElementDataValue = this.changeElementDataValue.bind(this);
    this.createUpdateData = this.createUpdateData.bind(this);
  }

  changeView() {
    if(this.state.view === "list") {
      this.setState({view: "edit"});
    }
    else {
      this.setState({view: "list"});
    }
  }

  changeElementDataValue(evt)  {
    const value = evt.target.value;

    this.setState({
      elementData: {
        ...this.state.elementData, [evt.target.name]: value
      }
    });

  }

  createUpdateData() {
    let dataContext = this.context;

    let dataArray = [];
    // Check if any data already exists
    if(dataContext.data[this.props.dbKey] && dataContext.data[this.props.dbKey].length >= 1) {
      // We have to copy the array, as .push operates inplace and returns len(array), but not the newly created array
      dataArray = dataContext.data[this.props.dbKey];
      dataArray.push(this.state.elementData);
    }
    else {
      dataArray = [this.state.elementData];
    }
    
    dataContext.update(this.props.dbKey, dataArray);

    this.setState({view: "list"});
  }


  render() {

    if(this.state.view === "list") {
      return (
        <DataContext.Consumer>
          {dataContext => (
            <>
              <Button variant="contained" color="primary" onClick={this.changeView} disableElevation>Neuer Eintrag</Button>
              { React.cloneElement(this.props.listItem, 
                  {data: dataContext.data[this.props.dbKey]}) }
            </>
          )}
        </DataContext.Consumer>
      )
    }
    else if(this.state.view === "edit") {
      return (
        <>
        { React.cloneElement(this.props.editScreen, 
          {data: this.state.elementData, onChange: this.changeElementDataValue, updateFunction: this.createUpdateData}) }
        </>
      )
    }
  }
}

DataList.contextType = DataContext;

export default DataList;