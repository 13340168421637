import React from "react";

import { Badge, BottomNavigation, BottomNavigationAction, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBook, faChartLine, faEllipsisH, faHome, faNotesMedical } from '@fortawesome/free-solid-svg-icons';

import useActivities from 'services/Activities/Activities';


export default function BottomNavbar(props) {

  const activities = useActivities();

  return (
    <>
      { (props.showMenu === undefined || props.showMenu === true) && 
      <Paper style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation showLabels>
          <BottomNavigationAction component={Link} to="/overview" label="Start" icon={<FontAwesomeIcon icon={faHome} />} />
          <BottomNavigationAction component={Link} to="/tasks" label="Aufgaben" style={{zIndex: "10"}} icon={<FontAwesomeIcon icon={faBell} />} />
          { (!! activities.activities && Object.keys(activities.activities).length > 0) &&
            <Badge color="secondary" overlap="circular" style={{position: "relative", right: "8%", top: "0.5em"}} badgeContent={Object.keys(activities.activities).length}></Badge>
          }
          <BottomNavigationAction component={Link} to="/measurements" label="Messungen" icon={<FontAwesomeIcon icon={faChartLine} />} />
          <BottomNavigationAction component={Link} to="/maternity-record" label="Mutterpass" icon={<FontAwesomeIcon icon={faBook} />} />
          <BottomNavigationAction component={Link} to="/more" label="Mehr" icon={<FontAwesomeIcon icon={faEllipsisH} />} />
        </BottomNavigation>
      </Paper>
      }
    </>
  );
}