import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class PregnancyIllnesses extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Untersuchungen &amp; Vorsorge &gt; Schwangerschaftsspezifische Erkrankungen</div>
            
            <Typography variant="h1">Gibt es Erkrankungen, die wirklich nur in der Schwangerschaft auftreten?</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Welche Erkrankungen können in der Schwangerschaft auftreten und auf was müssen Sie achten? Hier geben wir Ihnen die wichtigsten Informationen hierzu.</b></p>

            <p>In diesem Artikel geht es um das Thema schwangerschaftsspezifische Erkrankungen, also Erkrankungen, die nur während der Schwangerschaft oder dem Wochenbett auftreten. </p>
            <Typography variant="h2">Gestationsdiabetes</Typography>
            <p>In einem vorhergehenden Themenblock haben Sie bereits etwas über den Zuckerbelastungstest gehört und vielleicht hat auch Ihr Frauenarzt oder Ihre Frauenärztin mit Ihnen bereits über dieses Thema gesprochen. Es gibt Frauen, die entwickeln einen Schwangerschaftsdiabetes oder auch Gestationsdiabetes genannt. Dies ist eine Glukosetoleranzstörung und tritt typischerweise im 2. und 3. Schwangerschaftsdrittel auf. </p>
            <Typography variant="h3">Blutzucker-Kontrolle</Typography>
            <p>Falls Sie einen Schwangerschaftsdiabetes haben, sollten Sie sich bei einem Diabetologen, einem Arzt, der auf Blutzuckererkrankungen spezialisiert ist, vorstellen. Hier bekommen Sie Informationen und eine Ernährungsberatung. Denn im Regelfall können Sie durch eine Umstellung der Ernährung Ihre Blutzuckerwerte im Zielbereich halten. Zudem wird Ihnen gezeigt, wie Sie Ihren Blutzucker messen können. Am besten Sie messen diesen regelmäßig  nüchtern, 1 und 2 Stunden nach dem Essen und notieren sich die Werte in einem Büchlein oder einer Tabelle, dann können Sie sie bei den Kontrolluntersuchungen beim Diabetologen und Frauenarzt vorlegen und haben sie immer parat. </p>
            <Typography variant="h3">Ernährung</Typography>
            <p>Trotzdem sollten Sie auf Ihre Ernährung achten und sich nach den Vorgaben der Ernährungsberatung richten, die Blutzuckerwerte nicht im empfohlenen Bereich liegen,  dann kann eine Therapie mit Insulin notwendig werden. Hier ist es besonders wichtig, dass Sie sich an die Empfehlungen Ihrer Ärzte halten. Der Insulinbedarf kann sich im Verlauf der Schwangerschaft verändern und sollte daher engmaschig kontrolliert und angepasst werden. </p>
            <Typography variant="h3">Warum ist es so wichtig, dass der Blutzucker gut eingestellt ist?</Typography>
            <p>Ihr Blutzucker wirkt sich direkt über den Mutterkuchen auch auf Ihr Kind aus. Ist Ihr Blutzucker hoch, dann ist auch der Ihres Kindes hoch. Dies führt dazu, dass Ihr Kind zu schnell wächst, also zu schwer für die aktuelle Schwangerschaftswoche ist. Dies kann bei der Entbindung zu Problemen führen, sowohl für Sie als auch für Ihr Kind. Außerdem kann durch einen zu hohen Blutzucker, dass Fruchtwasser zu viel sein, sodass Ihr Bauch deutlich größer ist, als normal. Das Kind hat zudem selbst ein Risiko, später einen Diabetes zu entwickeln oder übergewichtig zu werden. Wenn der Blutzucker schlecht eingestellt ist, dann gibt es auch das Risiko, dass ein Kind im Bauch der Mutter verstirbt. </p>
            <p>Dies sind alles gute Gründe, den Blutzucker möglichst gut einzustellen. Damit tun Sie sich und Ihrem Kind in der aktuellen Schwangerschaft und für das weitere Leben Gutes. </p>
            <Typography variant="h3">Die gute Nachricht</Typography>
            <p>Und eine weitere gute Nachricht haben wir auch noch: Im Normalfall stellt sich der Körper nach der Entbindung schnell um, sodass sich die Blutzuckerwerte auch bei Frauen, die in der Schwangerschaft Insulin spritzen mussten, nach 2 Tagen auch ohne Insulin wieder unauffällige Werte haben. Trotzdem haben die Frauen mit Diabetes in der Schwangerschaft ein leicht erhöhtes Risiko später in ihrem Leben einen Diabetes mellitus zu entwickeln, daher sollten sie einmal jährlich einen Zuckerbelastungstest durchführen lassen. </p>
            <Typography variant="h2">Schwangerschaftsvergiftung / Präeklampsie</Typography>
            <p>Vielleicht haben Sie schon einmal etwas von einer Schwangerschaftsvergiftung gehört. Keine Sorge, dies ist keine Vergiftung an sich, sondern eine Erkrankung, die mit Bluthochdruck und erhöhter Eiweißausscheidung im Urin einhergeht. Daher werden diese beiden Werte bei jeder Schwangerschaftsvorsorge beim Frauenarzt getestet. Der Fachbegriff dafür heißt Präeklampsie und sie gehört zu den hypertensiven Schwangerschaftserkrankungen, also den Erkrankungen, die mit einem erhöhten Blutdruck einhergehen. </p>
            <Typography variant="h3">Erhöhter Blutdruck</Typography>
            <p>Manche Frauen entwickeln während der Schwangerschaft nur einen erhöhten Blutdruck, eine sogenannte Gestationshypertonie. Bei dieser werden höhere Werte als außerhalb der Schwangerschaft toleriert. Steigt der Blutdruck aber zu weit an, so ist der Beginn einer medikamentösen Therapie mit Tabletten zur Senkung des Blutdruckes nötig. Dies darf nur unter engmaschiger Kontrolle Ihres Arztes erfolgen. </p>
            <Typography variant="h3">Erhöhte Eiweißausscheidung</Typography>
            <p>Kommt zu einem erhöhten Blutdruck auch noch eine erhöhte Eiweißausscheidung dazu, so spricht man von einer Präeklampsie. Diese tritt bei 2% aller Schwangerschaften auf. Zu dem hohen Blutdruck können noch erhöhte Leberwerte, die sich durch rechtsseitige Oberbauchschmerzen äußern, Kopfschmerzen, Sehstörungen, Blutveränderungen und eine Verschlechterung der Nierenfunktion auftreten. Zudem kann es beim Kind zu Wachstumsstörungen durch eine Mangelversorgung kommen. Das Krankheitsbild kann für Mutter und Kind sehr gefährlich werden, daher sollten sich Frauen mit einer Präeklampsie  in stationärer oder engmaschiger ambulanter Kontrolle  befinden. </p>
            <p>Tritt diese Erkrankung früh in der Schwangerschaft auf, dann wird versucht, die Schwangerschaft fortzuführen, solange die Risiken für Mutter und Kind akzeptabel sind. Verschlechtert sich die Erkrankung oder besteht keine Frühgeburtlichkeit mehr, so wird die Schwangerschaft beendet, entweder mit einem Kaiserschnitt oder die Geburt wird eingeleitet und das Kind kann auf normalem Weg auf die Welt kommen.  Die Entbindung ist die einzige kausale Therapie für diese Erkrankung. Auch in den Tagen nach der Entbindung kann sich die Erkrankung verschlechtern.  Je länger die Entbindung her ist, desto besser werden im Regelfall die Beschwerden. Es kann sein, dass es etwas dauert, bis sich vor allem der Blutdruck normalisiert. Daher kann es sein, dass auch nach der Entbindung eine Blutdruckmedikation durchgeführt werden muss. </p>
            <Typography variant="h2">Vorstellung bei der Frauenärztin/beim Frauenarzt</Typography>
            <p>Sollten Sie bei sich etwas von den oben genannten Symptomen merken, dann sollten Sie sich bei Ihrem Frauenarzt, Ihrer Frauenärztin oder am Wochenende oder Nachts in der Klinik vorstellen. Aber keine Sorge, in 98% der Fälle verläuft die Schwangerschaft „vergiftungsfrei“. </p>
            <Typography variant="h2">Schwangerschaftscholestase: Erhöhte Gallensäure im Blut</Typography>
            <p>Eine weitere Erkrankung, die nur in der Schwangerschaft auftritt, ist wenn die Gallensäuren im Blut der Mutter ansteigen und führen zu Juckreiz an der Haut vor allem an Händen und Fußsohlen führen. Der Juckreiz kann aber am gesamten Körper auftreten. Zudem sind häufig die Leberwerte im Blut der Mutter erhöht. Die Gallensäuren sind vor allem für das Kind ein Risiko, daher sollte hier eine Therapie zur Senkung der Gallensäuren und eine engmaschige Kontrolle des kindlichen Wohlbefindens durchgeführt werden. Bei Frauen, die eine Schwangerschaftscholestase haben, wird das Kind entweder bei Kaiserschnitt geboren oder die Geburt früher eingeleitet, damit das Risiko für das Kind nach Abschluss der Frühgeburtlichkeit reduziert ist. </p>
            <Typography variant="h2">Juckreiz und Quaddelbildung</Typography>
            <p>Zudem gibt es weitere Schwangerschaftserkrankungen, die vor  allem mit Juckreiz einhergehen. Manche von Ihnen mit Quaddelbildung oder Ausschlag an der Haut. Diese sollten dermatologisch abgeklärt werden und können gut behandelt werden. </p>
            <Typography variant="h2">Keine Angst!</Typography>
            <p>Nach all diesen Informationen über Erkrankungen, die in der Schwangerschaft auftreten können, keine Angst.  Die meisten Schwangerschaften verlaufen ohne schwerwiegende Erkrankungen oder Zwischenfälle. Falls Sie unsicher sind oder Beschwerden feststellst, stehen Ihnen Ihre Frauenärztin oder Ihr Frauenarzt und auch die Kliniken jederzeit zu Seite. </p>

            <p><b>Genießen Sie Ihre Schwangerschaft ohne sich zu viele Sorgen zu machen! Und selbst wenn Sie erkranken – Ihre Ärztin/Ihr Arzt Ihnen und werden alles Mögliche tun um Sie gut durch Ihre Schwangerschaft zu begleiten.</b></p>
          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default PregnancyIllnesses;