import React from 'react';

import { Link } from 'react-router-dom'

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

import womanBooklet from 'resources/svgs/woman-booklet.svg';
import PrintLogin from 'components/PrintLogin/PrintLogin';

export default function PrintLoginCard() {
  const theme = useTheme(); 

  return (
    <Link to="/profile" className="overview-card">
      <Card>
        <CardActionArea>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              Meine App-ID lautet: <b><PrintLogin /></b>.<br />
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}
