import { useEffect, useState } from "react";

import React from 'react';

import { Line } from 'react-chartjs-2';

import {  formatISODateToGermanDate } from 'helpers/Helpers';

import { getWeeklyMinAvgMaxData } from "../../GraphUtils/GraphUtils";


const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    }, 
    zoom: {
      pan: {
        enabled: true,
        mode: 'x'
      },
      limits: {
        x: {
          min: 'original',
          max: 'original'
        },
        y: {
          min: 'original',
          max: 'original'
        }
        // axis limits
      },
      zoom: {
        wheel: {
          enabled: true
        },
        pinch: {
          enabled: true
        },
        mode: 'x'
        // zoom options and/or events
      }
    },
    tooltip: {
      callbacks: {
        // Remove the "title", which usually shows a arbitrary date in an incorrect format
        title: function(context) {
          /*console.log(context[0].label);
          return (new MomentUtils())*/
          return "";
        }
      }
    }
  },  
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'week',
        displayFormats: {
          week: '[KW] W/YYYY'
        }
      },
      ticks: {
        // display: false, // Remove dates
        /*maxRotation: 0,
        autoSkipPadding: 30,
        includeBounds: true*/
      },
      grid: {
        display: false, // Remove grid
        drawBorder: false, // Remove bottom border
      }
      // type: 'timeseries'
      
    },
    y: {
      ticks: {
        autoSkipPadding: 30,
        mirror: true, // Place inside the graph
        showLabelBackdrop: true, // Add a box around the axis legend
        backdropColor: 'rgba(255, 255, 255, 0.95)', // Make the box white
        z: 1 // Make sure the box appears in front of the chart
      },
      grid: {
        drawBorder: false, // Remove left border
        borderDash: [8, 4], // Make grid dashed
        // display: false
      }
      
    }
  }
};

export default function BloodPressureIntervalGraph(props) {

  const [data, setData] = useState(0);

  useEffect(() => {
    // Ensure prop objects are already initialized/set
    if(props.data.systolicMeasurements === undefined || props.data.diastolicMeasurements === undefined) {
      return;
    }

    let weeklySystolicData = getWeeklyMinAvgMaxData(props.data.systolicMeasurements);

    let weeklySystolicMeasurementMinimums = weeklySystolicData.weeklyMeasurementMinimums;
    let weeklySystolicMeasurementAverages = weeklySystolicData.weeklyMeasurementAverages;
    let weeklySystolicMeasurementMaximums = weeklySystolicData.weeklyMeasurementMaximums;
    let weeklySystolicMeasurementDayKeys = weeklySystolicData.dayKeys;

    let weeklyDiastolicData = getWeeklyMinAvgMaxData(props.data.diastolicMeasurements);

    let weeklyDiastoliceasurementMinimums = weeklyDiastolicData.weeklyMeasurementMinimums;
    let weeklyDiastolicMeasurementAverages = weeklyDiastolicData.weeklyMeasurementAverages;
    let weeklyDiastolicMeasurementMaximums = weeklyDiastolicData.weeklyMeasurementMaximums;
    let weeklyDiastolicMeasurementDayKeys = weeklyDiastolicData.dayKeys;

    if(weeklySystolicMeasurementMinimums !== undefined && 
      weeklySystolicMeasurementAverages !== undefined && 
      weeklySystolicMeasurementMaximums !== undefined &&
      weeklySystolicMeasurementDayKeys !== undefined &&
      weeklyDiastoliceasurementMinimums !== undefined && 
      weeklyDiastolicMeasurementAverages !== undefined && 
      weeklyDiastolicMeasurementMaximums !== undefined &&
      weeklyDiastolicMeasurementDayKeys !== undefined) 
    {
      setData({
        labels: weeklySystolicMeasurementDayKeys,
        datasets: [
          {
            label: "Durschschnittlich systolisch",
            type: "line",
            data: Object.values(weeklySystolicMeasurementAverages),
            fill: false,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
          },
          {
            label: "Maximal systolisch",
            type: "line",
            data: Object.values(weeklySystolicMeasurementMaximums),
            backgroundColor: 'rgb(75, 192, 255, 0.5)',
            borderColor: 'transparent',
            fill: 0,
            tension: 0,
            pointRadius: 0
          },
          {
            label: "Minimal systolisch",
            data: Object.values(weeklySystolicMeasurementMinimums),
            backgroundColor: 'rgb(75, 192, 255, 0.5)',
            borderColor: 'transparent',
            fill: 0,
            tension: 0,
            pointRadius: 0,
          },
          {
            label: "Durchschnittlich diastolisch",
            type: "line",
            data: Object.values(weeklyDiastolicMeasurementAverages),
            fill: false,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
          },
          {
            label: "Maximal diastolisch",
            type: "line",
            data: Object.values(weeklyDiastolicMeasurementMaximums),
            backgroundColor: 'rgb(75, 192, 255, 0.5)',
            borderColor: 'transparent',
            fill: 0,
            tension: 0,
            pointRadius: 0
          },
          {
            label: "Minmal diastolisch",
            data: Object.values(weeklyDiastoliceasurementMinimums),
            backgroundColor: 'rgb(75, 192, 255, 0.5)',
            borderColor: 'transparent',
            fill: 0,
            tension: 0,
            pointRadius: 0
          }
        ],
      });
    }
  }, [props])


  return (
    <>
      <div className="data-plot">
        <Line data={data} options={options} className="data-plot" />
      </div>

      <p className="graph-subtitle"><b>Durchschnitt aller Blutdruck-Messungen nach Woche (mmHg, Systolisch/Diastolisch).</b><br/>Blaue Bereiche: Spanne (Minimum/Maximum) des Blutdrucks in der jeweiligen Woche.<br/>
        { /* data && 
          <>
            { formatISODateToGermanDate(data.labels[0]) } bis { formatISODateToGermanDate(data.labels[data.labels.length - 1]) }
          </> */
        }
      </p>
    </>
  )
}

