import axios from 'axios';
import Globals from 'services/Globals';

import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { PaletteContext } from '../../../../App.js'

import withNotifications from 'services/NotificationService/withNotifications';


function PaletteSelector(props) {
  const { palette, setPalette } = React.useContext(PaletteContext);

  function saveColorPalette() {
    axios.post(Globals.SERVER_URL + "/settings", {
      key: "colorPalette",
      data: palette
    }).then(function(res) {
      props.notificationService.push("Einstellungen erfolgreich gespeichert.", "success");
      console.log(res);
    }).catch(function(err) {
      console.log(err);
      props.notificationService.push("Es ist ein Fehler beim Speichern des Farbschemas aufgetreten.", "error");
    });
  }

  const handleChange = (event) => {
    setPalette(event.target.value);
  };

  return (
    <Box>
      <FormControl component="fieldset" >
        <FormLabel component="legend">Farbschema</FormLabel>
        <RadioGroup aria-label="gender" name="gender1" value={palette} onChange={handleChange}>
          <FormControlLabel value="purplePalette" control={<Radio />} label="Lila" />
          <FormControlLabel value="greenPalette" control={<Radio />} label="Grün" />
          <FormControlLabel value="cyanPalette" control={<Radio />} label="Türkis" />
        </RadioGroup>
      </FormControl><br/>
      <Button variant="contained" color="primary" size="small" disableElevation onClick={saveColorPalette}>
        Einstellung dauerhaft speichern
      </Button>
    </Box>
  );
}

export default withNotifications(PaletteSelector);