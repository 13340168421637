import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class Emotions extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Emotionen in der Schwangerschaft</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Während der Schwangerschaft &gt; Emotionen in der Schwangerschaft</div>
            
            <Typography variant="h1">Emotionen in der Schwangerschaft</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Die Schwangerschaft ist eine Achterbahnfahrt. Hier wollen wir uns mit den Hochs und Tiefs in der Schwangerschaft und der Zeit danach beschäftigen.</b></p>

            <p>Emotionen in der Schwangerschaft sind vielfältig. Vielleicht haben Sie ja auch schon festgestellt, dass Ihre Gefühle manchmal Achterbahn fahren.  </p>
            <p>Häufig sind Sie sehr glücklich über die Schwangerschaft und die Entwicklung ihres Kindes. Während der Schwangerschaft kann es aber auch Phasen geben, in denen es Ihnen nicht so gut geht und Sie trauriger sind als sonst. </p>

            <Typography variant="h2">Depression und Traurigkeit in der Schwangerschaft</Typography>
            <p>Diese Traurigkeit wird durch hormonelle Veränderungen hervorgerufen und kann von leichter Traurigkeit über Depressionen bis zu schweren psychischen Erkrankungen reichen.</p>

            <p>Es gibt Situationen in denen eine Depression häufiger auftritt. Dies kann sein, wenn Sie schon einmal eine Depression hatten, Sie keinen unterstützenden Partner oder keine  unterstützende Familie haben, Ihr Baby zu früh auf die Welt gekommen ist, Sie finanzielle Schwierigkeiten haben oder kürzlich Schicksalsschläge erleiden mussten. All diese Situationen und die zusätzliche Achterbahnfahrt des Mutterseins können zusammen einfach zu viel für Ihren Körper sein.</p>

            <Typography variant="h2">Stimmungstief nach der Geburt</Typography>
            <p>Nach der Geburt kann es zu einem Stimmungstief kommen. Dies ist ein kurzfristiger Verstimmungszustand in den Tagen bis Wochen nach der Geburt und wird auch „Maternity Blues“ genannt. Zumeist tritt dieser zwischen dem zweiten und vierten Tag nach der Entbindung auf. Sie sind traurig, müssen häufig weinen und machen sich übermäßige Sorgen um Ihr Kind. Manche Frauen können nicht schlafen, sind ängstlich und werden als leicht reizbar wahrgenommen. Eine leichte Verstimmung tritt bei 70% aller Frauen im Wochenbett auf.  Verantwortlich dafür ist vor allem die hormonelle Umstellung durch den Abfall von Östrogen und Progesteron. Auch die seelische und körperliche Erschöpfung nach der Geburt, der mangelnde Schlaf, ggf. Stillprobleme und Versagensängste als Mutter spielen  eine Rolle. Aber keine Sorge – der Maternity Blues tritt meist nur kurz auf und verschwindet von alleine wieder.</p>

            <p>Sind die Symptome stark ausgeprägt oder dauert die Symptomatik länger an, kann eine postpartale Depression vorliegen. Sie tritt bei 10-20% aller Wöchnerinnen auf und kann auch noch bis zu zwei Jahre nach der Geburt entstehen. Auch hier spielen ein Traurigkeitsgefühl, Teilnahmslosigkeit, erhöhte Reizbarkeit, Ängste, Schlafstörungen und Panikattacken eine Rolle. Freudlosigkeit, verstärkte Müdigkeit, Hoffnungslosigkeit und Schuldgefühle sind ebenso Anzeichen für eine Depression. Sie können konträre Gefühle gegenüber Ihrem Kind haben, auch Tötungsgedanken sind nicht selten.  Falls Sie Anzeichen für eine depressive Episode haben, zögern Sie nicht um Hilfe zu bitten, sprechen mit einem Arzt oder einer Vertrauensperson über Ihre Gedanken. Eine Depression ist mehr, als sich unglücklich zu fühlen. Sie sollten in diesem Falle unbedingt professionelle Hilfe in Anspruch nehmen. Oftmals tut man sich selbst schwer Symptome zu erkennen oder schämt sich für seine Gedanken. Bitten Sie deshalb jemanden auf Sie Acht zu geben, wenn Sie sich unsicher sind, ob Sie Anzeichen für eine depressive Episode haben. </p>

            <Typography variant="h2">Tipps und Tricks</Typography>
            <p>Damit Sie emotional stabil durch die Schwangerschaft kommen, gibt es ein paar Tricks und Kniffe, die Sie schon während der Schwangerschaft nutzen können. Vorbeugend wirkt eine gesunde und ausgewogene Ernährung.  Auch schlafen, entspannen und Musik hören sind Strategien um eine Depression zu vermeiden. Durch Sport und Bewegung tun Sie physisch und psychisch Ihrem Körper etwas Gutes. Nicht zuletzt hilft es mit anderen Müttern, die ähnlich fühlen über die Situation zu sprechen und sich auszutauschen. </p>

            <p>Mutter werden ist eine emotionale und körperliche Herausforderung. Mit dem Wissen über die möglichen emotionalen Veränderungen in der Schwangerschaft werden Sie die Situation gut meistern. Ganz wichtig ist, dass Sie nicht zögern sich Hilfe zu holen, wenn Sie Ängste plagen – Sie sind nicht allein. </p>

            <p><b>Vergessen Sie nicht - nach einem Tief kommen auch wieder die schönen, ganz besonders glücklichen Emotionen der Schwangerschaft und mit Ihrem Kind.</b></p>


          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default Emotions;