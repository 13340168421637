import React from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

import taskImage from 'resources/svgs/tasks-hands.svg';
import { CardHeader } from '@material-ui/core';
import BoxedItem from 'components/BoxedItem/BoxedItem';
import MappingBoxedItem from 'components/MappingBoxedItem/MappingBoxedItem';
import { formatDate } from 'helpers/Helpers';


export default function GravidogramEntry(props) {

  const theme = useTheme(); 
    return (
      <Card variant="outlined" style={{border: "1px solid " + theme.palette.primary.main}}>
        <CardActionArea>
          <CardHeader className="gravidogram-header" style={{backgroundColor: theme.palette.primary.main}} title={formatDate(props.data["datum"])}></CardHeader>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="div">
              <div className="gravidogram-flex">
                <MappingBoxedItem name="Fundusstand" value={props.data["fundusstand"]} mapping={{1: "S (Symphyse)", 2: "S+1", 3: "S+2", 4: "N-3", 5: "N-2", 6: "N-1", 7: "N (Nabel)", 8: "N+1", 9: "N+2", 10: "N+3", 11: "Rb-2", 12: "Rb-1", 13: "Rb (Rippenbogen)", 99: "Nicht untersucht"}} />
                <MappingBoxedItem name="Kindslage" value={props.data["kindslage"]} mapping={{1: "Beckenendlage (BEL)", 2: "Schädellage (SL)", 3: "Querlage (QL)", 4: "Schräglage", 99: "Nicht untersucht"}} />
              </div>

              <div className="gravidogram-flex">
                <MappingBoxedItem name="Herztöne" value={props.data["herztoene"]} mapping={{1: "Ja, Herztöne detektiert", 0: "Nein, Herztöne nicht detektiert", 99: "Nicht untersucht"}} />
                <MappingBoxedItem name="Kindsbewegung" value={props.data["kindsbewegung"]} mapping={{1: "Ja, Kindsbewegung detektiert", 0: "Nein, keine Kindsbewegung detektiert", 99: "Nicht untersucht"}} />
              </div>

              <div className="gravidogram-flex">
                <MappingBoxedItem name="Ödeme" value={props.data["oedeme"]} mapping={{0: "Keine Ödeme", 1: "Leichte Ödeme", 2: "Mittelgradige Ödeme", 3: "Schwere Ödeme", 99: "Nicht untersucht"}}/>
                <MappingBoxedItem name="Varikosis" value={props.data["varikosis"]} mapping={{1: "Ja, Varikosis vorahnden", 0: "Nein, keine Varikosis vorahnden", 99: "Nicht untersucht" }} />
              </div>

              <div className="gravidogram-flex">
                <MappingBoxedItem name="Gewicht" value={props.data["gewicht"]} />
                <MappingBoxedItem name="RR (sys.)" value={props.data["bpsystolisch"]} />
                <MappingBoxedItem name="RR (dias.)" value={props.data["bpdiastolisch"]} />
                <MappingBoxedItem name="Hb (Eryl)" value={props.data["haemoglobin"]} />
              </div>

              <div className="gravidogram-flex">
                <MappingBoxedItem name="Urinanalyse auf Eiweiß" value={props.data["urinanalyseeiweiss"]} mapping={{0: "Negativ", 9: "Spuren", 1: "+", 2: "++", 3: "+++", 4: "++++", 99: "Nicht untersucht"}} />
                <MappingBoxedItem name="Urinanalyse auf Zucker" value={props.data["urinanalysezucker"]} mapping={{0: "Negativ", 9: "Spuren", 1: "+", 2: "++", 3: "+++", 4: "++++", 99: "Nicht untersucht"}} />
              </div>
              <div className="gravidogram-flex">
                <MappingBoxedItem name="Nitrit" value={props.data["nitrit"]} mapping={{0: "Negativ", 1: "Positiv", 99: "Nicht untersucht"}}/>              
                <MappingBoxedItem name="Blut" value={props.data["blut"]} mapping={{0: "Negativ", 1: "Spuren (nicht-hämolysiert)", 2: "++ (nicht-hämolysiert)", 3: "Spuren (hämolysiert)", 4: "+ (hämolysiert)", 5: "++ (hämolysiert)", 6: "+++ (hämolysiert)", 99: "Nicht untersucht"}}/>
                <MappingBoxedItem name="Leukozyten" value={props.data["leukozyten"]} mapping={{0: "Negativ", 9: "Spuren", 1: "+", 2: "++", 3: "+++", 99: "Nicht untersucht"}} />
              </div>
              <div className="gravidogram-flex">
                <MappingBoxedItem name="Urobilinogen" value={props.data["urobilinogen"]} mapping={{1: "Normal", 2: "2 mg/dL", 3: "4 mg/dL", 4: "≥ 8 mg/dL", 99: "Nicht untersucht"}} />
              </div>
              
              <div className="gravidogram-flex" style={{flexWrap: "wrap"}}>
                <MappingBoxedItem name="Sonstiges/Therapie/Maßnahmen" value={props.data["sonstiges"]} />
              </div>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );  
}