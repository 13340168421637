import React from 'react';

import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogActions from '@material-ui/core/DialogActions';


export default function NewlyRegisteredDialog(props) {
  return (
    <Dialog aria-labelledby="simple-dialog-title" open={props.dialogOpen}>
      <DialogTitle id="simple-dialog-title">Registrierung erfolgreich!</DialogTitle>
      <DialogContent>
        <p>Ihre App-ID lautet <b>{props.pseudonym}.</b></p>
        <p>Sie müssen sich diese App-ID merken. Sie kann später nicht wiederhergestellt werden.</p>
        <ul style={{paddingLeft: "20px"}}>
          <li>Schreiben Sie sich diese App-ID auf.</li>
          <li>Machen Sie einen Screenshot.</li>
          <li>Senden Sie sich selbst eine E-Mail. <a href={"mailto:?subject=Meine App-ID lautet " + props.pseudonym }>Hier klicken, um E-Mail zu senden</a>.</li>
          <li>Bitte bewahren Sie die App-ID zur eigenen Sicherheit nicht zusammen mit dem Passwort auf.</li>
        </ul>
        <p>Anschließend können Sie sich im System anmelden.</p>
      </DialogContent>
      <MuiDialogActions>
        <Button onClick={() => {props.setDialogOpen(false)}} autoFocus color="primary">
          Ja, ich habe meine App-ID sicher aufgeschrieben.
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
}