import React from 'react';
import axios from 'axios';

import Header from 'components/Header/Header';
import Globals from 'services/Globals';

import { Line } from 'react-chartjs-2';

import { formatISODateToGermanDate } from 'helpers/Helpers';


import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TabPanel } from 'components/TabPanel/TabPanel';

import BackToMeasurementsGeneralButton from '../BackToMeasurementsGeneralButton';

import '../Measurements.scss';
import SleepFullGraph from '../Graphs/Sleep/SleepFullGraph/SleepFullGraph';
import SleepIntervalGraph from '../Graphs/Sleep/SleepIntervalGraph/SleepIntervalGraph';
import SleepTendencyText from '../Graphs/Sleep/SleepTendencyText/SleepTendencyText';
import SleepTendencyGraph from '../Graphs/Sleep/SleepTendencyGraph/SleepTendencyGraph';
import ObfuscationQuestionnaire from '../ObfuscationStudy/ObfuscationQuestionnaire/ObfuscationQuestionnaire';
import AnswerFewQuestions from '../ObfuscationStudy/AnswerFewQuestions/AnswerFewQuestions';
import Footer from 'components/Footer/Footer';
import NotEnoughMeasurements from '../NotEnoughMeasurementsNotice';
import LoadingNotice from '../LoadingNotice';


class Sleep extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sleepData: {},
      activeTab: 0,
      loading: false
    };
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleTabChange(event, newValue) {
    this.setState({
        activeTab: newValue
    });
  }

  componentDidMount() {
    var self = this;
    this.setState({
      loading: true
    })

    axios.get(Globals.SERVER_URL + "/measurements/sleep", {
    }).then(function(res){
      self.setState({
        loading: false
      })
      let allDataPerDayList = res.data;
      let sleepPerDayList = {};

      for (let day in allDataPerDayList) {
        let thisSleepRate = allDataPerDayList[day].total_sleep_time;

        // Remove zero values
        if (thisSleepRate !== 0) {
          sleepPerDayList[day] = (thisSleepRate/3600);
        }
      }

      self.setState({
        sleepData: sleepPerDayList
      });

    }).catch(function(err){
      self.setState({
        loading: false
      })
      // TODO show error
      console.log(err);
      
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Messwerte</Typography>
        </Header>

        <AppBar position="static" className="margin-bottom-2em">
          <Tabs value={this.state.activeTab} onChange={this.handleTabChange} variant="scrollable" scrollButtons="auto">
            <Tab value={0} label="Alle Daten" />
            <Tab value={1} label="Monatlich" />
            <Tab value={2} label="Wöchentlich" />
            <Tab value={3} label="Text" />
          </Tabs>
        </AppBar>

        <main>
          <Container maxWidth="lg">
            <div className="breadcrumbs">Messwerte &gt; Schlaf</div>
            <Typography variant="h1">Schlaf</Typography>
            <BackToMeasurementsGeneralButton />
            <br/><br/>
            <>
              { this.state.loading
              ? <LoadingNotice />
              : (this.state.sleepData && Object.keys(this.state.sleepData).length <= 2)
                ? <NotEnoughMeasurements />
                : 
                <>
                <TabPanel value={this.state.activeTab} index={0}>
                  <SleepFullGraph data={this.state.sleepData} />
                  <AnswerFewQuestions />
                  <ObfuscationQuestionnaire location="regular-measurements" type="sleep-fullgraph" data={this.state.sleepData} />
                </TabPanel>
                <TabPanel value={this.state.activeTab} index={1}>
                  <SleepTendencyGraph data={this.state.sleepData} />
                  <AnswerFewQuestions />
                  <ObfuscationQuestionnaire location="regular-measurements" type="sleep-tendencygraph" data={this.state.sleepData} />
                </TabPanel>
                <TabPanel value={this.state.activeTab} index={2}>
                  <SleepIntervalGraph data={this.state.sleepData} />
                  <AnswerFewQuestions />
                  <ObfuscationQuestionnaire location="regular-measurements" type="sleep-intervalgraph" data={this.state.sleepData} />
                </TabPanel>
                <TabPanel value={this.state.activeTab} index={3}>
                  <SleepTendencyText data={this.state.sleepData} />
                  <AnswerFewQuestions />
                  <ObfuscationQuestionnaire location="regular-measurements" type="sleep-tendencytext" data={this.state.sleepData} />
                </TabPanel>
              </> 
            }
            </>
          </Container>
        </main>
        <Footer />
      </>
    );
  }
}

export default Sleep;