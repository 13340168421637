import React from 'react';

import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import { sizing } from '@material-ui/system';



class CardiotocographyEditScreen extends React.Component {

  render() {
    return (
      <>
        <h3>Neuer Eintrag</h3>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField name="date" value={this.props.data["date"] || ""} onChange={this.props.onChange} label="Datum" />
          </Grid>
          <Grid item xs={6}>
            <TextField name="pregnancy_week" value={this.props.data["pregnancy_week"] || ""} onChange={this.props.onChange} label="Rechn. SSW" />
          </Grid>
          <Grid item>
            <TextField multiline rows="3" name="assessment" value={this.props.data["assessment"] || ""} onChange={this.props.onChange} label="Beurteilung" />
          </Grid>
        </Grid>
        <Button variant="contained" color="primary" onClick={this.props.updateFunction} disableElevation>Speichern</Button>
      </>
    )
  }
}

export default CardiotocographyEditScreen;