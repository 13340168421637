import { useState, useEffect, useRef } from "react";
import axios from "axios"
import Globals from 'services/Globals';

export default function useJournal() {
  const [entries, setEntries] = useState([]);
  useEffect(() => {
    getEntries();
  }, []);


  const getEntries = () => {
    axios.get(Globals.SERVER_URL + "/journal").then(function(res) {
      let entries = res.data
      setEntries(entries)
      console.log(entries)
      // setPalette(settings.palette)
    }).catch(function(err) {
      console.log(err);
      // setPalette("purplePalette")
    });
  }

  const postEntry = (entry) => {
    axios.post(Globals.SERVER_URL + "/journal", entry).then(function(res) {
      setEntries(prev => [res.data, ...prev])
      console.log(res);
    }).catch(function(err) {
      console.log(err);
    });
  }

  const deleteEntry = (index, itemId) => {
    axios.delete(Globals.SERVER_URL + `/journal/${itemId}`, { id: itemId }).
      then((res) => {
        setEntries(prev => [
          ...prev.slice(0, index),
          ...prev.slice(index + 1),
        ]);
      }).catch((err) => {
        console.log(err);
      });
  }

  return [entries, postEntry, deleteEntry];
}
