import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import React from "react";
import CustomErrorMessage from "../CustomErrorMessage/CustomErrorMessage";

export default function FormikMaterialRadioGroups(props) {
  return(
    <FormControl component="fieldset" fullWidth={true}>
      <FormLabel component="legend">{props.label}</FormLabel>
      <CustomErrorMessage name={props.name} />
      <RadioGroup aria-label="position" name={props.name} value={props.formikValues[props.name]} onChange={props.formikHandleChange} required={true}>
        { props.options.map((value, index) => {
            return(
              <FormControlLabel key={index} value={value.code} control={<Radio color="primary" />} label={value.label} labelPlacement="end" />
            )
        })}
      </RadioGroup>
    </FormControl>
  )
}
