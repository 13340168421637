import moment from "moment";
import localization from 'moment/locale/de'
import { averageArray } from 'helpers/Helpers';


/**
 * Calculates weekly parameters (min, avg, max) based on daily data
 * and returns them sorted by calendar week
 * 
 * @param {*} data Daily data in the form of {[2021-05-01] => 7,...}
 * @returns {Object} In the form of {minimums, averages, maximums}, with each of the individual objects being structured {[KW] => Value,...}
 */

export function getWeeklyMinAvgMaxData(data) {
  /* Summarize data by week */

  let weeklyMeasurementValues = {};

  /**
   * Save the beginning day of each week separately, else we would have to
   * calculate the beginning day for each week later on in order to ensure
   * that the data is adequately displayed
   */
  let dayKeys = [];

  for (let day in data) {
    let dayAsDateType = new Date(day);

    let yearWeek = moment(dayAsDateType).year() + "-" + moment(dayAsDateType).isoWeek();
    
    // Skip if the entry is not a number, e.g. undefined
    if (typeof (data[day]) !== "number") {
      continue;
    }

    if (weeklyMeasurementValues[yearWeek] === undefined) {
      weeklyMeasurementValues[yearWeek] = [data[day]];
      dayKeys.push(day);
    }
    else {
      weeklyMeasurementValues[yearWeek].push(data[day]);
    }
  }


  /* Min/Average/Max for each week */
  let weeklyMeasurementAverages = {};
  let weeklyMeasurementMinimums = {};
  let weeklyMeasurementMaximums = {};

  for (let yearWeek in weeklyMeasurementValues) {
    weeklyMeasurementMinimums[yearWeek] = Math.min(...weeklyMeasurementValues[yearWeek]);
    weeklyMeasurementAverages[yearWeek] = averageArray(weeklyMeasurementValues[yearWeek]);
    weeklyMeasurementMaximums[yearWeek] = Math.max(...weeklyMeasurementValues[yearWeek]);
  }

  return {weeklyMeasurementMinimums, weeklyMeasurementAverages, weeklyMeasurementMaximums, dayKeys};
}

/**
 * 
 * @param {Object} data Daily data in the form of {[2021-05-01] => 7,...}
 * @param {function} dateConversionFunction Allows specification of key format, e.g. function could convert 2021-07-01 to "July 2021"
 * @returns {Object} structure of {2021-07 => 58}, where each month holds one average value
 */
export function getMonthlyAvgData(data, dateConversionFunction) {
  /* Summarize data by month */

  let monthlyMeasurements = {};

  for (let day in data) {
    let dayAsDateType = new Date(day);
    let yearMonthKey;

    if(dateConversionFunction !== undefined && typeof dateConversionFunction === "function") {
      yearMonthKey = dateConversionFunction(dayAsDateType);
    }
    else {
      yearMonthKey = getYearMonthFromDate(dayAsDateType)
    }

    // Skip if the entry is not a number, e.g. undefined
    if (typeof (data[day]) !== "number") {
      continue;
      
    }

    if (monthlyMeasurements[yearMonthKey] === undefined) {
      monthlyMeasurements[yearMonthKey] = [data[day]];
    }
    else {
      monthlyMeasurements[yearMonthKey].push(data[day]);
    }
  }

  /* Average over each month */
  for (let yearMonth in monthlyMeasurements) {
    monthlyMeasurements[yearMonth] = averageArray(monthlyMeasurements[yearMonth]);
  }

  return monthlyMeasurements;
}

/**
 * 
 * @param {date} date 
 * @returns Date as string in format "Juni 2021"
 */
export function getYearMonthGermanTextStringFromDate(date) {
  return moment(date).locale("de", localization).startOf("month").format("MMMM") + " " + date.getFullYear()
}

/**
 * 
 * @param {date} date 
 * @returns Date as string in format "2021-03"
 */
export function getYearMonthFromDate(date) {
  return date.getFullYear() + "-" + (date.getMonth() + 1)
}