import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class AlcoholNicotine extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Allgemein &gt; Information zu Alkohol, Nikotin und sonstigen Konsumgütern</div>
            
            <Typography variant="h1">Information zu Alkohol, Nikotin und sonstigen Konsumgütern</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Jede Mutter möchte das Beste für Ihr Kind.</b></p>

            <p>Jede Mutter will das Beste für Ihr Kind. Das Tolle daran ist, dass Sie in der Schwangerschaft sehr viel dafür tun können, dass Ihr Kind die besten Voraussetzungen im Leben hat. Durch die Phase im Mutterleib wird das Kind geprägt. Durch Ihr Verhalten in der Schwangerschaft können Sie das Risiko Ihres Kindes im späteren Leben  bestimmte Erkrankungen oder Verhaltensauffälligkeiten zu bekommen erhöhen oder deutlich senken. </p>
            <p>In der Schwangerschaft sollten Sie Alkohol-, Tabak- und andere Konsumgüter oder Drogen meiden.</p>
            
            <Typography variant="h2">Alkohol</Typography>
            <p>Alkohol schadet dem ungeborenen Kind. Alkoholkonsum in der Schwangerschaft kann zum „Fetalen Alkoholsyndrom – kurz FASD“ führen. Dies ist eine unheilbare Erkrankung, die nur durch Alkoholkonsum der Mutter in der Schwangerschaft entsteht. Sie zählt zu den häufigsten nicht durch genetische Störungen verursachten Erkrankungen und führt zu geistiger Behinderung, Wachstums- und Entwicklungsverzögerungen und Verhaltensauffälligkeiten bei den Kindern. Dies führt häufig zu Problemen im Alltag, sowohl für das Kind als auch für die ganze Familie. </p>
            <p>Das Problem am Alkoholkonsum ist, dass es keinen Grenzwert gibt bis zu dem Alkoholkonsum unbedenklich ist. Auch Kinder von Frauen, die nur wenig Alkohol während der Schwangerschaft getrunken hatten, zeigten schwere Auffälligkeiten. Der Alkohol wird über den Mutterkuchen direkt an das Kind weitergegeben. Das Kind scheidet ihn mit den Nieren ins Fruchtwasser aus und nimmt ihn dann erneut mit dem Trinken des Fruchtwassers auf. Dadurch ist der Alkoholspiegel beim Kind länger erhöht als bei der Mutter. </p>
            <p>Daher gilt: Sobald Siewissen, dass Sie schwanger sind, sollten Sie komplett auf Alkohol verzichten, damit Sie Ihr Kind nicht wissentlich schädigen. </p>
            <p>Falls Sie Alkohol getrunken haben, bevor Sie wussten, dass Sie schwanger sind, sollten  Sie sich nicht zu viele Sorgen machen. Glücklicherweise kommt es bei dem Großteil der Kinder nicht zu Langzeitschäden. Dennoch gilt, sobald Sie sich Ihrer Schwangerschaft bewusst sind und nun wissen, dass Alkohol für Ihr Kind schädlich ist, sollten Sie darauf verzichten! Je weniger Alkohol ein Kind in der Schwangerschaft abbekommt, desto besser. </p>
            <p>Falls es mal einen Grund zum Anstoßen gibt, dann können Sie entweder eine Saftschorle oder auch einen alkoholfreien Sekt nehmen. </p>
            <Typography variant="h2">Nikotin und Rauchen</Typography>
            <p>Nikotin und Rauchen wirkt sich ebenfalls negativ auf das ungeborene Kind aus. Durch Nikotin wird der Mutterkuchen weniger durchblutet. Dadurch wird Ihr Kind schlechter mit Sauerstoff und Nährstoffen versorgt. Zudem gelangen durch das Rauchen viel Kohlenmonoxid und viele giftige Stoffe in das Blut des Kindes. Das Kind ist diesem Einfluss ausgesetzt sowohl, wenn die Mutter aktiv raucht, aber auch wenn sie passiv dem Rauch ausgesetzt ist. Rauchen in der Schwangerschaft erhöht das Risiko dafür, dass eine Fehlgeburt auftritt, dass ein Kind plötzlich im Bauch der Mutter stirbt, dass das Neugeborene am Entbindungstermin zu leicht ist, dass das Kind zu früh auf die Welt kommt und in die Kinderklinik verlegt werden muss. Dies sind nur einige Beispiele für Risiken in der Schwangerschaft. Zudem bestehen Risiken nicht nur direkt in der Schwangerschaft, sondern auch während der weiteren Entwicklung des Kindes. Kommt ein Kind zu leicht auf die Welt, nimmt es in den folgenden Wochen mehr zu, um auf ein normales Gewicht zu kommen. Diese Kinder haben im Jugend- und Erwachsenenalter ein erhöhtes Risiko für Übergewicht und Erkrankungen wie Herzinfarkt oder Diabetes. Ebenso leiden die Kinder häufiger unter Asthma und Allergien als Kinder, bei denen die Mütter nicht geraucht haben.  </p>
            <p>Auch nach der Geburt ist es wichtig auf Rauchen möglichst zu verzichten. Neben dem Einatmen von Nikotin gelangen die Giftstoffe auch über die Muttermilch zum Säugling. Nach der Geburt erhöht Rauchen das Risiko des Kindes unter anderem für Allergien, Asthma und eine eingeschränkte Lungenfunktion, aber auch für den plötzlichen Kindstod. </p>
            <p>Mit Rauchen in der Schwangerschaft schädigen Sie Ihr Kind. Daher lohnt es sich am besten komplett mit dem Rauchen aufhören. Auch eine rauchfreie Umgebung hilft das Risiko des Kindes zu senken. Daher können auch die Väter durch einen Rauchverzicht viel für die Entwicklung Ihres Kindes tun. Falls Sie es nicht alleine schaffen mit dem Rauchen aufzuhören, können Sie sich Unterstützung über Raucherentwöhnungskurse oder weitere Angebote holen. Jede Zigarette weniger hilft Ihrem Kind! </p>
            <Typography variant="h2">Koffeein und Chinin</Typography>
            <p>Auch mit dem Konsum von Koffein sollten Sie zurückhaltend sein. Studien belegen einen negativen Einfluss von Koffein schon bei 2 Tassen Kaffee pro Tag. Die Kinder hatten ein niedrigeres Geburtsgewicht. Koffein ist sowohl in Kaffee, Cola, schwarzem Tee und in Bitterschokolade enthalten. Daher sollten diese nur mit Vorsicht und in niedriger Dosierung genossen werden. </p>
            <p>Ebenso sollten Sie Chininhaltige Getränke (Bitter Lemon usw.)  meiden, da diese ebenfalls schädlich für Ihr Baby sind. </p>
            <Typography variant="h2">Weitere Drogin</Typography>
            <p>Auch der Konsum von harten Drogen wie zum Beispiel Crystal Meth, Kokain, Heroin, LSD und Ecstasy ist  für die Entwicklung des Kindes schädlich. Es birgt ein erhöhtes Risiko für Frühgeburtlichkeit, ein zu leichtes Geburtsgewicht, Entwicklungsverzögerungen, Verhaltensauffälligkeiten beim Kind und Entzugssymptome beim Neugeborenen. Daher ist von Drogenkonsum in der Schwangerschaft unbedingt abzuraten. Falls Sie Drogen konsumieren oder abhängig sind, wenden Sie sich bitte an Ihren  Frauenarzt oder –Ärztin oder an die Drogenberatungsstelle. Diese können Ihnen helfen einen Entzug durchzuführen. </p>
            <p>Durch den Verzicht auf all diese Stoffe, sind Sie dem Kind in Ihrem Bauch eine große Hilfe und Unterstützung! Daher versuchen Sie, falls Sie einen oder mehrere dieser Stoffe bisher zu sich genommen haben, dies zu unterlassen oder es zu reduzieren. Ihr Kind wird es Ihnen danken! </p>
            <p><b>Verzichten Sie in der Schwangerschaft auf Drogen, Alkohol- und Nikotinkonsum, Ihr Kind wird es Ihnen danken</b></p>
          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default AlcoholNicotine;