import React from 'react';

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';


class NoBarelySometimesPrettyMuch extends React.Component {

  render(props) {
      return (
        <>
          <FormControlLabel value="1" control={<Radio color="primary" />} label="Gar nicht" labelPlacement="end"/>
          <FormControlLabel value="2" control={<Radio color="primary" />} label="Kaum" labelPlacement="end"/>
          <FormControlLabel value="3" control={<Radio color="primary" />} label="Teilweise" labelPlacement="end"/>
          <FormControlLabel value="4" control={<Radio color="primary" />} label="Ziemlich" labelPlacement="end"/>
          <FormControlLabel value="5" control={<Radio color="primary" />} label="Sehr" labelPlacement="end"/>
        </>
      )
  }
}

export default NoBarelySometimesPrettyMuch;