import React from 'react';

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./YesNoDetails.scss";

export default function YesNoDetails(props) {
  if(props.data) {
    return (
      <>
        <div className="yes">
          ✓
          <div className="view-more">
            <FontAwesomeIcon icon={faInfoCircle} />
            <div className="details">
              { JSON.stringify(props.data) }
            </div>
          </div>
        </div>
      </>
    );
  }
  else {
    return (
      <div className="no">✗</div>
    );
  }
}