import React from 'react';

export default function AudioPlayer(props) {
  return (
    <div style={{marginBottom: "1em"}}>
      <b>{ props.title }</b>
      <div>
      <audio controls>
        <source src={props.src} type="audio/mp3"></source>
        Ihr Browser unterstützt die Wiedergabe von Audio-Elementen leider nicht.
      </audio>
      </div>
    </div>
  )
}