import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class Puerperal extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Wochenbett &gt; Das Wochenbett</div>
            
            <Typography variant="h1">Das Wochenbett</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Die Geburt ist geschafft, doch was kommt danach? Was erwartet mich im Wochenbett?</b></p>

            <Typography variant="h2">Das frühe Wochenbett</Typography>
            <p>Das Wochenbett beginnt nach dem Ende der Geburt. Sie sind mit Ihrem Kind nach der Entbindung noch ca. zwei Stunden im Kreißsaal. Hier ist ganz viel Zeit vorhanden sich gegenseitig kennenzulernen und auch den Versuch des Anlegens zu unternehmen. Vor allem in den ersten Stunden nach der Geburt ist Ihr Baby wach und aufnahmefähig für die neue Welt. Während der Zeit nach der Geburt haben Sie ausreichend Zeit die Mutter-Kind-Bindung zu stärken. Auch wenn Sie einen Kaiserschnitt hatten bleiben Sie die ersten Stunden im Kreißsaal zur Überwachung und können Ihr Kind in die Arme schließen. Diese erste Kontaktaufnahme zwischen Mutter und Kind wird Bonding genannt und ist für die spätere Mutter-Kind-Bindung wichtig. </p>

            <Typography variant="h2">Das erste Anlegen</Typography>
            <p>Beim ersten Anlegen an die Brust steht Ihnen in jedem Fall professionelle Hilfe zur Seite. Ihr Kind sollte auch den Warzenvorhof beim Trinkversuch mit einbeziehen. Das Neugeborene trinkt zuerst nur die Vormilch, die Muttermilch schießt erst am zweiten bis dritten Tag nach der Geburt ein. Trotzdem reicht Ihrem Kind die Vormilch zum Satt werden aus. Die Vormilch enthält wenig Fette, jedoch vermehrt Eiweiß und Kohlenhydrate weil das Verdauungssystem des Neugeborenen noch unreif ist und die Fette nur bedingt verarbeiten kann.</p>
            <p>Neben dem Infektionsschutz und Unterstützung der Immunabwehr für das Kind hat das Stillen auch für Sie positive Effekte. Durch die Stimulation der Brustwarze wird Oxytocin ausgeschütttet, dies bewirkt dass die Gebärmutter sich zusammenzieht und fördert gleichzeitig die weitere Milchabgabe. Sie können Ihr Baby am Tag acht- bis zwölfmal anlegen, es wird sich aber auch von selbst melden, wenn es hungrig ist. Der Schlaf Ihres Kindes wird unruhiger und es strampelt mit Ärmchen und Beinchen, wenn es sich nach Nahrung sehnt. Auch der Kopf wird zur Seite gedreht und der Mund sucht nach seiner Andockstation, auch mit schmatzenden Geräuschen. Manchmal weint Ihr Baby aber auch nur wenn es Hunger hat oder saugt an seinen Händchen. Schon bald werden Sie alle Zeichen Ihres Kindes genau kennen und deuten können.</p>

            <Typography variant="h2">Wochenfluss, Pflege und Hygiene</Typography>
            <p>Das Wochenbett an sich ist dazu da, dass sich die Organe nach der Schwangerschaft zurückbilden. Das bedeutet, dass die Gebärmutter sich verkleinert und die Wunde, an der der Mutterkuchens an der Gebärmutter befestigt war, heilt. Hierbei sondert die Wundfläche den Wochenfluss ab. Diese Wundheilung ist erst nach ca. 6 Wochen abgeschlossen, während dieser ganzen Zeit ist meist auch der Wochenfluss vorhanden. Der Wochenfluss wird im Verlauf weniger, er  verändert zudem seine Farbe und den Geruch. Der Wochenfluss kann bei einem Kaiserschnitt etwas geringer ausfallen. </p>
            <p>In der Zeit der Wundheilung besteht auch eine erhöhte Infektionsgefahr, daher ist auf besondere Hygiene zu achten. Wenn eine Geburtsverletzung vorliegt spülen Sie den Intimbereich nach dem Toilettengang am besten mit klarem Wasser ab. Zudem mit dem Toilettenpapier nur vorsichtig tupfen. Auch Tampons erhöhen die Infektionsgefahr, daher sind während des Wochenflusses nur Vorlagen empfohlen. Auf ein ausgedehntes Vollbad sollten Sie in der ersten Zeit ebenfalls verzichten – je mehr Wochenfluss und je blutiger dieser ist, desto länger sollte man mit einem ausgedehnten Badevergnügen warten.</p>
            <p>Zudem sollte man darauf achten vor und nach dem Toilettengang und vor dem Stillen die Hände zu waschen, damit das Risiko einer Infektion für das Neugeborene vermindert wird.Auch die Brust wischen Sie am besten mit einem Einmalwaschlappen vor dem Stillen ab, damit sich keine Bakterien festsetzen können.</p>
            <p>Solltest der Wochenfluss plötzlich ausblieben, sollten Sie Ihre Hebamme oder den Arzt informieren. Es kann zu einem Aufstau des Wochenflusses kommen, der sogenannte Lochialstau. Er kann auch von einem unangenehmen Geruch, starken Kopfschmerzen im Bereich der Stirn oder von Fieber begleitet sein und zu einer Entzündung der Gebärmutter führen, die behandelt werden muss. </p>

            <Typography variant="h2">Rückbildung der Gebärmutter</Typography>
            <p>Die Gebärmutter hat sich erst nach ca. sechs Wochen vollständig zurückgebildet. Zu Beginn können Sie die Rückbildung anhand von sogenannten Nachwehen spüren. Keine Sorge, diese Kontraktionen sind viel geringer als die Geburtswehen und werden meist nur als ein leichtes Ziehen wahrgenommen. Wenn Sie schon mehrere Kinder geboren haben, kann dieses Ziehen etwas stärker sein.</p>
            <p>Nach einem Kaiserschnitt oder wenn Sie Zwillinge geboren haben, kann die Rückbildung etwas länger dauern.</p>
            <p>Nach ca. zehn Tagen kann man die Gebärmutter von außen jedoch nicht mehr ertasten.</p>

            <Typography variant="h2">Ablauf des Klinikaufenthaltes</Typography>
            <p>Bei einer normalen Entbindung sind es meist 2-3 Tage die Sie im Krankenhaus verbringen, hat ein Kaiserschnitt stattgefunden, werden Sie meist 1-2 Tage länger bleiben.  Bei einer normalen Entbindung ohne Komplikationen ist ggf. auch eine ambulante Entbindung möglich. Täglich wird die Rückbildung Ihrer Gebärmutter, der Wochenfluss, Blutdruck und Temperatur kontrolliert. Auch bei Geburtsverletzungen wird täglich die Wunde inspiziert um die Wundheilung zu überwachen.</p>
            <p>Vor Entlassung wird auch Ihr Baby nochmal von einem Kinderarzt untersucht, die sogenannte U2, die zwischen dem 3. Und 10 Lebenstag durchgeführt wird. Wenn Sie vorzeitig nach Hause gehen oder ambulant entbinden, findet diese beim niedergelassenen Kinderarzt statt. </p>

            <Typography variant="h2">Darmtätigkeit und Beckenboden</Typography>
            <p>Die Lage des Darms verändert sich durch die Verkleinerung der Gebärmutter, dies kann sich in Verstopfung äußern. Zur Vorbeugung wie auch zur Thromboseprophylaxe sollten Sie sich viel bewegen und auf ausreichende Trinkmengen achten.</p>
            <p>Bei Verstopfung sollte ein starkes Pressen auf der Toilette vermieden werden. Ihr Beckenboden ist nach der Geburt noch nicht gefestigt und braucht noch Erholungszeit. Druck sollten Sie in jeder Form vermeiden, das bedeutet soweit es geht nicht schwer heben und den Kopf beim Niesen, Husten oder Lachen am besten zur Seite drehen. So wird nur die seitliche Bauchmuskulatur belastet und der Beckenboden geschont. Nach Abschluss des Wochenbetts empfehlen wird Ihnen Rückbildungsgymnastik, die auch besonders eine Stärkung des Beckenbodens bewirkt. Ihre Hebamme wird Sie sicher mit Rat und Tat zur Seite stehen.</p>

            <Typography variant="h2">Mutterschutz und Erholungszeit</Typography>
            <p>Für Sie besteht in den ersten acht Wochen nach der Geburt ein absolutes Beschäftigungsverbot, Ihr Verdienstausfall wird ersetzt. Sie können sich somit ganz auf die Erholung und auf die Bindung zwischen Ihnen und Ihrem Baby konzentrieren. Auch auf eine Hebamme haben Sie Anspruch, die Ihnen bei Problemen in der Zeit des Wochenbetts zur Seite steht. </p>

            <p>Wir wünschen Ihnen hierbei ganz viel Freude bei dieser besonderen Zeit des Kennenlernens und alles Gute für den weiteren Verlauf der Schwangerschaft.</p>

            <b>Nehmen Sie sich Zeit, Ihr neues Familienmitglied kennenzulernen und gemeinsam im Alltag anzukommen. Alles Gute für die weitere Schwangerschaft und die Zeit danach! </b>

          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default Puerperal;