import React, { useState } from "react";

import { Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import EntryList from "./entryList";
import useJournal from "./useJournal";
import Entry from "./entry";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& .text-center": {
      margin: theme.spacing(1),
      textAlign: "center",
    },
  },
}));

export default function Journal() {
  const classes = useStyles();
  const [entries, postEntry, deleteEntry] = useJournal();

  const handlePostEntry = (entry) => postEntry(entry);
  const handleDeleteEntry = (index, id) => deleteEntry(index, id);
  return (
    <>
      <Header>
        <Typography variant="h2">Gefühls-Tagebuch</Typography>
      </Header>
      <main>
        <Container className={classes.root}>
          <Entry postEntry={handlePostEntry}/>
          <EntryList list={entries} deleteEntry={handleDeleteEntry} />
        </Container>
      </main>
      <Footer/>
    </>
  );
}
