import React from 'react';  

class StepperPage extends React.Component {
  render(props) {
    return (
      <>
        { this.props.activeStep === this.props.thisStep &&
          this.props.children
        }
      </>
    );
  }
}

export default StepperPage;