import React from 'react';

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';


class PSS10NeverRarelySometimesFrequentOftenASC extends React.Component {

  render(props) {
      return (
        <>
          <FormControlLabel value="1" control={<Radio color="primary" />} label="Nie" labelPlacement="end"/>
          <FormControlLabel value="2" control={<Radio color="primary" />} label="Fast nie" labelPlacement="end"/>
          <FormControlLabel value="3" control={<Radio color="primary" />} label="Manchmal" labelPlacement="end"/>
          <FormControlLabel value="4" control={<Radio color="primary" />} label="Ziemlich oft" labelPlacement="end"/>
          <FormControlLabel value="5" control={<Radio color="primary" />} label="Sehr oft" labelPlacement="end"/>
        </>
      )
  }
}

export default PSS10NeverRarelySometimesFrequentOftenASC;