import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Globals from 'services/Globals';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import { Link } from "react-router-dom";

import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "0",
    "& li": {
      listStyle: "none",
      padding: "1em",
      borderColor: theme.palette.primary.main,
      border: "1px solid",
      margin: "0.5em auto",
      fontSize: "1.2em",
      "& a": {
        color: "#FFF",
        textDecoration: "none",
      },
    }
  },
}));


export default function MyPreviousPregnancies() {
  const classes = useStyles();
  const [previousPregnancies, setPreviousPregnancies] = useState([]);

  useEffect(() => {
    getPregnancies();
  }, []);

  const getPregnancies = () => {
    axios.get(Globals.SERVER_URL + "/questionnaires?questionnaire=individualpreviouspregnancy").then(function(res) {
      setPreviousPregnancies(res.data);
    }).catch(function(err) {
      console.log(err);
    });
  }

  const deletePregnancy = (id) => {
    axios.delete(Globals.SERVER_URL + "/questionnaires/" + id).then(function(res) {
      getPregnancies();
    }).catch(function(err) {
      console.log(err);
    });
  }

  return (
    <>
      <Header>
        <Typography variant="h2">Frühere Schwangerschaften</Typography>
      </Header>
      <main>
        <Container maxWidth="lg">
          <Typography variant="h2">
            Meine früheren Schwangerschaften
          </Typography>
          <p>Der Abschnitt zu vorherigen Schwangerschaften ist im Mutterpass häufig spärlich ausgefüllt. Daher möchten wir Sie bitten, selbst ein paar Angaben zu Ihren vorangegangenen Schwangerschaften zu machen, sofern Sie vorher schon einmal schwanger waren. Diese Angaben können Sie hier verwalten.</p> 
          <Button component={Link} to="/questionnaires/individual-previous-pregnancy" variant="contained" color="primary" disableElevation>Weitere Schwangerschaft hinzufügen</Button>
          <ul className={classes.root}>
            { previousPregnancies.map((pregnancy, idx) => {
              if(pregnancy.data && JSON.parse(pregnancy.data) && JSON.parse(pregnancy.data).app_prepreg_id) {
                return(
                  <Grid component="li" key={idx} style={{display: "flex", alignItems: "center"}}>
                    <Grid item>
                      Schwangerschaft { JSON.parse(pregnancy.data).app_prepreg_id } (
                        { new Date(JSON.parse(pregnancy.data).app_prepreg_date).getFullYear() }, { " " }
                        {
                          ( JSON.parse(pregnancy.data).app_prepreg_end == 1 ) &&
                            <>Kind wurde lebend geboren</>
                        }
                        {
                          ( JSON.parse(pregnancy.data).app_prepreg_end == 2 ) &&
                            <>Kind ist kurz nach der Geburt verstorben</>
                        }
                        {
                          ( JSON.parse(pregnancy.data).app_prepreg_end == 3 ) &&
                            <>Kind wurde tot geboren</>
                        }
                        {
                          ( JSON.parse(pregnancy.data).app_prepreg_end == 4 ) &&
                            <>Fehlgeburt</>
                        }
                        {
                          ( JSON.parse(pregnancy.data).app_prepreg_end == 5 ) &&
                            <>Schwangerschaftsabbruch</>
                        }
                      )
                    </Grid>
                    <Grid item style={{marginLeft: "auto"}}>
                      <Button variant="contained" color="primary" size="small" disableElevation onClick={() => deletePregnancy(pregnancy.id)}>
                        Eintrag entfernen
                      </Button>
                    </Grid>
                  </Grid>
                );  
              }
            })
            }
          </ul>
        </Container>
      </main>
      <Footer />
    </>
  );
}