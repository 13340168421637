import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { Link } from "react-router-dom";
import StudyContactData from 'components/StudyContactData/StudyContactData';



export default function ForgotPassword() {

  return (
    <main>
      <Grid container spacing={1} direction="column" alignItems="center" justify="center" style={{ minHeight: '90vh', color: "#783476" }}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography variant="h1">Passwort vergessen</Typography>
          <p>
            Sie haben Ihr Passwort vergessen? Bitte wenden Sie sich an die Studienzentrale:
          </p>
          <StudyContactData />
          <Button component={Link} to="/" variant="contained" color="primary" size="small" disableElevation>
            <KeyboardArrowLeft />
            Zurück
          </Button>
        </Grid>
      </Grid>
    </main>
  );
}