import React from 'react';

import Header from '../../../components/Header/Header';
import Footer from 'components/Footer/Footer';

import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import BackToMindfulnessOverviewButton from '../BackToMindfulnessOverviewButton';



export default function MindfulnessIntroduction() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            <Typography variant="h2">Achtsamkeit: Einführung</Typography>
            <BackToMindfulnessOverviewButton />
            <p>
              Achtsamkeit bedeutet, den aktuellen Moment bewusst wahrzunehmen, mit allem was dazu gehört: Sinneseindrücke, körperliche Empfindungen, aufkommende Gedanken und Gefühle… Einfach alles, was mit einem selbst und um einen herum geschieht. Achtsamkeit bedeutet aber auch, das Wahrgenommene nicht sofort zu bewerten, sondern offen und unvoreingenommen zu sein. Das fällt vielen Menschen schwer und muss geübt werden.
            </p>
            <p>
              In der SMART Start Studie stellen wir Ihnen eine Reihe von Audios zur Verfügung, mit denen Sie Achtsamkeit trainieren können. Entscheiden Sie selbst, welche Übungen Sie machen möchten. Wir geben Ihnen bewusst keinen festen Plan vor. So können Sie die Übungen flexibel in Ihren Alltag integrieren, ganz wie Sie es möchten oder brauchen.
            </p>
          </Container>
        </main>
        <Footer/>
      </>
    );
}