import React from "react";
import './UEQItem.scss'

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { FormControlLabel, FormGroup, Radio, Typography } from '@material-ui/core';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';


export default function SUSItem(props) {
  return (
    <>
      <FormControl className="sus-group" component="fieldset" fullWidth={true}>
        <CustomErrorMessage name={props.name} />
        <div className="ueq-labels">
          <span className="left-label">
            {props.left}
          </span>
          <span className="right-label">
            {props.right}
          </span>
        </div>
        <RadioGroup className="sus-answers" aria-label="position" name={props.name} value={props.value} onChange={props.onChange} required={true}>
          <div>
            <FormControlLabel value="1" control={<Radio color="primary" />} />
          </div>
          <div>
            <FormControlLabel value="2" control={<Radio color="primary" />} />
          </div>
          <div>
            <FormControlLabel value="3" control={<Radio color="primary" />} />
          </div>
          <div>
            <FormControlLabel value="4" control={<Radio color="primary" />} />
          </div>
          <div>
            <FormControlLabel value="5" control={<Radio color="primary" />} />
          </div>
          <div>
            <FormControlLabel value="6" control={<Radio color="primary" />} />
          </div>
          <div>
            <FormControlLabel value="7" control={<Radio color="primary" />} />
          </div>
        </RadioGroup>
      </FormControl>
    </>
  );
}