import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import AuthContext from 'services/AuthContext';


function PrivateRoute (props) {
   const auth = useContext(AuthContext);
   const { component: Component, ...rest } = props;

   if(!auth.tokenExpired()){
      return ( <Route {...rest} render={(props) => 
        (<Component {...props}/>)
        }
        />
    )}
   //redirect if token has expired 
   console.log("Token expired or not set");
   return <Redirect to='/' />
}

export default PrivateRoute