import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';

import withNotifications from 'services/NotificationService/withNotifications';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import '../Questionnaire.scss';
import QuestionnaireHeadNotice from 'components/QuestionnaireHeadNotice/QuestionnaireHeadNotice';
import UEQItem from './UEQItem/UEQItem';
import ueqsample from './ueqsample.png';



//import Textfield from '../../components/Textfield/Textfield';
//import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';


class UEQ extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header backButton={false}>
          <Typography variant="h2">UEQ</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/2) * 100 } />
          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />

          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "ueq01": 1,
                "ueq02": 1,
                "ueq03": 1,
                "ueq04": 1,
                "ueq05": 1,
                "ueq06": 1,
                "ueq07": 1,
                "ueq08": 1,
                "ueq09": 1,
                "ueq10": 1,
                "ueq11": 1,
                "ueq12": 1,
                "ueq13": 1,
                "ueq14": 1,
                "ueq15": 1,
                "ueq16": 1,
                "ueq17": 1,
                "ueq18": 1,
                "ueq19": 1,
                "ueq20": 1,
                "ueq21": 1,
                "ueq22": 1,
                "ueq23": 1,
                "ueq24": 1,
                "ueq25": 1,
                "ueq26": 1,
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <div style={{textAlign: "center", marginTop: "1em"}}>
                  <Typography variant="h2">Bitte geben Sie Ihre Beurteilung ab.</Typography>
                  <p>
                    Um die SMART Start-App zu bewerten, füllen Sie bitte den nachfolgenden Fragebogen aus. Er besteht aus Gegensatzpaaren von Eigenschaften, die das Produkt haben kann. Abstufungen zwischen den Gegensätzen sind durch Kreise dargestellt. Durch Ankreuzen eines dieser Kreise können Sie Ihre Zustimmung zu einem Begriff äußern.
                  </p>
                  <p>
                    <b>Beispiel:</b><br/>
                    <img style={{width: "100%;", height: "auto", padding: "1em" }} src={ueqsample} />
                  </p>
                  <p>
                    Mit dieser Beurteilung sagen Sie aus, dass Sie das Produkt eher attraktiv als unattraktiv einschätzen.</p>
                  <p>
                    Entscheiden Sie möglichst spontan. Es ist wichtig, dass Sie nicht lange über die Begriffe nachdenken, damit Ihre unmittelbare Einschätzung zum Tragen kommt.
                  </p>
                  <p>
                    Bitte kreuzen Sie immer eine Antwort an, auch wenn Sie bei der Einschätzung zu einem Begriffspaar unsicher sind oder finden, dass es nicht so gut zum Produkt passt.
                  </p>
                  <p>
                    Es gibt keine „richtige“ oder „falsche“ Antwort. Ihre persönliche Meinung zählt!
                  </p>
                  <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Fragebogen starten</Button>
                </div>
              </StepperPage>
            </Container>

            <div className="questionnaire-body">
              <Container maxWidth="lg">
                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <p>Bitte geben Sie nun Ihre Einschätzung der SMART Start-App ab. Kreuzen Sie bitte nur einen Kreis pro Zeile an.</p>
                  <UEQItem name="ueq01" value={this.props.values.ueq01} left="unerfreulich" right="erfreulich" onChange={this.props.handleChange} />
                  <UEQItem name="ueq02" value={this.props.values.ueq02} left="unverständlich" right="verständlich" onChange={this.props.handleChange} />
                  <UEQItem name="ueq03" value={this.props.values.ueq03} left="kreativ" right="phantasielos" onChange={this.props.handleChange} />
                  <UEQItem name="ueq04" value={this.props.values.ueq04} left="leicht zu lernen" right="schwer zu lernen" onChange={this.props.handleChange} />
                  <UEQItem name="ueq05" value={this.props.values.ueq05} left="wertvoll" right="minderwertig" onChange={this.props.handleChange} />
                  <UEQItem name="ueq06" value={this.props.values.ueq06} left="langweilig" right="spannend" onChange={this.props.handleChange} />
                  <UEQItem name="ueq07" value={this.props.values.ueq07} left="uninteressant" right="interessant" onChange={this.props.handleChange} />
                  <UEQItem name="ueq08" value={this.props.values.ueq08} left="unberechenbar" right="voraussagbar" onChange={this.props.handleChange} />
                  <UEQItem name="ueq09" value={this.props.values.ueq09} left="schnell" right="langsam" onChange={this.props.handleChange} />
                  <UEQItem name="ueq10" value={this.props.values.ueq10} left="originell" right="konventionell" onChange={this.props.handleChange} />
                  <UEQItem name="ueq11" value={this.props.values.ueq11} left="behindernd" right="unterstützend" onChange={this.props.handleChange} />
                  <UEQItem name="ueq12" value={this.props.values.ueq12} left="gut" right="schlecht" onChange={this.props.handleChange} />
                  <UEQItem name="ueq13" value={this.props.values.ueq13} left="kompliziert" right="einfach" onChange={this.props.handleChange} />
                  <UEQItem name="ueq14" value={this.props.values.ueq14} left="abstoßend" right="anziehend" onChange={this.props.handleChange} />
                  <UEQItem name="ueq15" value={this.props.values.ueq15} left="herkömmlich" right="neuartig" onChange={this.props.handleChange} />
                  <UEQItem name="ueq16" value={this.props.values.ueq16} left="unangenehm" right="angenehm" onChange={this.props.handleChange} />
                  <UEQItem name="ueq17" value={this.props.values.ueq17} left="sicher" right="unsicher" onChange={this.props.handleChange} />
                  <UEQItem name="ueq18" value={this.props.values.ueq18} left="aktivierend" right="einschläfernd" onChange={this.props.handleChange} />
                  <UEQItem name="ueq19" value={this.props.values.ueq19} left="erwartungskonform" right="nicht erwartungskonform" onChange={this.props.handleChange} />
                  <UEQItem name="ueq20" value={this.props.values.ueq20} left="ineffizient" right="effizient" onChange={this.props.handleChange} />
                  <UEQItem name="ueq21" value={this.props.values.ueq21} left="übersichtlich" right="verwirrend" onChange={this.props.handleChange} />
                  <UEQItem name="ueq22" value={this.props.values.ueq22} left="unpragmatisch" right="pragmatisch" onChange={this.props.handleChange} />
                  <UEQItem name="ueq23" value={this.props.values.ueq23} left="aufgeräumt" right="überladen" onChange={this.props.handleChange} />
                  <UEQItem name="ueq24" value={this.props.values.ueq24} left="attraktiv" right="unattraktiv" onChange={this.props.handleChange} />
                  <UEQItem name="ueq25" value={this.props.values.ueq25} left="sympathisch" right="unsympathisch" onChange={this.props.handleChange} />
                  <UEQItem name="ueq26" value={this.props.values.ueq26} left="konservativ" right="innovativ" onChange={this.props.handleChange} />

                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                    <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                  </div>

                  <ForwardBackwardButtons handleBack={this.handleBack} />
                </StepperPage>
                
                {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}       
              </Container>  
            </div>
          </form>
        </main>
      <Footer showMenu={false} />
    </>
    );
  }
}

export default withNotifications(withFormik({
  mapPropsToValues: () => ({
    ueq01: '',
    ueq02: '',
    ueq03: '',
    ueq04: '',
    ueq05: '',
    ueq06: '',
    ueq07: '',
    ueq08: '',
    ueq09: '',
    ueq10: '',
    ueq11: '',
    ueq12: '',
    ueq13: '',
    ueq14: '',
    ueq15: '',
    ueq16: '',
    ueq17: '',
    ueq18: '',
    ueq19: '',
    ueq20: '',
    ueq21: '',
    ueq22: '',
    ueq23: '',
    ueq24: '',
    ueq25: '',
    ueq26: '',
  }),  
  validationSchema: Yup.object().shape({
    ueq01: Yup.number().required('Pflichtfeld'),
    ueq02: Yup.number().required('Pflichtfeld'),
    ueq03: Yup.number().required('Pflichtfeld'),
    ueq04: Yup.number().required('Pflichtfeld'),
    ueq05: Yup.number().required('Pflichtfeld'),
    ueq06: Yup.number().required('Pflichtfeld'),
    ueq07: Yup.number().required('Pflichtfeld'),
    ueq08: Yup.number().required('Pflichtfeld'),
    ueq09: Yup.number().required('Pflichtfeld'),
    ueq10: Yup.number().required('Pflichtfeld'),
    ueq11: Yup.number().required('Pflichtfeld'),
    ueq12: Yup.number().required('Pflichtfeld'),
    ueq13: Yup.number().required('Pflichtfeld'),
    ueq14: Yup.number().required('Pflichtfeld'),
    ueq15: Yup.number().required('Pflichtfeld'),
    ueq16: Yup.number().required('Pflichtfeld'),
    ueq17: Yup.number().required('Pflichtfeld'),
    ueq18: Yup.number().required('Pflichtfeld'),
    ueq19: Yup.number().required('Pflichtfeld'),
    ueq20: Yup.number().required('Pflichtfeld'),
    ueq21: Yup.number().required('Pflichtfeld'),
    ueq22: Yup.number().required('Pflichtfeld'),
    ueq23: Yup.number().required('Pflichtfeld'),
    ueq24: Yup.number().required('Pflichtfeld'),
    ueq25: Yup.number().required('Pflichtfeld'),
    ueq26: Yup.number().required('Pflichtfeld'),
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "ueq",
      questionnaireData: values,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
    });
  }
})(UEQ));