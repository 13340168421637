import React from 'react';

import Header from '../../../components/Header/Header';
import Footer from 'components/Footer/Footer';

import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import BackToMindfulnessOverviewButton from '../BackToMindfulnessOverviewButton';

import yoga6b from './6b_yoga_stehen.mp3';
import yoga11a from './11a_yoga_boden.mp3'
import AudioPlayer from '../AudioPlayer';

export default function Yoga() {
    return (
      <>
        <Header>
          <Typography variant="h2">Yoga</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            <Typography variant="h2">Yoga</Typography>
            <BackToMindfulnessOverviewButton />
            <Typography variant="h3" style={{marginTop: "1em"}}>Einleitung</Typography>
            <p>
            Yoga ist im Vergleich zum Bodyscan eine aktivere Form der Körperübung, die sich sehr gut dafür eignet, Achtsamkeit zu üben. Erspüren Sie Ihre Atmung, die sanften Dehnungen und Kontraktionen Ihrer Muskeln und die Entspannung danach.</p>
            <p>
            Ziehen Sie sich für diese Übungen an einen ruhigen Platz zurück und tragen Sie bequeme Kleidung. Stellen oder setzen Sie sich auf eine Unterlage, z.B. eine Yogamatte oder eine Decke.
            </p>
            <Typography variant="h3">Audio-Dateien</Typography>
            <AudioPlayer title="Yoga im Stehen (Dauer ca. 13 Minuten)" src={yoga6b} />
            <AudioPlayer title="Yoga am Boden (Dauer ca. 12 Minuten)" src={yoga11a} />
          </Container>
        </main>
        <Footer/>
      </>
    );
}