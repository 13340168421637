import React from 'react';
import axios from 'axios';

import Globals from 'services/Globals';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import useActivities from 'services/Activities/Activities';

import { Link } from "react-router-dom";

import ActivityItem from '../../components/ActivityItem/ActivityItem';
import { faBell, faCalendar, faCalendarAlt, faCalendarWeek, faClipboardList, faCogs, faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TopSpacer from 'components/TopSpacer/TopSpacer';
import { useTheme } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';



const useStyles = makeStyles(theme => ({
  root: {
    padding: "0",
    "& li": {
      listStyle: "none",
      padding: "1em",
      backgroundColor: theme.palette.primary.main,
      margin: "0.5em auto",
      color: "",
      fontSize: "1.2em",
      cursor: "pointer",
      "& a": {
        color: "#FFF",
        textDecoration: "none",
      },
    }
  },
}));


export default function Tasks() {
  const activities = useActivities();
  const classes = useStyles();
  const theme = useTheme(); 

  return (
    <>
      <Header>
        <Typography variant="h2">Aufgaben</Typography>
      </Header>
      <main>
        <Container maxWidth="lg">
          <TopSpacer />
          <Typography variant="h2">Meine aktuellen Aufgaben</Typography>
          <p>Wir bitten Sie zum Beispiel in <b>regelmäßigen Abständen</b> um das Ausfüllen von Fragebögen und andere Aufgaben. Einige Aufgaben sind auch <b>mehrfach und regelmäßig</b> (z.B. alle vier Wochen) zu bearbeiten.</p> 
          { activities.activities && activities.activityState === "pregnancy" &&
            <Paper variant="outlined" style={{padding: "1em", marginBottom: "1em"}}>
            Ihre Geburt hat stattgefunden? <Link to="/setup/delivery-note">Zur Geburtsmeldung</Link>
            </Paper>
          }
          <Typography variant="h3">
            Offene Aufgaben
          </Typography>
          <ul className={classes.root}>
            { activities.activities && activities.activities.initialQuestionnaire && 
              <ActivityItem icon={faCogs} title="Einstiegs-Fragebogen" to="/setup/initial-questionnaire"></ActivityItem>
            }
            { activities.activities && activities.activities.withingsSetup && 
              <ActivityItem icon={faCogs} title="Geräte-Ersteinrichtung" to="/setup/withings-devices"></ActivityItem>
            }
            { activities.activities && activities.activities.appInstallation && 
              <ActivityItem icon={faCogs} title="App-Installation" to="/setup/a2hs"></ActivityItem>
            }
            { activities.activities && activities.activities.ppaq && 
              <ActivityItem icon={faClipboardList} title="PPAQ" to="/questionnaires/ppaq"></ActivityItem>
            }
            { activities.activities && activities.activities.epds && 
              <ActivityItem icon={faClipboardList} title="EPDS" to="/questionnaires/epds"></ActivityItem>
            }
            { activities.activities && activities.activities.psqi && 
              <ActivityItem icon={faClipboardList} title="PSQI" to="/questionnaires/psqi"></ActivityItem>
            }
            { activities.activities && activities.activities.mapi && 
              <ActivityItem icon={faClipboardList} title="MAPI" to="/questionnaires/mapi"></ActivityItem>
            }
            { activities.activities && activities.activities.phbs && 
              <ActivityItem icon={faClipboardList} title="PHBS" to="/questionnaires/phbs"></ActivityItem>
            }
            { activities.activities && activities.activities.gesoek1 && 
              <ActivityItem icon={faClipboardList} title="Gesundheitsökonomie-Befragung (1)" to="/questionnaires/gesoek-1"></ActivityItem>
            }
            { activities.activities && activities.activities.gesoek2 && 
              <ActivityItem icon={faClipboardList} title="Gesundheitsökonomie-Befragung (2)" to="/questionnaires/gesoek-2"></ActivityItem>
            }
            { activities.activities && activities.activities.css_15 && 
              <ActivityItem icon={faClipboardList} title="CSS-15" to="/questionnaires/css-15"></ActivityItem>
            }
            { activities.activities && activities.activities.css_15_fu && 
              <ActivityItem icon={faClipboardList} title="CSS-15 (Follow Up)" to="/questionnaires/css-15-fu"></ActivityItem>
            }
            { activities.activities && activities.activities.ueq &&
              <ActivityItem icon={faClipboardList} title="UEQ" to="/questionnaires/ueq"></ActivityItem>
            }
            { activities.activities && activities.activities.sus &&
              <ActivityItem icon={faClipboardList} title="SUS" to="/questionnaires/sus"></ActivityItem>
            }
            { activities.activities && activities.activities.ux_bpm &&
              <ActivityItem icon={faClipboardList} title="Nutzungserfahrung Blutdruckmessung" to="/questionnaires/blood-pressure-usability"></ActivityItem>
            }
            { activities.activities && activities.activities.ux_scale &&
              <ActivityItem icon={faClipboardList} title="Nutzungserfahrung Waage" to="/questionnaires/scale-usability"></ActivityItem>
            }
            { activities.activities && activities.activities.ux_urine &&
              <ActivityItem icon={faClipboardList} title="Nutzungserfahrung Urinsticks" to="/questionnaires/urine-usability"></ActivityItem>
            }
            { activities.activities && activities.activities.ux_watch &&
              <ActivityItem icon={faClipboardList} title="Nutzungserfahrung Smartwatch" to="/questionnaires/smart-watch-usability"></ActivityItem>
            }
            { activities.activities && activities.activities.ux_sleep &&
              <ActivityItem icon={faClipboardList} title="Nutzungserfahrung Schlafmatte" to="/questionnaires/sleep-usability"></ActivityItem>
            }
            { activities.activities && activities.activities.socialdemographics &&
              <ActivityItem icon={faClipboardList} title="Soziodemografie-Fragebogen" to="/questionnaires/social-demographics"></ActivityItem>
            }
            { activities.activities && activities.activities.previouspregnancies &&
              <ActivityItem icon={faClipboardList} title="Frühere Schwangerschaften" to="/questionnaires/previous-pregnancies"></ActivityItem>
            }
            { activities.activities && activities.activities.maternity_record_photo && 
              <ActivityItem icon={faNotesMedical} title="Mutterpass-Foto aufnehmen" to="/photos/maternity-record-photo"></ActivityItem>
            }
            { activities.activities && activities.activities.urinalysis && 
              <ActivityItem icon={faNotesMedical} title="Urin-Analyse" to="/photos/urinalysis"></ActivityItem>
            }
            { activities.activities && activities.activities.calendar_invites_setup && 
              <ActivityItem icon={faCalendarAlt} title="Einrichtung Benachrichtigungen" to="/setup/calendar-invites"></ActivityItem>
            }

            { activities.activityState && activities.activityState === "finished" &&
              <Card style={{backgroundColor: theme.palette.primary.main, color: "white"}}>
                <CardContent>
                <p>Liebe Studienteilnehmerin,</p>
                <p>Sie haben alle Fragebögen abgeschlossen.<br/>
                Herzlichen Dank für Ihre Teilnahme an der SMART Start-Studie. <br/>
                Wir wünschen Ihnen und Ihrer Familie alles Gute!</p>
                <p>Ihr Team des Universitätsklinikum Erlangen</p>
                </CardContent>
              </Card>
            }

            {
              /*
              Stuff that is apparently no longer needed
              { activities.pss10 && 
              <ActivityItem icon={faClipboardList} title="PSS-10" to="/questionnaires/pss-10"></ActivityItem>
              }
              { activities.praqr2 && 
                <ActivityItem icon={faClipboardList} title="PRAQ-R2" to="/questionnaires/praq-r2"></ActivityItem>
              }
              */
            }
            { activities.activities && Object.keys(activities.activities).length === 0 &&
              <div>Alle Aufgaben erledigt :-)</div>
            }

          </ul>
          <span style={{fontSize: "0.6em"}}>Technische Information: "{ activities.activityState }"</span>
        </Container>
      </main>
      <Footer />
    </>
  );
}