import React from 'react';

import Header from '../../../components/Header/Header';
import Footer from 'components/Footer/Footer';

import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import BackToMindfulnessOverviewButton from '../BackToMindfulnessOverviewButton';

import sos14b from './14b_sos.mp3';
import AudioPlayer from '../AudioPlayer';

export default function MindfulnessSos() {
    return (
      <>
        <Header>
          <Typography variant="h2">Achtsamkeits-SOS</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            <Typography variant="h2">Achtsamkeits-SOS</Typography>
            <BackToMindfulnessOverviewButton />
            <Typography variant="h3" style={{marginTop: "1em"}}>Achtsamkeits-SOS</Typography>
            <p>
            Dieses gut 5-minütige Audio soll Ihnen schnelle Hilfe in einer akuten Stresssituation liefern.
            </p>
            <Typography variant="h3">Audio-Dateien</Typography>
            <AudioPlayer title="Achtsamkeits-SOS" src={sos14b} />
          </Container>
        </main>
        <Footer/>
      </>
    );
}