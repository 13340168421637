import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Input, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom'


import MuiDialogActions from '@material-ui/core/DialogActions';


import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import withNotifications from 'services/NotificationService/withNotifications';

import '../Questionnaire.scss';
import QuestionnaireHeadNotice from 'components/QuestionnaireHeadNotice/QuestionnaireHeadNotice';


//import Textfield from '../../components/Textfield/Textfield';
//import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';


class PreviousPregnancies extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Vorherige Schwangerschaften</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/3) * 100 } />

          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.finishedDialogOpen} setStatus={this.props.setStatus} />

          <Dialog aria-labelledby="simple-dialog-title" open={this.props.status.nextStepDialogOpen}>
            <DialogTitle id="simple-dialog-title">Eingaben gespeichert</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Bitte machen Sie im nächsten Schritt genauere Angaben zu Ihren früheren Schwangerschaften.
              </DialogContentText>
            </DialogContent>
            <MuiDialogActions>
              <Button component={Link} to="/questionnaires/individual-previous-pregnancy" autoFocus color="primary">
                Angaben vornehmen
              </Button>
            </MuiDialogActions>
          </Dialog>

          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "app_prepreg": 0,
                "app_gravida": 1,
                "app_preterm": 2,
                "app_miscarriage": 2,
                "app_abort": 2,
                "app_ectopic": 2,
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <p>
                  Der Abschnitt zu vorherigen Schwangerschaften ist im Mutterpass häufig spärlich ausgefüllt. Daher möchten wir Sie bitten, selbst ein paar Angaben zu Ihren vorangegangenen Schwangerschaften zu machen, sofern Sie vorher schon einmal schwanger waren.<br/><br/>
                </p>
                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="app_prepreg">Sind Sie zum ersten Mal schwanger?</FormLabel>
                  <CustomErrorMessage name="app_prepreg" />
                  <RadioGroup aria-label="position" name="app_prepreg" value={this.props.values.app_prepreg} onChange={this.props.handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Ja, ich bin zum ersten Mal schwanger" labelPlacement="end"/>
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein, ich war vorher schon einmal schwanger" labelPlacement="end"/>
                  </RadioGroup>
                </FormControl>
                <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
              </StepperPage>
            </Container>

            { this.state.activeStep >= 1 &&
              <QuestionnaireHeadNotice>
                Meine Schwangerschaften im Überblick
              </QuestionnaireHeadNotice>
            }
            <div className="questionnaire-body">
              <Container maxWidth="lg">
                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  { this.props.values.app_prepreg === "" &&
                    <>
                      <p>Bitte gehen Sie zur vorherigen Seite und beantworten Sie die Frage, um fortzufahren.</p>
                      <ForwardBackwardButtons handleBack={this.handleBack} />
                    </>
                  }
                  { this.props.values.app_prepreg === "1" && 
                    <>
                      <p>Vielen Dank. Da Sie zum ersten Mal schwanger sind, benötigen wir keine weiteren Angaben von Ihnen. Sie können den Fragebogen jetzt direkt absenden.</p>
                      <div style={{textAlign: "center", marginBottom: "1em"}}>
                        <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                      </div>
                      <ForwardBackwardButtons handleBack={this.handleBack} />
                    </>
                  }
                  { this.props.values.app_prepreg === "0" && 
                    <>
                      <FormControl component="fieldset" fullWidth={true}>
                        <FormLabel component="legend">Wie oft sind Sie bereits schwanger gewesen (inklusive Geburten, Frühgeburten,Fehlgeburten, Schwangerschaftenaußerhalb der Gebärmutter undSchwangerschaftsabbrüchen)?</FormLabel>
                          <CustomErrorMessage name="app_gravida" />
                          <Input
                            name="app_gravida"
                            value={this.props.values.app_gravida}
                            onChange={this.props.handleChange}
                            type="number"
                          />
                      </FormControl>
                      <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                    </>
                  }
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={2}>
                    <p>
                      Bitte geben Sie hier <b><u>alle</u></b> Schwangerschaften an, unabhängig davon, wie sie ausgegangen sind (also z.B. auch Fehl- oder Frühgeburten, Schwangerschaften außerhalb der Gebärmutter und Schwangerschaftsabbrüche).
                    </p>
                    <div>
                      <FormControl component="fieldset" fullWidth={true}>
                        <FormLabel component="legend">Wie viele Frühgeburten, also Geburten vor der 37. Schwangerschaftswoche, hatte Sie?</FormLabel>
                        <CustomErrorMessage name="app_preterm" />
                        <Input
                          name="app_preterm"
                          value={this.props.values.app_preterm}
                          onChange={this.props.handleChange}
                          type="number"
                        />
                      </FormControl>
                    </div>
                    <div>
                      <FormControl component="fieldset" fullWidth={true}>
                        <FormLabel component="legend">Wie viele Fehlgeburten hatten Sie?</FormLabel>
                        <CustomErrorMessage name="app_miscarriage" />
                        <Input
                          name="app_miscarriage"
                          value={this.props.values.app_miscarriage}
                          onChange={this.props.handleChange}
                          type="number"
                        />
                      </FormControl>
                    </div>
                    <div>
                      <FormControl component="fieldset" fullWidth={true}>
                        <FormLabel component="legend">Wie viele Schwangerschaftsabbrüche hatten Sie?</FormLabel>
                        <CustomErrorMessage name="app_abort" />
                        <Input
                          name="app_abort"
                          value={this.props.values.app_abort}
                          onChange={this.props.handleChange}
                          type="number"
                        />
                      </FormControl>
                    </div>
                    <div>
                      <FormControl component="fieldset" fullWidth={true}>
                        <FormLabel component="legend">Wie viele Schwangerschaften außerhalb der Gebärmutter (z.B. Eileiterschwangerschaften) hatten Sie?</FormLabel>
                        <CustomErrorMessage name="app_ectopic" />
                        <Input
                          name="app_ectopic"
                          value={this.props.values.app_ectopic}
                          onChange={this.props.handleChange}
                          type="number"
                        />
                      </FormControl>
                    </div>
                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                    <Button type="submit" variant="contained" color="primary" disableElevation>Eingaben speichern</Button>
                  </div>

                  <ForwardBackwardButtons handleBack={this.handleBack} />
                </StepperPage>

                {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}
              </Container>  
            </div>
          </form>
        </main>
      <Footer showMenu={false} />
    </>
    );
  }
}

export default withNotifications(withFormik({
  mapPropsToValues: () => ({
    app_prepreg: '',
    app_gravida: '',
    app_preterm: '',
    app_miscarriage: '',
    app_abort: '',
    app_ectopic: '',
  }),  
  validationSchema: Yup.object().shape({
    app_prepreg: Yup.number().required('Pflichtfeld'),
    app_gravida: Yup.number().when('app_prepreg', {
      is: '0',
      then: Yup.number().required()
    }),
    app_preterm: Yup.number().when('app_prepreg', {
      is: '0',
      then: Yup.number().required()
    }),
    app_miscarriage: Yup.number().when('app_prepreg', {
      is: '0',
      then: Yup.number().required()
    }),
    app_abort: Yup.number().when('app_prepreg', {
      is: '0',
      then: Yup.number().required()
    }),
    app_ectopic: Yup.number().when('app_prepreg', {
      is: '0',
      then: Yup.number().required()
    }),
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "previouspregnancies",
      questionnaireData: values,
    }).then(function(res){
      // First time pregnant -> back to main menu
      if(values.app_prepreg === "1") {
        props.setStatus({
          finishedDialogOpen: true
        })
      }
      // Pregnant in the past -> go to detailed pregnancies
      else {
        props.setStatus({
          nextStepDialogOpen: true
        })
      }
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Fragebogendaten aufgetreten.", "error", 0, err);
    });
  }
})(PreviousPregnancies));