import React from 'react';

export default function StudyContactData() {
  return (
    <>
      <div>
        <b>Frauenklinik des Universitätsklinikums Erlangen</b><br/>
        Universitätsstraße 21/23<br/>
        91054 Erlangen<br/>
        Telefon: <a href="tel:004991318544978">09131 85-44978</a><br/>
        E-Mail: <a href="mailto:fk-info-smartstart@uk-erlangen.de">fk-info-smartstart@uk-erlangen.de</a><br/>
        <small>
          Sie erreichen das Studienteam Mo-Do von 08:00-16:30 Uhr sowie Freitags von 08:00-15:00 Uhr.
        </small>
      </div><br/>
    </>
  )
}
