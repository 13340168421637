import React from 'react';

import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogActions from '@material-ui/core/DialogActions';


export default class QuestionnaireSubmittedDialogObfuscation extends React.Component {

  /*closeDialog() {
    this.props.setStatus({
      dialogOpen: false
    })
  }*/

  render(props) {
    return (
      <Dialog aria-labelledby="simple-dialog-title" open={this.props.dialogOpen}>
        <DialogTitle id="simple-dialog-title">Fragebogen erfolgreich abgeschickt</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>Bitte beachten Sie: Dieser Fragebogen wird <b>dauerhaft</b> unter "Meine Messwerte" <b>angezeigt</b>.</p>
            <p>Beantworten Sie ihn gerne <b>immer dann, wenn Ihnen etwas neues auffällt</b> oder Sie uns <b>Feedback zur Anzeige geben</b> möchten.</p>

          </DialogContentText>
        </DialogContent>
        <MuiDialogActions>
          <Button component={Link} to="/measurements" autoFocus color="primary">
            Zurück zu "meinen Messwerten"
          </Button>
        </MuiDialogActions>
      </Dialog>
    );
  }
}