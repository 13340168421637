import React from 'react';
import Menu from '../Menu/Menu';
import MenuToggle from '../MenuToggle/MenuToggle';

import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faBars, faCaretSquareLeft } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';

import './Header.scss';
import { Button, Container } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';

export default function Header(props) {
  const theme = useTheme(); 
  const history = useHistory();
  const goBackButtonVisible = true;

  if(props.backButton && props.backButton === false) {
    goBackButtonVisible = false;
  }
  return (
    <header style={{background: theme.palette.primary.main, color: '#fff'}}>
      <Container maxWidth="lg">
        { goBackButtonVisible && 
          <div onClick={() => {history.goBack()}} style={{display: "inline-block", verticalAlign: "middle" }}>         
            <KeyboardArrowLeft style={{marginLeft: "-10px", marginRight: "10px"}} />
          </div>
        }
        <div style={{display: "inline-block" }}>
        {
          props.children
        }
        </div>
      </Container>
    </header>
  );
}
