import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Typography } from '@material-ui/core';
import { TabPanel } from "components/TabPanel/TabPanel";

import Header from "components/Header/Header";
import DataList from "components/DataList/DataList";

import UltrasoundList from "./components/UltrasoundList";
import UltrasoundEditScreen from "./components/UltrasoundEditScreen";
import UltrasoundBasicExaminations from "./components/UltrasoundBasicExaminations";

import Container from "@material-ui/core/Container";
import Footer from "components/Footer/Footer";

class Ultrasound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0
        };

        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(event, newValue) {
        this.setState({
            activeTab: newValue
        });
    }

    render() {
        return (
            <>
                <Header>
                    <Typography variant="h2">Ultraschall</Typography>
                </Header>
                <AppBar position="static">
                    <Tabs
                        value={this.state.activeTab}
                        onChange={this.handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab value={0} label="Basis-Untersuchungen" />
                        <Tab value={1} label="Kontrolluntersuchungen" />
                        <Tab value={2} label="Weiterführende Untersuchungen" />
                        <Tab value={3} label="Dopplersonographische Untersuchungen" />
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.activeTab} index={0}>
                    <UltrasoundBasicExaminations />
                </TabPanel>
                <TabPanel value={this.state.activeTab} index={1}>
                    <main style={{marginTop: '2em'}}>
                        <Container>
                            <p>
                                <b>Ultraschall-Kontrolluntersuchungen</b> nach <b>Anlage 1 b</b> zu
                                den Mutterschafts-Richtlinien
                            </p>
                            <DataList
                                dbKey="ultrasound_control_examinations"
                                listItem={<UltrasoundList />}
                                editScreen={<UltrasoundEditScreen />}
                            />
                        </Container>
                    </main>
                </TabPanel>
                <TabPanel value={this.state.activeTab} index={2}>
                    <main style={{marginTop: '2em'}}>
                        <Container>
                            <p>
                                <b>Weiterführende Ultraschall-Untersuchungen</b> zur Abklärung und
                                Überwachung pathologischer Befunde nach <b>Anlage 1 c</b> zu den
                                Mutterschafts-Richtlinien
                            </p>
                            <DataList
                                dbKey="ultrasound_subsequent_examinations"
                                listItem={<UltrasoundList />}
                                editScreen={<UltrasoundEditScreen />}
                            />
                        </Container>
                    </main>
                </TabPanel>
                <TabPanel value={this.state.activeTab} index={3}>
                    <main style={{marginTop: '2em'}}>
                        <Container>
                            <p>
                                <b>Dopplersonographische</b> Untersuchungen nach <b>Anlage 1 d</b>
                            </p>
                            <DataList
                                dbKey="ultrasound_doppler_examinations"
                                listItem={<UltrasoundList />}
                                editScreen={<UltrasoundEditScreen />}
                            />
                        </Container>
                    </main>
                </TabPanel>
                <Footer />
            </>
        );
    }
}

export default Ultrasound;
