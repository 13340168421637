import React from "react";

import { Link } from 'react-router-dom'


import './Footer.scss';
import BottomNavbar from './BottomNavbar/BottomNavbar';


export default function Footer(props) {

  return (
    <>
      <BottomNavbar showMenu={props.showMenu} />
      <footer>
        <div className="legal">
          <Link to="/legal/imprint">Impressum</Link> <Link to="/legal/privacy">Datenschutz</Link> <Link to="/legal/terms-of-use">Nutzungsbedingungen</Link>
        </div>
        {props.children}
      </footer>
      <div style={{height: "50px"}} />  { /* Bottom navbar spacer */ }
    </>
  );
}
