import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class MaternityRecord extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Untersuchungen &amp; Vorsorge &gt; Der Mutterpass</div>
            
            <Typography variant="h1">Der Mutterpass</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Hier erfahren Sie etwas über Ihren ständigen Begleiter, der gleichzeitig das medizinische Tagebuch für Ihre Schwangerschaft ist.</b></p>

            <Typography variant="h2">Zentraler Bestandteil der Schwangerschaftsvorsorge</Typography>
            <p>Zu Beginn der Schwangerschaft erhält jede Patientin bei Ihrem Frauenarzt/Ihrer Frauenärztin einen Mutterpass. Dies ist das wichtigste Dokument für die kommenden Wochen und Monate. Bei Ihrem Einschluss in die Studie haben wir ihn uns bereits einmal angeschaut. Der Mutterpass sollte bei jedem Arztbesuch vorgelegt werden und gehört stets in Ihre Handtasche. Er ist ein Terminplaner für die gesamte Schwangerschaft, in dem die Untersuchungsergebnisse von  jedem Arzttermin, Ihre persönlichen Schwangerschaftsrisiken, Ihre Blutgruppe und Immunstatus notiert sind. Er ist das Dokumentations- und Kontrollsystem für sowohl Ihre als auch die Gesundheit Ihres Kindes. 1968 wurde er erstmals von dem Gemeinsamen Bundesausschuss der Ärzte und Krankenkassen herausgegeben und ist seither ein treuer Begleiter jeder Schwangeren in Deutschland. Ziel des Mutterpasses ist es durch eine regelmäßige Schwangerenvorsorge Risiken zu vermeiden bzw. rasch zu erkennen. Wichtig für Sie ist es zu wissen,  dass Ihr Mutterpass ein persönliches Dokument ist und Sie entscheiden, wer Einsicht darin hat. Ihr Arbeitgeber oder Behörden dürfen, wie im Mutterpass beschrieben, keine Einsicht verlangen. </p>
            
            <Typography variant="h2">Schritt für Schritt durch den Mutterpass</Typography>
            <p>Nehmen Sie jetzt gerne Ihren Mutterpass zur Hand. Dann können Sie disen Artikel besser verfolgen.</p>
            <Typography variant="h3">Die erste Seite</Typography>
            <p>Gleich beim Aufblättern erkennt man neben Tipps und einer Anleitung auf der allerersten Seite Ihren behandelnden Arzt. Dieser sollte auf Seite eins in das vorgegebene Feld bereits seinen Stempel gemacht haben. Darüber hinaus ist hier Ihr nächster Termin zur Vorsorgeuntersuchung vermerkt. </p>
            <Typography variant="h3">Antikörper-Untersuchungen</Typography>
            <p>Nach Umblättern auf Seite 2 und 3 wird neben Ihrer Blutgruppe und dem Rhesusfaktor und einem Antikörpersuchtest der Röteln-Titer dargestellt. Jede Schwangere wird zu Beginn der Schwangerschaft untersucht, ob sie gegen Röteln immun ist, eine Chlamydieninfektion oder eine Syphiliserkrankung  vorliegt beziehungsweise irgendwann vorlag. Diese Infektionen können beim Feten nämlich zu Fehlbildungen, Abort oder Frühgeburtsbestrebungen führen und sollten zügig behandelt werden,  falls sie vorliegen. </p>
            <p>Ebenfalls ist es empfohlen einen HIV-Test durchzuführen. Dieser Test ist freiwillig. Liegt eine unerkannte HIV-Infektion vor, so kann dies für Mutter und Kind ebenfalls schwere Folgen haben. Im Gegensatz kann bei der Diagnose einer HIV-Infektion durch den Beginn der richtigen Therapie das Risiko für die Mutter und eine Übertragung auf das Kind deutlich gesenkt werden. Im Mutterpass wird nur dokumentiert, ob der HIV-Test durchgeführt wurde. </p>
            <p>Das Ergebnis wird Ihnen aber mündlich mitgeteilt. </p>
            <p>Nach der 32. SSW wird hier auch der Immunstatus gegenüber Hepatits B notiert. Ist eine Frau Hepatitis B positiv, so wird das Kind direkt nach der Geburt geimpft. Dadurch kann auch hier meist verhindert werden, dass das Kind diese Infektion bekommt. </p>
            <Typography variant="h3">Vorherige Schwangerschaften und Risiken</Typography>
            <p>Auf Seite vier werden die vorrausgegangenen Schwangerschaften und Geburten beschrieben. Ihr persönliches Risikopotential wird auf Seite 5 definiert. Bei 75% der Schwangeren werden  Risiken vermerkt, somit ist es kein Grund beunruhigt zu sein, wenn hier ein „ja“ angekreuzt ist. Auf Seite 6 ist neben dem lang ersehnten Geburtstermin auch Platz für besondere Befunde, die in der Schwangerschaft erhoben werden. Für alle die sich nun fragen wie berechnet man denn eigentlich den Geburtstermin, hier nun eine Antwort. Hierfür gibt es die sogenannte Naegele-Regel. Der Geburtstermin ergibt sich somit aus dem Datum des ersten Tages der letzten Periodenblutung abzüglich 3 Monate plus 7 Tage plus 1 Jahr. Falls Ihr Zyklus  von den „normalen“ 28 Tagen abweichen sollte, dann müssen die abweichenden Tage dazugezählt bzw. abgezogen werden. Eine genauere Berechnung ist möglich, wenn man den exakten Tag der Befruchtung weiß. Somit wäre der Geburtstermin der Tag der Befruchtung minus 3 Monate minus 7 Tage plus 1 Jahr. Da am errechneten Termin jedoch nur 4% der Kinder zur Welt kommen und 66% eher 10 Tage vor bzw. danach ist dieser Wert nur ein Orientierungswert und kein festgelegter Zeitpunkt an dem Ihr Kind mit 100% Wahrscheinlichkeit zur Welt kommen wird. Der errechnete Geburtstermin wird in der Regel im ersten Trimenon durch die sonografische Vermessung Ihres Kindes mittels Scheitel-Steiß-Länge kontrolliert und ggf. angepasst. In dieser Phase wachsen alle Kinder ungefähr gleich schnell, daher ist hier eine Korrektur des errechneten Termines möglich.  </p>
            <Typography variant="h3">Das Gravidogramm</Typography>
            <p>Nun sind wir bei den wohl wichtigsten Seiten des Mutterpasses angekommen, eine ausfaltbare Doppelseite, die das sogennante Gravidogramm beinhaltet. Hier werden alle regelmäßig durchgeführten Untersuchungen notiert. Neben der Schwangerschaftswoche, dem höchsten Punkt der Gebärmutter, der Lage des Kindes, der Bewegung und der Herztöne werden auch wichtige klinische Parameter von Ihnen wie Gewicht, Blutdruck, Urinuntersuchung und die Ergebnisse der vaginalen Untersuchung festgehalten. Diese werden bei der Untersuchung beim Frauenarzt oder der betreuenden Hebamme erhoben und sollten auch im Pass eingetragen werden, damit Auffälligkeiten rasch festgestellt und ggf. behandelt werden können.</p>
            <Typography variant="h3">Stationäre Aufenthaltungen, CTG, Ultraschall</Typography>
            <p>Stationäre Behandlungen und CTG Befunde werden auf Seite 9 beschrieben. Die Doppelseiten 10 und 11 sind sehr wichtig, denn der Platz dient der Dokumentation der drei gesetzlich vorgeschriebenen Ultraschalluntersuchungen. Auf Seite 13 kann man das Wachstum Ihres Kindes in einer Wachstumskurve beschreiben. Es ist ein einfaches Diagramm mit der Schwangerschaftswoche auf der x-Achse und der mm Angabe der Scheitelsteißlänge, des biparietalen Kopfdurchmessers und des abdominalen Transversaldurchmessers. Diese sind neben der Femurlänge, also der Länge des Oberschenkelknochens, die wichtigsten Maßangaben Ihres Kindes. Dabei ist es wichtig, dass Ihr Kind perzentilengerecht wächst und dabei möglichst im Normbereich liegt, welcher durch die beiden im Diagramm beschriebenen Linien dargestellt wird.  Häufig haben die Frauenärztinnen und Ärzte diese  Wachstumskurve aber in einer Software auf ihrem Computer vermerkt, sodass hier nicht immer etwas eingetragen wird. </p>
            <Typography variant="h3">Nach der Geburt</Typography>
            <p>Auf den letzten Seiten werden  nach endlich geschaffter Geburt Geburtsdokumentation, Abschlussuntersuchung und Wochenbett-Verlauf dokumentiert. Insgesamt ist im Mutterpass Raum für die Dokumentation von 2 Schwangerschaften. Hoffentlich haben Sie Ihren Mutterpass nun ein wenig besser verstanden. </p>

            <p><b>Führen Sie Ihren Begleiter stets mit sich, denn er enthält sehr wertvolle Informationen, die Ihnen und Ihrem Kind eine angemessene Betreuung ermöglichen.</b></p>

          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default MaternityRecord;