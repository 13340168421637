import React from 'react';

import { FormControlLabel, FormGroup, Checkbox } from "@material-ui/core";

export default function UXCheckbox(props) {
  return (  
    <FormGroup>
      <FormControlLabel 
        control={<Checkbox checked={props.formikValues[props.name] === '1'} />} 
        label={props.label} 
        name={props.name} 
        onChange={(e) => {
          if(e.target.checked) {
            props.formikSetFieldValue(props.name, '1');
          }
          else {
            props.formikSetFieldValue(props.name, null);
          }
        }} 
      />
  </FormGroup>
  );
}