import React from 'react';

import Header from '../../components/Header/Header';
import Footer from 'components/Footer/Footer';

import { Button, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom'

import { KeyboardArrowLeft } from '@material-ui/icons';
import StudyContactData from 'components/StudyContactData/StudyContactData';


export default function HelpContact() {

    return (
      <>
        <Header>
          <Typography variant="h2">Hilfe &amp; Kontakt</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            <Typography variant="h2" style={{marginTop: "0.5em"}}>Hilfe &amp; Kontakt</Typography>
            <Button component={Link} to="/overview" variant="contained" color="primary" size="small" disableElevation>
              <KeyboardArrowLeft />
              Zurück zur Übersicht
            </Button>

            <Typography variant="h3" style={{marginTop: "0.5em"}}>Technik &amp; Studie</Typography>
            <p>
              Kontakt bei technischen Problemen oder Fragen rund um die Studienteilnahme:
            </p>
            <StudyContactData />
            <Typography variant="h3" style={{marginTop: "0.5em"}}>Medizinische Probleme</Typography>
            <p>
              Bei medizinischen Problemen erreichen Sie die Frauenklinik unter:
            </p>
            <p>
              Telefon: <a href="tel:004991318533553">09131 85-33553</a><br/>
              <small>24-Stunden Notfallnummer</small>
            </p>
          </Container>
        </main>
        <Footer/>
      </>
    );
}