import React from 'react';

import TextField from '@material-ui/core/TextField';

import DataContext from 'services/DataContext';


class Textfield extends React.Component {

  static dataContext = DataContext;

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    let dataContext = this.context;
    dataContext.update(this.props.identifier, event.target.value);
  }

  render(props) {
    if(this.props.textarea) {
      return (
        <DataContext.Consumer>
          {dataContext => (
            <TextField fullWidth multiline rows={this.props.rows} value={dataContext.data[this.props.identifier] || ""} onChange={this.handleChange} label={this.props.label || ""}  />
          )}
        </DataContext.Consumer>
      )  
    }
    else {
      return (
        <DataContext.Consumer>
          {dataContext => (
            <TextField fullWidth value={dataContext.data[this.props.identifier] || ""} onChange={this.handleChange} label={this.props.label || ""}  />
          )}
        </DataContext.Consumer>
      )
    }
  }
}

Textfield.contextType = DataContext;

export default Textfield;