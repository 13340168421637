import React from 'react';

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';


class PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent extends React.Component {

  render(props) {
      return (
        <>
          <FormControlLabel value="0" control={<Radio color="primary" />} label="Während der letzten vier Wochen gar nicht" labelPlacement="end"/>
          <FormControlLabel value="1" control={<Radio color="primary" />} label="Weniger als einmal pro Woche" labelPlacement="end"/>
          <FormControlLabel value="2" control={<Radio color="primary" />} label="Einmal oder zweimal pro Woche" labelPlacement="end"/>
          <FormControlLabel value="3" control={<Radio color="primary" />} label="Dreimal oder häufiger pro Woche" labelPlacement="end"/>
        </>
      )
  }
}

export default PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent;