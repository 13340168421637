import React, { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";

import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import AuthContext from '../../../services/AuthContext';


export default function Login() {
    const [pseudonym, setPseudonym] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();
    const authContext = useContext(AuthContext);

    async function login() {
        authContext.login(pseudonym, password).then(function(res){
            history.push('/overview');
        }).catch(function(err){
            alert(err);
        });
    }

    return (
        <main>
            <Grid container spacing={1} direction="column" alignItems="center" justify="center" style={{ minHeight: '90vh', color: "#783476" }}>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <Typography variant="h1">Anmelden</Typography>
                    <p>
                        Geben Sie Ihre App-ID und das Passwort an, um sich anzumelden.
                    </p>
                </Grid>
                <Grid item xs={12}>
                    <TextField name="pseudonym" value={pseudonym} onChange={e => setPseudonym(e.target.value)} label="App-ID" variant="outlined"/>
                </Grid>
                <Grid item xs={12}>
                    <TextField name="password" value={password} onChange={e => setPassword(e.target.value)} label="Passwort" type="password" autoComplete="current-password" variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" disableElevation onClick={login}>Anmelden</Button>
                </Grid>
            </Grid>
        </main>
    );
}