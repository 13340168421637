import React from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


import Header from 'components/Header/Header';
import DataList from 'components/DataList/DataList';

import InPatientStayList from './components/InPatientStayList';
import InPatientStayEditScreen from './components/InPatientStayEditScreen';


import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Footer from 'components/Footer/Footer';

class InPatientStay extends React.Component {

  render() {

    return (
          <>
            <Header>
              <h2>Stationäre Aufenthalte</h2>
            </Header>
            <Container>
              <main>
                <DataList dbKey="in_patient_stays" listItem={<InPatientStayList/>} editScreen={<InPatientStayEditScreen/>} />
              </main>
            </Container>
            <Footer />
          </>
    );
  }
}

export default InPatientStay;