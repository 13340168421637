import React from 'react';

import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

import StepperPage from 'components/StepperPage/StepperPage';

import axios from 'axios';
import Globals from 'services/Globals';
import moment from 'moment';

import { Link } from 'react-router-dom'

import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

import firefox1 from './images/edited/firefox-1.jpg';
import firefox2 from './images/edited/firefox-2.jpg';
import firefox3 from './images/edited/firefox-3.jpg';
import chrome1 from './images/edited/chrome-1.jpg';
import chrome2 from './images/edited/chrome-2.jpg';
import chrome3 from './images/edited/chrome-3.jpg';
import safari1 from './images/edited/safari-1.png';
import safari2 from './images/edited/safari-2.png';
import safari3 from './images/edited/safari-3.png';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

import { withRouter } from 'react-router-dom';
import withNotifications from 'services/NotificationService/withNotifications';

class AddToHomeScreenSetup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      userBrowser: undefined
    }

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.submitAppInstallation = this.submitAppInstallation.bind(this);
  }

  componentDidMount() {
    let userBrowser;

    // Taken from https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
    if(navigator.userAgent.indexOf("Firefox") > -1) {
      userBrowser = "firefox";
    }

    else if(navigator.userAgent.indexOf("Chrome") > -1) {
      userBrowser = "chrome";
    }

    else if(navigator.userAgent.indexOf("Safari") > -1) {
      userBrowser = "safari";
    }

    else if(navigator.userAgent.indexOf("SamsungBrowser") > -1) {
      userBrowser = "samsung";
    }

    else {
      userBrowser = "other"
    }

    this.setState({
      userBrowser: userBrowser
    })

  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  submitAppInstallation() {
    let self = this;
    axios.post(Globals.SERVER_URL + "/setup/app-installation", {
    }).then(function(res){
      self.props.notificationService.push("Installation erfolgreich abgeschlossen!", "success", 0);
      self.props.history.push('/overview');
    }).catch(function(err){
      self.props.notificationService.push("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie die Studienzentrale.", "error", 0, err);
      console.log(err);
    });
  }


  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Installation</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            <StepperPage activeStep={this.state.activeStep} thisStep={0}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Einführung</Typography>
                Bitte <b>installieren Sie diese App auf Ihrem Handy</b>, um schneller darauf zuzugreifen. Damit haben Sie die App immer zur Hand.<br/><br/>
                Diese Anleitung führt Sie <b>Schritt für Schritt</b> durch den Vorgang.<br/><br/>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Los geht's <KeyboardArrowRight /></Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={1}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Installation</Typography>
                Die App lässt sich in drei einfachen Schritten installieren: <br/><br/>
                {
                  this.state.userBrowser === "chrome" &&
                  <>
                    <Typography variant="h3">Öffnen Sie das Browser-Menü</Typography>
                    <p>Das Browser-Menü befindet sich am oberen rechten Bildschirmrand. Es lässt sich durch das "Drei-Punkte-Icon" öffnen.</p>
                    <img className="border" src={chrome1} alt="" /><br/>
                    <Typography variant="h3">Wählen Sie "App Installieren"</Typography>
                    <img className="border" src={chrome2} alt="" /><br/>
                    <Typography variant="h3">Bestätigen Sie durch "Installieren"</Typography>
                    <img className="border" src={chrome3} alt="" />
                  </>
                }
                {
                  this.state.userBrowser === "firefox" &&
                  <>
                    <Typography variant="h3">Öffnen Sie das Browser-Menü</Typography>
                    <p>Das Browser-Menü befindet sich am unteren rechten Bildschirmrand. Es lässt sich durch das "Drei-Punkte-Icon" öffnen.</p>
                    <img className="border" src={firefox1} alt="" /><br/>
                    <Typography variant="h3">Wählen Sie "Installieren"</Typography>
                    <img className="border" src={firefox2} alt="" /><br/>
                    <Typography variant="h3">Bestätigen Sie durch "Hinzufügen"</Typography>
                    <img className="border" src={firefox3} alt="" />
                  </>
                }
                {/*
                  this.state.userBrowser === "samsung" &&
                  <>
                    <b>TODO: Anleitung für Samsung folgt.</b>
                  </>*/
                }
                {
                  this.state.userBrowser === "safari" &&
                  <>
                    <Typography variant="h3">Wichtig</Typography>
                    <p>Sie verwenden ein iPhone oder iPad. Bitte stellen Sie unbedingt sicher, dass Sie diese <b>Webseite mit Safari geöffnet</b> haben. Aufgrund von Beschränkungen seitens Apple ist eine Installation nur mit Safari möglich.</p>
                    <Typography variant="h3">Öffnen Sie das Teilen-Menü</Typography>
                    <p>Das Menü befindet sich unten mittig.</p>
                    <img className="border" src={safari1} alt="" /><br/>
                    <Typography variant="h3">Wählen Sie "Zum Homescreen hinzufügen"</Typography>
                    <img className="border" src={safari2} alt="" /><br/>
                    <Typography variant="h3">Bestätigen Sie durch "Hinzufügen"</Typography>
                    <img className="border" src={safari3} alt="" />
                  </>
                }
                {
                  (this.state.userBrowser === "other" || this.state.userBrowser === "samsung") &&
                  <>
                    Die Installation ist Browserabhängig.<br/><br/>
                    Bitte sehen Sie in Ihrer Taskleiste (URL-Leiste/dort, wo Sie die Webseiten-Adresse eingeben) nach, ob ein "App hinzufügen"-Icon angezeigt wird.<br/><br/>
                    Falls nicht, öffnen Sie bitte das Menü (Drei-Punkte-Icon) und suchen Sie nach einem Menü-Eintrag "App installieren" oder ähnlich.<br/><br/>
                    Sie finden die entsprechende Option nicht? Es kann sein, dass Ihr Browser nicht unterstützt wird.<br/><br/>
                    In diesem Fall können Sie ein Lesezeichen anlegen.<br/><br/>
                    Hier sehen Sie einige Beispiel-Screenshots:<br/><br/>
                    <img className="border" src={chrome2} alt="" /><br/>
                    <img className="border" src={firefox2} alt="" /><br/>
                    <img className="border" src={safari2} alt="" /><br/>
                  </>
                }
                <p>Bitte führen Sie jetzt die o.g. Schritte durch.</p>
                <br/><br/>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Weiter <KeyboardArrowRight /></Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={2}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Alles erledigt?</Typography>
                <p>Bitte überprüfen Sie jetzt, ob die App in Ihrem/r Startbildschirm/Homescreen/App-Liste sichtbar ist.</p>
                <p>Wenn alles geklappt hat, klicken Sie auf den Button "Installation erfolgreich". Gehen Sie sonst zurück und sehen Sie sich die Anleitung erneut an.</p>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleBack}><KeyboardArrowLeft /> Zurück</Button><br/><br/>
                <Button variant="contained" onClick={this.submitAppInstallation} autoFocus color="primary">
                  Installation erfolgreich durchgeführt
                </Button>
              </div>
            </StepperPage>

            </Container>

        </main>
        <Footer />
      </>
    );  
  }
}

export default withNotifications(withRouter(AddToHomeScreenSetup));