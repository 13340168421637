import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';

import TextField from '@material-ui/core/TextField';

import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import QuestionnaireHeadNotice from 'components/QuestionnaireHeadNotice/QuestionnaireHeadNotice';
import QuestionnaireSubmittedDialogObfuscation from '../QuestionnaireSubmittedDialogObfuscation/QuestionnaireSubmittedDialogObfuscation';


class ObfuscationQuestionnaire extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <LinearProgress variant="determinate" value={ (this.state.activeStep/6) * 100 } />
        <QuestionnaireSubmittedDialogObfuscation dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />
        <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
          <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
            {
              "oq_1": 0,
              "oq_2": 1,
              "oq_3": 2,
              "oq_4": 3,
              "oq_5": 4,
              "oq_6": 5,
              "oq_7": 6,
              "op_8": 6
            }
          }/>

          <QuestionnaireHeadNotice>
            Bitte beziehen Sie Ihre Antworten ausschließlich auf die aktuell oben gezeigte Darstellungsvariante.
          </QuestionnaireHeadNotice>

          <div className="questionnaire-body">
            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">Gefällt Ihnen diese Darstellung?</FormLabel>
                  <CustomErrorMessage name="oq_1" />
                  <RadioGroup aria-label="position" name="oq_1" value={this.props.values.oq_1} onChange={this.props.handleChange} required={true}>
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="Gefällt mir gar nicht" labelPlacement="end"/>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Gefällt mir eher nicht" labelPlacement="end"/>
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Teils/teils" labelPlacement="end"/>
                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Gefällt mir eher gut" labelPlacement="end"/>
                    <FormControlLabel value="4" control={<Radio color="primary" />} label="Gefällt mir sehr gut" labelPlacement="end"/>
                  </RadioGroup>
                </FormControl>
                <ForwardBackwardButtons handleNext={this.handleNext} />
              </StepperPage>

              <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">Finden Sie diese Darstellung interessant?</FormLabel>
                  <CustomErrorMessage name="oq_2" />
                  <RadioGroup aria-label="position" name="oq_2" value={this.props.values.oq_2} onChange={this.props.handleChange} required={true}>
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="Gar nicht interessant" labelPlacement="end"/>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Eher nicht interessant" labelPlacement="end"/>
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Teils/teils" labelPlacement="end"/>
                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Eher interessant" labelPlacement="end"/>
                    <FormControlLabel value="4" control={<Radio color="primary" />} label="Sehr interessant" labelPlacement="end"/>
                  </RadioGroup>
                </FormControl>
                <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
              </StepperPage>

              <StepperPage activeStep={this.state.activeStep} thisStep={2}>
                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">Verstehen Sie diese Darstellung?</FormLabel>
                  <CustomErrorMessage name="oq_3" />
                  <RadioGroup aria-label="position" name="oq_3" value={this.props.values.oq_3} onChange={this.props.handleChange} required={true}>
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="Gar nicht" labelPlacement="end"/>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Eher nicht" labelPlacement="end"/>
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Teils/teils" labelPlacement="end"/>
                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Eher gut" labelPlacement="end"/>
                    <FormControlLabel value="4" control={<Radio color="primary" />} label="Sehr gut" labelPlacement="end"/>
                  </RadioGroup>
                </FormControl>
                <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
              </StepperPage>

              <StepperPage activeStep={this.state.activeStep} thisStep={3}>
                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">Ergibt diese Darstellung Sinn?</FormLabel>
                  <CustomErrorMessage name="oq_4" />
                  <RadioGroup aria-label="position" name="oq_4" value={this.props.values.oq_4} onChange={this.props.handleChange} required={true}>
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="Gar nicht schlüssig" labelPlacement="end"/>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Eher nicht schlüssig" labelPlacement="end"/>
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Teils/teils" labelPlacement="end"/>
                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Eher schlüssig" labelPlacement="end"/>
                    <FormControlLabel value="4" control={<Radio color="primary" />} label="Sehr schlüssig" labelPlacement="end"/>
                  </RadioGroup>
                </FormControl>
                <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
              </StepperPage>

              <StepperPage activeStep={this.state.activeStep} thisStep={4}>
                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">Motiviert Sie diese Darstellung, die SMART Start-App häufiger zu nutzen?</FormLabel>
                  <CustomErrorMessage name="oq_5" />
                  <RadioGroup aria-label="position" name="oq_5" value={this.props.values.oq_5} onChange={this.props.handleChange} required={true}>
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="Gar nicht motivierend" labelPlacement="end"/>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Eher nicht motivierend" labelPlacement="end"/>
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Teils/teils" labelPlacement="end"/>
                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Eher motivierend" labelPlacement="end"/>
                    <FormControlLabel value="4" control={<Radio color="primary" />} label="Sehr motivierend" labelPlacement="end"/>
                  </RadioGroup>
                </FormControl>
                <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
              </StepperPage>

              <StepperPage activeStep={this.state.activeStep} thisStep={5}>
                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">Verunsichert Sie diese Darstellung?</FormLabel>
                  <CustomErrorMessage name="oq_6" />
                  <RadioGroup aria-label="position" name="oq_6" value={this.props.values.oq_6} onChange={this.props.handleChange} required={true}>
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="Gar nicht verunsichert" labelPlacement="end"/>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Eher nicht verunsichert" labelPlacement="end"/>
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Teils/teils" labelPlacement="end"/>
                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Eher verunsichert" labelPlacement="end"/>
                    <FormControlLabel value="4" control={<Radio color="primary" />} label="Sehr verunsichert" labelPlacement="end"/>
                  </RadioGroup>
                </FormControl>
                <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
              </StepperPage>

              <StepperPage activeStep={this.state.activeStep} thisStep={6}>
                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">Suchen Sie aufgrund dieser Darstellung nach weiteren Informationen?</FormLabel>
                  <CustomErrorMessage name="oq_7" />
                  <RadioGroup aria-label="position" name="oq_7" value={this.props.values.oq_7} onChange={this.props.handleChange} required={true}>
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="Gar nicht" labelPlacement="end"/>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Eher nicht" labelPlacement="end"/>
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Teils/teils" labelPlacement="end"/>
                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Eher ja" labelPlacement="end"/>
                    <FormControlLabel value="4" control={<Radio color="primary" />} label="In jedem Fall" labelPlacement="end"/>
                  </RadioGroup>
                  <CustomErrorMessage name="oq_8" />
                  <TextField fullWidth name="oq_8" label="Falls ja, wo?" value={this.props.values.oq_8} onChange={this.props.handleChange} />
                </FormControl>
                <div style={{textAlign: "center", marginBottom: "1em"}}>
                  <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                </div>
                <ForwardBackwardButtons handleBack={this.handleBack} />
              </StepperPage>

              {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}       
            </Container>  
          </div>
        </form>
      </> 
    );
  }
}

export default withFormik({
  mapPropsToValues: () => ({
    oq_1: '',
    oq_2: '',
    oq_3: '',
    oq_4: '',
    oq_5: '',
    oq_6: '',
    oq_7: '',
    oq_8: ''
  }),  
  validationSchema: Yup.object().shape({
    oq_1: Yup.number().required('Pflichtfeld'),
    oq_2: Yup.number().required('Pflichtfeld'),
    oq_3: Yup.number().required('Pflichtfeld'),
    oq_4: Yup.number().required('Pflichtfeld'),
    oq_5: Yup.number().required('Pflichtfeld'),
    oq_6: Yup.number().required('Pflichtfeld'),
    oq_7: Yup.number().required('Pflichtfeld')
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    console.log(props);
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "oq" + "-" + props.props.location,
      questionnaireData: values,
      questionnaireMeta: {
        type: props.props.type,
        displayedData: props.props.data
      }
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      // TODO show error
      console.log(err);
    });
  }
})(ObfuscationQuestionnaire);

/* https://stackoverflow.com/questions/59497550/handling-formik-form-when-validation-error-occurs */