import React from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom'

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import StepperPage from 'components/StepperPage/StepperPage';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Typography } from '@material-ui/core';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import FileUploadNotice from 'components/FileUploadNotice/FileUploadNotice';

import Globals from 'services/Globals';

import HelperModal from './HelperModal';
import HelperModalButton from './HelperModalButton';

import urinstickBecher from './urinstick-becher.svg';
import urinstickReferenceCardExample from './urinstick-reference-card-example.jpeg';
import urinstickVisualisierungUpdown from './urinstick-visualisierung-updown.png';
import urinstickInBecher from './urinstick-in-becher.png';

import withNotifications from 'services/NotificationService/withNotifications';

/**
 * TODO:
 * Error handler in file upload
 * Add "please wait for upload" screen
 */

class Urinalysis extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      photoOneId: null,
      photoTwoId: null,
      photoThreeId: null,
      glucose: "",
      nitrite: "",
      protein: "",
      ph: "",
      leucocytes: "",
      startTime: null,
      nowTime: null,
      photoOneTime: null,
      photoTwoTime: null,
      photoThreeTime: null,
      helperModalToggled: false,
      isCurrentlyLoading: false,
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.toggleHelperModal = this.toggleHelperModal.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  toggleHelperModal () {
    this.setState({
      helperModalToggled: !this.state.helperModalToggled
    })
  }

  getCurrentTimestampAsSeconds() {
    let nowDate = new Date();
    let seconds = Math.round(nowDate.getTime() / 1000)

    return seconds;
  }

  startTimer()  {
    let self = this;

    this.setState({
      startTime: self.getCurrentTimestampAsSeconds(),
      nowTime: self.getCurrentTimestampAsSeconds()
    });

    // Update timestamp every 1000ms
    this.timer = setInterval(function(){
      self.setState({
        nowTime: self.getCurrentTimestampAsSeconds()
      });
    }, 1000);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  urineImageFileUploadHandler = (event) => {
    let self = this;

    const fd = new FormData();
    fd.append("photoData", event.target.files[0]);
    fd.append("photoType", "urinalysis");

    self.setState({isCurrentlyLoading: true});

    axios.post(Globals.SERVER_URL + "/photos", fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        self.setState({isCurrentlyLoading: false});
        
        // Get photo ID/FK from response
        // Check for current step - save accordingly
        let currentStep = this.state.activeStep;

        if (currentStep === 10) {
          // Image 1: Glucose
          this.setState({
            photoOneId: res.data.photoId,
            photoOneTime: self.getCurrentTimestampAsSeconds()
          });
        }
        else if (currentStep === 12) {
          // Image 2: pH, Protein, Nitrit
          this.setState({
            photoTwoId: res.data.photoId,
            photoTwoTime: self.getCurrentTimestampAsSeconds()
          });
        }
        else if (currentStep === 14) {
          // Image 3: Leukos
          this.setState({
            photoThreeId: res.data.photoId,
            photoThreeTime: self.getCurrentTimestampAsSeconds()
          });
        }
        else {
          // Huston, we have a problem
          this.props.notificationService.push("Es ist ein Fehler bei der Bildzuordnung aufgetreten.", "error");
        }

        // Proceed to next step
        this.handleNext();
      }).catch((err) =>{
        this.props.notificationService.push("Fehler beim Hochladen des Bilds. Bitte versuchen Sie es später erneut.", "error", 0, err); 
      });
  };


  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Urinanalyse</Typography>
        </Header>
        <main>
          <>
            { this.state.helperModalToggled &&
              <HelperModal toggle={this.toggleHelperModal} />
            }
          </>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/17) * 100 } />

          <FileUploadNotice loading={this.state.isCurrentlyLoading} />

          <Container maxWidth="lg">
            <StepperPage activeStep={this.state.activeStep} thisStep={0}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <div className="photo-circle">
                  <img src={urinstickBecher} alt="Illustration eines Urinbechers und einer Referenzkarte." />
                </div>
                <Typography variant="h2">Einführung</Typography>
                Bei der Urinanalyse werden verschiedene <b>Werte im Urin</b> untersucht. <br/><br/>
                Dazu tauchen Sie einen <b>Messstreifen</b> in einen Becher mit Urin ein. <br/><br/>
                Sie müssen mehrere <b>Bilder</b> mit Ihrer <b>Kamera aufnehmen.</b><br/><br/>
                Außerdem müssen Sie die Farbfelder des Urin-Teststreifens mit der Referenzkarte <b>vergleichen.</b><br/><br/>
                Diese Anleitung führt Sie <b>Schritt für Schritt</b> durch den Vorgang.<br/><br/>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Los geht's <KeyboardArrowRight /></Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={1}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Hinweise (1/5)</Typography>
                <Typography variant="h3">Hilfe-Button</Typography>
                Im Folgenden geben wir Ihnen mehrere <b>Hinweise zur Durchführung</b>.<br/><br/>
                Während der Studie gibt es einen <b>Button "Hilfe/Hinweise einblenden"</b>.<br/><br/>
                Sie können die folgenden Hinweise dort <b>jederzeit nachlesen.</b><br/><br/>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleBack}><KeyboardArrowLeft /> Zurück</Button>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Weiter <KeyboardArrowRight /></Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={2}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Hinweise (2/5)</Typography>
                <Typography variant="h3">Bildaufnahme</Typography>
                In allen Bildern müssen die <b>Referenzkarte und der Urin-Teststreifen gleichzeitig</b> enthalten sein.<br/><br/>
                <b>Vermeiden</b> Sie <b>Schatten, Reflektionen oder Spiegelungen</b> im Bild.<br/><br/>
                Unten haben wir ein <b>gutes Beispiel</b> abgebildet.<br/><br/>
                <img src={urinstickReferenceCardExample} style={{maxWidth: "80%"}} alt="" /><br/><br/>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleBack}><KeyboardArrowLeft /> Zurück</Button>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Weiter <KeyboardArrowRight /></Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={3}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Hinweise (3/5)</Typography>
                <Typography variant="h3">Vergleich der Farbfelder</Typography>
                Nach jeder Bildaufnahme müssen Sie <b>selbst Farben vergleichen</b>. Das erfolgt rein visuell, d.h. mit Ihren <b>eigenen Augen</b>.<br/><br/>
                Suchen Sie den Parameter auf der Referenzkarte (Kürzel, z.B. "GLU").<br/>
                Suchen Sie das dazugehörige Feld auf dem Messstreifen.<br/><br/>
                Vergleichen Sie die Farben. Wählen Sie das Feld aus, das <b>am besten übereinstimmt</b>. Manchmal sind die Farben nicht eindeutig. Wählen Sie dennoch das Feld, das "am ehesten" passt.<br/><br/>
                <br/>                  
                <img src={urinstickReferenceCardExample} style={{maxWidth: "80%"}} alt="" /><br/><br/>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleBack}><KeyboardArrowLeft /> Zurück</Button>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Weiter <KeyboardArrowRight /></Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={4}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Hinweise (4/5)</Typography>
                <Typography variant="h3">Position des Urinsticks/der Referenzkarte</Typography>
                Achten Sie auf die <b>folgende Orientierung</b> für das visuelle Ablesen der Ergebnisse:<br/><br/>
                Der Urinstick (links im Bild) muss mit dem <b>"Siemens"-Schriftzug nach unten</b> gehalten/verglichen werden.<br/>
                Die Referenzkarte (rechts im Bild) muss mit dem <b>Pfeil nach oben</b> gehalten/verglichen werden.<br/><br/>
                <img src={urinstickVisualisierungUpdown} style={{maxWidth: "80%"}} alt="" /><br/><br/>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleBack}><KeyboardArrowLeft /> Zurück</Button>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Weiter <KeyboardArrowRight /></Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={5}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Hinweise (5/5)</Typography>
                <Typography variant="h3">Zeitabstände</Typography>
                Einzelne Urin-Werte müssen zu <b>unterschiedlichen Zeitpunkten</b> abgelesen werden. Daher erfolgen auch die Fotos und der Farbvergleich zu unterschiedlichen Zeiten.<br/><br/>
                Führen Sie die Bildaufnahme und den Farbvergleich <b>zügig, aber sorgfältig durch</b>. <br/><br/>
                Falls Sie zu schnell sind, zeigt die App einen <b>Count-Down</b> an.<br/><br/>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleBack}><KeyboardArrowLeft /> Zurück</Button>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Weiter <KeyboardArrowRight /></Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={6}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Vorbereitung</Typography>
                Folgende Dinge benötigen Sie jetzt. Bitte legen Sie diese jetzt bereit:<br/>
                <ul>
                  <li>Urinteststreifen</li>
                  <li>Referenzkarte</li>
                  <li>Sauberer, trockener Becher (für den Urin)</li>
                  <li>Ebene Unterlage für Teststreifen und Referenzkarte</li>
                  <li>Ggfs. ein Papiertaschentuch als Unterlage</li>
                </ul><br/>
                Sie müssen noch nichts weiter machen. Die App führt Sie <b>Schritt für Schritt</b> durch den Vorgang.<br/><br/>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Alles notwendige liegt bereit. Es kann losgehen <KeyboardArrowRight /></Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={7}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Urin einsammeln</Typography>
                Fangen Sie jetzt den Harn in einem sauberen, trockenen Gefäß (Becher) auf.<br/><br/>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Urin aufgefangen <KeyboardArrowRight /></Button>
              </div>
            </StepperPage>
            
            <StepperPage activeStep={this.state.activeStep} thisStep={8}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Urinstreifen eintauchen</Typography>
                Einen Urinteststreifen aus der Verpackung entnehmen.<br/>
                Anschließend den Teststreifen in den Urin eintauchen.<br/>
                Der Streifen kann bei Bedarf leicht gebogen werden.<br/>
                Sobald alle Testfelder benetzt sind, den Teststreifen direkt wieder herausnehmen.<br/>
                Überschüssigen Urin an der Unterseite des Teststreifens vorsichtig am Becherrand abstreifen.<br/><br/>
                
                <img src={urinstickInBecher} style={{width: "15%"}} alt="" /><br/><br/>

                <Button variant="contained" color="primary" align="center" disableElevation 
                  onClick={() => {
                    this.startTimer();
                    this.handleNext();
                  }}
                >
                  Teststreifen wurde eingetaucht <KeyboardArrowRight />
                </Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={9}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Teststreifen neben Referenzkarte legen</Typography>
                Legen Sie den Teststreifen neben die Referenzkarte.<br/>
                Vermeiden Sie Schatten, Spiegelungen oder Reflektionen.<br/><br/>
                Achten Sie auf die richtige Orientierung.<br/>
                Der Urinstick (links im Bild) muss mit dem <b>"Siemens"-Schriftzug nach unten</b> platziert werden.<br/>
                Die Referenzkarte (rechts im Bild) muss mit dem <b>Pfeil nach oben</b> platziert werden.<br/><br/>
                
                <img src={urinstickVisualisierungUpdown} style={{maxWidth: "50%"}} alt="" /><br/><br/>
                
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Weiter <KeyboardArrowRight /></Button><br/><br/>
                <HelperModalButton toggle={this.toggleHelperModal} />
              </div>
            </StepperPage>
            <StepperPage activeStep={this.state.activeStep} thisStep={10}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Bild 1/3: Bildaufnahme</Typography>
                {
                  ((this.state.nowTime - this.state.startTime) <= 30) ?
                    <>
                    <b>Bitte warten Sie noch {30 - (this.state.nowTime - this.state.startTime)} Sekunden.</b>
                    </>
                    :
                    <>
                    Die Bildaufnahme ist bereit. Tippen Sie auf den Button, um das Bild aufzunehmen.<br/><br/>
                    <Button variant="contained" color="primary" component="label" disableElevation disabled={this.state.isCurrentlyLoading}>
                      Bild aufnehmen
                      <input type="file" accept="image/*" capture="camera" onChange={this.urineImageFileUploadHandler} hidden />
                    </Button><br/><br/>
                    <HelperModalButton toggle={this.toggleHelperModal} />
                    </>
                }
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={11}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Bild 1/3: Werte ablesen</Typography>
                Vergleichen Sie den Urinstreifen mit der Referenzkarte. Lesen Sie die folgenden Parameter ab:<br/><br/>
                <b>Glukose (GLU):</b><br/><br/>
                <FormControl fullWidth={true}>
                  <InputLabel id="glucose-label">Glucose (GLU)</InputLabel>
                  <Select native id="glucose" labelId="glucose-label" name="glucose" value={this.state.glucose} onChange={this.handleChange}>
                    <option value={""}></option>
                    <option value={"NEG."}>NEG.</option>
                    <option value={"Trace.&#183;Sp."}>Trace.&#183;Sp.</option>
                    <option value={"+"}>+</option>
                    <option value={"++"}>++</option>
                    <option value={"+++"}>+++</option>
                    <option value={"++++"}>++++</option>
                  </Select>
                  <div className="inline-error-message">{ this.state.errorMessage }</div><br/><br/>
                </FormControl>

                <Button variant="contained" color="primary" align="center" disableElevation 
                  onClick={() => {
                    if(this.state.glucose !== "") {
                      this.setState({errorMessage: ""});
                      this.handleNext();
                    } else {
                      this.setState({errorMessage: "Bitte treffen Sie eine Auswahl."});
                    }
                  }}>
                    Weiter <KeyboardArrowRight />
                </Button><br/><br/>
                <HelperModalButton toggle={this.toggleHelperModal} />
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={12}>
              <div style={{textAlign: "center", marginTop: "1em"}}>

              <Typography variant="h2">Bild 2/3: Bildaufnahme</Typography>
                {
                  ((this.state.nowTime - this.state.startTime) <= 60) ?
                    <>
                    <b>Bitte warten Sie noch {60 - (this.state.nowTime - this.state.startTime)} Sekunden.</b>
                    </>
                    :
                    <>
                    Die Bildaufnahme ist bereit. Tippen Sie auf den Button, um das Bild aufzunehmen.<br/><br/>
                    <Button variant="contained" color="primary" component="label" disableElevation disabled={this.state.isCurrentlyLoading}>
                      Bild aufnehmen
                      <input type="file" accept="image/*" capture="camera" onChange={this.urineImageFileUploadHandler} hidden />
                    </Button><br/><br/>
                    <HelperModalButton toggle={this.toggleHelperModal} />
                    </>
                }
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={13}>
            <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Bild 2/3: Werte ablesen</Typography>
                Vergleichen Sie den Urinstreifen mit der Referenzkarte. Lesen Sie die folgenden Parameter ab:<br/><br/>
                <b>Nitrit (NIT):</b><br/><br/>
                <FormControl fullWidth={true}>
                  <InputLabel id="nitrite-label">Nitrit (NIT)</InputLabel>
                  <Select native id="nitrite" labelId="nitrite-label" name="nitrite" value={this.state.nitrite} onChange={this.handleChange}>
                    <option value={""}></option>
                    <option value={"NEG."}>NEG.</option>
                    <option value={"left"}>&larr;</option>
                    <option value={"right"}>&rarr;</option>
                  </Select>
                </FormControl><br/><br/>

                <b>Protein (PRO):</b><br/><br/>
                <FormControl fullWidth={true}>
                  <InputLabel id="protein-label">Protein (PRO)</InputLabel>
                  <Select native id="protein" labelId="protein-label" name="protein" value={this.state.protein} onChange={this.handleChange}>
                    <option value={""}></option>
                    <option value={"NEG."}>NEG.</option>
                    <option value={"Trace.&#183;Sp."}>Trace.&#183;Sp.</option>
                    <option value={"+"}>+</option>
                    <option value={"++"}>++</option>
                    <option value={"+++"}>+++</option>
                    <option value={"++++"}>++++</option>
                  </Select>
                </FormControl><br/><br/>

                <b>pH-Wert (pH):</b><br/><br/>
                <FormControl fullWidth={true}>
                  <InputLabel id="ph-label">pH-Wert (pH)</InputLabel>
                  <Select native id="ph" labelId="ph-label" name="ph" value={this.state.ph} onChange={this.handleChange}>
                    <option value={""}></option>
                    <option value={"5.0"}>5.0</option>
                    <option value={"6.0"}>6.0</option>
                    <option value={"6.5"}>6.5</option>
                    <option value={"7.0"}>7.0</option>
                    <option value={"7.5"}>7.5</option>
                    <option value={"8.0"}>8.0</option>
                    <option value={"8.5"}>8.5</option>
                  </Select>
                </FormControl><br/><br/>

                <div className="inline-error-message">{ this.state.errorMessage }</div><br/><br/>

                <Button variant="contained" color="primary" align="center" disableElevation 
                  onClick={() => {
                    if(this.state.nitrite === "" || this.state.protein === "" || this.state.ph === "") {
                      this.setState({errorMessage: "Alle drei Felder (Nitrit, Protein, pH) müssen ausgefüllt sein."});
                    } else {
                      this.setState({errorMessage: ""});
                      this.handleNext();
                    }
                  }}>
                    Weiter <KeyboardArrowRight />
                </Button><br/><br/>
                <HelperModalButton toggle={this.toggleHelperModal} />
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={14}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Bild 3/3: Bildaufnahme</Typography>
                {
                  ((this.state.nowTime - this.state.startTime) <= 120) ?
                    <>
                    <b>Bitte warten Sie noch {120 - (this.state.nowTime - this.state.startTime)} Sekunden.</b>
                    </>
                  :
                  <>
                    Die Bildaufnahme ist bereit. Tippen Sie auf den Button, um das Bild aufzunehmen.<br/><br/>
                    <Button variant="contained" color="primary" component="label" disableElevation disabled={this.state.isCurrentlyLoading}>
                      Bild aufnehmen
                      <input type="file" accept="image/*" capture="camera" onChange={this.urineImageFileUploadHandler} hidden />
                    </Button><br/><br/>
                    <HelperModalButton toggle={this.toggleHelperModal} />
                  </>
                }
              </div>                
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={15}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Bild 3/3: Werte ablesen</Typography>
                Vergleichen Sie den Urinstreifen mit der Referenzkarte. Lesen Sie die folgenden Parameter ab:<br/><br/>
                <b>Leukozyten (LEU):</b><br/><br/>
                <FormControl fullWidth={true}>
                  <InputLabel id="leucocytes-label">Leukozyten (LEU)</InputLabel>
                  <Select native id="leucocytes" labelId="leucocytes-label" name="leucocytes" value={this.state.leucocytes} onChange={this.handleChange}>
                    <option value={""}></option>
                    <option value={"NEG."}>NEG.</option>
                    <option value={"Trace.&#183;Sp."}>Trace.&#183;Sp.</option>
                    <option value={"+"}>+</option>
                    <option value={"++"}>++</option>
                    <option value={"+++"}>+++</option>
                  </Select>
                  <div className="inline-error-message">{ this.state.errorMessage }</div><br/><br/>
                </FormControl>

                <Button variant="contained" color="primary" align="center" disableElevation 
                  onClick={() => {
                    if(this.state.leucocytes !== "") {
                      this.setState({errorMessage: ""});
                      this.handleNext();
                    } else {
                      this.setState({errorMessage: "Bitte treffen Sie eine Auswahl."});
                    }
                  }}>
                    Weiter <KeyboardArrowRight />
                </Button><br/><br/>
                <HelperModalButton toggle={this.toggleHelperModal} />
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={16}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Alle Angaben gesammelt</Typography>
                Vielen Dank für das Ablesen der Ergebnisse. Sie können diese jetzt absenden.<br/><br/>
                
                <Button variant="contained" color="primary" align="center" disableElevation 
                  onClick={() => {
                    let self = this;
                    axios.post(Globals.SERVER_URL + "/urinalysis", {
                      startTime: this.state.startTime,
                      photoOneId: this.state.photoOneId,
                      photoTwoId: this.state.photoTwoId,
                      photoThreeId: this.state.photoThreeId,
                      photoOneTime: this.state.photoOneTime,
                      photoTwoTime: this.state.photoTwoTime,
                      photoThreeTime: this.state.photoThreeTime,
                      glucose: this.state.glucose,
                      nitrite: this.state.nitrite,
                      protein: this.state.protein,
                      ph: this.state.ph,
                      leucocytes: this.state.leucocytes
                    }).then(function(res){
                      console.log(res);
                      self.handleNext();
                    }).catch(function(err){
                      this.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
                    });
                  }}>
                  Ergebnisse absenden
                </Button><br/><br/>

                <div className="inline-error-message">{ this.state.errorMessage }</div><br/><br/>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={17}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Vielen Dank!</Typography>
                  Ihre Angaben wurden gespeichert.<br/><br/>
                  <Button component={Link} to="/overview" autoFocus color="primary">
                    Zurück zur Startseite
                  </Button>
              </div>
            </StepperPage>
          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default withNotifications(Urinalysis);