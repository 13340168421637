import React from 'react';

import Header from '../../../components/Header/Header';
import Footer from 'components/Footer/Footer';

import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import BackToLastPage from '../../../components/BackToLastPage/BackToLastPage';

export default function Imprint() {
    return (
      <>
        <Header>
          <Typography variant="h2">Impressum</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            <Typography variant="h2">Impressum</Typography>
            <BackToLastPage />
            <p>
                <strong>Universitätsklinikum Erlangen</strong>
            </p>
            <p>
                Maximiliansplatz 2
            </p>
            <p>
                91054 Erlangen
            </p>
            <p>
                Telefon: 09131 85-0
            </p>
            <p>
                Internet: www.uk-erlangen.de
            </p>
            <p>
                Postanschrift:
            </p>
            <p>
                Postfach 2306
            </p>
            <p>
                91012 Erlangen
            </p>
            <p>
                <strong>Verantwortlich im Sinne des Medienrechts</strong>
            </p>
            <p>
                Klinikdirektor Prof. Dr. med. Matthias W. Beckmann
            </p>
            <p>
                <strong>Umsatzsteuer-Identifikationsnummer</strong>
            </p>
            <p>
                DE 248558812
            </p>
            <p>
                Das Universitätsklinikum Erlangen ist eine rechtsfähige Anstalt des
                öffentlichen Rechts.
            </p>
            <p><strong>Klinikumsvorstand</strong></p>
            <p>
              <ul>
                <li>Prof. Dr. med. Dr. h. c. Heinrich Iro, Ärztlicher Direktor (Vorsitz)</li>
                <li>Dr. jur. Albrecht Bender, Kaufmännischer Direktor</li>
                <li>Reiner Schrüfer, Pflegedirektor</li>
                <li>Prof. Dr. med. Markus F. Neurath, Dekan der Medizinischen Fakultät</li>
              </ul>
            </p>
            <p>
                Die Vertretungsbefugnisse sind in der <a href="https://www.uk-erlangen.de/fileadmin/dateien/content_pool_dateien/UK_09_Satzung_Vertretung.pdf"> Satzung zur rechtlichen Vertretung des Universitätsklinikums Erlangen</a> vom 18.06.2009 geregelt.
            </p>
            <p>
                <strong>Aufsichtsrat</strong>
            </p>
            <p>
                <strong><em>Aufsichtsratsvorsitzender</em></strong>
            </p>
            <p>
                Markus Blume - Bayerischer Staatsminister für Wissenschaft und Kunst
            </p>
            <p>
                <strong><em>Vertreter des Aufsichtsratsvorsitzenden</em></strong>
            </p>
            <p>
                Dr. Michael Mihatsch - Bayerisches Staatsministerium für Wissenschaft und
                Kunst
            </p>
            <p>
                <strong><em>Aufsichtsratsmitglieder</em></strong>
            </p>
            <p>
              <ul>
                <li>Thomas Butzenlechner - Bayerisches Staatsministerium für Wissenschaft und
                Kunst</li>
                <li>David Holzmeier - Bayerisches Staatsministerium der Finanzen, für
                Landesentwicklung und Heimat</li>
                <li>Alexander Kraemer - Bayerisches Staatsministerium für Gesundheit und
                Pflege</li>
                <li>Prof. Dr. Joachim Hornegger - Präsident der
                Friedrich-Alexander-Universität Erlangen-Nürnberg</li>
                <li>Prof. Dr. Andreas Mackensen - Direktor der Medizinischen Klinik 5 des
                Universitätsklinikums Erlangen</li>
                <li>Prof. Dr. Erich Reinhardt - Externer Wirtschaftsfachmann</li>
                <li>Prof. Dr. Elke Roeb - Leiterin einer externen klinischen Einrichtung</li>
              </ul>
            </p>
            <p>
                <strong>Aufsichtsbehörde</strong>
            </p>
            <p>
                Bayerisches Staatsministerium für Wissenschaft und Kunst
            </p>
            <p>
                Salvatorstraße 2
            </p>
            <p>
                80333 München
            </p>
            <p>
                <strong>Verbraucherstreitbeilegung</strong>
            </p>
            <p>
                Das Universitätsklinikum Erlangen nimmt nicht an einem
                Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle nach dem
                Verbraucherstreitbeilegungsgesetz (VSBG) teil und ist hierzu auch nicht
                verpflichtet (§ 36 Abs. 1 VSBG).
            </p>
            <p>
                <strong>Beauftragter für Medizinproduktesicherheit</strong>
            </p>
            <p>
                <a href="mailto:beauftragter-mpsicherheit@uk-erlangen.de">
                    beauftragter-mpsicherheit(at)uk-erlangen.de
                </a>
            </p>
            <p>
                <strong>Zuständige Kammern</strong>
            </p>
            <p>
                <strong><em>Ärzte:</em></strong>
            </p>
            <p>
                Landesärztekammer Bayern: <a href="https://www.blaek.de/">Kammer</a>,
                <a
                    href="https://www.blaek.de/kammerrecht/berufsordnung-fuer-die-aerzte-bayerns"
                >
                    Berufsrecht
                </a>
            </p>
            <p>
                <strong><em>Zahnärzte:</em></strong>
            </p>
            <p>
                Zahnärztekammer Bayern: <a href="https://www.blzk.de/">Kammer</a>,
                <a
                    href="https://www.blzk.de/blzk/site.nsf/gfx/berufsordnung.pdf/$file/berufsordnung.pdf"
                >
                    Berufsrecht
                </a>
            </p>
            <p>
                <strong><em>Apotheker:</em></strong>
            </p>
            <p>
            Landesapothekerkammer Bayern: <a href="https://www.blak.de/">Kammer</a>,    <a href="https://www.blak.de/berufsordnung">Berufsrecht</a>
            </p>
            <p>
                <strong>
                    <em>
                        Psychologische Psychotherapeuten und Kinder- und
                        Jugendlichenpsychotherapeuten:
                    </em>
                </strong>
            </p>
            <p>
                Bayerische Landeskammer der Psychologischen Psychotherapeuten und der
            Kinder- und Jugendlichenpsychotherapeuten:    <a href="https://www.ptk-bayern.de/">Kammer</a>,
                <a
                    href="https://www.ptk-bayern.de/ptk/web.nsf/gfx/6C346C54E1CF5561C1257D630045CA14/$file/Berufsordnung_PTK_Bayern.pdf"
                >
                    Berufsrecht
                </a>
            </p>
            <p>
                Die jeweilige gesetzliche Berufsbezeichnung wurde in Deutschland verliehen.
                Sofern sie nicht in Deutschland verliehen wurde, ist sie entsprechend
                gekennzeichnet.
            </p>
            <p>
                <strong>Verantwortlicher für die Webseite der Anwendung:</strong>
            </p>
            <p>
                <strong>Prof. Dr. med. Matthias W. Beckmann</strong>
            </p>
            <p>
                Telefon: +49 9131 85-33451
            </p>
            <p>
                Fax: +49 9131 85-33456
            </p>
            <p>
                E-Mail:
                <a href="mailto:fk-direktion@uk-erlangen.de">
                    fk-direktion(at)uk-erlangen.de
                </a>
            </p>
            <p>
                <strong>
                    Ansprechpartner für Anwendung/Entwicklung der Anwendung durch:
                </strong>
            </p>
            <p>
                Friedrich-Alexander-Universität Erlangen-Nürnberg
            </p>
            <p>
                Lehrstuhl für Maschinelles Lernen und Datenanalytik (MaD Lab)
            </p>
            <p>
                Carl-Thiersch-Straße 2b
            </p>
            <p>
                91052 Erlangen
            </p>
            <p>
                Telefon: +49 9131 85-28990
            </p>
            <p>
                <a href="https://www.mad.tf.fau.de/">https://www.mad.tf.fau.de/</a>
            </p>
            <p>
                <strong>Bildnachweise</strong>
                <ul>
                  <li>Illustrationen: Simone Rahm, Michael Nissen / Lehrstuhl für Maschinelles Lernen und Datenanalytik</li>
                </ul>
            </p>
            <p>
                <strong>Disclaimer</strong>
            </p>
            <p>
                Das Universitätsklinikum hat auf seinen Webseiten Links zu anderen Seiten
                im Internet gelegt. Für alle Links auf diesen Webseiten gilt, dass das
                Uni-Klinikum keinen Einfluss auf die Gestaltung und Inhalte der gelinkten
                Seiten hat. Deshalb distanziert sich das Uni-Klinikum hiermit ausdrücklich
                von allen Inhalten aller gelinkten Seiten und macht sich ihre Inhalte nicht
                zu eigen.
            </p>
            <p>
                Urheberrecht (Copyright)
            </p>
            <p>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
                Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
                Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
                des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
                Autors bzw. Erstellers. Dies gilt insbesondere für Texte, Bilder, Grafiken,
                Stylesheets-, Ton-, Video- oder Multimediadateien, sowie jeglicher Art von
                Programmcode, einschließlich deren Anordnung auf den Webseiten.
            </p>
            <p align="right">
                Stand: 12.04.2022
            </p>          
            </Container>
          </main>
        <Footer/>
      </>
    );
}