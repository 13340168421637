import React from 'react';

import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

export default class BackToMindfulnessOverviewButton extends React.Component {

  render() {
    return (
      <>
        <Button component={Link} to="/mindfulness" variant="contained" color="primary" size="small" disableElevation>
          <KeyboardArrowLeft />
          Zurück zur Übungs-Übersicht
        </Button>
      </>
    );
  }
}