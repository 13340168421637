import React, { useContext, useEffect, useState } from 'react';
import AuthContext from 'services/AuthContext';

export default function PrintLogin(props) {

  const auth = useContext(AuthContext);

  return (
    <>
    { auth.getLogin() }
    </>
  )
}