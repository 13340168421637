import React from 'react';

import { Link } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import './ActivityItem.scss';

export default function ActivityItem(props) {
   const theme = useTheme();

   return (
      <Link to={props.to} className="activity-overview-item">
         <li style={{ backgroundColor: theme.palette.primary.main, borderRadius: "1em" }}>
            <FontAwesomeIcon icon={props.icon} /> {props.title}
            <FontAwesomeIcon className="activity-icon-right" icon={faAngleRight} />
         </li>
      </Link>
   )
}
