import React from 'react';
import axios from 'axios';

import Header from 'components/Header/Header';
import Globals from 'services/Globals';

import { Line } from 'react-chartjs-2';

import { formatISODateToGermanDate } from 'helpers/Helpers';

import 'chartjs-adapter-moment';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TabPanel } from 'components/TabPanel/TabPanel';

import BackToMeasurementsGeneralButton from '../BackToMeasurementsGeneralButton';

import '../Measurements.scss';
import BloodPressureFullGraph from '../Graphs/BloodPressure/BloodPressureFullGraph/BloodPressureFullGraph';
import BloodPressureTendencyGraph from '../Graphs/BloodPressure/BloodPressureTendencyGraph/BloodPressureTendencyGraph';
import BloodPressureIntervalGraph from '../Graphs/BloodPressure/BloodPressureIntervalGraph/BloodPressureIntervalGraph';
import BloodPressureTendencyText from '../Graphs/BloodPressure/BloodPressureTendencyText/BloodPressureTendencyText';
import ObfuscationQuestionnaire from '../ObfuscationStudy/ObfuscationQuestionnaire/ObfuscationQuestionnaire';
import AnswerFewQuestions from '../ObfuscationStudy/AnswerFewQuestions/AnswerFewQuestions';
import Footer from 'components/Footer/Footer';
import NotEnoughMeasurements from '../NotEnoughMeasurementsNotice';
import LoadingNotice from '../LoadingNotice';


class BloodPressure extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      bloodPressureData: {},
      activeTab: 0,
      loading: false
    };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleTabChange(event, newValue) {
    this.setState({
        activeTab: newValue
    });
  }


  componentDidMount() {
    var self = this;

    this.setState({
      loading: true
    })

    axios.get(Globals.SERVER_URL + "/measurements/blood-pressure").then(function(res){
      self.setState({loading: false})
      console.log(res);

      self.setState({
        bloodPressureData: {
          systolicMeasurements: res.data.systolic,
          diastolicMeasurements: res.data.diastolic
        }
      })
    }).catch(function(err){
      // TODO show error
      self.setState({loading: false})
      console.log(err);
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Messwerte</Typography>
        </Header>

        <AppBar position="static" className="margin-bottom-2em">
          <Tabs value={this.state.activeTab} onChange={this.handleTabChange} variant="scrollable" scrollButtons="auto">
            <Tab value={0} label="Alle Daten" />
            <Tab value={1} label="Monatlich" />
            <Tab value={2} label="Wöchentlich" />
            <Tab value={3} label="Text" />
          </Tabs>
        </AppBar>

        <main>
          <Container maxWidth="lg">

            <div className="breadcrumbs">Messwerte &gt; Blutdruck</div>
            <Typography variant="h1">Blutdruck</Typography>
            <BackToMeasurementsGeneralButton />
            <br/><br/>

            <>
              { this.state.loading
              ? <LoadingNotice />
              : (this.state.bloodPressureData.systolicMeasurements && Object.keys(this.state.bloodPressureData.systolicMeasurements).length <= 2)
                ? <NotEnoughMeasurements />
                : <>
                  <TabPanel value={this.state.activeTab} index={0}>
                    <BloodPressureFullGraph data={this.state.bloodPressureData} />
                    <AnswerFewQuestions />
                    <ObfuscationQuestionnaire location="regular-measurements" type="blood-pressure-fullgraph" data={this.state.bloodPressureData} />
                  </TabPanel>
                  <TabPanel value={this.state.activeTab} index={1}>
                    <BloodPressureTendencyGraph data={this.state.bloodPressureData} />
                    <AnswerFewQuestions />
                    <ObfuscationQuestionnaire location="regular-measurements" type="blood-pressure-tendencygraph" data={this.state.bloodPressureData} />
                  </TabPanel>
                  <TabPanel value={this.state.activeTab} index={2}>
                    <BloodPressureIntervalGraph data={this.state.bloodPressureData} />
                    <AnswerFewQuestions />
                    <ObfuscationQuestionnaire location="regular-measurements" type="blood-pressure-intervalgraph" data={this.state.bloodPressureData} />
                  </TabPanel>
                  <TabPanel value={this.state.activeTab} index={3}>
                    <BloodPressureTendencyText data={this.state.bloodPressureData} />
                    <AnswerFewQuestions />
                    <ObfuscationQuestionnaire location="regular-measurements" type="blood-pressure-tendencytext" data={this.state.bloodPressureData} />
                  </TabPanel>
                </>
              }
            </>
          </Container>
        </main>
        <Footer />
      </>
    );
  }
}

export default BloodPressure;