import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import DataContext from 'services/DataContext';


/**
 * Creates several checkboxes that act like radio buttons, i.e. only one option at a time may be selected
 * However, the use of checkboxes allows the user to de-select a previously 
 */


class RadioCheckboxes extends React.Component {

  constructor(props) {
    super(props);
    //this.state = {checkedBox: undefined};
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    let dataContext = this.context;

    // Check whether the user is trying to unselect the box
    //if(event.target.name === this.state.checkedBox) {
    if(event.target.name === dataContext.data[this.props.identifier]) {
      //this.setState({checkedBox: undefined});
      dataContext.update(this.props.identifier, undefined);
    } // Else set the new box
    else {
      //this.setState({checkedBox: event.target.name});
      dataContext.update(this.props.identifier, event.target.name);
    }
    
    //dataContext.update(this.props.identifier, this.state.checkedBox);
  }


  render(props) {
    return (
      <DataContext.Consumer>
        {dataContext => (
          <>
            {
              this.props.options.map(({ value, label }, index) => 
              {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={dataContext.data[this.props.identifier] === value}
                        onChange={this.handleChange}
                        name={value}
                        color="primary"
                      />
                      }
                    label={label}
                    key={value}
                  />
                );
              })
            }
          </>
      )}
    </DataContext.Consumer>
    )
  }
}

RadioCheckboxes.contextType = DataContext;

export default RadioCheckboxes;