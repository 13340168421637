import React from 'react';

import NotificationContext from './NotificationContext';
import NotificationModal from './NotificationModal';

// https://levelup.gitconnected.com/react-template-for-jwt-authentication-with-private-routes-and-redirects-f77c488bfb85

class NotificationContextProvider extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      notifications: [],
      info: "Test!"
    };

    this.push = this.push.bind(this);
    this.clear = this.clear.bind(this);
  }

  /**
   * Add a new notification to the notification stack.
   * As a result, the notification is displayed to the user
   * TODO: Finish implementation
   * 
   * @param {String} newNotification 
   * @param {"error", "info", "warning"} type 
   * @param {Integer} fadeDuration Time after which the notification window should fade automatically.
   * @param {Object} details Additional error information, e.g. err object - will be console.log'd automatically.
   */
  push(newNotification, type, fadeDuration, details) {
    // array.push is inplace, thus we need to clone the object to use setState correctly
    let newArray = this.state.notifications
    newArray.push(newNotification);

    this.setState({
      notifications: newArray
    });

    if(details) {
      console.log(details);
    }

    // TODO Automatically report error
  }

  pop() {

  }

  clear() {
    this.setState({
      notifications: []
    });
  }

  render() {
      return (
          <NotificationContext.Provider
            value={{
              push: this.push,
              popError: this.pop
            }}
          >
            <NotificationModal notifications={this.state.notifications} pop={this.pop} clear={this.clear} />
            {this.props.children}
          </NotificationContext.Provider>
      );
  }
}

export default NotificationContextProvider;