import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import { Typography } from '@material-ui/core';

import withNotifications from 'services/NotificationService/withNotifications';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent from './StandardizedAnswerOptions/PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent';

import '../Questionnaire.scss';
import QuestionnaireHeadNotice from 'components/QuestionnaireHeadNotice/QuestionnaireHeadNotice';

class PSQI extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">PSQI</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/20) * 100 } />
          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />
          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "psqi01": 1,
                "psqi02": 2,
                "psqi03": 3,
                "psqi04": 4,
                "psqi05a": 5,
                "psqi05b": 6,
                "psqi05c": 7,
                "psqi05d": 8,
                "psqi05e": 9,
                "psqi05f": 10,
                "psqi05g": 11,
                "psqi05h": 12,
                "psqi05i": 13,
                "psqi05j_boolean": 14,
                "psqi05j_free_text": 14,
                "psqi05j": 14, 
                "psqi06": 15, /* offset on purpose due to three questions on one page*/
                "psqi07": 16,
                "psqi08": 17,
                "psqi09": 18,
                "psqi10": 19,
                "psqi10a": 19,
                "psqi10b": 19,
                "psqi10c": 19,
                "psqi10d": 19,
                "psqi10e": 19,
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <div style={{textAlign: "center", marginTop: "1em"}}>
                Die folgenden Fragen beziehen sich auf Ihre üblichen Schlafgewohnheiten und zwar nur während der letzten vier Wochen. Ihre Antworten sollten möglichst genau sein und sich auf die Mehrzahl der Tage und Nächte während der letzten vier Wochen beziehen. Beantworten Sie bitte alle Fragen.<br/><br/>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Fragebogen starten</Button>
                </div>
              </StepperPage>
            </Container>

            { this.state.activeStep >= 5 && this.state.activeStep <= 14 &&
              <QuestionnaireHeadNotice>
              Wie oft haben Sie während der letzten vier Wochen schlecht geschlafen,...
              </QuestionnaireHeadNotice>         
            }
            <div className="questionnaire-body">
              <Container maxWidth="lg">
                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wann sind Sie während der letzten vier Wochen gewöhnlich abends zu Bett gegangen?</FormLabel>
                    <CustomErrorMessage name="psqi01" />
                    <Input
                      name="psqi01"
                      label="Uhrzeit auswählen (SS:MM)"
                      type="time"
                      endAdornment={<InputAdornment position="end">Uhr</InputAdornment>}
                      value={this.props.values.psqi01} 
                      onChange={this.props.handleChange}
                    />
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={2}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wie lange hat es während der letzten vier Wochen gewöhnlich gedauert, bis Sie nachts eingeschlafen sind?</FormLabel>
                    <CustomErrorMessage name="psqi02" />
                    <Input
                      name="psqi02"
                      value={this.props.values.psqi02}
                      onChange={this.props.handleChange}
                      endAdornment={<InputAdornment position="end">Minuten</InputAdornment>}
                      inputProps={ { min: 1, max: 500 } } 
                      type="number"
                    />
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={3}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wann sind Sie während der letzten vier Wochen gewöhnlich morgens aufgestanden?</FormLabel>
                    <CustomErrorMessage name="psqi03" />
                    <Input
                      name="psqi03"
                      label="Uhrzeit auswählen (SS:MM)"
                      type="time"
                      endAdornment={<InputAdornment position="end">Uhr</InputAdornment>}
                      value={this.props.values.psqi03} 
                      onChange={this.props.handleChange}
                    />
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={4}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wieviele Stunden haben Sie während der letzten vier Wochen pro Nacht tatsächlich geschlafen? (Das muss nicht mit der Anzahl der Stunden, die Sie im Bett verbracht haben, übereinstimmen.)</FormLabel>
                    <CustomErrorMessage name="psqi04" />
                    <Input
                      name="psqi04"
                      value={this.props.values.psqi04}
                      onChange={this.props.handleChange}
                      endAdornment={<InputAdornment position="end">Stunden pro Nacht</InputAdornment>}
                      inputProps={ { min: 1, max: 24 } } 
                      type="number"
                    />
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={5}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... weil Sie nicht innerhalb von 30 Minuten einschlafen konnten?</FormLabel>
                    <CustomErrorMessage name="psqi05a" />
                    <RadioGroup aria-label="position" name="psqi05a" value={this.props.values.psqi05a} onChange={this.props.handleChange} required={true}>
                      <PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={6}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... weil Sie mitten in der Nacht oder früh morgens aufgewacht sind?</FormLabel>
                    <CustomErrorMessage name="psqi05b" />
                    <RadioGroup aria-label="position" name="psqi05b" value={this.props.values.psqi05b} onChange={this.props.handleChange} required={true}>
                      <PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={7}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... weil Sie aufstehen mussten, um zur Toilette zu gehen?</FormLabel>
                    <CustomErrorMessage name="psqi05c" />
                    <RadioGroup aria-label="position" name="psqi05c" value={this.props.values.psqi05c} onChange={this.props.handleChange} required={true}>
                      <PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={8}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... weil Sie Beschwerden beim Atmen hatten?</FormLabel>
                    <CustomErrorMessage name="psqi05d" />
                    <RadioGroup aria-label="position" name="psqi05d" value={this.props.values.psqi05d} onChange={this.props.handleChange} required={true}>
                      <PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={9}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... weil Sie husten mussten oder laut geschnarcht haben?</FormLabel>
                    <CustomErrorMessage name="psqi05e" />
                    <RadioGroup aria-label="position" name="psqi05e" value={this.props.values.psqi05e} onChange={this.props.handleChange} required={true}>
                      <PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={10}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... weil Ihnen zu kalt war?</FormLabel>
                    <CustomErrorMessage name="psqi05f" />
                    <RadioGroup aria-label="position" name="psqi05f" value={this.props.values.psqi05f} onChange={this.props.handleChange} required={true}>
                      <PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={11}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... weil Ihnen zu warm war?</FormLabel>
                    <CustomErrorMessage name="psqi05g" />
                    <RadioGroup aria-label="position" name="psqi05g" value={this.props.values.psqi05g} onChange={this.props.handleChange} required={true}>
                      <PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={12}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... weil Sie schlecht geträumt hatten?</FormLabel>
                    <CustomErrorMessage name="psqi05h" />
                    <RadioGroup aria-label="position" name="psqi05h" value={this.props.values.psqi05h} onChange={this.props.handleChange} required={true}>
                      <PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={13}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">... weil Sie Schmerzen hatten?</FormLabel>
                    <CustomErrorMessage name="psqi05i" />
                    <RadioGroup aria-label="position" name="psqi05i" value={this.props.values.psqi05i} onChange={this.props.handleChange} required={true}>
                      <PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={14}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">...aus anderen Gründen?</FormLabel>
                    <CustomErrorMessage name="psqi05j_boolean" />
                    <RadioGroup aria-label="position" name="psqi05j_boolean" value={this.props.values.psqi05j_boolean} onChange={this.props.handleChange} required={true}>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Ja" labelPlacement="end"/>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>

                  { this.props.values.psqi05j_boolean === "1" &&
                    <>
                      <div>
                        <CustomErrorMessage name="psqi05j" />
                        <TextField fullWidth name="psqi05j_free_text" label="Bitte beschreiben" value={this.props.values.psqi05j_free_text} onChange={this.props.handleChange} />
                        
                        <b>Und wie oft während des letzten Monats konnten Sie aus diesem Grund schlecht schlafen?</b><br/>
                        <CustomErrorMessage name="psqi05j" />
                        <RadioGroup aria-label="position" name="psqi05j" value={this.props.values.psqi05j} onChange={this.props.handleChange} required={true}>
                          <PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent />
                        </RadioGroup>
                      </div>
                    </>
                   }

                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={15}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wie würden Sie insgesamt die Qualität Ihres Schlafes während der letzten vier Wochen beurteilen?</FormLabel>
                    <CustomErrorMessage name="psqi06" />
                    <RadioGroup aria-label="position" name="psqi06" value={this.props.values.psqi06} onChange={this.props.handleChange} required={true}>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Sehr gut" labelPlacement="end"/>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Ziemlich gut" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Ziemlich schlecht" labelPlacement="end"/>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Sehr schlecht" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={16}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wie oft haben Sie während der letzten vier Wochen Schlafmittel eingenommen (vom Arzt verschriebene oder frei verkäufliche)?</FormLabel>
                    <CustomErrorMessage name="psqi07" />
                    <RadioGroup aria-label="position" name="psqi07" value={this.props.values.psqi07} onChange={this.props.handleChange} required={true}>
                      <PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={17}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Wie oft hatten Sie während der letzten vier Wochen Schwierigkeiten wachzubleiben, etwa beim Autofahren, beim Essen oder bei gesellschaftlichen Anlässen?</FormLabel>
                    <CustomErrorMessage name="psqi08" />
                    <RadioGroup aria-label="position" name="psqi08" value={this.props.values.psqi08} onChange={this.props.handleChange} required={true}>
                      <PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={18}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Hatten Sie während der letzten vier Wochen Probleme, mit genügend Schwung die üblichen Alltagsaufgaben zu erledigen?</FormLabel>
                    <CustomErrorMessage name="psqi09" />
                    <RadioGroup aria-label="position" name="psqi09" value={this.props.values.psqi09} onChange={this.props.handleChange} required={true}>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Keine Probleme" labelPlacement="end"/>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Kaum Probleme" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Etwas Probleme" labelPlacement="end"/>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Große Probleme" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={19}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Schlafen Sie allein in Ihrem Zimmer?</FormLabel>
                    <CustomErrorMessage name="psqi10" />
                    <RadioGroup aria-label="position" name="psqi10" value={this.props.values.psqi10} onChange={this.props.handleChange} required={true}>
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="Ja" labelPlacement="end"/>
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Ja, aber ein Partner/Mitbewohner schläft in einem anderen Zimmer" labelPlacement="end"/>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Nein, der Partner schläft im selben Zimmer, aber nicht im selben Bett" labelPlacement="end"/>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein, der Partner schläft im selben Bett" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  { ["0", "1", "2"].includes(this.props.values.psqi10) &&
                    <>
                      <QuestionnaireHeadNotice>
                        Fragen Sie Ihren Mitbewohner:in/Partner:in bitte, ob und wie oft er/sie bei Ihnen folgendes bemerkt hat.
                      </QuestionnaireHeadNotice> 
                      <FormControl component="fieldset" fullWidth={true}>
                        <FormLabel component="legend">Lautes Schnarchen</FormLabel>
                        <CustomErrorMessage name="psqi10a" />
                        <RadioGroup aria-label="position" name="psqi10a" value={this.props.values.psqi10a} onChange={this.props.handleChange} required={true}>
                          <PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent />
                        </RadioGroup>
                      </FormControl>
                      <FormControl component="fieldset" fullWidth={true}>
                        <FormLabel component="legend">Lange Atempausen während des Schlafes</FormLabel>
                        <CustomErrorMessage name="psqi10b" />
                        <RadioGroup aria-label="position" name="psqi10b" value={this.props.values.psqi10b} onChange={this.props.handleChange} required={true}>
                          <PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent />
                        </RadioGroup>
                      </FormControl>
                      <FormControl component="fieldset" fullWidth={true}>
                        <FormLabel component="legend">Zucken oder ruckartige Bewegungen der Beine während des Schlafes</FormLabel>
                        <CustomErrorMessage name="psqi10c" />
                        <RadioGroup aria-label="position" name="psqi10c" value={this.props.values.psqi10c} onChange={this.props.handleChange} required={true}>
                          <PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent />
                        </RadioGroup>
                      </FormControl>
                      <FormControl component="fieldset" fullWidth={true}>
                        <FormLabel component="legend">Nächtliche Phasen von Verwirrung oder Desorientierung während des Schlafes</FormLabel>
                        <CustomErrorMessage name="psqi10d" />
                        <RadioGroup aria-label="position" name="psqi10d" value={this.props.values.psqi10d} onChange={this.props.handleChange} required={true}>
                          <PSQINeverFourWeeksLessOnceWeekOnceTwiceWeekThreeOrMoreFrequent />
                        </RadioGroup>
                      </FormControl>
                      <FormControl component="fieldset" fullWidth={true}>
                        <FormLabel component="legend">Oder andere Formen von Unruhe während des Schlafes.</FormLabel>
                        <CustomErrorMessage name="psqi10e" />
                        <TextField fullWidth name="psqi10e" label="Bitte beschreiben" value={this.props.values.psqi10e} onChange={this.props.handleChange} />
                      </FormControl>
                    </>
                  }
                    
                    <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                  </StepperPage>


                <StepperPage activeStep={this.state.activeStep} thisStep={20}>
                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                    <Typography variant="h2">Fragebogen absenden</Typography>
                    <p>Vielen Dank! Sie können den Fragebogen nun absenden.</p>
                    <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                  </div>
                </StepperPage>

                {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}       
              </Container>  
            </div>
          </form>
        </main>
      <Footer showMenu={false} />
    </>
    );
  }
}

export default withNotifications(withFormik({
  mapPropsToValues: () => ({
    psqi01: '',
    psqi02: '',
    psqi03: '',
    psqi04: '',
    psqi05a: '',
    psqi05b: '',
    psqi05c: '',
    psqi05d: '',
    psqi05e: '',
    psqi05f: '',
    psqi05g: '',
    psqi05h: '',
    psqi05i: '',
    psqi05j_boolean: '',
    psqi05j_free_text: '',
    psqi05j: '', 
    psqi06: '', /* offset on purpose due to three questions on one page*/
    psqi07: '',
    psqi08: '',
    psqi09: '',
    psqi10: '',
    psqi10a: '',
    psqi10b: '',
    psqi10c: '',
    psqi10d: '',
    psqi10e: '',

  }),  
  validationSchema: Yup.object().shape({
      psqi01: Yup.string().required('Pflichtfeld'),
      psqi02: Yup.number().required('Pflichtfeld'),
      psqi03: Yup.string().required('Pflichtfeld'),
      psqi04: Yup.number().required('Pflichtfeld'),
      psqi05a: Yup.number().required('Pflichtfeld'),
      psqi05b: Yup.number().required('Pflichtfeld'),
      psqi05c: Yup.number().required('Pflichtfeld'),
      psqi05d: Yup.number().required('Pflichtfeld'),
      psqi05e: Yup.number().required('Pflichtfeld'),
      psqi05f: Yup.number().required('Pflichtfeld'),
      psqi05g: Yup.number().required('Pflichtfeld'),
      psqi05h: Yup.number().required('Pflichtfeld'),
      psqi05i: Yup.number().required('Pflichtfeld'),
      psqi05j_boolean: Yup.number().required('Pflichtfeld'),
      psqi05j_free_text: Yup.string().when('psqi05j_boolean', {
        is: 1,
        then: Yup.string().required('Pflichtfeld')
      }),
      psqi05j: Yup.number().when('psqi05j_boolean', {
        is: 1,
        then: Yup.number().required('Pflichtfeld')
      }),
      psqi06: Yup.number().required('Pflichtfeld'),
      psqi07: Yup.number().required('Pflichtfeld'),
      psqi08: Yup.number().required('Pflichtfeld'),
      psqi09: Yup.number().required('Pflichtfeld'),
      psqi10: Yup.number().required('Pflichtfeld'),
      psqi10a: Yup.number().when('psqi10', (value, schema) => {
        console.log(value);
        return ([0, 1, 2].includes(value) ?
          schema.required('Pflichtfeld'): schema)
      }),
      psqi10b: Yup.number().when('psqi10', (value, schema) => {
        console.log(value);
        return ([0, 1, 2].includes(value) ?
          schema.required('Pflichtfeld'): schema)
      }),
      psqi10c: Yup.number().when('psqi10', (value, schema) => {
        return ([0, 1, 2].includes(value) ?
          schema.required('Pflichtfeld'): schema)
      }),
      psqi10d: Yup.number().when('psqi10', (value, schema) => {
        return ([0, 1, 2].includes(value) ?
          schema.required('Pflichtfeld'): schema)
      }),
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "psqi",
      questionnaireData: values,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
    });
  }
})(PSQI));

/* https://stackoverflow.com/questions/59497550/handling-formik-form-when-validation-error-occurs */