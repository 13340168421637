import React from 'react';

import Header from 'components/Header/Header';

import Textfield from 'components/Textfield/Textfield';
import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import './NotableFindings.scss';
import Footer from 'components/Footer/Footer';



class NotableFindings extends React.Component {
    render() {
        return (
          <>
            <Header>
              <h2>Besondere Befunde</h2>
            </Header>
            <Container maxWidth="lg">
              <main>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      27. Behandlungsbedürftige Allgemeinerkrankungen, ggfs. welche <br/>
                      <Textfield identifier="findings_general_conditions_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_general_conditions"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      28. Dauermedikation <br/>
                      <Textfield identifier="findings_permanent_medication_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_permanent_medication"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      29. Abusus <br/>
                      <Textfield identifier="findings_abusus_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_abusus"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      30. Besondere psychische Belastungen <br/>
                      <Textfield identifier="findings_special_psychic_burdens_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_special_psychic_burdens"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      31. Besondere soziale Belastungen <br/>
                      <Textfield identifier="findings_special_social_burdens_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_special_social_burdens"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      32. Blutungen vor der 28. SSW <br/>
                      <Textfield identifier="findings_bleeding_before_week_28_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_bleeding_before_week_28"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      33. Blutungen nach der 28. SSW <br/>
                      <Textfield identifier="findings_bleeding_after_week_28_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_bleeding_after_week_28"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      34. Placenta praevia <br/>
                      <Textfield identifier="findings_placenta_praevia_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_placenta_praevia"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      35. Mehrlingsschwangerschaft <br/>
                      <Textfield identifier="findings_multiple_pregnancy_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_multiple_pregnancy"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      36. Hydramnion <br/>
                      <Textfield identifier="findings_hydramnion_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_hydramnion"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      37. Oligohydramnie <br/>
                      <Textfield identifier="findings_oligohydramnie_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_oligohydramnie"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      38. Terminunklarheit <br/>
                      <Textfield identifier="findings_unclear_date_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_unclear_date"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      39. Placenta-Insuffizienz <br/>
                      <Textfield identifier="findings_placental_insufficiency_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_placental_insufficiency"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      40. Isthmozervikale Insuffizienz <br/>
                      <Textfield identifier="findings_isthmocervical_insufficiency_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_isthmocervical_insufficiency"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      41. Vorzeitige Wehentätigkeit <br/>
                      <Textfield identifier="findings_preterm_labor_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_preterm_labor"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      42. Anämie <br/>
                      <Textfield identifier="findings_anaemia_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_anaemia"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      43. Harnwegsinfektion <br/>
                      <Textfield identifier="findings_urinary_tract_infection_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_urinary_tract_infection"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      44. Indirekter Coombstest positiv <br/>
                      <Textfield identifier="findings_indirect_coombs_test_positive_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_indirect_coombs_test_positive"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      45. Risiko aus anderen serologischen Befunden <br/>
                      <Textfield identifier="findings_risk_other_serologic_findings_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_risk_other_serologic_findings"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                      46. Hypertonie (Blutdruck über 140/90) <br/>
                      <Textfield identifier="findings_hypertonia_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_hypertonia"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                        47. Eiweißausscheidung 1% (entspr. 1000mg/l) oder mehr<br/>
                      <Textfield identifier="findings_proteine_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_proteine"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                        48. Mittelgradige - schwere Ödeme <br/>
                      <Textfield identifier="findings_edema_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_edema"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                        49. Hypotonia <br/>
                      <Textfield identifier="findings_hypotonia_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_hypotonia"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                        50. Gestationsdiabetes <br/>
                      <Textfield identifier="findings_gestational_diabetes_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_gestational_diabetes"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                    <Grid item xs={3}>
                      Vortest:
                    </Grid>
                    <Grid item xs={9}>
                      <RadioCheckboxes
                        identifier="findings_gestational_diabetes_pretest_conducted"
                        options={[
                          { value: "pos", label: 'Durchgeführt' },
                          { value: "neg", label: 'Nicht durchgeführt' },
                        ]} />
                      <hr />
                      <RadioCheckboxes
                        identifier="findings_gestational_diabetes_pretest_suspicious"
                        options={[
                          { value: "pos", label: 'Auffällig' },
                          { value: "neg", label: 'Nicht auffällig' },
                        ]} />
                    </Grid>
                    <Grid item xs={3}>
                      Diagnosetest:
                    </Grid>
                    <Grid item xs={9}>
                      <RadioCheckboxes
                        identifier="findings_gestational_diabetes_diagnosis_test_conducted"
                        options={[
                          { value: "pos", label: 'Durchgeführt' },
                          { value: "neg", label: 'Nicht durchgeführt' },
                        ]} />
                      <hr />
                      <RadioCheckboxes
                        identifier="findings_gestational_diabetes_diagnosis_test_suspicious"
                        options={[
                          { value: "pos", label: 'Auffällig' },
                          { value: "neg", label: 'Nicht auffällig' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                        51. Einstellungsanomalie<br/>
                      <Textfield identifier="findings_fetal_position_anomaly_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_fetal_position_anomaly"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="anamnesis-item">
                    <Grid item xs={9}>
                        52. Andere Besonderheiten <br/>
                      <Textfield identifier="findings_other_peculiarities_remark" label="Bemerkung" />
                    </Grid>
                    <Grid item xs={3}>
                      <RadioCheckboxes
                        identifier="findings_other_peculiarities"
                        options={[
                          { value: "pos", label: 'ja' },
                          { value: "neg", label: 'nein' },
                        ]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item sm={12}>
                      <b>Besonderheiten zu den Katalogen A. und B.</b>
                      <Textfield identifier="pecularities_catalogue_a_b" textarea={true} rows="8"/>
                    </Grid>
                  </Grid>
              </main>
              <Footer />
            </Container>
          </>
        );
      }
    }

export default NotableFindings;