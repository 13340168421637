import React, { useState, useEffect, useLayoutEffect } from 'react';
import { shuffleArray } from 'helpers/Helpers';

export default function ShuffleChildren(props) {
  // Create an array with ascending elements [0, 1, ..., n], where n is the number of elements in props.children
  const [childrenOrder, setChildrenOrder] = useState(Array.from(Array(React.Children.toArray(props.children).length).keys()));
  const [shuffled, setShuffled] = useState(false);

  // Shuffle the array only once before render, which will represent the new rendering order
  useLayoutEffect(() => {
    if(!shuffled) {
        setChildrenOrder(shuffleArray(childrenOrder));    
        setShuffled(true);
    }
  }, [props.children])

  return(
    <>
      {
        childrenOrder.map((order, i) => {
            return(<React.Fragment key={i}>{props.children[order]}</React.Fragment>);
        })
      }
    </>
  )
}