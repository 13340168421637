import { useEffect, useState } from "react";

import React from 'react';

import { Line } from 'react-chartjs-2';

import { getMonthlyAvgData } from "../../GraphUtils/GraphUtils";


const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    }, 
    zoom: {
      pan: {
        enabled: true,
        mode: 'x'
      },
      limits: {
        x: {
          min: 'original',
          max: 'original'
        },
        y: {
          min: 'original',
          max: 'original'
        }
        // axis limits
      },
      zoom: {
        wheel: {
          enabled: true
        },
        pinch: {
          enabled: true
        },
        mode: 'x'
        // zoom options and/or events
      }
    },
    tooltip: {
      callbacks: {
        // Remove the "title", which usually shows a arbitrary date in an incorrect format
        title: function(context) {
          /*console.log(context[0].label);
          return (new MomentUtils())*/
          return "";
        }
      }
    }
  },    
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'month'
      },
      ticks: {
        // display: false, // Remove dates
        /*maxRotation: 0,
        autoSkipPadding: 30,
        includeBounds: true*/
      },
      grid: {
        display: false, // Remove grid
        drawBorder: false, // Remove bottom border
      }
      // type: 'timeseries'
      
    },
    y: {
      ticks: {
        autoSkipPadding: 30,
        mirror: true, // Place inside the graph
        showLabelBackdrop: true, // Add a box around the axis legend
        backdropColor: 'rgba(255, 255, 255, 0.95)', // Make the box white
        z: 1 // Make sure the box appears in front of the chart
      },
      grid: {
        drawBorder: false, // Remove left border
        borderDash: [8, 4], // Make grid dashed
        // display: false
      }
      
    }
  }
};

export default function SleepTendencyGraph(props) {

  const [data, setData] = useState(0);

  useEffect(() => {
    let monthlySleepData = getMonthlyAvgData(props.data);

    if(monthlySleepData !== undefined) {
      setData({
        labels: Object.keys(monthlySleepData),
        datasets: [
          {
            data: Object.values(monthlySleepData),
            fill: false,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
          },
        ],
      });
    }
  }, [props])

  return (
    <>
      <div className="data-plot">
        <Line data={data} options={options} className="data-plot" />
      </div>

      <p className="graph-subtitle"><b>Durchschnittliche Schlafdauer (Stunden/Nacht) nach Monat</b><br/>
      </p>
    </>
  )
}

