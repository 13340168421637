import React from "react";
import { Typography } from "@material-ui/core";

export default function AnswerFewQuestions() {
  return (
    <>
      <div style={{height: "50px"}} />
      <Typography variant="h3">Beantworten Sie einige kurze Fragen...</Typography>
      Das Beantworten dauert weniger als 90 Sekunden. <br/>
    </>
  );
}