import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import withNotifications from 'services/NotificationService/withNotifications';

import AuthContext from '../../services/AuthContext';


import './Start.scss';
import Footer from 'components/Footer/Footer';
import { Paper } from '@material-ui/core';
import NewlyRegisteredDialog from './NewlyRegisteredDialog/NewlyRegisteredDialog';

import bmgAcknowledgement from 'resources/img/bmg_acknowledgement.png'
import fauLogo from 'resources/img/fau_logo.svg'
import ukerLogo from 'resources/img/uker_logo.svg'



function Start(props) {

  const [pseudonym, setPseudonym] = useState("");
  const [password, setPassword] = useState("");
  const [newlyRegisteredPseudonym, setNewlyRegisteredPseudonym] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const history = useHistory();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    if ( urlParams.get('app_id') && urlParams.get('app_id') != "" ) {
      setPseudonym(urlParams.get('app_id'));
      setNewlyRegisteredPseudonym(urlParams.get('app_id'));
      setDialogOpen(true);
    }
  }, []);

  async function login() {
    authContext.login(pseudonym, password).then(function(res){
      history.push('/overview');
    }).catch(function(err){
      if(err.response.status === 401) {
        props.notificationService.push("Ungültige App-ID oder Passwort.", "error");
      }
      else if (err.response.status === 500) {
        props.notificationService.push("Interner Serverfehler.", "error", 0, err); 
      }
      else {
        props.notificationService.push("Unbekannter sonstiger Fehler.", "error", 0, err);
      }
    });
  }

  return (
    <>
      <div className="start-container">
        <div className="smartstart-logo">
        </div>
        <div className="digital-maternity-log-caption">
          <span style={{color: "#ff6bae", fontSize: "3em"}}>Studien-App<br/><br/></span>
        </div>
        <NewlyRegisteredDialog dialogOpen={dialogOpen} pseudonym={newlyRegisteredPseudonym} setDialogOpen={setDialogOpen} />
        <div>
          <Container maxWidth="lg">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField name="pseudonym" label="App-ID" value={pseudonym} onChange={e => setPseudonym(e.target.value)} variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField name="password" label="Passwort" type="password" value={password} onChange={e => setPassword(e.target.value)} autoComplete="current-password" variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" style={{color: "white", fontSize: "20px", textTransform: "none"}} disableElevation onClick={login}>Anmelden</Button><br/>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div style={{marginTop: "2em"}}>
          <p>Noch nicht registriert?</p>
          <Link to="/register" style={{color: "white", textDecoration: "none"}}>
            <Button variant="contained" color="secondary" disableElevation style={{textTransform: "none"}}>
              Jetzt registrieren
            </Button>
          </Link>
        </div>
        <div style={{marginTop: "1em"}}><Link to="/forgot-password">Passwort vergessen?</Link></div>
        <div className="smartstart-text"><span style={{color: "#783476"}}>SMART</span> <span style={{color: "#ff6bae"}}>Start</span></div>
        <div className="partner-logos" style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}} >
        <img src={bmgAcknowledgement} style={{width: "200px", height: "auto"}} />
        <img src={fauLogo} style={{width: "250px", height: "auto"}} />
        <img src={ukerLogo} style={{width: "250px", height: "auto"}} />
        </div>
      </div>
      <Footer showMenu={false} />
    </>
  );
}

export default withNotifications(Start);