import React, { useMemo, useState, useEffect } from "react";

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

/* Key app functionalities */
import Start from './scenes/Start/Start';
import Login from './scenes/Start/Login/Login';
import Register from './scenes/Start/Register/Register';
import StartDMR from './scenes/StartDMR/StartDMR';

import Profile from './scenes/Profile/Profile';
import ChangePassword from 'scenes/Profile/ChangePassword/ChangePassword';
import Customize from "scenes/Profile/Customize/Customize";
import MyPreviousPregnancies from "scenes/Profile/MyPreviousPregnancies/MyPreviousPregnancies";

/* Setup */
import InitialQuestionnaire from './scenes/Setup/InitialQuestionnaire/InitialQuestionnaire';
import WithingsSetup from './scenes/Setup/WithingsSetup/WithingsSetup';
import PushNotificationSetup from './scenes/Setup/PushNotificationSetup/PushNotificationSetup';
import AddToHomeScreenSetup from 'scenes/Setup/AddToHomeScreenSetup/AddToHomeScreenSetup';
import DeliveryNote from "scenes/Setup/DeliveryNote/DeliveryNote";

import Overview from './scenes/Overview/Overview';
import Tasks from './scenes/Tasks/Tasks';

import MaternityRecordPhoto from 'scenes/Photos/MaternityRecordPhoto/MaternityRecordPhoto';
import Urinalysis from 'scenes/Photos/Urinalysis/Urinalysis';

import AnalyticsRouterObserver from 'services/Analytics/AnalyticsRouterObserver';

/* Questionnaires */
import PPAQ from './scenes/Questionnaires/PPAQ/PPAQ';
import PRAQ_R2 from './scenes/Questionnaires/PRAQ_R2/PRAQ_R2';
import EPDS from './scenes/Questionnaires/EPDS/EPDS';
import PSQI from 'scenes/Questionnaires/PSQI/PSQI';
import MAPI from './scenes/Questionnaires/MAPI/MAPI';
import PSS_10 from 'scenes/Questionnaires/PSS_10/PSS_10';
import PHBS from 'scenes/Questionnaires/PHBS/PHBS';
import GesOek1 from 'scenes/Questionnaires/GesOek1/GesOek1';
import CSS_15 from 'scenes/Questionnaires/CSS_15/CSS_15';
import CSS_15_FU from 'scenes/Questionnaires/CSS_15/CSS_15_FU';
import UEQ from './scenes/Questionnaires/UEQ/UEQ';
import SUS from './scenes/Questionnaires/SUS/SUS';
import SocialDemographics from "scenes/Questionnaires/SocialDemographics/SocialDemographics";
import PreviousPregnanciesQuestionnaire from "scenes/Questionnaires/PreviousPregnancies/PreviousPregnancies";

import Questionnaires from './scenes/Questionnaires/Questionnaire';

/* Digital Maternity Record */
import Ultrasound from 'scenes/MaternityRecord/Ultrasound/Ultrasound';
import Anamnesis from 'scenes/MaternityRecord/Anamnesis/Anamnesis';
import LabRubella from "scenes/MaternityRecord/LabRubella/LabRubella";
import PreviousPregnancies from "scenes/MaternityRecord/PreviousPregnancies/PreviousPregnancies";
import Consultation from "scenes/MaternityRecord/Consultation/Consultation";
import NotableFindings from "scenes/MaternityRecord/NotableFindings/NotableFindings";
import DateOfBirthDetermination from "scenes/MaternityRecord/DateOfBirthDetermination/DateOfBirthDetermination";
import Gravidogram from "scenes/MaternityRecord/Gravidogram/Gravidogram";
import InPatientStay from "scenes/MaternityRecord/InPatientStay/InPatientStay";
import Cardiotocography from "scenes/MaternityRecord/Cardiotocography/Cardiotocography";
import Epicrisis from "scenes/MaternityRecord/Epicrisis/Epicrisis";
import MaternityRecordOverview from 'scenes/MaternityRecord/MaternityRecord';
import PregnancyCard from "scenes/MaternityRecord/PregnancyCard/PregnancyCard";

/* My pregnancy week */
import MyPregnancyWeek from "scenes/MyPregnancyWeek/MyPregnancyWeek";

/* Information section */
import General from './scenes/Informations/General/General';
import AlcoholNicotine from './scenes/Informations/General/AlcoholNicotine';
import Nutrition from './scenes/Informations/DuringPregnancy/Nutrition';
import Nausea from './scenes/Informations/DuringPregnancy/Nausea';
import MyBody from './scenes/Informations/DuringPregnancy/MyBody';
import LifeTravelSauna from './scenes/Informations/DuringPregnancy/LifeTravelSauna';
import Sports from './scenes/Informations/DuringPregnancy/Sports';
import SkinHair from './scenes/Informations/DuringPregnancy/SkinHair';
import Sex from './scenes/Informations/DuringPregnancy/Sex';
import Emotions from './scenes/Informations/DuringPregnancy/Emotions';
import MaternityRecord from 'scenes/Informations/PerinatalCare/MaternityRecord';
import UltrasoundExaminations from 'scenes/Informations/PerinatalCare/UltrasoundExaminations';
import PrenatalDiagnostics from 'scenes/Informations/PerinatalCare/PrenatalDiagnostics';
import Midwives from 'scenes/Informations/PerinatalCare/Midwives';
import Examinations from 'scenes/Informations/PerinatalCare/Examinations';
import PregnancyIllnesses from 'scenes/Informations/PerinatalCare/PregnancyIllnesses';
import StemCells from 'scenes/Informations/PerinatalCare/StemCells';
import BirthPreparationClass from 'scenes/Informations/Birth/BirthPreparationClass';
import Birth from 'scenes/Informations/Birth/Birth';
import PainManagement from 'scenes/Informations/Birth/PainManagement';
import BirthChecklist from 'scenes/Informations/Birth/BirthChecklist';
import Puerperal from 'scenes/Informations/Puerperal/Puerperal';
import Nursing from 'scenes/Informations/Puerperal/Nursing';
import Informations from './scenes/Informations/Informations';

/* Mindfulness */
import MindfulnessOverview from "scenes/Mindfulness/MindfulnessOverview";
import MindfulnessIntroduction from "scenes/Mindfulness/MindfulnessIntroduction/MindfulnessIntroduction";

/* Measurements */
import HeartRate from 'scenes/Measurements/HeartRate/HeartRate';
import Sleep from 'scenes/Measurements/Sleep/Sleep';
import BloodPressure from 'scenes/Measurements/BloodPressure/BloodPressure';
import Measurements from 'scenes/Measurements/Measurements';
import ObfuscationStudy from "scenes/Measurements/ObfuscationStudy/ObfuscationStudy";


/* Misc */
import PrivateRoute from './components/PrivateRoute/PrivateRoute';

/* Journal */
import Journal from 'scenes/Journal/journal';

import DataContextProvider from './services/DataContextProvider';
import AuthContextProvider from './services/AuthContextProvider';
import NotificationContextProvider from './services/NotificationService/NotificationContextProvider';


import {
  purple,
  green,
  teal,
  pink
} from "@material-ui/core/colors";

import './App.scss';

import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import axios from 'axios';
import Globals from 'services/Globals';
import ScrollToTop from "helpers/ScrollToTop";
import ActivitiesFabRouterObserver from "components/ActivitiesFab/ActivitiesFabRouterObserver";
import IndividualPreviousPregnancy from "scenes/Questionnaires/PreviousPregnancies/IndividualPreviousPregnancy";
import GesOek2 from "scenes/Questionnaires/GesOek2/GesOek2";
import AdminUsers from "scenes/Admin/AdminUsers/AdminUsers";
import AdminMeasurements from "scenes/Admin/AdminMeasurements/AdminMeasurements";
import CalendarInvites from "scenes/Setup/CalendarInvites/CalendarInvites";
import ForgotPassword from "scenes/Start/ForgotPassword/ForgotPassword";
import BloodPressureUsability from "scenes/Questionnaires/WearableUsability/BloodPressureUsability/BloodPressureUsability";
import ScaleUsability from "scenes/Questionnaires/WearableUsability/ScaleUsability/ScaleUsability";
import UrineUsability from "scenes/Questionnaires/WearableUsability/UrineUsability/UrineUsability";
import SmartWatchUsability from "scenes/Questionnaires/WearableUsability/SmartWatchUsability/SmartWatchUsability";
import SleepUsability from "scenes/Questionnaires/WearableUsability/SleepUsability/SleepUsability";
import HelpContact from "scenes/HelpContact/HelpContact";
import Bodyscan from "scenes/Mindfulness/Bodyscan/Bodyscan";
import Yoga from "scenes/Mindfulness/Yoga/Yoga";
import SittingMeditation from "scenes/Mindfulness/SittingMeditation/SittingMeditation";
import MindfulnessSos from "scenes/Mindfulness/MindfulnessSos/MindfulnessSos";
import Imprint from "scenes/Legal/Imprint/Imprint";
import TermsOfUse from "scenes/Legal/TermsOfUse/TermsOfUse";
import DataPrivacy from "scenes/Legal/DataPrivacy/DataPrivacy";
import More from "scenes/More/More";
import Children from "scenes/MaternityRecord/Children/Children";
import RetractConsent from "scenes/Profile/RetractConsent/RetractConsent";

const palettes = {
  purplePalette: {
    primary: {
      main: '#783476',
      light: '#eaabff'
    },
    secondary: {
      main: '#e7b7eb',
      light: '#ffd7eb'
    },
    success: {
      main: '#5db35f',
    }
  },
  greenPalette: {
    primary: {
      main: green[700]
    },
    secondary: {
      main: green[100]
    }
  },
  cyanPalette: {
    primary: {
      main: teal[500]
    },
    secondary: {
      main: teal[100]
    }
  },
}

export const DarknessContext = React.createContext();
export const PaletteContext = React.createContext();

function App() {

  const [darkMode, setDarkMode] = useState(false);
  const [palette, setPalette] = useState("purplePalette");

  useEffect(() => {
    axios.get(Globals.SERVER_URL + "/settings/colorPalette").then(function(res) {
      if(res.data.length !== 0) {
        let userPalette = res.data;
        setPalette(userPalette)  
      }
    });
  }, []);

  const theme = React.useMemo(() =>
    createTheme({
      typography: {
        fontFamily: ['Montserrat', 'sans-serif'],
        h1: {
          color: palettes[palette].primary.main,
          fontSize: '2.5rem',
          fontWeight: 500
        },
        h2: {
          color: palettes[palette].primary.main,
          fontSize: '2rem',
          fontWeight: 500
        },
        h3: {
          color: palettes[palette].primary.main,
          fontSize: '1.5rem',
          fontWeight: 500
        },
      },
      palette: {
        type: darkMode ? "dark" : "light",
        ...palettes[palette]
      },
      shape: {
        borderRadius: 25
      }
    }),
    [darkMode, palette],
  );


  return (
    <DarknessContext.Provider value={{ darkMode, setDarkMode }}>
      <PaletteContext.Provider value={{ palette, setPalette }}>
        <ThemeProvider theme={theme}>
          <DataContextProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <CssBaseline/>
              <Router>
                <ScrollToTop />
                <NotificationContextProvider>
                  <AuthContextProvider>
                    { /* <ActivitiesFabRouterObserver /> */ }
                    <AnalyticsRouterObserver />
                    <Switch>
                      <Route path="/login" component={Login} />
                      <Route path="/register" component={Register} />
                      <Route path="/forgot-password" component={ForgotPassword} />
                      <Route path="/mutterpass" component={StartDMR} />

                      <Route path="/legal/imprint" component={Imprint} />
                      <Route path="/legal/terms-of-use" component={TermsOfUse} />
                      <Route path="/legal/privacy" component={DataPrivacy} />

                      <PrivateRoute path="/admin/users" component={AdminUsers} />
                      <PrivateRoute path="/admin/measurements" component={AdminMeasurements} />

                      <PrivateRoute path="/more" component={More} />

                      <PrivateRoute path="/profile/change-password" component={ChangePassword} />
                      <PrivateRoute path="/profile/customize" component={Customize} />
                      <PrivateRoute path="/profile/my-previous-pregnancies" component={MyPreviousPregnancies} />
                      <PrivateRoute path="/profile/retract-consent" component={RetractConsent} />
                      <PrivateRoute path="/profile" component={Profile} />

                      <PrivateRoute path="/setup/initial-questionnaire" component={InitialQuestionnaire} />
                      <PrivateRoute path="/setup/withings-devices" component={WithingsSetup} />
                      <PrivateRoute path="/setup/push-notifications" component={PushNotificationSetup} />
                      <PrivateRoute path="/setup/a2hs" component={AddToHomeScreenSetup} />
                      <PrivateRoute path="/setup/delivery-note" component={DeliveryNote} />
                      <PrivateRoute path="/setup/calendar-invites" component={CalendarInvites} />

                      <PrivateRoute path="/overview" component={Overview} />
                      <PrivateRoute path="/tasks" component={Tasks} />

                      <PrivateRoute path="/maternity-record/lab-rubella" component={LabRubella} />
                      <PrivateRoute path="/maternity-record/previous-pregnancies" component={PreviousPregnancies} />
                      <PrivateRoute path="/maternity-record/consultation" component={Consultation} />
                      <PrivateRoute path="/maternity-record/anamnesis" component={Anamnesis} />
                      <PrivateRoute path="/maternity-record/notable-findings" component={NotableFindings} />
                      <PrivateRoute path="/maternity-record/dob-determination" component={DateOfBirthDetermination} />
                      <PrivateRoute path="/maternity-record/gravidogram" component={Gravidogram} />
                      <PrivateRoute path="/maternity-record/in-patient-stay" component={InPatientStay} />
                      <PrivateRoute path="/maternity-record/cardiotocography" component={Cardiotocography} />
                      <PrivateRoute path="/maternity-record/ultrasound" component={Ultrasound} />
                      <PrivateRoute path="/maternity-record/epicrisis" component={Epicrisis} />
                      <PrivateRoute path="/maternity-record/pregnancy-card" component={PregnancyCard} />
                      <PrivateRoute path="/maternity-record/children" component={Children} />
                      <PrivateRoute path="/maternity-record" component={MaternityRecordOverview} />

                      <PrivateRoute path="/questionnaires/ppaq" component={PPAQ} />
                      <PrivateRoute path="/questionnaires/praq-r2" component={PRAQ_R2} />
                      <PrivateRoute path="/questionnaires/epds" component={EPDS} />
                      <PrivateRoute path="/questionnaires/psqi" component={PSQI} />
                      <PrivateRoute path="/questionnaires/mapi" component={MAPI} />
                      <PrivateRoute path="/questionnaires/pss-10" component={PSS_10} />
                      <PrivateRoute path="/questionnaires/phbs" component={PHBS} />
                      <PrivateRoute path="/questionnaires/gesoek-1" component={GesOek1} />
                      <PrivateRoute path="/questionnaires/gesoek-2" component={GesOek2} />
                      <PrivateRoute path="/questionnaires/css-15" component={CSS_15} />
                      <PrivateRoute path="/questionnaires/css-15-fu" component={CSS_15_FU} />
                      <PrivateRoute path="/questionnaires/sus" component={SUS} />
                      <PrivateRoute path="/questionnaires/ueq" component={UEQ} />
                      <PrivateRoute path="/questionnaires/social-demographics" component={SocialDemographics} />
                      <PrivateRoute path="/questionnaires/previous-pregnancies" component={PreviousPregnanciesQuestionnaire} />
                      <PrivateRoute path="/questionnaires/individual-previous-pregnancy" component={IndividualPreviousPregnancy} />
                      <PrivateRoute path="/questionnaires/blood-pressure-usability" component={BloodPressureUsability} />
                      <PrivateRoute path="/questionnaires/scale-usability" component={ScaleUsability} />
                      <PrivateRoute path="/questionnaires/urine-usability" component={UrineUsability} />
                      <PrivateRoute path="/questionnaires/smart-watch-usability" component={SmartWatchUsability} />
                      <PrivateRoute path="/questionnaires/sleep-usability" component={SleepUsability} />



                      <PrivateRoute path="/questionnaires" component={Questionnaires} />
                      
                      <PrivateRoute path="/photos/maternity-record-photo" component={MaternityRecordPhoto} />
                      <PrivateRoute path="/photos/urinalysis" component={Urinalysis} />

                      <PrivateRoute path="/my-pregnancy-week" component={MyPregnancyWeek} />
                      
                      <PrivateRoute path="/informations/general/general" component={General} />
                      <PrivateRoute path="/informations/general/alcohol-nicotine" component={AlcoholNicotine} />
                      <PrivateRoute path="/informations/during-pregnancy/nutrition" component={Nutrition} />
                      <PrivateRoute path="/informations/during-pregnancy/nausea" component={Nausea} />
                      <PrivateRoute path="/informations/during-pregnancy/my-body" component={MyBody} />
                      <PrivateRoute path="/informations/during-pregnancy/life-travel-sauna" component={LifeTravelSauna} />
                      <PrivateRoute path="/informations/during-pregnancy/sports" component={Sports} />
                      <PrivateRoute path="/informations/during-pregnancy/skin-hair" component={SkinHair} />
                      <PrivateRoute path="/informations/during-pregnancy/sex" component={Sex} />
                      <PrivateRoute path="/informations/during-pregnancy/emotions" component={Emotions} />
                      <PrivateRoute path="/informations/perinatal-care/maternity-record" exact component={MaternityRecord} />
                      <PrivateRoute path="/informations/perinatal-care/ultrasound-examinations" component={UltrasoundExaminations} />
                      <PrivateRoute path="/informations/perinatal-care/prenatal-diagnostics" component={PrenatalDiagnostics} />
                      <PrivateRoute path="/informations/perinatal-care/midwives" component={Midwives} />
                      <PrivateRoute path="/informations/perinatal-care/examinations" component={Examinations} />
                      <PrivateRoute path="/informations/perinatal-care/pregnancy-illnesses" component={PregnancyIllnesses} />
                      <PrivateRoute path="/informations/perinatal-care/stem-cells" component={StemCells} />
                      <PrivateRoute path="/informations/birth/birth-preparation-class" component={BirthPreparationClass} />
                      <PrivateRoute path="/informations/birth/birth" component={Birth} />
                      <PrivateRoute path="/informations/birth/pain-management" component={PainManagement} />
                      <PrivateRoute path="/informations/birth/birth-checklist" component={BirthChecklist} />
                      <PrivateRoute path="/informations/puerperal/puerperal" component={Puerperal} />
                      <PrivateRoute path="/informations/puerperal/nursing" component={Nursing} />
                      <PrivateRoute path="/informations" component={Informations} />

                      <PrivateRoute path="/mindfulness/introduction" component={MindfulnessIntroduction} />
                      <PrivateRoute path="/mindfulness/bodyscan" component={Bodyscan} />
                      <PrivateRoute path="/mindfulness/yoga" component={Yoga} />
                      <PrivateRoute path="/mindfulness/sitting-meditation" component={SittingMeditation} />
                      <PrivateRoute path="/mindfulness/sos" component={MindfulnessSos} />
                      <PrivateRoute path="/mindfulness" component={MindfulnessOverview} />

                      <PrivateRoute path="/measurements/heart-rate" component={HeartRate} />
                      <PrivateRoute path="/measurements/sleep" component={Sleep} />
                      <PrivateRoute path="/measurements/blood-pressure" component={BloodPressure} />
                      <PrivateRoute path="/measurements/study" component={ObfuscationStudy} />
                      <PrivateRoute path="/measurements" component={Measurements} />

                      <PrivateRoute path="/help-contact" component={HelpContact} />
                      

                      <PrivateRoute path="/journal" component={Journal} />
                      <Route path="/" component={Start} />
                    </Switch>
                  </AuthContextProvider>
                </NotificationContextProvider>
              </Router>
            </MuiPickersUtilsProvider>
          </DataContextProvider>
        </ThemeProvider>
      </PaletteContext.Provider>
    </DarknessContext.Provider>
  );
}

export default App;
