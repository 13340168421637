import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Checkbox, FormGroup, TextField, Typography } from '@material-ui/core';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import withNotifications from 'services/NotificationService/withNotifications';

import 'scenes/Questionnaires/Questionnaire.scss';
import QuestionnaireHeadNotice from 'components/QuestionnaireHeadNotice/QuestionnaireHeadNotice';
import UXCheckbox from '../Components/UXCheckbox/UXCheckbox';
import FormikMaterialRadioGroups from 'components/Forms/FormikMaterialRadioGroup/FormikMaterialRadioGroup';
import { YesOnceYesSeveralNo } from '../StandardizedAnswerOptions/YesOnceYesSeveralNo/YesOnceYesSeveralNo';
import { AgreeDisagreeLikert } from '../StandardizedAnswerOptions/YesOnceYesSeveralNo/AgreeDisagreeLikert';


//import Textfield from '../../components/Textfield/Textfield';
//import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';


class SleepUsability extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Usability: Schlaf-Messungen</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/12) * 100 } />
          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />
          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "ux_sleep_01_yn": 1,
                "ux_sleep_02": 2,
                "ux_sleep_03": 3,
                "ux_sleep_04": 4,
                "ux_sleep_05": 5,
                "ux_sleep_06": 6,
                "ux_sleep_07": 7,
                "ux_sleep_08": 8,
                "ux_sleep_09": 9,
                "ux_sleep_10": 10,
                "ux_sleep_11": 11,
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <div style={{textAlign: "center", marginTop: "1em"}}>
                Lassen Sie uns wissen, was Sie von der Nutzung der Schlafmatte halten. Bitte füllen Sie dazu den folgenden Fragebogen vollständig und ehrlich aus. Wählen Sie die Antwortoption, die für Sie am ehesten zutrifft.<br/><br/>
                  <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Fragebogen starten</Button>
                </div>
              </StepperPage>
            </Container>

            { this.state.activeStep >= 4 && this.state.activeStep <= 12 &&
              <QuestionnaireHeadNotice>
                In wieweit stimmen Sie der folgenden Aussage zu?
              </QuestionnaireHeadNotice>
            }

            <div className="questionnaire-body">
              <Container maxWidth="lg">
                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Haben Sie Ihren Schlaf durchgehend mit der Schlafmatte aufgezeichnet?</FormLabel>
                    <CustomErrorMessage name="ux_sleep_01_yn" />
                    <RadioGroup aria-label="position" name="ux_sleep_01_yn" value={this.props.values.ux_sleep_01_yn} onChange={this.props.handleChange} required={true}>
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Ja" labelPlacement="end"/>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>

                  { this.props.values.ux_sleep_01_yn === "0" &&
                  <>
                    <b>Wenn Sie Messungen nicht gemacht haben, was waren die Gründe dafür (Mehrfachantwort möglich)?</b>
                    <UXCheckbox label="Die Schlafmatte ließ sich nicht bedienen." name="ux_sleep_01a" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    <UXCheckbox label="Ich hatte keinen Stromanschluss in der Nähe." name="ux_sleep_01b" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    <UXCheckbox label="Ich hatte die Schlafmatte nicht zur Hand." name="ux_sleep_01c" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    <UXCheckbox label="Ich hatte Sicherheitsbedenken." name="ux_sleep_01d" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    <UXCheckbox label="Ich hatte keine Zeit." name="ux_sleep_01e" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    <UXCheckbox label="Ich hatte keine Lust." name="ux_sleep_01f" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    <UXCheckbox label="Ich habe vergessen, die Schlafmatte zu aktivieren." name="ux_sleep_01g" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    <UXCheckbox label="Die Schlafmatte war aktiv, aber hat meinen Schlaf nicht erfasst." name="ux_sleep_01h" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    <UXCheckbox label="Andere Gründe" name="ux_sleep_01i" formikValues={this.props.values} formikSetFieldValue={this.props.setFieldValue} />
                    { 
                      this.props.values.ux_sleep_01i === "1" &&
                      <>
                        <FormControl component="fieldset" fullWidth={true}>
                          <CustomErrorMessage name="ux_sleep_01i_txt" />
                          <TextField fullWidth name="ux_sleep_01i_txt" label="Anderer Grund" value={this.props.values.ux_sleep_01i_txt} onChange={this.props.handleChange} />
                        </FormControl>
                      </>
                    }
                  </>
                  }
                  <ForwardBackwardButtons handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={2}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Haben Sie aufgrund eines gemessenen Wertes einen Arzt kontaktiert, z.B. Ihren Frauenarzt/Ihre Frauenärztin oder Ihren Studienarzt/Ihre Studienärztin?"
                    name="ux_sleep_02" 
                    options={YesOnceYesSeveralNo}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={3}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Sind Sie aufgrund eines gemessenen Wertes zum Arzt gegangen, z.B. zu Ihrem Frauenarzt/Ihrer Frauenärztin oder in die Frauenklinik?"
                    name="ux_sleep_03" 
                    options={YesOnceYesSeveralNo}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={4}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Ich fand es einfach, die Schlafmatte zu verwenden."
                    name="ux_sleep_04" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={5}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Ich habe mich im Umgang mit der Schlafmatte unsicher gefühlt."
                    name="ux_sleep_05" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={6}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Die Schlafmatte in meinen Alltag zu integrieren war unproblematisch."
                    name="ux_sleep_06" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={7}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Die Schlafmatte zu verwenden war lästig."
                    name="ux_sleep_07" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={8}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Die engmaschige Überwachung meines Schlafes hat mich beruhigt."
                    name="ux_sleep_08" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={9}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Ich würde die Schlafmatte zu Hause nur über einen kurzen Zeitraum, z.B. einen Monat, verwenden."
                    name="ux_sleep_09" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={10}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Ich würde die Schlafmatte zu Hause auch über die gesamte Schwangerschaft verwenden."
                    name="ux_sleep_10" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={11}>
                  <FormikMaterialRadioGroups formikValues={this.props.values} formikHandleChange={this.props.handleChange}
                    label="Die Analyse meines Schlafes zu Hause wäre für mich eine sinnvolle Ergänzung zur Vorsorge beim Frauenarzt/bei der Frauenärztin."
                    name="ux_sleep_11" 
                    options={AgreeDisagreeLikert}
                  />
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={12}>
                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                  <Typography variant="h2">Fragebogen absenden</Typography>
                  Vielen Dank! Sie können den Fragebogen nun absenden.<br/><br/>
                  <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                  </div>
                </StepperPage>

                {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}       
              </Container>  
            </div>
          </form>
        </main>
      <Footer showMenu={false} />
    </>
    );
  }
}

export default withNotifications(withFormik({
  mapPropsToValues: () => ({
    ux_sleep_01_yn: '',
    ux_sleep_01a: '',
    ux_sleep_01b: '',
    ux_sleep_01c: '',
    ux_sleep_01d: '',
    ux_sleep_01e: '',
    ux_sleep_01f: '',
    ux_sleep_01g: '',
    ux_sleep_01h: '',
    ux_sleep_01i: '',
    ux_sleep_01i_txt: '',
    ux_sleep_02: '',
    ux_sleep_03: '',
    ux_sleep_04: '',
    ux_sleep_05: '',
    ux_sleep_06: '',
    ux_sleep_07: '',
    ux_sleep_08: '',
    ux_sleep_09: '',
    ux_sleep_10: '',
    ux_sleep_11: '',
  }),  
  validationSchema: Yup.object().shape({
    ux_sleep_01_yn: Yup.number().required('Pflichtfeld'),
    ux_sleep_02: Yup.number().required('Pflichtfeld'),
    ux_sleep_03: Yup.number().required('Pflichtfeld'),
    ux_sleep_04: Yup.number().required('Pflichtfeld'),
    ux_sleep_05: Yup.number().required('Pflichtfeld'),
    ux_sleep_06: Yup.number().required('Pflichtfeld'),
    ux_sleep_07: Yup.number().required('Pflichtfeld'),
    ux_sleep_08: Yup.number().required('Pflichtfeld'),
    ux_sleep_09: Yup.number().required('Pflichtfeld'),
    ux_sleep_10: Yup.number().required('Pflichtfeld'),
    ux_sleep_11: Yup.number().required('Pflichtfeld'),
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "ux_sleep",
      questionnaireData: values,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
    });
  }
})(SleepUsability));

/* https://stackoverflow.com/questions/59497550/handling-formik-form-when-validation-error-occurs */