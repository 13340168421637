import React from 'react';
import axios from 'axios';
import { Typography } from '@material-ui/core';

import Header from 'components/Header/Header';
import Globals from 'services/Globals';

import { Line } from 'react-chartjs-2';

import { formatISODateToGermanDate } from 'helpers/Helpers';


import Container from '@material-ui/core/Container';

import BackToMeasurementsGeneralButton from '../BackToMeasurementsGeneralButton';

import HeartRateTendencyGraph from '../Graphs/HeartRate/HeartRateTendencyGraph/HeartRateTendencyGraph';
import HeartRateIntervalGraph from '../Graphs/HeartRate/HeartRateIntervalGraph/HeartRateIntervalGraph';
import HeartRateTendencyText from '../Graphs/HeartRate/HeartRateTendencyText/HeartRateTendencyText';
import HeartRateFullGraph from '../Graphs/HeartRate/HeartRateFullGraph/HeartRateFullGraph';


import SleepFullGraph from '../Graphs/Sleep/SleepFullGraph/SleepFullGraph';

import '../Measurements.scss';
import SleepIntervalGraph from '../Graphs/Sleep/SleepIntervalGraph/SleepIntervalGraph';
import SleepTendencyGraph from '../Graphs/Sleep/SleepTendencyGraph/SleepTendencyGraph';
import SleepTendencyText from '../Graphs/Sleep/SleepTendencyText/SleepTendencyText';
import BloodPressureFullGraph from '../Graphs/BloodPressure/BloodPressureFullGraph/BloodPressureFullGraph';
import BloodPressureTendencyGraph from '../Graphs/BloodPressure/BloodPressureTendencyGraph/BloodPressureTendencyGraph';
import BloodPressureIntervalGraph from '../Graphs/BloodPressure/BloodPressureIntervalGraph/BloodPressureIntervalGraph';
import BloodPressureTendencyText from '../Graphs/BloodPressure/BloodPressureTendencyText/BloodPressureTendencyText';
import ObfuscationQuestionnaire from './ObfuscationQuestionnaire/ObfuscationQuestionnaire';
import ShuffleChildren from 'components/ShuffleChildren/ShuffleChildren';
import Footer from 'components/Footer/Footer';



class ObfuscationStudy extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      heartRateData: {},
      sleepData: {},
      bloodPressureData: {},
      test: "hi"
    };

    this.test = this.test.bind(this);

  }

  componentDidMount() {
    var self = this;

    /**
     * Heart rate
     */
    axios.get(Globals.SERVER_URL + "/measurements/summary/all").then(function(res){
      let allDataPerDayList = res.data;
      let heartRatePerDayList = {};

      for (let day in allDataPerDayList) {
        let thisDayHeartRate = allDataPerDayList[day].hr_average;

        // Remove zero values
        if (thisDayHeartRate !== 0) {
          heartRatePerDayList[day] = thisDayHeartRate;
        }
      }

      self.setState({
          heartRateData: heartRatePerDayList
      });
    }).catch(function(err){
      // TODO show error
      console.log(err);
    });



    /**
     * Sleep
     */

    axios.get(Globals.SERVER_URL + "/measurements/sleep", {
    }).then(function(res){
      let allDataPerDayList = res.data;
      let sleepPerDayList = {};

      for (let day in allDataPerDayList) {
        let thisSleepRate = allDataPerDayList[day].total_sleep_time;

        // Remove zero values
        if (thisSleepRate !== 0) {
          sleepPerDayList[day] = (thisSleepRate/3600);
        }
      }

      self.setState({
        sleepData: sleepPerDayList
      });
    }).catch(function(err){
      // TODO show error
      console.log(err);
    });

    /**
     * Blood pressure
     */
     axios.get(Globals.SERVER_URL + "/measurements/blood-pressure").then(function(res){
      self.setState({
        bloodPressureData: {
          systolicMeasurements: res.data.systolic,
          diastolicMeasurements: res.data.diastolic
        }
      })      
    }).catch(function(err){
      // TODO show error
      console.log(err);
    });
  }

  test() {
    this.setState({test: "blub"})
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Messwerte-Studie</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            <ShuffleChildren>            
              <React.Fragment>
                <Typography variant="h1">Herzfrequenz</Typography>
                <HeartRateFullGraph data={this.state.heartRateData} />
                <HeartRateTendencyGraph data={this.state.heartRateData} />
                <HeartRateIntervalGraph data={this.state.heartRateData} />
                <HeartRateTendencyText data={this.state.heartRateData} />
              </React.Fragment>

              <React.Fragment>
                <Typography variant="h1">Schlaf</Typography>
                <SleepFullGraph data={this.state.sleepData} />
                <SleepIntervalGraph data={this.state.sleepData} />
                <SleepTendencyGraph data={this.state.sleepData} />
                <SleepTendencyText data={this.state.sleepData} />
              </React.Fragment>

              <React.Fragment>
                <Typography variant="h1">Blutdruck</Typography>
                <BloodPressureFullGraph data={this.state.bloodPressureData} />
                <BloodPressureTendencyGraph data={this.state.bloodPressureData} />
                <BloodPressureIntervalGraph data={this.state.bloodPressureData} />
                <BloodPressureTendencyText data={this.state.bloodPressureData} />
              </React.Fragment>
            </ShuffleChildren>

            

            <ObfuscationQuestionnaire />
          </Container>
        </main>
        <Footer />
      </>
    );
  }
}

export default ObfuscationStudy;