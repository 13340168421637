import React from "react";
import { useLocation } from "react-router-dom";
import Analytics from 'services/Analytics/Analytics';

export default  function AnalyticsRouterObserver() {
  
  let location = useLocation();
  React.useEffect(() => {
    Analytics.emit("pageview", "change", {path: location.pathname});
  }, [location]);

  return null;
}