import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class Nausea extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Während der Schwangerschaft &gt; Übelkeit und Erbrechen</div>

            <BackToInformationsGeneralButton />
            
            <Typography variant="h1">Übelkeit und Erbrechen</Typography>

            <p><b>Vor allem in den frühen Wochen haben Sie oft mit Übelkeit und Erbrechen zu kämpfen. Hier erfahren Sie Tipps und Tricks.</b></p>

            <p>Für viele Frauen gehört Übelkeit und Erbrechen in der Frühschwangerschaft zur Schwangerschaft einfach dazu. Insgesamt leiden rund 50% aller Schwangeren an Übelkeit und Erbrechen und 25% nur an Übelkeit. Medizinisch gehört es sogar zu den unsicheren Schwangerschaftsanzeichen. Jedoch kann diese Symptomatik bei jedem sehr unterschiedlich sein. Einige werden Übelkeit nur bei bestimmten Lebensmitteln oder Gerüchen bekommen, andere werden kaum bis gar nicht daran leiden. Für einige wenige wird Erbrechen jedoch nicht nur gelegentlich auftreten und ein wenig störend sein, sondern regelmäßig und dazu führen, dass ein normaler Tagesablauf und auch eine Nahrungsaufnahme nur noch geringfügig bis gar nicht mehr möglich ist. Wenn die Schwangere an dauerhaftem Erbrechen mit Dehydratation, Elektrolytverschiebung und mehr als 5% Verlust des Körpergewichts leidet,  spricht man von einer Hyperemsis gravidarum, einem übernormalen Erbrechen. Diese Erkrankung kommt bei etwa 0,2-2% der Schwangerschaften vor. </p>

            <Typography variant="h2">Besprechung mit der Ärztin/dem Arzt</Typography>
            <p>Falls Ihnen das jetzt ganz bekannt vorkommt, dann ist es für Sie und auch Ihr Kind wichtig dies mit Ihrem Frauenarzt zu besprechen. Ihr Frauenarzt kann untersuchen, ob dieses Erbrechen noch im Normalbereich der Schwangerschaft liegt, oder ob dies erkrankungsbedingt ist und eine Hyperemesis gravidarum vorliegt. Diese kann durch bestimmte Veränderung der Essgewohnheiten oder Medikamente behandelt werden. In seltenen Fällen kann auch ein stationärer Aufenthalt im Krankenhaus mit Infusionen (Flüssigkeit, Medikamente oder Ernährung über die Vene) notwendig sein. Doch jetzt keine Angst, Erbrechen und Morgenübelkeit haben Sie meistens nur im ersten Trimenon der Schwangerschaft und die Beschwerden sind danach in der Regel rückläufig. Insgesamt hat leichte Übelkeit und Erbrechen keine negativen Folgen für Ihr Kind.  Wenn Sie jedoch keine Nahrung oder Flüssigkeit mehr aufnehmen können, sollten Sie Ihren Arzt oder am Wochenende ein Krankenhaus aufsuchen. Denn diese schweren Formen der Hyperemesis können auch auf die kindliche Entwicklung Auswirkungen haben. Die Ursachen der Hyperemesis gravidarum sind nicht ganz geklärt, man geht zum einen von einer Umstellung des weiblichen Stoffwechsels und Hormonhaushaltes und der erhöhten Menge an HCG (Humanes Choriongonadotropin), einem Schwangerschaftshormon, das bis zur 12.-14. SSW stetig ansteigt, als Ursache aus. Ebenso kann eine Mehrlingsschwangerschaft vermehrte Übelkeit verursachen, wie auch eine psychische Belastungssituation oder Stress. Auch Vitamin B-Mangel, und eine reduzierte Motilität der Speiseröhre können Ursachen sein. </p>

            <Typography variant="h2">Muster bei Übelkeit und Erbrechen</Typography>
            <p>Wichtig zu wissen ist, dass Übelkeit und Erbrechen in der Schwangerschaft häufig eigenen Mustern folgt, deshalb ist es manchmal hilfreich ein Tagebuch zu führen, in dem man vermerkt unter welchen Bedingungen die Übelkeit besonders ausgeprägt und unter welchen sie kaum vorhanden war. Vielleicht kann man die auslösenden Faktoren einfach vermeiden. </p>
            
            <Typography variant="h2">Tipps: Ernährung und Mahlzeiten</Typography>
            <p>Insgesamt sollten Sie bei vermehrter Übelkeit versuchen eher mehrere kleine Mahlzeiten pro Tag zu sich zu nehmen. Die Ernährung sollte idealerweise ausgewogen sein, bei Dauerübelkeit sollte man das zu sich nehmen, was man am besten verträgt. Scharf, saure, stark gewürzte oder fettige Mahlzeiten oder Flüssigkeiten mit Kohlensäure sollten Sie eher vermeiden. Zudem sollten die Speisen und auch Getränke nicht zu heiß oder kalt sein. Bei Morgenübelkeit oder Erbrechen, kann eine kleine Mahlzeit bereits morgens im Bett eingenommen werden. Ein Zwieback, Salzstangen oder Pfefferminztee sind dafür optimal, danach sollte man noch etwa 20-30 Minuten liegen bleiben. </p>
            <p>Halten Sie über den Tag verteilt immer kleine Mahlzeiten (Kekse oder Joghurt) bereit, denn bei leerem Magen tritt die Übelkeit häufiger auf. </p>
            <p>Vermeiden Sie Stress beim Essen, aber auch in Ihrem Tagesablauf. Gönnen Sie sich ein paar Ruhepausen am Tag. Vitamin B-Präparate, Medikamente gegen Übelkeit und Erbrechen und weitere Medikamente bitte nur in Rücksprache mit Ihrem Arzt einnehmen! </p>
            <p>Doch bevor man gleich zu Medikamenten greift, kann man einen Akupressurpunkt aus der traditionellen chinesischen Medizin ausprobieren. Er wird Nei Kuan „die Barriere“ genannt; und liegt etwa 3cm unterhalb des Handgelenks in der kleinen Grube zwischen den Sehnen und ist somit der 6 Punkt auf dem Meridian der Gefäße. Diesen kann man zwischen durch immer mal wieder für 3 Sekunden drücken oder man kann sich ein spezielles Akupressurarmband aus der Apotheke kaufen, das diesen Akupressurpunkt stimuliert.  </p>
            <p>Ein Geheimtipp, den jede Schwangere mit Übelkeit mal ausprobieren kann, ist Vitamin B6 (20mg p.o.3xtgl.) und Ingwer, z.B. als Tee oder Plätzchen. Ingwer-Pulver war in einer wissenschaftlichen Studie wirksamer als ein Placebo ohne dabei fruchtschädigend zu sein.  </p>
            <p>Falls die Nahrungs- oder Flüssigkeitsaufnahme eingeschränkt ist,  man Gewicht verliert oder sich sehr häufig erbricht, kann der Frauenarzt Medikamente verschreiben.  Dimenhydrinat (Vomex) als Zäpfchen kann in der Schwangerschaft eingenommen werden, ebenso kann Metoclopramid (MCP) eine Hilfe sein. Bitte nehmen Sie Medikamente nur in Rücksprache mit Ihrem Frauenarzt ein! </p>
            
            <Typography variant="h2">Wenn alles nichts hilft</Typography>
            <p>Sollte all dies nicht helfen, können ein stationärer Aufenthalt mit intravenöser Gabe von Flüssigkeit- und Glukoselösungen sowie die Substitution von Vitaminen sinnvoll sein. </p>
            
            <Typography variant="h2">Positiv denken</Typography>
            <p>Und nicht vergessen, positiv denken und durchhalten, nach dem ersten Schwangerschaftsdrittel ist die Symptomatik meist rückläufig oder ganz vorbei.</p>

            <p><b>Mit diesen Tipps kommen Sie gut durch die ersten Wochen. Ganz wichtig ist auch die psychische Gesundheit in dieser Zeit. Suchen Sie gezielt Ruhe und Erholung, auch dies kann vermehrte Übelkeit und Erbrechen reduzieren.</b></p>

            

          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default Nausea;