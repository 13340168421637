import React from 'react';

import Header from '../../../components/Header/Header';
import Footer from 'components/Footer/Footer';

import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import BackToLastPage from '../../../components/BackToLastPage/BackToLastPage';

export default function TermsOfUse() {
    return (
      <>
        <Header>
          <Typography variant="h2">Nutzungsbedingungen</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            <Typography variant="h2">Nutzungsbedingungen</Typography>
            <BackToLastPage />
            <p>
              <strong>Universitätsklinikum Erlangen Nutzungsbedingungen</strong>
            </p>
            <p>
                Vielen Dank, dass Sie einen Dienst nutzen, der Ihnen vom
                Universitätsklinikum Erlangen, Maximiliansplatz 2, 91054 Erlangen zur
                Verfügung gestellt wird.
            </p>
            <p>
                Lesen Sie diese Nutzungsbedingungen bitte sorgfältig durch, bevor Sie von
            unseren Diensten Gebrauch machen. Durch die Verwendung unserer Anwendung    <strong> </strong>SMART Start erklären Sie Ihr Einverständnis mit diesen
                Nutzungsbedingungen und stimmen zu, die Bedingungen einzuhalten.
            </p>
            <p>
                <strong>Nutzungsbedingungen für die Software</strong>
            </p>
            <p>
                <strong>a. Nutzung der Software</strong>
            </p>
            <p>
                Das Universitätsklinikum gewährt Ihnen den kostenlosen Zugriff auf Software
                in Form einer Progressive Web App. Die Nutzung der Software ist nur zu
                privaten und nichtkommerziellen Zwecken im Rahmen der Teilnahme an der
                Studie „Smarte Sensorik in der Schwangerschaft – Ein integratives Konzept
                zur digitalen, präventiven Versorgung schwangerer Frauen (SMART Start)“
                gestattet.
            </p>
            <p>
                <strong>b. </strong>
                <strong>Registrierung </strong>
            </p>
            <p>
                <a name="_heading=h.gjdgxs"></a>
                Sie erhalten die Software auf Ihr persönliches Smartphone. Es ist Ihnen
                nicht gestattet, die Benutzer-Account-Daten einer anderen Person zu
                verwenden. Das Universitätsklinikum kann für jegliche Verluste oder
                Beschädigungen, die durch eine Unterlassung der Erfüllung dieser
                Verpflichtung durch Sie entstehen, keine Haftung übernehmen und wird dies
                auch nicht tun.
            </p>
            <p>
                Für Sie, als teilnehmende Person hat die Frauenklinik des
                Universitätsklinikums Erlangen einen persönlichen Datenspeicher angelegt.
                Sie sind verpflichtet, die Zugangsdaten vertraulich zu behandeln und
                Dritten gegenüber nicht zu offenbaren.
            </p>
            <p>
                <strong>c. Haftung</strong>
            </p>
            <p>
                Obgleich sich das Universitätsklinikum stets bemüht, sein digitales Angebot
                fortwährend schadsoftwarefrei zu halten, kann das Universitätsklinikum die
                Schadsoftwarefreiheit nicht in allen denkbaren Fällen, z. B. Kopie und
                Veränderung von Softwareangeboten durch Dritte, garantieren.
            </p>
            <p>
                Das Universitätsklinikum übernimmt keine Haftung für:
            </p>
            <ul>
              <li>Ausfall oder Verzögerungen der Internetverbindung,</li>
              <li>jederzeitige Verfügbarkeit und/oder Aktualisierung der Software,</li>
              <li>Datenverlust bei Ihnen infolge von vermeidbarem Fehlgebrauch der Software oder</li>
              <li>Verteilung von Schadsoftware, ohne dass das Universitätsklinikum seine Sorgfaltspflichten schuldhaft verletzt hat.</li>
            </ul>
            <p>
                Das Universitätsklinikum haftet für Vorsatz und grobe Fahrlässigkeit
                unbeschränkt. Bei leichter Fahrlässigkeit haftet das Universitätsklinikum
                nur bei der Verletzung von Pflichten, die zur Erreichung des Vertragszwecks
                von wesentlicher Bedeutung sind und dann auch nur in Höhe des
                typischerweise vorhersehbaren Schadens. Die vorstehenden
                Haftungsbeschränkungen gelten nicht bei Verletzung von Leben, Körper und
                Gesundheit, im Anwendungsbereich des Produkthaftungsgesetzes sowie im Fall
                der Übernahme ausdrücklicher Garantien.
            </p>
            <p>
                Wir weisen hiermit ausdrücklich darauf hin, dass es sich bei der
                vorliegenden Software um kein Medizinprodukt gemäß der EU Richtlinie
                93/42/EWG oder der EU Medizinprodukteverordnung 2017/745 handelt.
            </p>
            <p>
                Die eingegebenen Daten und Werte dienen lediglich der Visualisierung und
                dürfen nicht als Basis für eine therapeutische Maßnahme herangezogen
                werden. Die Software ist nicht Teil eines diagnostisch medizinischen
                Systems und ersetzt ein solches auch nicht.
            </p>
            <p>
                Unsere Anwendung bietet eine kostenlose und unverbindliche Dienstleistung
                im Rahmen der Teilnahme an der Studie und kann in keinem Fall im
                Zusammenhang mit dem Abschluss eines Vertrages oder einer anderen
                rechtsbindenden Vereinbarung geltend gemacht werden.
            </p>
            <p>
                <strong>d. Urheberrechte </strong>
            </p>
            <p>
                Der gesamte Inhalt der App (insbesondere Text, Grafik, Logos, Button-Icons,
                Bilder, etc.) ist urheberrechtlich geschützt. Ohne vorherige schriftliche
                Einwilligung vom Universitätsklinikum dürfen die Inhalte nicht
                vervielfältigt, nachgeahmt, öffentlich wiedergegeben oder auf sonstige
                Weise verwertet oder genutzt werden. Urheberrechtshinweise, Quellenangaben
                und Markenbezeichnungen dürfen weder verändert noch beseitigt werden.
            </p>
            <p>
                <strong>3. Schlussbestimmungen</strong>
            </p>
            <p>
                Das Universitätsklinikum behält sich das Recht vor, diese
                Nutzungsbedingungen jederzeit zu ändern. Das Universitätsklinikum wird Sie
                hierüber in dieser Applikation informieren.
            </p>
            <p>
                Sollten einzelne Regelungen dieser Nutzungsbedingungen unwirksam sein oder
                werden, wird dadurch die Wirksamkeit der übrigen Regelungen nicht berührt.
                Sofern Teile dieser Nutzungsbedingungen der geltenden Rechtslage nicht,
                nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen
                Teile in ihrem Inhalt und ihrer Gültigkeit davon unberührt und der
                ungültige Teil gilt als durch einen solchen ersetzt, der dem Sinn und Zweck
                des unwirksamen Teils bzw. dem Parteiwillen in rechtswirksamer Weise
                wirtschaftlich am nächsten kommt.
            </p>
            <p>
                Auf die vorliegenden Bestimmungen findet deutsches Recht Anwendung.
                Gerichtsstand ist, soweit gesetzlich zulässig, der Sitz des
                Universitätsklinikums in Erlangen.
            </p>
            <p>
                <a name="_heading=h.30j0zll"></a>
                Erlangen im April 2022
            </p>
          </Container>
        </main>
        <Footer/>
      </>
    );
}