import React from 'react';

import { Link } from 'react-router-dom'

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

import womanMbsrBelly from 'resources/svgs/woman-mbsr-belly.svg';

function MBSRCard() {
  const theme = useTheme(); 

  return (
    <Link to="/mindfulness" className="overview-card">
      <Card>
        <CardActionArea>
          <CardMedia image={womanMbsrBelly} title="Meine Aufgaben" className="overview-card-media" style={{backgroundSize: "60%", backgroundPosition: "center"}} style={{backgroundColor: theme.palette.secondary.main}} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2" style={{color: theme.palette.primary.main }}>
              Achtsamkeitsübungen
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Achtsam durch die Schwangerschaft. <br />
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" variant="contained" disableElevation>
            Jetzt ansehen
          </Button>
        </CardActions>
      </Card>
    </Link>
  );
}

export default MBSRCard;
