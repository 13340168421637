import React from 'react';

import Header from 'components/Header/Header';
import DataList from 'components/DataList/DataList';

import CardiotocographyList from './components/CardiotocographyList';
import CardiotocographyEditScreen from './components/CardiotocographyEditScreen';


import Container from '@material-ui/core/Container';
import Footer from 'components/Footer/Footer';

class Cardiotocography extends React.Component {

  render() {

    return (
          <>
            <Header>
              <h2>Cardiotokographische Befunde</h2>
            </Header>
            <main>
              <Container>
                <DataList dbKey="cardiotocography" listItem={<CardiotocographyList/>} editScreen={<CardiotocographyEditScreen/>} />
              </Container>
            </main>
            <Footer />
          </>
    );
  }
}

export default Cardiotocography;