import React from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


import Header from 'components/Header/Header';

import Container from '@material-ui/core/Container';

import DataContext from 'services/DataContext';

import './PreviousPregnancies.scss';
import Footer from 'components/Footer/Footer';



class PreviousPregnancies extends React.Component {


  static dataContext = DataContext;

  constructor(props) {
    super(props);
    this.state = {
      view: "list",
      elementData: {}
    }
    this.changeView = this.changeView.bind(this);
    this.changeElementDataValue = this.changeElementDataValue.bind(this);
    this.createUpdateData = this.createUpdateData.bind(this);
  }

  changeView() {
    if(this.state.view === "list") {
      this.setState({view: "edit"});
    }
    else {
      this.setState({view: "list"});
    }
  }

  changeElementDataValue(evt)  {
    const value = evt.target.value;
    /*this.setState({
      ...this.state,
      [evt.target.name]: value
    });*/


    this.setState({
      elementData: {
        ...this.state.elementData, [evt.target.name]: value
      }
    });

  }



  createUpdateData() {
    let dataContext = this.context;

    let previousPregnanciesArray = [];
    // Check if any data already exists
    if(dataContext.data["previous_pregnancies"] && dataContext.data["previous_pregnancies"].length >= 1) {
      // We have to copy the array, as .push operates inplace and returns len(array), but not the newly created array
      previousPregnanciesArray = dataContext.data["previous_pregnancies"];
      previousPregnanciesArray.push(this.state.elementData);
    }
    else {
      previousPregnanciesArray = [this.state.elementData];
    }
    
    dataContext.update("previous_pregnancies", previousPregnanciesArray);

    this.setState({view: "list"});
  }


  render() {

    return (
      <DataContext.Consumer>
        {dataContext => (
          <>
            <Header>
              <h2>Vorangegangene Schwangerschaften</h2>
            </Header>
            <main>
              <Container>
              { this.state.view == "list" &&
                <>
                  <Button variant="contained" color="primary" onClick={this.changeView} disableElevation>Neuer Eintrag</Button>
                  {
                    dataContext.data["previous_pregnancies"] && 
                    dataContext.data["previous_pregnancies"].map((value, index) => {
                      return (
                        <div className="previous-pregnancies-item" key={index}>
                          <div>{ value["year"] }: {value["outcome"]} ({ value["duration"] })</div>
                          <div>{ value["notes"] }</div>
                        </div>
                      )
                    })
                  }
                  </>
              }
              { this.state.view == "edit" &&
                <>
                  Jahr: <TextField name="year" value={this.state.elementData["year"] || ""} onChange={this.changeElementDataValue}></TextField><br/>
                  Ausgang: <TextField name="outcome" value={this.state.elementData["outcome"] || ""} onChange={this.changeElementDataValue}></TextField><br/>
                  SS-Dauer in W.: <TextField name="duration" value={this.state.elementData["duration"] || ""} onChange={this.changeElementDataValue}></TextField><br/>
                  Notizen (Geschlecht, Gewicht, Verlauf, Komplikationen): <TextField name="notes" value={this.state.elementData["notes"] || ""} onChange={this.changeElementDataValue}></TextField><br/>
                  <Button variant="contained" color="primary" onClick={this.createUpdateData} disableElevation>Speichern</Button>
                </>

              }
              
              </Container>
            </main>
            <Footer />
          </>
        )}
      </DataContext.Consumer>
    );
  }
}

PreviousPregnancies.contextType = DataContext;

export default PreviousPregnancies;