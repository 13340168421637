import React from 'react';
import DataContext from './DataContext';

class DataContextProvider extends React.Component {
    state = {
      anamnesis_adipositas: "neg",
      anamnesis_age: "32",
      anamnesis_allergy_medication: "pos",
      anamnesis_allergy_medication_remark: "Heuschnupfen",
      anamnesis_blood_transfusions: "neg",
      anamnesis_complications_post_partum: "pos",
      anamnesis_complications_post_partum_remark: "Atonie postpartal",
      anamnesis_diabetes: "neg",
      anamnesis_dwarfism: "neg",
      anamnesis_familial_anamnesis: "pos",
      anamnesis_familial_anamnesis_remark: "Diabetes, Hypertonie",
      anamnesis_gravida: "V",
      anamnesis_hemorrhage: "neg",
      anamnesis_miscarriages: "neg",
      anamnesis_multipara: "neg",
      anamnesis_other_uterus_operations: "neg",
      anamnesis_over_35: "neg",
      anamnesis_own_severe_conditions: "pos",
      anamnesis_own_severe_conditions_remark: "Hypothyreose",
      anamnesis_para: "III",
      anamnesis_pregnancy_risk: "pos",
      anamnesis_premature_birth: "neg",
      anamnesis_previous_birth_complications: "neg",
      anamnesis_previous_dead_impaired_child: "neg",
      anamnesis_rhesus_incompability: "neg",
      anamnesis_sectio: "pos",
      anamnesis_short_pregnancy_pause: "pos",
      anamnesis_size: "170",
      anamnesis_skeletal_anomalies: "pos",
      anamnesis_skeletal_anomalies_remark: "Skoliose",
      anamnesis_small_for_gestational_age_remark: "2015",
      anamnesis_special_psychic_burdens: "neg",
      anamnesis_special_social_burdens: "neg",
      anamnesis_sterility_treatment: "neg",
      anamnesis_under_18: "neg",
      anamnesis_weight_before_pregnancy: "70",
      consultation_birth_preparation_gym: "pos",
      consultation_cancer_early_detection_examination: "pos",
      consultation_hiv_antibodies_test: "pos",
      consultation_hiv_antibodies_test_conducted: "pos",
      consultation_nutrition_medicaton_drugs: "pos",
      consultation_profession_sports_travel: "pos",
      consultation_risk: "pos",
      consultation_oral_health: "pos",
      dob_determination_calculated_delivery_date: "02.08.2020",
      dob_determination_cycle: "28/7",
      dob_determination_date_pregnancy_ascertained: "15.12.2020",
      dob_determination_date_pregnancy_ascertained_week: "6+0",
      dob_determination_last_period: "3.11.2019",
      dob_determination_remark: "Mutterschutz: 01. Juli 2020",
      findings_gestational_diabetes_diagnosis_test_conducted: "pos",
      findings_gestational_diabetes_diagnosis_test_suspicious: "neg",
      findings_gestational_diabetes_pretest_conducted: "pos",
      findings_gestational_diabetes_pretest_suspicious: "neg",
      findings_permanent_medication: "pos",
      findings_preterm_labor: undefined,
      pecularities_catalogue_a_b: "CAVE Clexane im Wochenbett!\nToxoplasmose/CMV abgelehnt!",
      ultrasound_basic_III_additional_examination: "neg",
      ultrasound_basic_III_amniotic_fluid_volume_control_indication: "neg",
      ultrasound_basic_III_biometry_III: ".",
      ultrasound_basic_III_body_development_control_indication: "neg",
      ultrasound_basic_III_heart_action: "pos",
      ultrasound_basic_III_placenta_localisation_structure: "normal",
      ultrasound_basic_III_pregnancy_week_corrected: "31+6",
      ultrasound_basic_III_remarks: "~2900g (45 Perz)",
      ultrasound_basic_III_singleton: "pos",
      ultrasound_basic_III_timely_development: "pos",
      ultrasound_basic_II_additional_examination: "pos",
      ultrasound_basic_II_amniotic_fluid_volume_control_indication: "neg",
      ultrasound_basic_II_bladder_depictable: "pos",
      ultrasound_basic_II_body_development_control_indication: "neg",
      ultrasound_basic_II_cerebellum: "neg",
      ultrasound_basic_II_consipicous_heart_thorax_relation: "neg",
      ultrasound_basic_II_date: "10.03.",
      ultrasound_basic_II_disruption_frontal_abdomen: "neg",
      ultrasound_basic_II_dorsal_skin_contour_irregularities: "neg",
      ultrasound_basic_II_head_form_abnormalities: "neg",
      ultrasound_basic_II_heart_action: "pos",
      ultrasound_basic_II_heart_four_chamber_depictable: "pos",
      ultrasound_basic_II_left_heart_position: "pos",
      ultrasound_basic_II_persistent_arrhythmia_during_examination: "neg",
      ultrasound_basic_II_placenta_localisation_structure: "normal",
      ultrasound_basic_II_pregnancy_week: "",
      ultrasound_basic_II_pregnancy_week_corrected: "19+2",
      ultrasound_basic_II_remarks: "Organscreening auf Pat Wunsch",
      ultrasound_basic_II_singleton: "pos",
      ultrasound_basic_II_stomach_left_upper_abdomen_depictable: "pos",
      ultrasound_basic_II_timely_development: "pos",
      ultrasound_basic_II_ventricular_abnormalities: "neg",
      ultrasound_basic_I_additional_examination: "neg",
      ultrasound_basic_I_date: "20.12",
      ultrasound_basic_I_embryo: "pos",
      ultrasound_basic_I_heart_action: "pos",
      ultrasound_basic_I_intrauterine_position: "pos",
      ultrasound_basic_I_monochorial: "neg",
      ultrasound_basic_I_multifetal: "neg",
      ultrasound_basic_I_pregnancy_week: "6+5",
      ultrasound_basic_I_pregnancy_week_corrected: "7+5",
      ultrasound_basic_I_suspicious: "neg",
      ultrasound_basic_I_timely_development: "control",
      lab_rubella: [
          {
              "type": "blood_group",
              "result": "ab-",
              "date": "08.01.2020",
              "lab_nr": "3898183081",
              "remark": "Entnommen aus 1. MP!"
          },
          {
              "type": "antibodies_search_test",
              "result": "negative",
              "date": "11.05.2020",
              "lab_nr": "587988456231"
          },
          {
              "type": "rubella_antibody_test",
              "result": "",
              "immunity": "yes"
          },
          {
              "type": "chlamydia_trachomatis",
              "result": "negative",
              "date": "11.05.2020",
              "lab_nr": "89898455321"
          },
          {
              "type": "antibodies_search_test",
              "result": "negative",
              "name": "Kontrolle",
              "date": "06.04.2020"
          },
          {
              "type": "hiv_examination",
              "state": "done",
              "date": "13.05.2020",
              "lab_nr": "588948452"
          },
          {
              "type": "antibodies_search_test",
              "name": "AK Kontrolle",
              "result": "negative",
              "date": "10.07.2020"
          },
          {
              "type": "others",
              "name": "Nachweis von HBs-Antigen aus dem Serum",
              "result": "negative",
              "date": "10.07.2020"
          }
      ],  
      previous_pregnancies: [
        {
            "year": "2010",
            "outcome": "Spontan",
            "duration": "40+0",
            "notes": "Spontan männ"
        },
        {
            "year": "2015",
            "outcome": "prim Sektio",
            "duration": "38+4",
            "notes": "2800g UKER"
        },
        {
            "year": "2019",
            "outcome": "VE",
            "duration": "39+4",
            "notes": "3400g W Z.n. Atonie"
        },
        {
            "year": "2008",
            "outcome": "Abort + Curettage ",
            "duration": "14",
            "notes": ""
        }
    ],
      in_patient_stays: [
        {date_start: '20.05.2020', date_end: '05.06.2020', hospital: 'UKER', diagnosis: 'Vorz. WTK (Cx 23cm)', therapy: 'ASS-Gabe Cx 27cm'},
      ],
      cardiotocography: [
        {date: '20.05.2020', pregnancy_week: '29+3', assessment: 'CTG normal, Kontraindikation Cervix'},
        {date: '06.06.2020', pregnancy_week: '31+6', assessment: 'Normal'},
      ],
      gravidogram: [
        {
            "type": "",
            "date": "01.12.2019",
            "gestation_week": "",
            "gestation_week_corrected": "5+1",
            "fundal_height": "S",
            "fetal_position": "/",
            "heart_tones": "/",
            "child_movement": "/",
            "edema": "/",
            "varikosis": "/",
            "weight": "70",
            "blood_pressure": "120/70",
            "haemoglobin_erythrocytes": "12",
            "proteine": "/",
            "glucose": "/",
            "nitrite": "/",
            "blood": "/",
            "vaginal_examination": "PH 4, Bakto oB",
            "risk_no": "",
            "others_therapy_treatment": "Folio Start"
        },
        {
            "type": "",
            "date": "15.12.2019",
            "gestation_week_corrected": "6+0",
            "fundal_height": "S",
            "fetal_position": "/",
            "heart_tones": "+",
            "child_movement": "/",
            "edema": "/",
            "varikosis": "/",
            "weight": "69,3",
            "blood_pressure": "114/69",
            "haemoglobin_erythrocytes": "12",
            "proteine": "/",
            "glucose": "7",
            "nitrite": "7",
            "blood": "7",
            "vaginal_examination": "ph4, opB",
            "others_therapy_treatment": "Start ASS 150mg ab 12+0 SSW"
        },
        {
            "type": "",
            "date": "15.01.2020",
            "gestation_week_corrected": "11+3",
            "fundal_height": "N/S",
            "fetal_position": "QL",
            "heart_tones": "+",
            "child_movement": "/",
            "edema": "/",
            "varikosis": "/",
            "weight": "69,4",
            "blood_pressure": "110/70",
            "haemoglobin_erythrocytes": "13",
            "proteine": "-",
            "glucose": "oB",
            "nitrite": "oB",
            "blood": "oB",
            "vaginal_examination": "opB",
            "risk_no": "28",
            "others_therapy_treatment": "VHD, Jod"
        },
        {
            "type": "",
            "date": "13.02.2020",
            "gestation_week_corrected": "15+5",
            "fetal_position": "N-1",
            "heart_tones": "QL",
            "child_movement": "/",
            "edema": "-",
            "varikosis": "-",
            "weight": "71",
            "blood_pressure": "120/80",
            "haemoglobin_erythrocytes": "/",
            "proteine": "oB",
            "glucose": "oB",
            "nitrite": "oB",
            "blood": "oB",
            "sediment_bacteriology": "opB"
        },
        {
            "type": "",
            "date": "10.03.2020",
            "gestation_week_corrected": "19+2",
            "fundal_height": "N",
            "fetal_position": "BEL",
            "heart_tones": "+",
            "child_movement": "+",
            "varikosis": "/",
            "weight": "72",
            "blood_pressure": "130/112",
            "haemoglobin_erythrocytes": "13",
            "proteine": "oB",
            "glucose": "oB",
            "nitrite": "oB",
            "blood": "oB",
            "sediment_bacteriology": "",
            "edema": "/",
            "vaginal_examination": "ph6, Leukorrho",
            "others_therapy_treatment": "Sobeliu Creme 4 Tage,CMV, Foto abgelehnt"
        },
        {
            "type": "",
            "date": "15.04.2020",
            "gestation_week_corrected": "24+3",
            "fundal_height": "N+1",
            "fetal_position": "BEL",
            "heart_tones": "+",
            "child_movement": "+",
            "edema": "/",
            "varikosis": "/",
            "weight": "72",
            "blood_pressure": "123/66",
            "haemoglobin_erythrocytes": "13",
            "proteine": "oB",
            "glucose": "oB",
            "nitrite": "oB",
            "blood": "oB",
            "sediment_bacteriology": "",
            "vaginal_examination": "opB",
            "others_therapy_treatment": "Cx6cm, oGTT50aB"
        },
        {
            "type": "",
            "date": "20.05.2020",
            "gestation_week_corrected": "29+3",
            "fundal_height": "N+2",
            "fetal_position": "BEL II",
            "heart_tones": "CTG+",
            "child_movement": "+",
            "edema": "/",
            "varikosis": "/",
            "weight": "74",
            "blood_pressure": "106/65",
            "haemoglobin_erythrocytes": "12,5",
            "proteine": "-",
            "glucose": "-",
            "nitrite": "+",
            "blood": "-",
            "sediment_bacteriology": "",
            "vaginal_examination": "ph4 opB",
            "risk_no": "41",
            "others_therapy_treatment": "Vorz. WTK cx 25mm -> KrankenhausRhophlac 300 Ch.-B P10013898 20.05.2020"
        },
        {
            "type": "",
            "date": "06.06.2020",
            "gestation_week": "",
            "gestation_week_corrected": "31+6",
            "fundal_height": "N+3",
            "fetal_position": "I BEL",
            "heart_tones": "CTG +",
            "child_movement": "+",
            "edema": "/",
            "varikosis": "/",
            "weight": "76",
            "blood_pressure": "121/90",
            "haemoglobin_erythrocytes": "12,4",
            "proteine": "-",
            "glucose": "-",
            "nitrite": "-",
            "blood": "-",
            "sediment_bacteriology": "",
            "vaginal_examination": "ph4 opB, CX 27mm",
            "others_therapy_treatment": "Stabil aus KH entlassen!"
        },
        {
            "type": "",
            "date": "Test"
        }
      ],
      ultrasound_control_examinations: [
        {
            "comment": "41: Cx-Sonokontrolle bis 34+0"
        }
      ],
      ultrasound_subsequent_examinations: [
        {
            "date": "25.01.2020",
            "examiner": "Müller",
            "indication": "ETS+Harmony",
            "findings": "Nacken opB",
            "comment": "Harmony -> Risiko 1: 40000 n.u."
        },
        {
            "date": "25.03.2020",
            "examiner": "Müller",
            "indication": "Organscreening",
            "findings": "opB",
            "comment": ""
        }
    ]
    };

    initialize() {

    }

    login() {
      console.log("login! from data context");
    }


    render() {
        return (
            <DataContext.Provider
                value={{
                    data: this.state, 
                    update: (key, value) => {
                      console.log("value", value);
                      this.setState({
                        [key]: value
                        /*data: {
                          ...this.state.data, [key]: value
                        }*/
                      });
                      console.log(this.state);
                    },
                    login: this.login
                }}
            >
              {this.props.children}
            </DataContext.Provider>
        );
    }
}

export default DataContextProvider;