import React from 'react';

import Header from '../../../components/Header/Header';
import Footer from 'components/Footer/Footer';

import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import BackToMindfulnessOverviewButton from '../BackToMindfulnessOverviewButton';

import seat5a from './5a_hinhoeren.mp3';
import seat6a from './6a_sitzmeditation.mp3';
import seat10a from './10a_selbstmitgefuehl.mp3';
import seat10b from './10b_sitzmeditation_selbstgefuehl.mp3';
import seat13a from './13a_lange_sitzmeditation.mp3';

import AudioPlayer from '../AudioPlayer';
import sittingIllustration from './sitting.jpg';


export default function SittingMeditation() {
    return (
      <>
        <Header>
          <Typography variant="h2">Sitzmeditation</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            <Typography variant="h2">Sitzmeditation</Typography>
            <BackToMindfulnessOverviewButton />
            <Typography variant="h3" style={{marginTop: "1em"}}>Einleitung</Typography>
            <p>
              Bei der Sitzmediation können Sie sich Zeit nehmen, einfach nur still dasitzen und die Gedanken ziehen lassen. Setzen Sie sich hierfür auf einen Stuhl oder ein Sitzkissen und mache Sie es sich bequem, gerne auch im Schneidersitz.
            </p>
            <img src={sittingIllustration} style={{width: '100%', height: 'auto'}} />
            <p>
              Die Sitzmeditation soll Sie dabei unterstützen Ihre Aufmerksamkeit auf den Atem, die Körperempfindungen, Geräusche, Gedanken und Gefühle zu richten ohne diese dabei zu bewerten. Alles darf sein, so wie es in diesem Moment ist.
            </p>
            <Typography variant="h3">Audio-Dateien</Typography>
            <AudioPlayer title='Sitzmeditation "Hinhören" (Dauer ca. 13 Minuten)' src={seat5a} />
            <AudioPlayer title='Sitzmeditation "Bewerten" (Dauer ca. 11 Minuten)' src={seat6a} />
            <AudioPlayer title='Sitzmeditation "Selbstmitgefühl (Dauer ca. 8 Minuten)' src={seat10a} />
            <AudioPlayer title='Freie Sitzmeditation (Dauer ca. 10 Minuten)' src={seat10b} />
            <AudioPlayer title='Lange Sitzmeditation (Dauer ca. 14 Minuten)' src={seat13a} />
          </Container>
        </main>
        <Footer/>
      </>
    );
}