import React from 'react';

import Button from '@material-ui/core/Button';
import LabRubellaEntry from './LabRubellaEntry';
import LabRubellaEdit from './LabRubellaEdit';

import DataContext from 'services/DataContext';
import { arrayIncludes } from '@material-ui/pickers/_helpers/utils';


class LabRubellaData extends React.Component {

  static dataContext = DataContext;

  constructor(props) {
    super(props);
    this.state = {view: "list"}
    this.changeView = this.changeView.bind(this);
    this.createNewData = this.createNewData.bind(this);
  }

  changeView() {
    if(this.state.view === "list") {
      this.setState({view: "addNew"});
    }
    else {
      this.setState({view: "list"});
    }
  }

  createNewData(data) {
    let dataContext = this.context;

    let labRubellaArray = [];
    // Check if any data already exists
    if(dataContext.data["lab_rubella"] && dataContext.data["lab_rubella"].length >= 1) {
      // We have to copy the array, as .push operates inplace and returns len(array), but not the newly created array
      labRubellaArray = dataContext.data["lab_rubella"];
      labRubellaArray.push(data);
    }
    else {
      labRubellaArray = [data];
    }
    
    dataContext.update("lab_rubella", labRubellaArray);

    this.setState({view: "list"});
  }

  updateExistingData(data) {

  }

  render() {

    const view = this.state.view;
    
    if(view == "list") {
      return (
        <DataContext.Consumer>
          {dataContext => (
            <>
              <Button variant="contained" color="primary" onClick={this.changeView} disableElevation>Neuer Eintrag</Button>
              <div className="lab-rubella-container">
                {
                  dataContext.data["lab_rubella"] &&
                  dataContext.data["lab_rubella"].map((value, index) => {
                    return (
                      <LabRubellaEntry data={value}></LabRubellaEntry>
                    )
                  })
                }
              </div>
            </>
        )}
        </DataContext.Consumer>
      );  
    }
    else if(view == "addNew"){
      return <LabRubellaEdit updateFunction={this.createNewData}></LabRubellaEdit>    
    }
  }
}

LabRubellaData.contextType = DataContext;

export default LabRubellaData;