import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class General extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Allgemein &gt; Allgemeines zur Schwangerschaft</div>
            
            <Typography variant="h1">Allgemeines zur Schwangerschaft</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Die Schwangerschaft ist eine spannende Zeit.</b></p>

            <p>Herzlichen Glückwunsch! Bald wird aus der winzigen Eizelle und dem Spermium Ihr kleines Baby, das das Licht der Welt erblicken wird. Auf diesem Weg möchten wir Sie begleiten. </p>

            <p>Die Eizelle nistet sich ca. vier Tage nach der Befruchtung in Ihrer Gebärmutter ein und beginnt kontinuierlich zu wachsen. Später werden wir Ihnen noch Woche für Woche berichten, was sich so bei Ihrem kleinen Baby tut. Einer der ersten großen Glücksmomente ist sicher der positive Schwangerschaftstest gewesen. Auch das erste Mal den Herzschlag auf dem Ultraschallbild zu sehen ist ein ganz besonderes Erlebnis. </p>

            <p>Ihr Geburtstermin wird im Mutterpass dokumentiert, aber Sie können ihn auch leicht selbst berechnen. Er ist das Datum des ersten Tages der letzten Periode minus 3 Monate plus 7 Tage plus 1 Jahr. Aber eigentlich kommen nur 4% aller Kinder genau an diesem Tag zur Welt. Ca. 2/3 erblicken die Welt innerhalb von 10 Tagen vor oder nach dem errechneten Geburtstermin. </p>
            
            <p>Von einer Frühgeburt spricht man, wenn Ihr Baby vor 37+0 Schwangerschaftswochen zur Welt kommen sollte. </p>

            <p>Insgesamt wird Ihre Schwangerschaft in drei Abschnitte zu je drei Monaten eingeteilt. Diese werden Trimenon genannt. </p>

            <Typography variant="h2">1. Trimenon:</Typography>
            <p>In den ersten drei Monaten entwickelt sich Ihr Baby sehr rasch. Das Dehnen der Mutterbänder kann zwischendrin auch zu einem Ziehen in der Leistengegend führen. Zudem kann Ihnen in dieser Zeit übel sein, keine Sorge die Übelkeit und das Erbrechen verschwindet meist nach der 12. Woche wieder. Natürlich machen Sie sich die Schwangerschaft über Sorgen, dass Sie ein gesundes Kind zur Welt bringen – keine Sorge. 97% aller Kinder sind gesund und haben keine Fehlbildungen.</p>

            <Typography variant="h2">2. Trimenon</Typography>
            <p>Im zweiten Schwangerschaftsdrittel kann nun auch jeder von außen sehen, dass Sie ein Kind erwarten. Auch werden Sie sicher nach der 12. Woche aufatmen, dann sinkt das Fehlgeburtsrisiko auf unter 1%. Um die 14. Woche herum können Sie auch das erste Mal Ihre Gebärmutter selbst fühlen, da ist sie schon so groß geworden, dass sie über dem Schambein tastbar ist. </p>
            <p>Im Verlauf werden Sie Ihr Baby auch das erste Mal spüren, dies ist ein tolles Gefühl. Wenn das schon Ihr zweites Kind ist, verspüren Sie die Bewegungen meistens schon früher, um die 18-20. Schwangerschaftswoche. Wenn es Ihr erstes Kind ist, kann das auch etwas länger dauern. Ihr Partner kann die Tritte Ihres Babys ca. ab der 22. Woche von außen fühlen. </p>

            <Typography variant="h2">3. Trimenon</Typography>
            <p>Im dritten Schwangerschaftsdrittel beginnt der Endspurt. Alle ihre Fragen zur Geburt sollten Sie am besten in dieser Phase mit Ihrer Hebamme und Ihrem Arzt oder Ihrer Ärztin besprechen, sodass Sie gut vorbereitet sind für das besondere Erlebnis. In der letzten Phase der Schwangerschaft können Rückenschmerzen, Sodbrennen oder Kurzatmigkeit auftreten. Diese entstehen meist durch die wachsende Gebärmutter. Wahrscheinlich warten Sie hier schon sehnsüchtig, dass der Tag der Tage endlich kommt. Eine gute ausgewogene Ernährung ist besonders wichtig in der Schwangerschaft, auch hier werden wir Ihnen später noch ein paar Tipps geben können. Während Ihrer Schwangerschaft nehmen Sie insgesamt im Schnitt 11-16kg zu. </p>

            <p>In der Schwangerschaftsvorsorge sind in Deutschland bei Bekanntwerden der Schwangerschaft Untersuchungen im Abstand von vier Wochen, ab der 32. Schwangerschaftswoche in einem Abstand von zwei Wochen empfohlen. Falls sich Ihr Baby über den Geburtstermin noch wohl in Ihrem Bauch fühlt, werden alle 2 Tage Kontrollen durchgeführt. </p>

            <p>Alle Befunde werden im Mutterpass dokumentiert, den Sie gegen Mitte bis Ende des ersten Schwangerschaftsdrittels von Ihrem Frauenarzt oder Ihrer Frauenärztin ausgestellt bekommen. Der Mutterpass wird Ihr wichtigster Begleiter in den 9 Monaten. Sie sollten ihn immer bei sich haben, da hier alles Wichtige dokumentiert ist. </p>

            <p>Wir wünschen Ihnen eine schöne und spannende Schwangerschaft und viel Freude beim Zuhören Ihrer nächsten Audiofiles zur Schwangerschaft. </p>
            
            <b>Versuchen Sie diese Zeit zu genießen und sich immer wieder bewusst Zeit für sich und Ihr ungeborenes Kind zu nehmen.</b>

          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default General;