import React from 'react';

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';


class PHBSNeverRarelySometimesFrequentOften extends React.Component {

  render(props) {
      return (
        <>
          <FormControlLabel value="0" control={<Radio color="primary" />} label="Nie" labelPlacement="end"/>
          <FormControlLabel value="1" control={<Radio color="primary" />} label="Selten" labelPlacement="end"/>
          <FormControlLabel value="2" control={<Radio color="primary" />} label="Manchmal" labelPlacement="end"/>
          <FormControlLabel value="3" control={<Radio color="primary" />} label="Oft" labelPlacement="end"/>
          <FormControlLabel value="4" control={<Radio color="primary" />} label="Sehr oft" labelPlacement="end"/>
        </>
      )
  }
}

export default PHBSNeverRarelySometimesFrequentOften;