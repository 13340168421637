import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class BirthChecklist extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Geburt &gt; Was brauche ich für die Geburt/fürs Krankenhaus?</div>
            
            <Typography variant="h1">Was brauche ich für die Geburt/fürs Krankenhaus?</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Was brauchen Sie alles für die erste Zeit danach? Dieser Artikel beschäftigt sich mit allem was Sie und Ihr Baby für die Geburt und danach alles benötigt.</b></p>

            <p>Die Zeit in der Schwangerschaft vergeht häufig schneller als gedacht, sodass dann plötzlich die Geburt ansteht. Damit Sie gut vorbereitet in Ihren neuen Lebensabschnitt starten können, haben wir für Sie kurz die wichtigsten Dinge für den stationären Aufenthalt im Krankenhaus, den Heimweg und die erste Woche zu Hause aufgelistet. </p>
            <Typography variant="h2">Fragen über Fragen</Typography>
            <p>Der Aufenthalt in einem Klinik und vor allem die Zeit in einem Kreissaal wird für viele von Ihnen das erste Mal in einem Krankenhaus sein. Die Erwartungen an die Zeit dort sind hoch und bei manchen von Ihnen vielleicht auch mit etwas Angst verbunden. Werde ich rechtzeitig dort sein? Wie wird die Entbindung ablaufen? Habe ich alles vorbereitet? Und was brauche ich eigentlich im Krankenhaus? Dies ist die Frage, die wir heute besprechen. </p>
            <Typography variant="h2">2-3 Tage in der Klinik</Typography>
            <p>Ein Handgepäck-Koffer reicht in der Regel, da man bei einer normalverlaufenden Geburt bereits nach 2 bis 3 Tagen das Krankenhaus wieder nach Hause kann. Es empfiehlt sich, diese Tasche bereits einige Wochen vor dem errechneten Entbindungstermin zu packen und griffbereit zu Hause zu haben.  </p>
            <Typography variant="h2">Für den eigentlichen Aufenthalt</Typography>
            <p>Für den Aufenthalt im Krankenhaus benötigen Sie ein Nachthemd oder einen Schlafanzug, Hausschuhe und einen Morgenmantel. Ein paar bequeme Kleidungsstücke zum Spazieren gehen vor der Geburt und für die Zeit danach sind auch hilfreich. Falls Sie bereits ein liebgewonnenes Stillkissen besitzen, gehört dies auch mit ins Gepäck ebenso wie Unterwäsche inklusive Still-BH und ein paar Kosmetik-Produkte. Das wichtigste ist aber immer noch Ihr Mutterpass. Diesen sollten Sie immer bei sich tragen. Und ein kleines Stofftier, natürlich für Ihr Kind, kann auch nicht schaden. </p>
            <Typography variant="h2">Erstausstattung</Typography>
            <p>Da wären wir auch schon beim Thema Kind und dem großen Thema der Erstausstattung. Nur etwa 50cm groß und wenige Kilogramm schwer, aber dennoch braucht es „viel“ Gepäck. Im Krankenhaus gibt es zum Glück genügend Ausstattung für das Babybettchen, auch Spucktücher und Handtücher sind auf den Geburtsstationen vorhanden. Zum Anziehen für das Neugeborene gibt es meist auch etwas, aber dennoch sind Ihre eigenen Sachen für Sie vermutlich die Schönsten. Ein paar Bodys in Größe 56 und 62, zwei- drei Strampler und ein Jäckchen aus Baumwolle sollten mit ins Krankenhaus genommen werden. Warme Söckchen (auch für Sie als Mutter) und ein kleines Mützchen braucht es dringend. Manchmal sind auch kleine Handschuhe gut, zum einen damit die Händchen warm bleiben, aber auch damit sich Ihr Kleines nicht mit den Fingernägeln kratzt. Pflegeprodukte für Ihr Baby gibt es im Krankenhaus genug. </p>
            <Typography variant="h2">Zuhause</Typography>
            <p>Für daheim ist es gut, wenn Sie einiges vor der Entbindung vorbereiten. Hierzu gehört der Aufbau des Bettchens und der Wickelkommode. Gegebenenfalls ist auch eine Wärmelampe vorhanden. Funktioniert die Wärmelampe bereits? Sind Windeln und der Mülleimer in greifbarer Nähe zur Wickelkommode? Pflegetücher feucht und trocken, Pflegecreme für den Windelbereich, Windeln in Größe 1 und 2 und ein paar Mullwindeln mit Windeleinlage legen Sie am besten bereit. Ein Schlafsack für die Nacht, eine saugstarke Unterlage fürs Babybett und Bettlaken zum Wechseln sollten auch nicht fehlen. </p>
            <p>Eine kleine Badewanne mit Thermometer, ein Fieberthermometer, eine weiche Haarbürste und eine kleine Babynagelschere sind ebenso sinnvoll. Zusätzlich können Sie auch ein Kapuzenhandtuch oder etwas Spielzeug besorgen, wenn Sie möchten. Hier bekommen Sie aber vielleicht auch etwas zur Geburt geschenkt. </p>
            <p>Beim Thema Kinderwagen schauen Sie sich am besten in einem Fachgeschäft um, welches Modell Sie möchten. Hier müssen Sie beachten, dass die Firmen zum Teil eine Lieferzeit von mehreren Wochen haben. Oder alternativ haben Sie im Bekanntenkreis vielleicht jemanden, der seinen Kinderwagen aktuell abgeben möchte. </p>
            <p>Für den Weg nach Hause ist eine Babyschale im Auto Pflicht. Daher empfiehlt es sich, auch diese vorab zu besorgen. </p>

            <p><b>Jetzt sind Sie gut für die Zeit danach ausgestattet und können die Zeit vollends mit Ihrem Nachwuchs genießen.</b></p>

          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default BirthChecklist;