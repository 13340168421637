import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';


import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import withNotifications from 'services/NotificationService/withNotifications';

import '../Questionnaire.scss';

class GesOek1 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Gesundheitsökonomie</Typography>
        </Header>
        <main>
        <LinearProgress variant="determinate" value={ (this.state.activeStep/2) * 100 } />
          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />
          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "done": 0,
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <div style={{textAlign: "center", marginTop: "1em"}}>
                  <p>bei der nächsten Befragung handelt es sich um eine Untersuchung des Lehrstuhls für Gesundheitsmanagement. Bitte klicken Sie auf den folgenden Link, um zum Fragebogen zu gelangen: </p>
                  <a href="https://ww2.unipark.de/uc/wtpi/">https://ww2.unipark.de/uc/wtpi/</a>
                  <p>Sollten Sie Probleme beim Aufrufen des Links oder andere Fragen an uns haben, melden Sie sich bitte bei Kristina Kast unter der Tel.: +499115302-95383 oder per E-Mail: kristina.kast@fau.de. Vielen Dank!</p>
                  <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Ich habe den Fragebogen abeschlossen</Button>
                </div>
              </StepperPage>

              <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                <p>
                  Bitte bestätigen Sie, dass Sie den Fragebogen auf der externen Seite bearbeitet haben.
                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                    <Button type="submit" variant="contained" color="primary" disableElevation>Ja, ich habe den Fragebogen abgeschlossen</Button>
                  </div>
                </p>
              </StepperPage>
              {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}
            </Container>
          </form>
        </main>
      <Footer showMenu={false} />
    </>
    );
  }
}

export default withNotifications(withFormik({
  mapPropsToValues: () => ({
    done: 1,
  }),  
  validationSchema: Yup.object().shape({
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "gesoek1",
      questionnaireData: values,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
    });
  }
})(GesOek1));

/* https://stackoverflow.com/questions/59497550/handling-formik-form-when-validation-error-occurs */