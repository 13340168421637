import React, { useEffect, useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Typography } from '@material-ui/core';
import { TabPanel } from "components/TabPanel/TabPanel";

import Header from "components/Header/Header";
import DataList from "components/DataList/DataList";

import Container from "@material-ui/core/Container";
import Footer from "components/Footer/Footer";
import Child from "./components/Child";
import axios from "axios";
import Globals from "services/Globals";

export default function Children(props) {

    const [activeTab, setActiveTab] = useState(0);
    const [data, setData] = useState({});

    useEffect(() => {
      axios.get(Globals.SERVER_URL + "/dmr/children").then(function (res) {
          if ( !res.data ) return;
          setData(res.data);
          console.log(res.data);
      }).catch(function (err) {
          console.log(err);
      });
    }, []);
    

    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
    }

    return (
    <>
        <Header>
            <Typography variant="h2">Abschluss-Untersuchung</Typography>
        </Header>
        <AppBar position="static">
          <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
          >
            { data.child1 !== undefined && <Tab value={0} label="Kind 1" /> }
            { data.child2 !== undefined && <Tab value={1} label="Kind 2" /> }
            { data.child3 !== undefined && <Tab value={2} label="Kind 3" /> }
            { data.child4 !== undefined && <Tab value={3} label="Kind 4" /> }
            { data.child5 !== undefined && <Tab value={4} label="Kind 5" /> }
          </Tabs>
        </AppBar>
        <TabPanel value={activeTab} index={0}>
          <Child data={data} index="child1" />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Child data={data} index="child2" />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <Child data={data} index="child3" />
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          <Child data={data} index="child4" />
        </TabPanel>
        <TabPanel value={activeTab} index={4}>
          <Child data={data} index="child5" />
        </TabPanel>
        <Footer />
    </>);
}