import React from "react";

import NotificationContext from "./NotificationContext";

const withNotifications = Component => {
  class WithNotificationsComponent extends React.Component {

    constructor(props) {
      super(props);
    }
    static contextType = NotificationContext;

    render() {
      /*return (
        <NotificationContext.Consumer>
          {({notificationService}) => <Component value={notificationService} />}
        </NotificationContext.Consumer>
      );*/
      return <Component notificationService={this.context} {...this.props} />
    }
  }

  return WithNotificationsComponent;
};

export default withNotifications;
