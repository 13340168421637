import React from 'react';
import axios from 'axios';

import Globals from 'services/Globals';
import Header from '../../../components/Header/Header';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faCog } from '@fortawesome/free-solid-svg-icons';

import womanBloodPressure from 'resources/svgs/woman-blood-pressure.svg';

import './WithingsSetup.scss';
import Footer from 'components/Footer/Footer';


export default class WithingsSetup extends React.Component {
  constructor(props) {
    super(props);

    // Check if user was already redirected from witings website by checking GET parameter
    let params = new URLSearchParams(window.location.search);
    this.withingsCode = params.get('code');
    this.state = {
      displayState: ""
    };
  }

  componentDidMount() {
    let self = this;

    if(this.withingsCode) {
      this.setState({
        displayState: "loading"
      });
      axios.post(Globals.SERVER_URL + "/withings/setup", {
        code: self.withingsCode
      }).then(function(res){
        self.setState({
          displayState: "success"
        });
        console.log(res);
      }).catch(function(err){
        self.setState({
          displayState: "failed"
        });
        console.log(err);
      });  
    }
  }

  render() {
    // Show screen AFTER user clicked on "submit"
    if(this.withingsCode) {
      return (
        <>
          <Header>
            <Typography variant="h2">Geräte-Ersteinrichtung</Typography>
          </Header>
          <main>
            <Container maxWidth="lg">
              <div style={{textAlign: "center", marginTop: "1em"}}>
              { this.state.displayState === "loading" && 
                <div className="waiting">
                  <div className="icon">
                    <FontAwesomeIcon icon={faCog}/>
                  </div>
                  Ersteinrichtung wird durchgeführt. Bitte warten.
                </div>
              }
              { this.state.displayState === "success" && 
                <div className="finished">
                  <Typography variant="h2">Einrichtung erfolgreich abgeschlossen!</Typography>
                  <p>Vielen Dank, die Ersteinrichtung wurde erfolgreich abgeschlossen!</p>
                  <Button component={Link} variant="contained" color="primary" to="/overview" disableElevation><FontAwesomeIcon icon={faAngleLeft} style={{marginRight: "1em"}} /> Zur Startseite</Button>
                </div>
              }
              { this.state.displayState === "failed" && 
                <>
                  <Typography variant="h2">Es ist ein Fehler aufgetreten.</Typography>
                  <p>Bitte versuchen Sie es später erneut. Falls dieser Fehler weiterhin auftreten sollte, wenden Sie sich bitte an die Studienleitung.</p>
                  <Button component={Link} variant="contained" color="primary" to="/overview" disableElevation><FontAwesomeIcon icon={faAngleLeft} style={{marginRight: "1em"}} /> Zur Startseite</Button>
                </>
              }
              </div>
            </Container>
          </main>
        </>
      );  
    }
    else {
      return (
        <>
          <Header>
            <Typography variant="h2">Geräte-Ersteinrichtung</Typography>
          </Header>
          <main>
            <Container maxWidth="lg">
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <div className="photo-circle">
                  <img src={womanBloodPressure} alt="Illustration einer Frau, die ihren Blutdruck misst." />
                </div>
                <Typography variant="h2">Geräte-Ersteinrichtung</Typography>
                <p>In der Studie werden Bewegungs- und Gesundheitsdaten durch tragbare Geräte aufgenommen. Dazu gehören zum Beispiel Smartwatch, Blutdruckmessgerät und Waage.</p>
                <p><b>Wir müssen diese Geräte mit deinem Studienteilnahme-Konto verbinden</b>.</p>                
                <p>Durch Klick auf den Button wirst du auf die <b>Webseite von Withings</b> weitergeleitet. Nach der <b>Anmeldung mit Deinem Withings-Konto</b> musst du auf den <b>Button "Diese App zulassen" klicken</b>, um die Konten zu verknüpfen.</p>
                <p>Hinweis für <b>Nutzerinnen von anderen Gesundheits-Apps (z.B. Apple Health, Samsung Health)</b>: <br/>Während der Einrichtung wirst du gefragt, ob du bestehende Gesundheitsdaten (aus deiner Gesundheits-App) zu Withings Health Mate hinzufügen (importieren) möchtest. Falls du dies zulässt, werden die Daten auch an den Studienserver übertragen und für Forschungszwecke verwendet.</p>
                <Button href="https://account.withings.com/oauth2_user/authorize2?response_type=code&client_id=b75e08a586c0027c6c44baf2548b862cbe91f879f85c4187461ea00a669d4870&state=42&scope=user.metrics,user.activity,user.info,user.sleepevents&redirect_uri=https://smartstart-fk.uk-erlangen.de/setup/withings-devices" variant="contained" color="primary" disableElevation>Einrichtung durchführen</Button>
              </div>
            </Container>
          </main>
          <Footer/>
        </>
      );  
    }
  }
}