import React from 'react';
import './Menu.scss';
import MenuToggle from '../MenuToggle/MenuToggle';

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faHome, faUser, faVial, faHistory, faCommentMedical, faInfo, faClipboardList, faStethoscope, faClock, faChartLine, faHospital, faVolumeUp, faChartArea, faCheck, faList, faBabyCarriage, faMusic, faEnvelope, faGlass, faFillDrip } from '@fortawesome/free-solid-svg-icons';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { toggleMenu } from 'helpers/MenuHelper';
import MenuListItem from './MenuListItem/MenuListItem';


const styles = (theme) => ({
  "menu": {
    "& li": {
      backgroundColor: theme.palette.primary.main
    }
  }
});

function Menu(props) {
  const theme = useTheme(); 
  const { classes } = props;

  return (
    <nav className={"primary-menu " + classes.menu}  style={{backgroundColor: theme.palette.secondary.main}}>
      <div className="menu-toggle-button" style={{backgroundColor: theme.palette.primary.main}}><MenuToggle><FontAwesomeIcon icon={faTimes}/> Menü schließen</MenuToggle></div>
      <p style={{textAlign: "center"}}>Studie</p>
      <ul>
        <MenuListItem destination="/overview" label="Startseite" icon={<FontAwesomeIcon icon={faHome}/>}/>
        <MenuListItem destination="/tasks" label="Offene Aufgaben" icon={<FontAwesomeIcon icon={faList}/>}/>
        <MenuListItem destination="/profile" label="Profil" icon={<FontAwesomeIcon icon={faUser}/>}/>
        <MenuListItem destination="/help-contact" label="Hilfe &amp; Kontakt" icon={<FontAwesomeIcon icon={faEnvelope}/>}/>
      </ul>
      <p style={{textAlign: "center"}}>Messungen im Heimbereich</p>
      <ul>
        <MenuListItem destination="/measurements" label="Meine Messwerte" icon={<FontAwesomeIcon icon={faHome}/>}/>
        <MenuListItem destination="/photos/urinalysis" label="Urinanalyse" icon={<FontAwesomeIcon icon={faFillDrip}/>}/>
      </ul>
      <p style={{textAlign: "center"}}>Digitaler Mutterpass</p>
      <ul>
        { /*
        <li><Link to="/questionnaires"><FontAwesomeIcon icon={faVial}/> Umfragen</Link></li>
        */ }
        {/* <li className="test3"><Link to="/mutterpass"><FontAwesomeIcon icon={faCommentMedical}/> Mutterpass</Link></li> */ }
        <MenuListItem destination="/maternity-record/lab-rubella" label="Labor" icon={<FontAwesomeIcon icon={faVial}/>}/>
        <MenuListItem destination="/maternity-record/previous-pregnancies" label="Vorh. Schwangerschaften" icon={<FontAwesomeIcon icon={faHistory}/>}/>
        <MenuListItem destination="/maternity-record/consultation" label="Beratung" icon={<FontAwesomeIcon icon={faCommentMedical}/>}/>
        <MenuListItem destination="/maternity-record/anamnesis" label="Vorgeschichte (Anamnese)" icon={<FontAwesomeIcon icon={faClipboardList}/>}/>
        <MenuListItem destination="/maternity-record/notable-findings" label="Besondere Befunde" icon={<FontAwesomeIcon icon={faStethoscope}/>}/>
        <MenuListItem destination="/maternity-record/dob-determination" label="Terminbestimmung" icon={<FontAwesomeIcon icon={faClock}/>}/>
        <MenuListItem destination="/maternity-record/gravidogram" label="Gravidogramm" icon={<FontAwesomeIcon icon={faChartLine}/>}/>
        <MenuListItem destination="/maternity-record/in-patient-stay" label="Stationäre Aufenthalte" icon={<FontAwesomeIcon icon={faHospital}/>}/>
        <MenuListItem destination="/maternity-record/cardiotocography" label="CTG" icon={<FontAwesomeIcon icon={faHospital}/>}/>
        <MenuListItem destination="/maternity-record/ultrasound" label="Ultraschall" icon={<FontAwesomeIcon icon={faVolumeUp}/>}/>
        {/*<li><Link to="/maternity-record/anamnesis"><FontAwesomeIcon icon={faChartArea}/> Normkurven</Link></li>*/}
        <MenuListItem destination="/maternity-record/epicrisis" label="Abschluss-Untersuchung" icon={<FontAwesomeIcon icon={faCheck}/>}/>
      </ul>
      <p style={{textAlign: "center"}}>Mehr</p>
      <ul>
        <MenuListItem destination="/my-pregnancy-week" label="Meine Schwangerschaftswoche" icon={<FontAwesomeIcon icon={faBabyCarriage}/>}/>
        <MenuListItem destination="/informations" label="Information &amp; Wissen" icon={<FontAwesomeIcon icon={faInfo}/>}/>
        <MenuListItem destination="/mindfulness" label="Achtsamkeitsübungen" icon={<FontAwesomeIcon icon={faMusic}/>}/>
        <MenuListItem destination="/journal" label="Gefühls-Tagebuch" icon={<FontAwesomeIcon icon={faClipboardList}/>}/>
      </ul>
    </nav>
  );
}

export default withStyles(styles)(Menu);
