import React, { useEffect, useState } from 'react';

import Header from '../../components/Header/Header';
import Footer from 'components/Footer/Footer';
import { Box, Container, Grid, ImageList, ImageListItem, Typography } from "@material-ui/core";
import MenuCard from "components/MenuCard/MenuCard";
import MenuCardGridItem from "components/MenuCardGridItem/MenuCardGridItem";
import ActivityItem from "components/ActivityItem/ActivityItem";
import TopSpacer from "components/TopSpacer/TopSpacer";

import axios from 'axios';
import Globals from 'services/Globals';
import StudyContactData from 'components/StudyContactData/StudyContactData';


export default function MaternityRecord() {

  const [userAppPackage, setUserAppPackage] = useState();

  useEffect(() => {
    axios.get(Globals.SERVER_URL + "/settings/study_package").then(function(res) {
      setUserAppPackage(res.data);
      console.log(res);
    }).catch(function(err) {
      console.log(err);
    });
  });

  return (
    <>
      <Header>
        <Typography variant="h2">Digitaler Mutterpass</Typography>
      </Header>
      <main>
        <TopSpacer />
        <Container maxWidth="lg">
          <Typography variant="h2">Digitaler Mutterpass</Typography>

          {
              (userAppPackage === 'App' || userAppPackage === 'AppA' || userAppPackage === 'AppB' || userAppPackage === 'AppAB') ? 
              <>
                <ul className="activity-list">
                  <ActivityItem to="/maternity-record/pregnancy-card" title="Schwangerschaftskarte" />
                  <ActivityItem to="/maternity-record/gravidogram" title="Gravidogramm" />
                  <ActivityItem to="/maternity-record/children" title="Kinder" />
                  {
                    /*
                    <ActivityItem to="/mutterpass" title="Digitaler Mutterpass" description="Lorem ipsum" />
                    <ActivityItem to="/maternity-record/lab-rubella" title="Labor" />
                    <ActivityItem to="/maternity-record/previous-pregnancies" title="Vorh. Schwangerschaften" />
                    <ActivityItem to="/maternity-record/consultation" title="Beratung" />
                    <ActivityItem to="/maternity-record/anamnesis" title="Vorgeschichte (Anamnese)" />
                    <ActivityItem to="/maternity-record/notable-findings" title="Besondere Befunde" />
                    <ActivityItem to="/maternity-record/dob-determination" title="Terminbestimmung" />
                    <ActivityItem to="/maternity-record/gravidogram" title="Gravidogramm" />
                    <ActivityItem to="/maternity-record/in-patient-stay" title="Stationäre Aufenthalte" />
                    <ActivityItem to="/maternity-record/cardiotocography" title="CTG" />
                    <ActivityItem to="/maternity-record/ultrasound" title="Ultraschall" />
                    <ActivityItem to="/maternity-record/epicrisis" title="Abschluss-Untersuchung" />
                    */
                  }
                  
                  </ul>
                </> :
              <>
                <p>Schade, diesen Bereich der App können Sie aktuell leider nicht nutzen!</p>
                <p>Möchten Sie verschiedene Inhalte Ihres Mutterpasses, z.B. die Befunde aus dem Gravidogramm, in der Mutterpass-App anschaulich dargestellt haben?</p>
                <p>Dann kontaktieren Sie eine der folgenden Anlaufstellen und lassen Sie sich dort vor Ort für die SMART Start Studie registrieren.</p>

                <p>Ihre Anlaufstellen für die SMART Start Studie sind:</p>
                <StudyContactData />
              </>
            }




        </Container>
      </main>
      <Footer />
    </>
  );
}