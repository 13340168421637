import React from 'react';

import Header from '../../../components/Header/Header';
import Footer from 'components/Footer/Footer';

import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import BackToMindfulnessOverviewButton from '../BackToMindfulnessOverviewButton';

import bodyscan2b from './2b_langer_bodyscan.mp3';
import bodyscan5b from './5b_bodyscan.mp3'
import AudioPlayer from '../AudioPlayer';

export default function Bodyscan() {
    return (
      <>
        <Header>
          <Typography variant="h2">Bodyscan</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            <Typography variant="h2">Bodyscan</Typography>
            <BackToMindfulnessOverviewButton />
            <Typography variant="h3" style={{marginTop: "1em"}}>Einleitung</Typography>
            <p>
              Der Bodyscan ist eine formale Achtsamkeitsübung, bei der Sie Ihre Aufmerksamkeit gezielt von Körperteil zu Körperteil wandern lassen und so den gesamten Körper erspüren. Nehmen Sie Ihren Körper bewusst in ruhendem Zustand wahr und nutzen Sie ihn als Anker für das Hier und Jetzt.
            </p>
            <p>
              Ziehen Sie sich für diese Übungen an einen ruhigen Platz zurück und tragen Sie bequeme Kleidung. Am besten machen Sie die Übungen, indem Sie auf dem Rücken liegen, z.B. auf einer Yogamatte oder Decke. Sie können sich auch ein kleines Kissen unter den Kopf oder in die Kniekehlen legen. Bei Unwohlsein können Sie die Übungen aber auch im Sitzen auf der Vorderkante eines Stuhls, oder auf der Seite liegend durchführen.
            </p>
            <Typography variant="h3">Audio-Dateien</Typography>
            <AudioPlayer title="Bodyscan (Dauer ca. 10 Minuten)" src={bodyscan5b} />
            <AudioPlayer title="Langer Bodyscan (Dauer ca. 26 Minuten)" src={bodyscan2b} />
          </Container>
        </main>
        <Footer/>
      </>
    );
}