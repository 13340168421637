import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

import BackToInformationsGeneralButton from '../BackToInformationsGeneralButton';


class Midwives extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            
            <div className="breadcrumbs">Informationen &gt; Untersuchungen &amp; Vorsorge &gt; Hebammen &amp; Die Hebammensuche</div>
            
            <Typography variant="h1">Hebammen und die Hebammensuche</Typography>

            <BackToInformationsGeneralButton />

            <p><b>Dieser Artikel fasst alle Infos zur Suche einer Hebamme, die Sie die während der Schwangerschaft und danach begleiten wird, zusammen.</b></p>

            <Typography variant="h2">Was machen Hebammen?</Typography>
            <p>Hebamme ist einer der ältesten Frauenberufe. Die Hebamme kümmert sich während der Schwangerschaft, der Geburt, dem Wochenbett und der Stillzeit um Sie. Sie begleitet Sie und steht Ihnen mit Rat und Tat zur Seite. </p>
            <p>Die Hebammenhilfe ist eine Leistung der Krankenversicherung. Diese kann von jeder Frau in Anspruch genommen werden.</p>

            <p>Im engeren Sinn umfasst die Hebammenhilfe die Beratung und Betreuung während der Schwangerschaft und im Wochenbett, die Schwangerenvorsorge, die Geburtsvorbereitung, die Betreuung während der Geburt und  die Rückbildung. </p>

            <Typography variant="h3">In der Vorsorge</Typography>
            <p>Im Bereich der Schwangerenvorsorge können alle Vorsorgeuntersuchungen, ausgenommen die Ultraschalluntersuchungen, die Ausstellung des Mutterpasses sowie die zusätzliche Pränataldiagnostik auch durch Ihre Hebamme vorgenommen werden.</p>

            <Typography variant="h3">Geburtsvorbereitung</Typography>
            <p>Viele Hebammen bieten Geburtsvorbereitungskurse an. Bei diesen werden Informationen zum Ablauf der Entbindung gegeben und vor allem die Körperwahrnehmung, die Atem- und Entspannungsübungen sowie Gebärpositionen geübt. Diese Kurse gibt es auch als Partnerkurse, die Kosten für Ihren Partner müssen Sie bei einigen Krankenkassen leider selbst übernehmen. Am besten informieren Sie sich auch hier im Vorhinein.</p>

            <Typography variant="h3">Während der Geburt</Typography>
            <p>Auch unter Geburt werden Sie von einer Hebamme betreut, dies ist gesetzlich vorgegeben. Ihre Hebamme unterstützt Sie während der Geburt, egal für welche Geburtsform Sie sich entscheiden werden. </p>

            <Typography variant="h3">Im Wochenbett und danach</Typography>
            <p>Genauso kümmert sich die Hebamme in der Zeit des Wochenbetts umfassend um Sie und ihr Kind. Sie betreut Ihr Baby mit und schaut, ob es ihm gut geht, ob es genug trinkt und an Gewicht zulegt und kontrolliert den Zustand des Nabels Ihres Babys. Darüber hinaus gibt sie Ihnen auch Ernährungstipps. Auch beim Stillen steht sie Ihnen tatkräftig zur Seite, kontrolliert die Wundheilung und die Gebärmutter-Rückbildung. Impfen, Kindererziehung und die weitere Familienplanung sind ebenfalls Themen, die es zu besprechen gibt.</p>

            <p>6 bis 8 Wochen nach der Entbindung wird die Rückbildungsgymnastik empfohlen. Bei dieser werden vor allem Übungen zur Regeneration des Beckenbodens durchgeführt. Ihr Beckenboden muss sich langsam nach der Schwangerschaft und der Geburt erholen und Ihre Hebamme wird Ihnen hierbei helfen. Auch hier gibt es verschiedene Kurse, die Sie belegen können. Hier können Sie wählen zwischen Kursen mit und ohne Kind. Die Krankenkasse übernimmt die Kosten für die ersten 10 Stunden. </p>

            <Typography variant="h3">Weitere Angebote</Typography>
            <p>Viele Hebammen bieten auch zusätzliche Angebote an, die Sie in der Zeit der Schwangerschaft oder nach der Geburt unterstützen können. Hierzu gehören z.B. Akupunktur, Aquafitness in der Schwangerschaft,  Reflexzonenmassage, Yoga, Babyschwimmen oder Nordic Walking mit Kind.</p>

            <Typography variant="h2">Eine Hebamme finden</Typography>
            <p>Um die richtige Hebamme zu finden, hören Sie sich am besten im Bekanntenkreis um oder recherchieren im Internet. Vielleicht arbeitet auch Ihr Frauenarzt oder Ihre Frauenärztin mit einer Hebamme zusammen, die Sie mit betreut. Am besten suchen Sie sich eine Hebamme, die in der Nähe Ihres Wohnortes arbeitet. Sie sollten sich frühzeitig um eine Hebamme kümmern, da eine Hebamme immer nur eine bestimmte Anzahl an Frauen gleichzeitig betreuen kann und die Nachfrage sehr hoch ist. </p>

            <p>Wenn Sie eine Hebamme gefunden haben, ist es wichtig, dass Sie sich bei ihr wohl fühlen und die Chemie zwischen Ihnen stimmt – hier zählt Ihr Bauchgefühl und nichts anderes. Besprechen Sie Ihre Erwartungen, Wünsche und Befürchtungen mit ihr, sodass Sie Sicherheit gewinnen. </p>

            <p>Sollte das erste vereinbarte Gespräch kein Aufschluss geben, können Sie gerne das Gespräch mit einer zweiten Hebamme suchen – bedenken Sie aber, dass Sie dies ggf. selbst bezahlen müssen.</p>

            <p>Falls es wirklich so sein sollte, dass Sie keine Hebamme finden sollten, gibt es auf der Seite des deutschen Hebammenverbundes, eine Liste auf der Sie sich eintragen können. So soll der Hebammenmangel erfasst werden und diesem entgegengesteuert werden. </p>

            <p><b>Am besten suchen Sie sich frühzeitig eine Hebamme, die Sie im Wochenbett und ggf. auch schon in der Schwangerschaft begleiten kann. Sie wird Ihnen und Ihrem Kind eine große Hilfe sein.</b></p>

          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default Midwives;