import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';

import withNotifications from 'services/NotificationService/withNotifications';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import NeverRarelySometimesFrequentOften from './StandardizedAnswerOptions/NeverRarelySometimesFrequentOften'

import '../Questionnaire.scss';
import QuestionnaireHeadNotice from 'components/QuestionnaireHeadNotice/QuestionnaireHeadNotice';


//import Textfield from '../../components/Textfield/Textfield';
//import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';


class MAPI extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">MAPI</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/4) * 100 } />
          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />
          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "mapi1": 1,
                "mapi2": 2,
                "mapi3": 3,
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <div style={{textAlign: "center", marginTop: "1em"}}>
                  Der nachfolgende Fragebogen bezieht sich auf Ihr Schlafverhalten.
                  <br/><br/>
                  <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Fragebogen starten</Button>
                </div>
              </StepperPage>
            </Container>

            { this.state.activeStep >= 1 &&
              <QuestionnaireHeadNotice>
                Während der letzten 3 Monate Ihrer Schwangerschaft, wie oft haben Sie (oder auch Ihr Partner) bei sich eines der folgenden Symptome bemerkt?
              </QuestionnaireHeadNotice>
            }
            <div className="questionnaire-body">
              <Container maxWidth="lg">
                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Lautes Schnarchen</FormLabel>
                    <CustomErrorMessage name="mapi1" />
                    <RadioGroup aria-label="position" name="mapi1" value={this.props.values.mapi1} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={2}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Schnauben oder Keuchen</FormLabel>
                    <CustomErrorMessage name="mapi2" />
                    <RadioGroup aria-label="position" name="mapi2" value={this.props.values.mapi2} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={3}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Atemaussetzer, Erstickungsgefühle oder Ringen nach Luft</FormLabel>
                    <CustomErrorMessage name="mapi3" />
                    <RadioGroup aria-label="position" name="mapi3" value={this.props.values.mapi3} onChange={this.props.handleChange} required={true}>
                      <NeverRarelySometimesFrequentOften />
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>
                
                <StepperPage activeStep={this.state.activeStep} thisStep={4}>
                  <Typography variant="h2">Fragebogen absenden</Typography>
                  Vielen Dank! Sie können den Fragebogen nun absenden.
                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                    <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                  </div>
                </StepperPage>

                {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}       
              </Container>  
            </div>
          </form>
        </main>
      <Footer showMenu={false} />
    </>
    );
  }
}

export default withNotifications(withFormik({
  mapPropsToValues: () => ({
    mapi1: '',
    mapi2: '',
    mapi3: '',
  }),  
  validationSchema: Yup.object().shape({
    mapi1: Yup.number().required('Pflichtfeld'),
    mapi2: Yup.number().required('Pflichtfeld'),
    mapi3: Yup.number().required('Pflichtfeld'),
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "mapi",
      questionnaireData: values,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
    });
  }
})(MAPI));

/* https://stackoverflow.com/questions/59497550/handling-formik-form-when-validation-error-occurs */