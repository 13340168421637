import React from 'react';

import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';


import './HelperModal.scss';
import urinstickVisualisierungUpdown from './urinstick-visualisierung-updown.png';


function HelperModal(props) {
  // Declare a new state variable, which we'll call "count"
  return (
    <div className="helper-modal">
      <div className="helper-modal-content">
        <Typography variant="h2">Hilfe</Typography>
        <Typography variant="h3">Bildaufnahme</Typography>
        <b>Vermeiden</b> Sie <b>Schatten, Reflektionen oder Spiegelungen</b> im Bild.<br/><br/>
        <Typography variant="h3">Orientierung</Typography>
        Der Urinstick (links im Bild) muss mit dem <b>"Siemens"-Schriftzug nach unten</b> gehalten/verglichen werden.<br/><br/>
        Die Referenzkarte (rechts im Bild) muss mit dem <b>Pfeil nach oben</b> gehalten/verglichen werden.<br/><br/>
        <img src={urinstickVisualisierungUpdown} style={{maxWidth: "80%"}} alt="" />

        <br/><br/>
        <Button variant="contained" color="primary" size="small" disableElevation onClick={props.toggle}>
          Ausblenden
        </Button>
      </div>
    </div>
);
}

export default HelperModal;