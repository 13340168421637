import React from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import { Button, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom'

import { KeyboardArrowLeft } from '@material-ui/icons';
import StudyContactData from 'components/StudyContactData/StudyContactData';


export default function RetractConsent() {

    return (
      <>
        <Header>
          <Typography variant="h2">Einverständnis zurückziehen</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            <p>
              Wenn Sie Ihr Einverständnis zur Studienteilnahme zurückziehen möchten, senden Sie uns bitte eine E-Mail.
            </p>
            <p>
              Bitte geben Sie unbedingt Ihre App-ID mit an.
            </p>
            <StudyContactData />
          </Container>
        </main>
        <Footer/>
      </>
    );
}