import React from 'react';

import { Link } from 'react-router-dom'

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

import useActivities from 'services/Activities/Activities';


import taskImage from 'resources/svgs/tasks-hands.svg';

export default function TaskCard() {
  const activities = useActivities();
  const theme = useTheme(); 

  return (
    <Link to="/tasks" className="overview-card">
      <Card variant="outlined" style={{border: "1px solid " + theme.palette.primary.main}}>
        <CardActionArea>
          <CardMedia image={taskImage} title="Umfragen" className="overview-card-media" style={{backgroundColor: theme.palette.primary.main}} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2" style={{color: theme.palette.primary.main }}>
              Meine Aufgaben
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              { activities.activities && 
              <>
                <b>Zur Zeit {Object.keys(activities.activities).length === 0 ? 'keine' : Object.keys(activities.activities).length} offene Aufgabe(n)</b><br />
                { activities.nextActivityInDays !== undefined &&
                  <>
                    { activities.nextActivityInDays === 0 ?
                      <>
                        <b>Weitere Aufgaben werden in weniger als 24 Stunden freigeschaltet.</b>
                      </> :
                      <>
                        <b>Weitere Aufgaben werden in { activities.nextActivityInDays } Tag(en) freigeschaltet.</b>
                      </>
                    }
                  </>
                }
              </>
              }
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" variant="contained" disableElevation>
            Jetzt starten
          </Button>
        </CardActions>
      </Card>
    </Link>
  );
}
