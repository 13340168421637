import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { InputLabel, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import withNotifications from 'services/NotificationService/withNotifications';


import 'scenes/Questionnaires/Questionnaire.scss';
import TopSpacer from 'components/TopSpacer/TopSpacer';
import StudyContactData from 'components/StudyContactData/StudyContactData';


class InitialQuestionnaire extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Willkommen</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/3) * 100 } />
          <QuestionnaireSubmittedDialog dialogOpen={this.props.status.dialogOpen} setStatus={this.props.setStatus} />
          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                // "study_package": 1,
                "pregnancy_week": 1,
                "pregnancy_day": 1,
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <div style={{textAlign: "center", marginTop: "1em"}}>
                  <Typography variant="h2">Herzlich Willkommen!</Typography>
                  <p>Wir freuen uns sehr, dass Sie bei unserer Studie mitmachen!</p>
                  <p>Zu Beginn möchten wir Ihnen einige kurze Fragen stellen. Diese dienen dazu, die App optimal auf Sie abzustimmen.</p>
                  <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Jetzt beantworten</Button>
                </div>
              </StepperPage>
            </Container>

            <div className="questionnaire-body">
              <Container maxWidth="lg">
                { /*<StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Welches Leistungspaket testen Sie während Ihrer Teilnahme an der Studie?<br/><small>Diese Information finden Sie ggfs. in den Unterlagen, die Sie zu Studieneinschluss erhalten haben.</small></FormLabel>
                    <CustomErrorMessage name="study_package" />
                    <RadioGroup aria-label="position" name="study_package" value={this.props.values.study_package} onChange={this.props.handleChange} required={true}>
                      <FormControlLabel value="App" control={<Radio color="primary" />} label="Mutterpass-App" labelPlacement="end"/><br/><br/>
                      <FormControlLabel value="AppA" control={<Radio color="primary" />} label="Mutterpass-App und Paket A (d.h. Waage, Blutdruckmessgerät und Urinsticks)" labelPlacement="end"/><br/><br/>
                      <FormControlLabel value="AppB" control={<Radio color="primary" />} label="Mutterpass-App und Paket B (d.h. Smart Watch und Schlafmatte)" labelPlacement="end"/><br/><br/>
                      <FormControlLabel value="AppAB" control={<Radio color="primary" />} label="Mutterpass-App und beide Pakete A und B (d.h. Waage, Blutdruckmessgerät, Urinsticks, Smart Watch, Schlafmatte)" labelPlacement="end"/>
                    </RadioGroup>
                  </FormControl>
                  <ForwardBackwardButtons handleNext={this.handleNext} />
            </StepperPage> */ }
                <TopSpacer />

                <StepperPage activeStep={this.state.activeStep} thisStep={1}>
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">In welcher Schwangerschaftswoche sind Sie heute?<br/><small>Bitte geben Sie diese Daten im Format "Woche+Tag", also z.B. 9+3, an.</small></FormLabel>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                          <CustomErrorMessage name="pregnancy_week" />
                          <InputLabel htmlFor="pregnancy-week">Woche</InputLabel>
                          <Input
                            id="pregnancy_week"
                            name="pregnancy_week"
                            value={this.props.values.pregnancy_week}
                            onChange={this.props.handleChange}
                            inputProps={ { min: 0, max: 42 } } 
                            type="number"
                          />  
                        </FormControl>
                      </Grid>
                      <Grid item xs={1} style={{textAlign: "center", fontSize: "2em"}}>
                        <b>+</b> 
                      </Grid>
                      <Grid item xs={5}>
                        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                          <CustomErrorMessage name="pregnancy_day" />
                          <InputLabel htmlFor="pregnancy-day">Tag</InputLabel>
                          <Input
                            id="pregnancy_day"
                            name="pregnancy_day"
                            value={this.props.values.pregnancy_day}
                            onChange={this.props.handleChange}
                            inputProps={ { min: 0, max: 6 } } 
                            type="number"
                          />  
                        </FormControl>
                      </Grid>
                    </Grid>
                  </FormControl>
                  <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={2}>
                  <Typography variant="h2">Jetzt mehr Funktionen aktivieren!</Typography>
                  <p>
                    Möchten Sie die SMART Start Mutterpass-App mit all ihren Funktionen nutzen oder sogar unsere smarten Geräte (z.B. Blutdruckmanschette, Smart Watch) leihen, um zu Hause Messungen durchzuführen? Dann melden Sie sich einfach bei uns und fragen Sie nach den erweiterten Teilnahmemöglichkeiten für die SMART Start Studie:
                  </p>
                  <StudyContactData />
                  <ForwardBackwardButtons handleNext={this.handleNext} />
                </StepperPage>

                <StepperPage activeStep={this.state.activeStep} thisStep={3}>
                  <Typography variant="h2">Fragebogen absenden</Typography>
                  Vielen Dank! Sie können den Fragebogen nun absenden.
                  <div style={{textAlign: "center", marginBottom: "1em"}}>
                    <Button type="submit" variant="contained" color="primary" disableElevation>Fragebogen absenden</Button>
                  </div>
                </StepperPage>

                {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}       
              </Container>  
            </div>
          </form>
        </main>
      <Footer/>
    </>
    );
  }
}

export default withNotifications(withFormik({
  mapPropsToValues: () => ({
    // study_package: '',
    pregnancy_week: '',
    pregnancy_day: '',
  }),  
  validationSchema: Yup.object().shape({
    // study_package: Yup.string().required('Pflichtfeld'),
    pregnancy_week: Yup.number().min(0, "Die SSW muss mindestens 0 sein.").max(42, "Die SSW darf maximal 42 sein.").required('Pflichtfeld'),
    pregnancy_day: Yup.number().min(0, "Der Tag muss mindestens 0 sein.").max(6, "Der Tag darf maximal 6 sein.").required('Pflichtfeld'),
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/setup/initial-questionnaire", {
      // study_package: values.study_package,
      pregnancy_week: values.pregnancy_week,
      pregnancy_day: values.pregnancy_day,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
    });
  }
})(InitialQuestionnaire));

/* https://stackoverflow.com/questions/59497550/handling-formik-form-when-validation-error-occurs */