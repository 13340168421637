import React from 'react';
import axios from 'axios';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Input, InputAdornment, TextareaAutosize, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom'
import MuiDialogActions from '@material-ui/core/DialogActions';


import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import QuestionnaireSubmittedDialog from 'components/QuestionnaireSubmittedDialog/QuestionnaireSubmittedDialog';
import Globals from 'services/Globals';
import ErrorFocus from 'components/Forms/ErrorFocus/ErrorFocus';
import CustomErrorMessage from 'components/Forms/CustomErrorMessage/CustomErrorMessage';
import StepperPage from 'components/StepperPage/StepperPage';
import ForwardBackwardButtons from 'components/ForwardBackwardButtons/ForwardBackwardButtons'

import withNotifications from 'services/NotificationService/withNotifications';

import '../Questionnaire.scss';
import QuestionnaireHeadNotice from 'components/QuestionnaireHeadNotice/QuestionnaireHeadNotice';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import { withRouter } from 'react-router-dom';


//import Textfield from '../../components/Textfield/Textfield';
//import RadioCheckboxes from 'components/RadioCheckboxes/RadioCheckboxes';


class IndividualPreviousPregnancy extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      previousPregnancies: []
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setStep = this.setStep.bind(this);
    this.reloadForm = this.reloadForm.bind(this);
  }

  componentDidMount() {
    var self = this;

    axios.get(Globals.SERVER_URL + "/questionnaires/?questionnaire=individualpreviouspregnancy").then(function(res){
      self.setState({
        previousPregnancies: res.data
      });
      self.props.setFieldValue("app_prepreg_id", (res.data.length + 1))
    }).catch(function(err){
      self.props.notificationService.push("Es ist ein Fehler bei der Abfrage der vorherigen Schwangerschaften aufgetreten.", "error", 0, err);
    });
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.props.validateForm();
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  setStep (newStep) {
    this.setState({
      activeStep: newStep
    });
  }

  reloadForm() {
    window.location.reload();
  }

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Vorherige Schwangerschaften</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/13) * 100 } />

          <Dialog aria-labelledby="simple-dialog-title" open={this.props.status.dialogOpen}>
            <DialogTitle id="simple-dialog-title">Eingaben gespeichert</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Bitte wählen Sie die passende Option aus:
              </DialogContentText>
            </DialogContent>
            <MuiDialogActions style={{flexWrap: "wrap"}}>
              <Button component={Link} to="/overview" autoFocus color="primary" style={{textAlign: "center"}}>
                Ich habe alle Schwangerschaften angelegt
              </Button><br/>
              <Button onClick={this.reloadForm} autoFocus color="primary">
                Es gibt weitere Schwangerschaften
              </Button>
            </MuiDialogActions>
          </Dialog>



          <form onSubmit={this.props.handleSubmit} className="formcontrol-children-margin-vertical-1em">
            <ErrorFocus parentProps={this.props} setStep={this.setStep} stepMapping={
              {
                "app_prepreg_id": 0,
                "app_prepreg_date": 0,
                "app_prepreg_ssw": 0,
                "app_prepreg_multi": 0,
                "app_prepreg_end": 0,
                "app_prepreg_mode": 0,
                "app_prepreg_csec": 0,
                "app_prepreg_concep": 0,
                "app_prepreg_weight": 0,
                "app_prepreg_sex": 0,
                "app_prepreg_comp_yn": 0,
                "app_prepreg_comp": 0,
              }
            }/>

            <Container maxWidth="lg">
              <StepperPage activeStep={this.state.activeStep} thisStep={0}>
                <Typography variant="h2" style={{marginTop: "1em"}}>Angaben zur {this.props.values.app_prepreg_id}. Schwangerschaft</Typography>
                <p>
                  Bitte geben Sie nun alle Ihre vorherigen Schwangerschaften etwas genauer an. Beginnen Sie mit der ersten Schwangerschaft. Am Ende der Dateneingabe haben Sie dann die Möglichkeit, eine weitere Schwangerschaft anzulegen.
                </p>
                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">Für welche Schwangerschaft möchten Sie Daten eingeben? </FormLabel>
                  <FormLabel component="legend" style={{fontSize: "14px"}}>Hinweis: Dieses Feld wird durch das System auf Basis Ihrer vorherigen Eingaben vorausgefüllt. Bitte korrigieren Sie es, falls das System einen Fehler gemacht hat.</FormLabel>
                  <CustomErrorMessage name="app_prepreg_id" />
                  <Input
                    name="app_prepreg_id"
                    value={this.props.values.app_prepreg_id}
                    onChange={this.props.handleChange}
                    endAdornment={<InputAdornment position="end">.Schwangerschaft</InputAdornment>}
                    inputProps={ { min: 1, max: 50 } }
                    type="number"
                  />
                </FormControl>

                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">Wann endete die Schwangerschaft?</FormLabel>
                  <CustomErrorMessage name="app_prepreg_date" />
                  <DatePicker
                    views={["year", "month"]}
                    label="Jahr und Monat"
                    value={this.props.values.app_prepreg_date}
                    onChange={value => this.props.setFieldValue("app_prepreg_date", value)}
                  />
                </FormControl>

                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">In der wievielten Schwangerschaftswoche endete die Schwangerschaft?</FormLabel>
                  <CustomErrorMessage name="app_prepreg_ssw" />
                  <Input
                    name="app_prepreg_ssw"
                    value={this.props.values.app_prepreg_ssw}
                    onChange={this.props.handleChange}
                    endAdornment={<InputAdornment position="end">.Schwangerschaftswoche</InputAdornment>}
                    inputProps={ { min: 1, max: 50 } }
                    type="number"
                  />
                </FormControl>

                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">War es eine Mehrlingsschwangerschaft?</FormLabel>
                  <CustomErrorMessage name="app_prepreg_multi" />
                  <RadioGroup aria-label="position" name="app_prepreg_multi" value={this.props.values.app_prepreg_multi} onChange={this.props.handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Ja" labelPlacement="end"/>
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein" labelPlacement="end"/>
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">Wie ist die Schwangerschaft ausgegangen?</FormLabel>
                  <CustomErrorMessage name="app_prepreg_end" />
                  <RadioGroup aria-label="position" name="app_prepreg_end" value={this.props.values.app_prepreg_end} onChange={this.props.handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Kind wurde lebend geboren" labelPlacement="end"/>
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Kind ist kurz nach der Geburt verstorben" labelPlacement="end"/>
                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Kind wurde tot geboren" labelPlacement="end"/>
                    <FormControlLabel value="4" control={<Radio color="primary" />} label="Fehlgeburt" labelPlacement="end"/>
                    <FormControlLabel value="5" control={<Radio color="primary" />} label="Schwangerschaftsabbruch" labelPlacement="end"/>
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">Geburtsmodus</FormLabel>
                  <CustomErrorMessage name="app_prepreg_mode" />
                  <RadioGroup aria-label="position" name="app_prepreg_mode" value={this.props.values.app_prepreg_mode} onChange={this.props.handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Spontangeburt" labelPlacement="end"/>
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Geplanter Kaiserschnitt" labelPlacement="end"/>
                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Ungeplanter Kaiserschnitt" labelPlacement="end"/>
                    <FormControlLabel value="4" control={<Radio color="primary" />} label="Saugglockengeburt" labelPlacement="end"/>
                    <FormControlLabel value="5" control={<Radio color="primary" />} label="Zangengeburt" labelPlacement="end"/>
                  </RadioGroup>
                </FormControl>

                {
                  (this.props.values.app_prepreg_mode === "2" || this.props.values.app_prepreg_mode === "3") &&
                  <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">Grund für Kaiserschnitt</FormLabel>
                  <CustomErrorMessage name="app_prepreg_csec" />
                  <RadioGroup aria-label="position" name="app_prepreg_csec" value={this.props.values.app_prepreg_csec} onChange={this.props.handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Eigener Wunsch" labelPlacement="end"/>
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="Medizinisch empfohlen/notwendig" labelPlacement="end"/>
                  </RadioGroup>
                </FormControl>
                }

                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">Entstehung der Schwangerschaft</FormLabel>
                  <CustomErrorMessage name="app_prepreg_concep" />
                  <RadioGroup aria-label="position" name="app_prepreg_concep" value={this.props.values.app_prepreg_concep} onChange={this.props.handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Spontan" labelPlacement="end"/>
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Hormonbehandlung" labelPlacement="end"/>
                    <FormControlLabel value="3" control={<Radio color="primary" />} label="In vitro Fertilisation" labelPlacement="end"/>
                    <FormControlLabel value="4" control={<Radio color="primary" />} label="Ungewollt" labelPlacement="end"/>
                    <FormControlLabel value="5" control={<Radio color="primary" />} label="Unbekannt" labelPlacement="end"/>
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">Kindsgewicht</FormLabel>
                  <CustomErrorMessage name="app_prepreg_weight" />
                  <Input
                    name="app_prepreg_weight"
                    value={this.props.values.app_prepreg_weight}
                    onChange={this.props.handleChange}
                    type="number"
                    endAdornment={<InputAdornment position="end">Gramm</InputAdornment>}
                    inputProps={ { min: 1, max: 10000 } }
                  />
                </FormControl>

                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">Geschlecht</FormLabel>
                  <CustomErrorMessage name="app_prepreg_sex" />
                  <RadioGroup aria-label="position" name="app_prepreg_sex" value={this.props.values.app_prepreg_sex} onChange={this.props.handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Weiblich" labelPlacement="end"/>
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Männlich" labelPlacement="end"/>
                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Unbestimmt" labelPlacement="end"/>
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend">Gab es Komplikationen oder Besonderheiten bei der Geburt oder danach? (z.B. verstärkte Blutungen)</FormLabel>
                  <CustomErrorMessage name="app_prepreg_comp_yn" />
                  <RadioGroup aria-label="position" name="app_prepreg_comp_yn" value={this.props.values.app_prepreg_comp_yn} onChange={this.props.handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Ja" labelPlacement="end"/>
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="Nein" labelPlacement="end"/>
                  </RadioGroup>
                </FormControl>

                { this.props.values.app_prepreg_comp_yn === "1" &&
                  <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">Komplikationen/Besonderheiten:</FormLabel>
                    <CustomErrorMessage name="app_prepreg_comp" />
                    <TextareaAutosize
                      component="textarea"
                      name="app_prepreg_comp"
                      value={this.props.values.app_prepreg_comp}
                      onChange={this.props.handleChange}
                      minRows={4}
                    />
                  </FormControl>
                }

                <div style={{textAlign: "center", marginBottom: "1em"}}>
                  <Button type="submit" variant="contained" color="primary" disableElevation>Eingaben speichern</Button>
                </div>

                { /* <ForwardBackwardButtons handleBack={this.handleBack} handleNext={this.handleNext} /> */ }
              </StepperPage>

              {this.props.errors.name && this.props.touched.name && <div id="feedback">{this.props.errors.name}</div>}
            </Container>
          </form>
        </main>
      <Footer showMenu={false} />
    </>
    );
  }
}

export default withRouter(withNotifications(withFormik({
  mapPropsToValues: () => ({
    app_prepreg_id: 1,
    app_prepreg_date: null,
    app_prepreg_ssw: '',
    app_prepreg_multi: '',
    app_prepreg_end: '',
    app_prepreg_mode: '',
    app_prepreg_csec: '',
    app_prepreg_concep: '',
    app_prepreg_weight: '',
    app_prepreg_sex: '',
    app_prepreg_comp_yn: '',
    app_prepreg_comp: '',
  }),  
  validationSchema: Yup.object().shape({
    /* app_prepreg_id: Yup.number().required('Pflichtfeld'), */
    app_prepreg_date: Yup.string().required('Pflichtfeld'),
    app_prepreg_ssw: Yup.number().required('Pflichtfeld'),
    app_prepreg_multi: Yup.number().required('Pflichtfeld'),
    app_prepreg_end: Yup.number().required('Pflichtfeld'),
    //app_prepreg_mode: Yup.number().required('Pflichtfeld'),
    app_prepreg_csec: Yup.number().when('app_prepreg_mode', {
      is: (mode => mode === "2" || mode === "3"),
      then: Yup.number().required('Pflichtfeld')
    }),
    app_prepreg_concep: Yup.number().required('Pflichtfeld'),
    //app_prepreg_weight: Yup.number().required('Pflichtfeld'),
    //app_prepreg_sex: Yup.number().required('Pflichtfeld'),
    //app_prepreg_comp_yn: Yup.number().required('Pflichtfeld'),
    app_prepreg_comp: Yup.string().when('app_prepreg_comp_yn', {
      is: "1",
      then: Yup.string().required('Pflichtfeld')
    }),
  }),
  mapPropsToStatus: () => ({
    dialogOpen: false,
  }),
  handleSubmit: (values, props) => {
    axios.post(Globals.SERVER_URL + "/questionnaires", {
      questionnaireName: "individualpreviouspregnancy",
      questionnaireData: values,
    }).then(function(res){
      props.setStatus({
        dialogOpen: true
      })  
    }).catch(function(err){
      props.props.notificationService.push("Es ist ein Fehler bei der Übertragung der Ergebnisse aufgetreten.", "error", 0, err);
    });
  }
})(IndividualPreviousPregnancy)));