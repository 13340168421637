import React from 'react';
import axios from 'axios';

import Header from 'components/Header/Header';
import Globals from 'services/Globals';

import { Link } from 'react-router-dom'

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';

import withNotifications from 'services/NotificationService/withNotifications';

import StepperPage from 'components/StepperPage/StepperPage';
import FileUploadNotice from 'components/FileUploadNotice/FileUploadNotice';

import mutterpassAufnahme from './mutterpass-aufnahme.svg';
import mutterpassBeispiel from './mutterpass.jpg'
import Footer from 'components/Footer/Footer';


class MaternityRecordPhoto extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      isCurrentlyLoading: false,
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.fileUploadHandler = this.fileUploadHandler.bind(this);
  }

  handleBack () {
    this.setState({
      activeStep: (this.state.activeStep - 1)
    });
  }

  handleNext () {
    this.setState({
      activeStep: (this.state.activeStep + 1)
    });
  }

  fileUploadHandler = (event) => {
    let self = this;

    const fd = new FormData();
    fd.append("photoData", event.target.files[0]);
    fd.append("photoType", "maternity-record-" + this.state.activeStep);

    // Activate "loading" notice
    self.setState({isCurrentlyLoading: true});

    axios.post(Globals.SERVER_URL + "/photos", fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // Disable "is loading" notice
        self.setState({isCurrentlyLoading: false});
        this.props.notificationService.push("Bild erfolgreich hochgeladen.", "success", 2000); 

        // Proceed to next step
        this.handleNext();
      }).catch((err) =>{
        this.props.notificationService.push("Fehler beim Hochladen des Bilds. Bitte versuchen Sie es später erneut.", "error", 0, err); 
      });
  };

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Bildaufnahme Mutterpass</Typography>
        </Header>
        <main>
          <LinearProgress variant="determinate" value={ (this.state.activeStep/10) * 100 } />

          <FileUploadNotice loading={this.state.isCurrentlyLoading} />

          <Container maxWidth="lg">
            <StepperPage activeStep={this.state.activeStep} thisStep={0}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <div className="photo-circle">
                  <img src={mutterpassAufnahme} alt="Illustration eines Mutterpasses vor einem Handy" />
                </div>
                <Typography variant="h2">Einleitung</Typography>
                <p>Wir bitten Sie in regelmäßigen Abständen darum, mehrere Bilder von Ihrem Mutterpass aufzunehmen. Diese Bilder helfen uns bei der Durchführung der Studie.</p>
                <p>Im folgenden werden Sie Schritt für Schritt alle Seiten des Mutterpasses abfotografieren.</p>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Los geht's <KeyboardArrowRight /></Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={1}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Hinweise zur Bildaufnahme</Typography>
                <p>Wählen Sie einen Ort mit <b>heller, ausreichender Beleuchtung</b>.</p>
                <p>Achten Sie darauf, dass die jeweiligen Seiten <b>vollständig im Bild enthalten</b> sind.</p>
                <p>Unten sehen Sie ein gutes Beispiel:</p>
                <img src={mutterpassBeispiel} alt="Beispielbild eines Mutterpasses in heller Umgebung auf weißem Hintergrund" />
                <p>Achtung! Bitte stellen Sie sicher, dass auf den Fotos keine identifizierenden Angaben, wie z.B. Name, Geburtsdatum, Adressen (auch nicht von Ihrem Arzt/Ihrer Ärztin) oder Patientennummern etc. erkennbar sind.</p>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleBack}><KeyboardArrowLeft /> Zurück</Button>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext}>Weiter <KeyboardArrowRight /></Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={2}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Seite 1</Typography>
                <p>Öffnen Sie die erste Seite (Stempel des Arztes, Untersuchungstermine). Tippen Sie anschließend auf den Button, um das Bild aufzunehmen.</p>
                <p><b>Sie waren bereits in der Vergangenheit schwanger?</b></p>
                <p> Falls für die aktuelle Schwangerschaft der frühere Mutterpass weiterverwendet wurde, gehen Sie bitte direkt zu Seite 17 ("Weitere Schwangerschaft"). Bitte fotografieren Sie dann auch auf den Folgeseiten jeweils die Bilder/Seiten der aktuellen Schwangerschaft ab.</p>
                <Button variant="contained" color="primary" component="label" disableElevation disabled={this.state.isCurrentlyLoading}>
                  Bild aufnehmen
                  <input type="file" accept="image/*" capture="camera" onChange={this.fileUploadHandler} hidden />
                </Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={3}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Seiten 2-4</Typography>
                <p>Öffnen Sie die Seiten 2-4 (Laboruntersuchungen und Rötelnschutz, Angaben zu vorangegegangenen Schwangerschaften, Beratung der Schwangeren).</p> 
                <p>Bitte achten Sie darauf, dass Sie <b>alle drei Seiten gemeinsam</b> aufnehmen. Bitte beachten Sie, dass Seite 4 <b>ausgeklappt</b> werden muss.</p>
                <Button variant="contained" color="primary" component="label" disableElevation disabled={this.state.isCurrentlyLoading}>Bild aufnehmen
                  <input type="file" accept="image/*" capture="camera" onChange={this.fileUploadHandler} hidden />
                </Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={4}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Seiten 5-8</Typography>
                <p>Öffnen Sie die Seiten 5-8 (Anamnese/Erste Vorsorge-Untersuchung, Besondere Befunde in der Schwangerschaft, Gravidogramm).</p> 
                <p>Bitte achten Sie darauf, dass Sie <b>alle vier Seiten gemeinsam</b> aufnehmen. Bitte beachten Sie, dass Seite 5 sowie Seite 7/8 <b>ausgeklappt</b> werden müssen.</p>
                <Button variant="contained" color="primary" component="label" disableElevation disabled={this.state.isCurrentlyLoading}>Bild aufnehmen
                  <input type="file" accept="image/*" capture="camera" onChange={this.fileUploadHandler} hidden />
                </Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={5}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Seiten 9-11</Typography>
                <p>Öffnen Sie die Seiten 9-11 (Besonderheiten zu den Katalogen A. und B., Ultraschall-Untersuchungen).</p> 
                <p>Bitte achten Sie darauf, dass Sie <b>alle drei Seiten gemeinsam</b> aufnehmen. Bitte beachten Sie, dass Seite 9 <b>ausgeklappt</b> werden muss.</p>
                <Button variant="contained" color="primary" component="label" disableElevation disabled={this.state.isCurrentlyLoading}>Bild aufnehmen
                  <input type="file" accept="image/*" capture="camera" onChange={this.fileUploadHandler} hidden />
                </Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={6}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Seiten 12/13</Typography>
                <p>Öffnen Sie die Seiten 12/13 (Ultraschall-Kontrolluntersuchungen nach Anlage 1b, Normkurven).</p> 
                <Button variant="contained" color="primary" component="label" disableElevation disabled={this.state.isCurrentlyLoading}>Bild aufnehmen
                  <input type="file" accept="image/*" capture="camera" onChange={this.fileUploadHandler} hidden />
                </Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={7}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Seiten 14/15</Typography>
                <p>Öffnen Sie die Seiten 14/15 (Weiterführende Ultraschall-Untersuchungen, Abschluss-Untersuchung/Epikrise).</p> 
                <Button variant="contained" color="primary" component="label" disableElevation disabled={this.state.isCurrentlyLoading}>Bild aufnehmen
                  <input type="file" accept="image/*" capture="camera" onChange={this.fileUploadHandler} hidden />
                </Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={8}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Seite 16</Typography>
                <p>Öffnen Sie die Seite 16/17 (2. Untersuchung nach der Entbindung (6.-8 Woche).</p> 
                <Button variant="contained" color="primary" component="label" disableElevation disabled={this.state.isCurrentlyLoading}>Bild aufnehmen
                  <input type="file" accept="image/*" capture="camera" onChange={this.fileUploadHandler} hidden />
                </Button>
              </div>
            </StepperPage>
            
            <StepperPage activeStep={this.state.activeStep} thisStep={9}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Sonstige Dokumente/Seiten</Typography>
                <p>Falls in Ihrem Mutterpass noch weitere Dokumente (z.B. zusätzlich Gravidogramm-Seiten) enthalten sind, können Sie diese hier noch zusätzlich abfotografieren.</p>
                <Button variant="contained" color="primary" component="label" disableElevation disabled={this.state.isCurrentlyLoading}>
                  Zusätzliches Bild aufnehmen
                  <input type="file" accept="image/*" capture="camera" onChange={this.fileUploadHandler} hidden />
                </Button><br/>
                <Button variant="contained" color="primary" align="center" disableElevation onClick={this.handleNext} disabled={this.state.isCurrentlyLoading}>
                  Keine weiteren Bilder aufnehmen <KeyboardArrowRight />
                </Button>
              </div>
            </StepperPage>

            <StepperPage activeStep={this.state.activeStep} thisStep={10}>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Typography variant="h2">Vielen Dank!</Typography>
                  Die Bilder wurde erfolgreich übertragen.<br/><br/>
                  <Button variant="contained" component={Link} to="/overview" autoFocus color="primary">
                    Zurück zur Startseite
                  </Button>
              </div>
            </StepperPage>
          </Container>
        </main>
        <Footer />
      </>
    );
  }
}

export default withNotifications(MaternityRecordPhoto);