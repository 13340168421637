import { useEffect, useState } from "react";

import React from 'react';

import { getMonthlyAvgData, getYearMonthGermanTextStringFromDate } from "../../GraphUtils/GraphUtils";

export default function BloodPressureTendencyText(props) {

  const [data, setData] = useState(0);

  useEffect(() => {
    // Ensure prop objects are already initialized/set
    if(props.data.systolicMeasurements === undefined || props.data.diastolicMeasurements === undefined) {
        return;
    }

    let monthlySystolicBloodPressure = getMonthlyAvgData(props.data.systolicMeasurements, getYearMonthGermanTextStringFromDate);
    let monthlyDiastolicBloodPressure = getMonthlyAvgData(props.data.diastolicMeasurements, getYearMonthGermanTextStringFromDate);

    setData({monthlySystolicBloodPressure, monthlyDiastolicBloodPressure});
  }, [props])

  return (
    <>
      { typeof data === 'object' && 


        <table class="wearable-data-table">
          <thead>
            <tr>
              <th>Monat</th>
              <th>Durchschnittlicher Blutdruck</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(data.monthlySystolicBloodPressure).map((key) => {
                return(
                  <tr>
                    <td>{key}</td>
                    <td>{ Math.round(data.monthlySystolicBloodPressure[key], 2) }/{ Math.round(data.monthlyDiastolicBloodPressure[key], 2) } mmHg</td>
                    {
                    /*
        <>
         { 
            Object.keys(data.monthlySystolicBloodPressure).map((key) => {
              return(
                <>
                  Im <b>{key}</b> betrug Ihr Blutdruck durchschnittlich <b>{ Math.round(data.monthlySystolicBloodPressure[key], 2) }/{ Math.round(data.monthlyDiastolicBloodPressure[key], 2) }</b> mmHg. <br/>
                </>
              );
            })
          }
        </>                    */
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>

      }
    </>
  )
}

