import React from 'react';

import Header from 'components/Header/Header';

import './LabRubella.scss';



import Container from '@material-ui/core/Container';
import LabRubellaData from './components/LabRubellaData';
import Footer from 'components/Footer/Footer';

class LabRubella extends React.Component {
  render() {
    return (
      <>
        <Header>
          <h2>Laboruntersuchungen und Rötelnschutz</h2>
        </Header>
        <main>
          <Container maxWidth="lg">
            <LabRubellaData />
          </Container>
        </main>
        <Footer />
      </>
    );
  }
}

export default LabRubella;