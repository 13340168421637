import React from "react";
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import PropTypes from 'prop-types';


export const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: 'Sehr schlecht',
  },
  2: {
    icon: <SentimentDissatisfiedIcon />,
    label: 'Eher schlecht',
  },
  3: {
    icon: <SentimentSatisfiedIcon />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Eher glücklich',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon />,
    label: 'Sehr glücklich',
  },
};


function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CustomizedRatings({ value, onChange }) {
  return (
    <div>
      <Box component="fieldset" mb={3} borderColor="transparent">
        <Typography component="legend">Wie fühlen Sie sich gerade?</Typography>
        <Rating
          name="customized-icons"
          getLabelText={(value) => customIcons[value].label}
          IconContainerComponent={IconContainer}
          onChange={onChange}
          value={value}
        />
      </Box>
    </div>
  )
}
