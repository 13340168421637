import React from 'react';

import Header from '../../../components/Header/Header';
import Footer from 'components/Footer/Footer';

import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import BackToLastPage from '../../../components/BackToLastPage/BackToLastPage';

export default function DataPrivacy() {
    return (
      <>
        <Header>
          <Typography variant="h2">Datenschutzerklärung</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            <Typography variant="h2">Datenschutzerklärung</Typography>
            <BackToLastPage />
            <p>
              <strong>Datenschutzerklärung Anwendung SMART Start</strong>
            </p>
            <p>
                <strong>1. </strong>
                <strong>Verantwortliche Stelle</strong>
            </p>
            <p>
                Verantwortliche Stelle für die Erhebung, Verarbeitung und Nutzung von
            personenbezogenen Daten im Zusammenhang mit der    <strong>Anwendung SMART Start </strong>ist das
                <br/>
                <br/>
            </p>
            <p>
                <strong>Universitätsklinikum Erlangen</strong>
            </p>
            <p>
                Anstalt des öffentlichen Rechts
            </p>
            <p>
                Maximiliansplatz 2
            </p>
            <p>
                91054 Erlangen
            </p>
            <p>
                Telefon: 09131 85-0
            </p>
            <p>
                <strong>2. </strong>
                <strong>Installation der Anwendung</strong>
            </p>
            <p>
                Bei der Anwendung handelt es sich um eine Webanwendung („Progressive Web
                App“). Sie können die Anwendung entweder in Ihrem Browser verwenden, oder
                eine Verknüpfung zur Anwendung auf Ihrem Home-Bildschirm/App-Bildschirm
                hinzufügen.
            </p>
            <p>
                <strong>3. </strong>
                <strong>Erforderliche Berechtigungen</strong>
            </p>
            <p>
                Damit die Webseite SMART Start ordnungsgemäß funktionieren kann, ist es
                notwendig, dass Sie den Zugriff auf bestimmte Funktionen gewähren.
            </p>
            <p>
                <u>Technisch bedingte Zugriffsberechtigungen:</u>
            </p>
            <p>
                <strong>Internet-/Netzwerkverbindung:</strong>
            </p>
            <p>
                Zur Verwendung der Anwendung und Übertragung der Daten von und in die
                Studiendatenbank ist eine Internetverbindung notwendig. Diese kann entweder
                per WLAN oder Mobilfunkverbindung hergestellt werden.
            </p>
            <p>
                <strong>Kamera und Mikrofon:</strong>
            </p>
            <p>
                Für die Verwendung von Studienfunktion, z.B. der Übermittlung der
                Mutterpass-Inhalte oder der Urin-Analyse.
            </p>
            <p>
                <strong>Dateien/Kalender:</strong>
            </p>
            <p>
                Zum Download und Speicherung von Dateien, zum Beispiel von
                Kalendereinladungen.
            </p>
            <p>
                <strong>Cookies:</strong>
            </p>
            <p>
                Die SMART Start Anwendung verwendet ausschließlich technisch notwendige
                Cookies. Cookies sind kleine Textdateien, die vorübergehend in Ihrem
                Browser gespeichert sind.
            </p>
            <p>
                <strong>Withings:</strong>
            </p>
            <p>
                Falls Sie im Rahmen der Studie Withings-Geräte verwenden, geben Sie auf der
                Withings-Webseite Ihr Einverständnis dazu, dass wir Ihre Daten von Ihrem
                Withings-Konto abrufen und in unserer Studien-Datenbank dauerhaft
                speichern.
            </p>
            <p>
                <strong>4. </strong>
                <strong>
                    Welche Daten werden zur Nutzung der Anwendung erhoben und/oder
                    verarbeitet?
                </strong>
            </p>
            <p>
            Im Folgenden möchten wir Sie darüber informieren, welche Daten über die    <strong>Anwendung SMART Start </strong>erhoben, verarbeitet und gespeichert
                werden. Alle Daten werden anonymisiert erhoben, außer Sie erklären sich zur
                Teilnahme an der SMART Start Studie bereit. In diesem Falle werden Ihre
                Daten mit einem Studien-Pseudonym verknüpft und in der Folge
                pseudonymisiert verarbeitet. Das bedeutet, dass Ihnen ein Studien-Pseudonym
                aus Zahlen und Buchstaben zugeordnet wird. Ihre Daten werden nur mit diesem
                Studien-Pseudonym verknüpft, es werden keine Informationen gespeichert,
                durch die Sie identifiziert werden können (z.B. Name, Geburtsdatum, E-Mail
                etc.). Welcher Patientin welches Studien-Pseudonym zugeordnet wurde, steht
                auf einer Entschlüsselungsliste. Diese Liste wird sicher aufbewahrt. Nur
                Studienpersonal hat darauf Zugriff. Ohne diese Liste ist es nicht möglich,
                eine Verbindung zwischen Ihnen und Ihren Daten herzustellen. So bleibt Ihre
                Identität geheim.
            </p>
            <p>
                <strong> 4.1. </strong>
                <strong>Einrichtung und Konto</strong>
            </p>
            <p>
                Zur Einrichtung Ihres Benutzerkontos benötigen wir ein von Ihnen vergebenes
                Passwort. Dieses Passwort wird auf unseren Servern verschlüsselt
                („gehasht“) abgespeichert. Sie erhalten bei Studienanmeldung eine App-ID.
                Daten werden nur in Verbindung mit Ihrer App-ID weitergegeben.
            </p>
            <p>
                <strong> 4.2. </strong>
                <strong>Gesundheitsdaten</strong>
            </p>
            <p>
                Im Rahmen der Anwendungsnutzung haben Sie die Möglichkeit, eigenständig
                studienrelevante Gesundheitsdaten einzugeben (z.B. Konzeptionszeitpunkt,
                Geburtszeitpunkt, Fragebögen zu Schlaf, Aktivität, Gesundheitsverhalten,
                Depression). Die eingegebenen Daten werden auf einen Server des
                Universitätsklinikums übermittelt und dort sicher nach aktuellem Stand der
                Technik gespeichert. Darüber hinaus werden auch die von Ihnen hochgeladenen
                Mutterpass-Fotos gespeichert. Bei Teilnahme an der SMART Start Studie
                werden all diese Daten durch das Studien-Team über die reine Speicherung
                hinaus für Studienzwecke weiterverarbeitet. Dies gilt auch für
                Wearable-Daten, die automatisch von Ihrem Withings-Konto synchronisiert
                werden, soweit Sie die dafür erforderliche Zustimmung gegeben haben.
            </p>
            <p>
                <strong> 4.3. </strong>
                <strong>Nutzungsdaten</strong>
            </p>
            <p>
                Die Anwendung speichert und übermittelt Daten über Ihr Nutzungsverhalten
                der Applikation. Diese werden für Forschungszwecke verwendet. Dazu zählen
                zum Beispiel Login-Zeiten, aufgerufene Seiten, Verweildauer auf Seiten
                sowie die allgemeine Interaktion mit der Applikation.
            </p>
            <p>
                <strong> 4.4. </strong>
                <strong>Logdateien</strong>
            </p>
            <p>
                Wir erheben und speichern automatisch Informationen in so genannten
                Server-Log-Dateien. Diese übermittelt Ihr Browser automatisch an uns. Dazu
                gehören:
            </p>
            <ul>
                <li>
                    Browsertyp und Browserversion
                </li>
                <li>
                    verwendetes Betriebssystem
                </li>
                <li>
                    Referrer URL
                </li>
                <li>
                    Hostname des zugreifenden Rechners
                </li>
                <li>
                    Uhrzeit der Serveranfrage
                </li>
                <li>
                    IP-Adresse
                </li>
            </ul>
            <p>
                Wir nehmen keine Zusammenführung dieser Daten mit anderen Datenquellen vor.
                Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
                DSGVO (Funktionalität der Anwendung).
            </p>
            <p>
                <strong> 4.5. </strong>
                <strong>Andere erfasste Daten </strong>
            </p>
            <p>
                Sind Sie im System eingeloggt und geben Daten ein, werden nach Speichern
                der Daten auch Datum und Uhrzeit erfasst und gespeichert.
            </p>
            <p>
                <strong>5. </strong>
                <strong>
                    Erhalten das Universitätsklinikum Erlangen oder dessen Partner Daten
                    und zu welchem Zweck?
                </strong>
            </p>
            <p>
                Ihre Daten werden zum Teil auf einem lokalen Server des
                Universitätsklinikums Erlangen gespeichert. Die Verantwortlichkeit
                bezüglich dieser Daten liegt beim Universitätsklinikum Erlangen.
            </p>
            <p>
                Ihre Daten werden in pseudonymisierter Form an die Kooperationspartner der
                Friedrich-Alexander-Universität Erlangen-Nürnberg (FAU) und des
                Universitätsklinikums Erlangen (UKER) übermittelt:
            </p>
            <ul>
              <li>Lehrstuhl für Informatik 14 (Maschinelles Lernen und Datenanalytik) (FAU)</li>
              <li>Lehrstuhl für Informatik 15 (Machine Intelligence) (FAU)</li>
              <li>Lehrstuhl für Systematische Theologie II (FAU)</li>
              <li>Lehrstuhl für Betriebswirtschaftslehre, insbes. Gesundheitsmanagement
                (FAU)</li>
              <li>Lehrstuhl für Psychiatrie und Psychotherapie (UKER)</li>
            </ul>
            <p>
            Die Übermittlung von Daten zwischen der    <strong>Anwendung SMART Start </strong>und den hier beschriebenen
                Empfängern erfolgt immer mit einer Verschlüsselung, die dem aktuellen Stand
                der Technik entspricht.
            </p>
            <p>
                Sofern Sie die Geräte von Withings und die dazugehörige Health Mate App
                nutzen, müssen Sie bei der Registrierung die Datenschutzerklärung von
                Withings lesen und akzeptieren, bevor eine Nutzung möglich ist. Die über
                die Geräte erhobenen personenbezogenen Daten werden über die Health Mate
                App und die Cloud an den Withings Server übertragen und auf diesem
                gespeichert, wenn Sie Ihr Gerät mit der Health Mate App synchronisieren
                oder Ihr Gerät mit dem Internet verbinden. Die Synchronisierung auf den in
                Frankreich befindlichen Servern ist nötig. Gemäß der Datenschutzerklärung
                von Withings können Sie Ihre Rechte nach DSGVO gegenüber Withings geltend
                machen. Der Datenverantwortliche ist in diesem Fall Withings. Nähere
                Informationen zum Datenschutz bei Withings finden Sie unter
                <a href="https://www.withings.com/eu/de/legal/privacy-policy">
                    https://www.withings.com/eu/de/legal/privacy-policy
                </a>
                .
            </p>
            <p>
                Des Weiteren können Ihre Daten an zu diesem Zeitpunkt noch nicht bekannte
                Institutionen im akademischen und nichtakademischen industriellen Bereich
                im In- und Ausland (auch USA) übermittelt werden, wenn dies für die
                Auswertung einer bestimmten Fragestellung der Studie notwendig ist. Zu
                jedem Zeitpunkt der Studie können Sie Auskunft über die Weitergabe Ihrer
                Daten erhalten. Wir weisen Sie darauf hin, dass in den USA kein
                angemessenes Datenschutzniveau nach der DSGVO besteht, weil weder ein
                Angemessenheitsbeschluss der Europäischen Kommission, noch geeignete
                Garantien nach Art. 46 DSGVO vorhanden sind. Daher kann nicht garantiert
                werden, dass das Datenschutzniveau mit dem der DSGVO vergleichbar ist.
                Hieraus resultieren Risiken für den Schutz ihrer personenbezogenen Daten.
            </p>
            <p>
                <strong>6. </strong>
                <strong>Datenerhebung zu Analyse-Zwecken und Fehlerbehebung </strong>
            </p>
            <p>
                Es werden Daten zu Analysezwecken und Fehlerbehebung durch die Anwendung
                erhoben (z.B. Übermittlung und Protokollierung von Fehlermeldungen) und
                gespeichert.
            </p>
            <p>
                <strong>7. </strong>
                <strong>Speicherfristen und Löschung der genutzten Daten</strong>
            </p>
            <p>
                Ihre eingegebenen Daten werden auf dem Studien-Server des
                Universitätsklinikums Erlangen auf Basis Ihrer App-ID bzw. Studien-ID
                gespeichert. Übermittelte Daten an das Universitätsklinikum werden maximal
                10 Jahre gespeichert, sofern nicht längere Aufbewahrungsfristen gem. Art. 6
                DSGVO bestehen.
            </p>
            <p>
                <strong>8. </strong>
                <strong>Ihre Rechte</strong>
            </p>
            <p>
                Ihnen stehen die folgenden Rechte zu:
            </p>
            <p>
                a) Recht auf Auskunft (Art. 15 DSGVO)
            </p>
            <p>
                b) Recht auf Berichtigung (Art. 16 DSGVO)
            </p>
            <p>
                c) Recht auf Löschung (Art. 17 DSGVO)
            </p>
            <p>
                d) Recht auf Einschränkung (Art. 18 DSGVO)
            </p>
            <p>
                e) Recht auf Widerspruch (Art. 21 DSGVO)
            </p>
            <p>
                Die Anwendung wird nicht für Profiling- oder Scoring-Maßnahmen nach Art. 22
                DSGVO genutzt.
            </p>
            <p>
                <strong>9. </strong>
                <strong>Kommunikationswege</strong>
            </p>
            <p>
                Sofern Sie Kontakt zum Universitätsklinikum Erlangen aufnehmen wollen,
                stehen Ihnen nachfolgende Einrichtungen als Ansprechpartner zur Verfügung:
            </p>
            <p>
                <u>Kontaktdaten Datenschutzbeauftragte und Aufsichtsbehörden:</u>
            </p>
            <p>
                Universitätsklinikum Erlangen
            </p>
            <p>
                Datenschutzbeauftragter
            </p>
            <p>
                Krankenhausstr. 12
            </p>
            <p>
                91054 Erlangen
            </p>
            <p>
                E-Mail: datenschutz@uk-erlangen.de
            </p>
            <p>
                Der Bayerische Landesbeauftragte für den Datenschutz
            </p>
            <p>
                Wagmüllerstr. 18
            </p>
            <p>
                80538 München
            </p>
            <p>
                <strong>10.</strong>
                <strong> Änderung oder Anpassung der Datenschutzerklärung</strong>
            </p>
            <p>
                Die Datenschutzerklärung ist aktuell gültig und datiert vom 05.04.2022.
                Durch die Weiterentwicklung unserer Anwendung oder der Implementierung
                neuer Technologien kann es notwendig werden, diese Datenschutzerklärung zu
                ändern<a name="_heading=h.gjdgxs"></a>.
            </p>
          </Container>
        </main>
        <Footer/>
      </>
    );
}