import React from 'react';

import Header from '../../components/Header/Header';
import Footer from 'components/Footer/Footer';

import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom'


import womanBooklet from 'resources/svgs/woman-booklet.svg';


class Informations extends React.Component {

  render() {
    return (
      <>
        <Header>
          <Typography variant="h2">Info-Bereich</Typography>
        </Header>
        <main>
          <Container maxWidth="lg">
            <div style={{textAlign: "center", paddingTop: "1em", paddingBottom: "1em"}}>
              <div className="photo-circle">
                <img src={womanBooklet} alt="Illustration einer Frau, die etwas auf dem Handy liest." />
              </div>
            </div>
            Hier finden Sie <b>nützliche und interessante Informationen</b> rund um Ihre Schwangerschaft und Ihr Baby.
            <Typography variant="h2" style={{marginTop: "0.5em"}}>Allgemein</Typography>
            <ul>
              <li><Link to="/informations/general/general">Allgemeines zur Schwangerschaft</Link></li>
              <li><Link to="/informations/general/alcohol-nicotine">Alkohol, Nikotin und sonstige Konsumgüter</Link></li>
            </ul>
            <Typography variant="h2">Während der Schwangerschaft</Typography>
            <ul>
              <li><Link to="/informations/during-pregnancy/nutrition">Ernährung</Link></li>
              <li><Link to="/informations/during-pregnancy/nausea">Übelkeit und Erbrechen</Link></li>
              <li><Link to="/informations/during-pregnancy/my-body">Was passiert mit meinem Körper?</Link></li>
              <li><Link to="/informations/during-pregnancy/life-travel-sauna">Verhalten in der Schwangerschaft: Reisen, Freizeit, Sauna</Link></li>
              <li><Link to="/informations/during-pregnancy/sports">Sport und Freizeit</Link></li>
              <li><Link to="/informations/during-pregnancy/skin-hair">Haut und Haar</Link></li>
              <li><Link to="/informations/during-pregnancy/sex">Geschlechtsverkehr</Link></li>
              <li><Link to="/informations/during-pregnancy/emotions">Emotionen</Link></li>
            </ul>
            <Typography variant="h2">Untersuchungen und Vorsorge</Typography>
            <ul>
              <li><Link to="/informations/perinatal-care/maternity-record">Der Mutterpass</Link></li>
              <li><Link to="/informations/perinatal-care/ultrasound-examinations">Ultraschalluntersuchungen</Link></li>
              <li><Link to="/informations/perinatal-care/prenatal-diagnostics">Pränataldiagnostik</Link></li>
              <li><Link to="/informations/perinatal-care/midwives">Hebammen und Hebammensuche</Link></li>
              <li><Link to="/informations/perinatal-care/examinations">Sonstige Untersuchungen</Link></li>
              <li><Link to="/informations/perinatal-care/pregnancy-illnesses">Schwangerschaftsspezifische Erkrankungen</Link></li>
              <li><Link to="/informations/perinatal-care/stem-cells">Stammzellen in der Nabelschnur</Link></li>
            </ul>
            <Typography variant="h2">Geburt</Typography>
            <ul>
              <li><Link to="/informations/birth/birth-preparation-class">Geburtsvorbereitungskurs</Link></li>
              <li><Link to="/informations/birth/birth">Die Geburt</Link></li>
              <li><Link to="/informations/birth/pain-management">Schmerztherapie unter Geburt</Link></li>
              <li><Link to="/informations/birth/birth-checklist">Was brauche ich für die Geburt/fürs Krankenhaus?</Link></li>
            </ul>
            <Typography variant="h2">Wochenbett</Typography>
            <ul>
              <li><Link to="/informations/puerperal/puerperal">Das Wochenbett</Link></li>
              <li><Link to="/informations/puerperal/nursing">Stillen</Link></li>
            </ul>
          </Container>
        </main>
        <Footer/>
      </>
    );
  }
}

export default Informations;