import React from 'react';

import { useTheme } from '@material-ui/core/styles';

export default function LabRubellaEntry(props) {
  const theme = useTheme(); 
  
    if(props.data["type"] === "blood_group") {
      return (
        <div className="lab-rubella-entry" style={{backgroundColor: theme.palette.secondary.main }}>
          <b>Blutgruppenzugehörigkeit</b><br/>
          Ergebnis: {props.data["result"]}<br/>
          Datum: {props.data["date"]}<br/>
          Protokoll-Nr.: {props.data["lab_nr"]}<br/>
          {props.data["remark"]}
        </div>
      )
    }

    else if(props.data["type"] === "rubella_antibody_test") {
      return (
        <div className="lab-rubella-entry" style={{backgroundColor: theme.palette.secondary.main }}> 
          <b>Röteln-Antikörpertest</b><br/>
          Ergebnis: {props.data["result"]}<br/>
          Wert: {props.data["value"]} {props.data["unit"]}<br/>
          Immunität anzn.: {props.data["immunity"]}<br/>
          Datum: {props.data["date"]}<br/>
          Protokoll-Nr.: {props.data["lab_nr"]}<br/>
          {props.data["remark"]}
        </div>
      )
    }

    else if(props.data["type"] === "hiv_examination") {
      return (
        <div className="lab-rubella-entry" style={{backgroundColor: theme.palette.secondary.main }}>
          <b>HIV</b><br/>
          Status: {props.data["state"]}<br/>
          Datum: {props.data["date"]}<br/>
          Protokoll-Nr.: {props.data["lab_nr"]}<br/>
          {props.data["remark"]}
        </div>
      )
    }

    else if(props.data["type"] === "chlamydia_trachomatis") {
      return (
        <div className="lab-rubella-entry" style={{backgroundColor: theme.palette.secondary.main }}>
          <b>Chlamydia trachomatis DNA-Test</b><br/>
          Ergebnis: {props.data["result"]}<br/>
          Datum: {props.data["date"]}<br/>
          Protokoll-Nr.: {props.data["lab_nr"]}<br/>
          {props.data["remark"]}
        </div>
      )
    }

    else if(props.data["type"] === "lues_search_reaction") {
      return (
        <div className="lab-rubella-entry" style={{backgroundColor: theme.palette.secondary.main }}>
          <b>Lues-Such-Reaktion</b><br/>
          Ergebnis: {props.data["result"]}<br/>
          Datum: {props.data["date"]}<br/>
          Protokoll-Nr.: {props.data["lab_nr"]}<br/>
          {props.data["remark"]}
        </div>
      )
    }

    else if(props.data["type"] === "hepatitis_b_antigen") {
      return (
        <div className="lab-rubella-entry" style={{backgroundColor: theme.palette.secondary.main }}>
          <b>Hepatitis-B-Antigen</b><br/>
          Ergebnis: {props.data["result"]}<br/>
          Datum: {props.data["date"]}<br/>
          Protokoll-Nr.: {props.data["lab_nr"]}<br/>
          {props.data["remark"]}
        </div>
      )
    }

    else if(props.data["type"] === "generic") {
      return (
        <div className="lab-rubella-entry" style={{backgroundColor: theme.palette.secondary.main }}>
          {Object.keys(props.data).map((value, key, test) => <>{test}</>)}
        </div>
      )
    }

    else {
      return (
        <div className="lab-rubella-entry" style={{backgroundColor: theme.palette.secondary.main }}>
          <b>{props.data["name"]}</b><br/>
          Ergebnis: {props.data["result"]}<br/>
          Datum: {props.data["date"]}<br/>
          Protokoll-Nr.: {props.data["lab_nr"]}<br/>
        </div>
      )
    }
}