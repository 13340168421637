import { useEffect, useState } from "react";

import React from 'react';

import moment from "moment";
import "moment/locale/de";



import { Line } from 'react-chartjs-2';

import {  formatISODateToGermanDate } from 'helpers/Helpers';

import { getWeeklyMinAvgMaxData } from "../../GraphUtils/GraphUtils";


const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    }, 
    zoom: {
      pan: {
        enabled: true,
        mode: 'x'
      },
      limits: {
        x: {
          min: 'original',
          max: 'original'
        },
        y: {
          min: 'original',
          max: 'original'
        }
        // axis limits
      },
      zoom: {
        wheel: {
          enabled: true
        },
        pinch: {
          enabled: true
        },
        mode: 'x'
        // zoom options and/or events
      }
    },
    tooltip: {
      callbacks: {
        // Remove the "title", which usually shows a arbitrary date in an incorrect format
        title: function(context) {
          /*console.log(context[0].label);
          return (new MomentUtils())*/
          return "";
        }
      }
    }
  },  
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'week',
        displayFormats: {
          week: '[KW] W/YYYY'
        }
      },
      ticks: {
        // display: false, // Remove dates
        /*maxRotation: 0,
        autoSkipPadding: 30,
        includeBounds: true*/
      },
      grid: {
        display: false, // Remove grid
        drawBorder: false, // Remove bottom border
      }
      // type: 'timeseries'
      
    },
    y: {
      ticks: {
        autoSkipPadding: 30,
        mirror: true, // Place inside the graph
        showLabelBackdrop: true, // Add a box around the axis legend
        backdropColor: 'rgba(255, 255, 255, 0.95)', // Make the box white
        z: 1 // Make sure the box appears in front of the chart
      },
      grid: {
        drawBorder: false, // Remove left border
        borderDash: [8, 4], // Make grid dashed
        // display: false
      }
      
    }
  }
};

export default function HeartRateIntervalGraph(props) {

  const [data, setData] = useState(0);


  useEffect(() => {

    moment.locale('de');


    let {weeklyMeasurementMinimums, weeklyMeasurementAverages, weeklyMeasurementMaximums, dayKeys} = getWeeklyMinAvgMaxData(props.data);

    if(weeklyMeasurementMinimums !== undefined && 
      weeklyMeasurementAverages !== undefined && 
      weeklyMeasurementMaximums !== undefined) 
    {
      setData({
        labels: dayKeys,
        datasets: [
          {
            label: "Durchschnittlich",
            type: "line",
            data: Object.values(weeklyMeasurementAverages),
            fill: false,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
          },
          {
            label: "Maximal",
            type: "line",
            data: Object.values(weeklyMeasurementMaximums),
            backgroundColor: 'rgb(75, 192, 255, 0.5)',
            borderColor: 'transparent',
            fill: 0,
            tension: 0,
            pointRadius: 0
          },
          {
            label: "Minimal",
            type: "line",
            data: Object.values(weeklyMeasurementMinimums),
            backgroundColor: 'rgb(75, 192, 255, 0.5)',
            borderColor: 'transparent',
            fill: 0,
            tension: 0,
            pointRadius: 0
          }
        ],
      });
    }
  }, [props])


  return (
    <>
      <div className="data-plot">
        <Line data={data} options={options} />
      </div>

      <p className="graph-subtitle"><b>Durchschnittliche Wöchentliche Herzfrequenz (Schläge pro Minute).</b><br/>Blaue Bereiche: Spanne (Minimum/Maximum) der durchschnittlichen täglichen Herzfrequenz in der jeweiligen Woche.</p>
        { /* data && 
          <>
            { formatISODateToGermanDate(data.labels[0]) } bis { formatISODateToGermanDate(data.labels[data.labels.length - 1]) }
          </> */
        } 
    </>
  )
}

