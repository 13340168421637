import React from 'react';

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';


class A_DAY_0_7_30_60_120_180 extends React.Component {

  render(props) {
      return (
        <>
          <FormControlLabel value="0" control={<Radio color="primary" />} label="Keine" labelPlacement="end"/>
          <FormControlLabel value="7" control={<Radio color="primary" />} label="Weniger als 30 Minuten am Tag" labelPlacement="end"/>
          <FormControlLabel value="30" control={<Radio color="primary" />} label="Zwischen 30 Minuten und einer Stunde am Tag" labelPlacement="end"/>
          <FormControlLabel value="60" control={<Radio color="primary" />} label="1-2 Stunden am Tag" labelPlacement="end"/>
          <FormControlLabel value="120" control={<Radio color="primary" />} label="2-3 Stunden am Tag" labelPlacement="end"/>
          <FormControlLabel value="180" control={<Radio color="primary" />} label="3 oder mehr Stunden am Tag" labelPlacement="end"/>
        </>
      )
  }
}

export default A_DAY_0_7_30_60_120_180;