import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import {customIcons} from './customizedRatings'
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles({
  root: {
    minWidth: 250,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function EntryList({ list, deleteEntry }) {
  const classes = useStyles();
  const handleDeleteClick = (index, id) => (e) => {
    deleteEntry(index, id);
  };
  // const currentDate = selectedDate.setHours(0,0,0,0)

  return (
    <>
      {list &&
        list
          .map((item, i) => {
            return (
              <Card className={classes.root} variant="outlined" raised={true} key={uuidv4()}  >
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {new Date(item.datetime_added).toLocaleString()}
                  </Typography>
                  {customIcons[parseInt(item.mood)].icon}
                  <Typography variant="body2" component="p">
                    {item.message}
                  </Typography>
                </CardContent>
                <CardActions>
                  { /*<IconButton aria-label="edit" color="primary" onClick={console.log("edit")}>
                    <EditIcon />
                  </IconButton> */ }
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={handleDeleteClick(i, item.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            );
          })}
    </>
  );
}
