import React from 'react';
import { ErrorMessage } from 'formik';

import './CustomErrorMessage.scss';


class CustomErrorMessage extends React.Component {

  render(props) {
    return (
      <>
        <ErrorMessage name={this.props.name}>
          { msg => 
            <div className="color-red">{msg}</div>
          }
        </ErrorMessage>
      </>
    );
  }
}

export default CustomErrorMessage;
