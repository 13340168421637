import React from 'react';

import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogActions from '@material-ui/core/DialogActions';


class QuestionnaireSubmittedDialog extends React.Component {

  /*closeDialog() {
    this.props.setStatus({
      dialogOpen: false
    })
  }*/

  render(props) {
    return (
      <Dialog aria-labelledby="simple-dialog-title" open={this.props.dialogOpen}>
        <DialogTitle id="simple-dialog-title">Fragebogen erfolgreich abgeschickt</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vielen Dank. Der Fragebogen wurde erfolgreich abgeschickt.
          </DialogContentText>
        </DialogContent>
        <MuiDialogActions>
          <Button component={Link} to="/tasks" autoFocus color="primary">
            Zurück zu den aktuellen Aufgaben
          </Button>
        </MuiDialogActions>
      </Dialog>
    );
  }
}

export default QuestionnaireSubmittedDialog;